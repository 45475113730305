var addressList = [
    // {
    //   "areaId": 0,
    //   "id": null,
    //   "areaName": "全国",
    //   "areaCode": null,
    //   "areaShort": null,
    //   "areaInitials": null,
    //   "cityCode": null,
    //   "center": null,
    //   "areaLevel": 1,
    //   "areaParentId": null,
    //   "areaDictionaryVOList": null
    // },
    {
      "areaId": 110000,
      "id": 14,
      "areaName": "北京",
      "areaCode": "010",
      "areaShort": "Beijing",
      "areaInitials": null,
      "cityCode": "010",
      "center": "116.407394,39.904211",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 110100,
          "id": 15,
          "areaName": "北京市",
          "areaCode": "010",
          "areaShort": "Beijing",
          "areaInitials": null,
          "cityCode": "131",
          "center": "116.407394,39.904211",
          "areaLevel": 2,
          "areaParentId": 110000,
          "areaDictionaryVOList": [
            {
              "areaId": 110101,
              "id": 16,
              "areaName": "东城区",
              "areaCode": "010",
              "areaShort": "Dongcheng",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.41649,39.928341",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110102,
              "id": 17,
              "areaName": "西城区",
              "areaCode": "010",
              "areaShort": "Xicheng",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.365873,39.912235",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110105,
              "id": 18,
              "areaName": "朝阳区",
              "areaCode": "010",
              "areaShort": "Chaoyang",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.443205,39.921506",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110106,
              "id": 19,
              "areaName": "丰台区",
              "areaCode": "010",
              "areaShort": "Fengtai",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.287039,39.858421",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110107,
              "id": 20,
              "areaName": "石景山区",
              "areaCode": "010",
              "areaShort": "Shijingshan",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.222933,39.906611",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110108,
              "id": 21,
              "areaName": "海淀区",
              "areaCode": "010",
              "areaShort": "Haidian",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.298262,39.95993",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110109,
              "id": 22,
              "areaName": "门头沟区",
              "areaCode": "010",
              "areaShort": "Mentougou",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.101719,39.940338",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110111,
              "id": 23,
              "areaName": "房山区",
              "areaCode": "010",
              "areaShort": "Fangshan",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.143486,39.748823",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110112,
              "id": 24,
              "areaName": "通州区",
              "areaCode": "010",
              "areaShort": "Tongzhou",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.656434,39.909946",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110113,
              "id": 25,
              "areaName": "顺义区",
              "areaCode": "010",
              "areaShort": "Shunyi",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.654642,40.130211",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110114,
              "id": 26,
              "areaName": "昌平区",
              "areaCode": "010",
              "areaShort": "Changping",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.231254,40.220804",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110115,
              "id": 27,
              "areaName": "大兴区",
              "areaCode": "010",
              "areaShort": "Daxing",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.341483,39.726917",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110116,
              "id": 28,
              "areaName": "怀柔区",
              "areaCode": "010",
              "areaShort": "Huairou",
              "areaInitials": null,
              "cityCode": "010",
              "center": "116.631931,40.316053",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110117,
              "id": 29,
              "areaName": "平谷区",
              "areaCode": "010",
              "areaShort": "Pinggu",
              "areaInitials": null,
              "cityCode": "010",
              "center": "117.121351,40.140595",
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110228,
              "id": 30,
              "areaName": "密云县",
              "areaCode": "010",
              "areaShort": "Miyun",
              "areaInitials": null,
              "cityCode": "010",
              "center": null,
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 110229,
              "id": 31,
              "areaName": "延庆县",
              "areaCode": "010",
              "areaShort": "Yanqing",
              "areaInitials": null,
              "cityCode": "010",
              "center": null,
              "areaLevel": 3,
              "areaParentId": 110100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 120000,
      "id": 32,
      "areaName": "天津",
      "areaCode": "022",
      "areaShort": "Tianjin",
      "areaInitials": null,
      "cityCode": "022",
      "center": "117.200983,39.084158",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 120100,
          "id": 33,
          "areaName": "天津市",
          "areaCode": "022",
          "areaShort": "Tianjin",
          "areaInitials": null,
          "cityCode": "332",
          "center": "117.200983,39.084158",
          "areaLevel": 2,
          "areaParentId": 120000,
          "areaDictionaryVOList": [
            {
              "areaId": 120101,
              "id": 34,
              "areaName": "和平区",
              "areaCode": "022",
              "areaShort": "Heping",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.214699,39.117196",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120102,
              "id": 35,
              "areaName": "河东区",
              "areaCode": "022",
              "areaShort": "Hedong",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.251584,39.128294",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120103,
              "id": 36,
              "areaName": "河西区",
              "areaCode": "022",
              "areaShort": "Hexi",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.223371,39.109563",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120104,
              "id": 37,
              "areaName": "南开区",
              "areaCode": "022",
              "areaShort": "Nankai",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.150738,39.138205",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120105,
              "id": 38,
              "areaName": "河北区",
              "areaCode": "022",
              "areaShort": "Hebei",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.196648,39.147869",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120106,
              "id": 39,
              "areaName": "红桥区",
              "areaCode": "022",
              "areaShort": "Hongqiao",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.151533,39.167345",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120110,
              "id": 40,
              "areaName": "东丽区",
              "areaCode": "022",
              "areaShort": "Dongli",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.31362,39.086802",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120111,
              "id": 41,
              "areaName": "西青区",
              "areaCode": "022",
              "areaShort": "Xiqing",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.008826,39.141152",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120112,
              "id": 42,
              "areaName": "津南区",
              "areaCode": "022",
              "areaShort": "Jinnan",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.35726,38.937928",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120113,
              "id": 43,
              "areaName": "北辰区",
              "areaCode": "022",
              "areaShort": "Beichen",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.135488,39.224791",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120114,
              "id": 44,
              "areaName": "武清区",
              "areaCode": "022",
              "areaShort": "Wuqing",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.044387,39.384119",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120115,
              "id": 45,
              "areaName": "宝坻区",
              "areaCode": "022",
              "areaShort": "Baodi",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.309874,39.717564",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120116,
              "id": 46,
              "areaName": "滨海新区",
              "areaCode": "022",
              "areaShort": "Binhaixinqu",
              "areaInitials": null,
              "cityCode": "022",
              "center": "117.698407,39.01727",
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120221,
              "id": 47,
              "areaName": "宁河县",
              "areaCode": null,
              "areaShort": "Ninghe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120223,
              "id": 48,
              "areaName": "静海县",
              "areaCode": null,
              "areaShort": "Jinghai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 120225,
              "id": 49,
              "areaName": "蓟县",
              "areaCode": null,
              "areaShort": "Jixian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 120100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 130000,
      "id": 50,
      "areaName": "河北省",
      "areaCode": "[]",
      "areaShort": "Hebei",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "114.530235,38.037433",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 130100,
          "id": 51,
          "areaName": "石家庄市",
          "areaCode": "0311",
          "areaShort": "Shijiazhuang",
          "areaInitials": null,
          "cityCode": "150",
          "center": "114.514793,38.042228",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130102,
              "id": 52,
              "areaName": "长安区",
              "areaCode": "0311",
              "areaShort": "Chang'an",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.539395,38.036347",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130104,
              "id": 53,
              "areaName": "桥西区",
              "areaCode": "0311",
              "areaShort": "Qiaoxi",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.461088,38.004193",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130105,
              "id": 54,
              "areaName": "新华区",
              "areaCode": "0311",
              "areaShort": "Xinhua",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.463377,38.05095",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130107,
              "id": 55,
              "areaName": "井陉矿区",
              "areaCode": "0311",
              "areaShort": "Jingxingkuangqu",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.062062,38.065151",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130108,
              "id": 56,
              "areaName": "裕华区",
              "areaCode": "0311",
              "areaShort": "Yuhua",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.531202,38.00643",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130109,
              "id": 57,
              "areaName": "藁城区",
              "areaCode": "0311",
              "areaShort": "Gaocheng",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.847023,38.021453",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130110,
              "id": 58,
              "areaName": "鹿泉区",
              "areaCode": "0311",
              "areaShort": "Luquan",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.313654,38.085953",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130111,
              "id": 59,
              "areaName": "栾城区",
              "areaCode": "0311",
              "areaShort": "Luancheng",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.648318,37.900199",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130121,
              "id": 60,
              "areaName": "井陉县",
              "areaCode": "0311",
              "areaShort": "Jingxing",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.145242,38.032118",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130123,
              "id": 61,
              "areaName": "正定县",
              "areaCode": "0311",
              "areaShort": "Zhengding",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.570941,38.146444",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130125,
              "id": 62,
              "areaName": "行唐县",
              "areaCode": "0311",
              "areaShort": "Xingtang",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.552714,38.438377",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130126,
              "id": 63,
              "areaName": "灵寿县",
              "areaCode": "0311",
              "areaShort": "Lingshou",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.382614,38.308665",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130127,
              "id": 64,
              "areaName": "高邑县",
              "areaCode": "0311",
              "areaShort": "Gaoyi",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.611121,37.615534",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130128,
              "id": 65,
              "areaName": "深泽县",
              "areaCode": "0311",
              "areaShort": "Shenze",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "115.20092,38.184033",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130129,
              "id": 66,
              "areaName": "赞皇县",
              "areaCode": "0311",
              "areaShort": "Zanhuang",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.386111,37.665663",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130130,
              "id": 67,
              "areaName": "无极县",
              "areaCode": "0311",
              "areaShort": "Wuji",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.97634,38.179192",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130131,
              "id": 68,
              "areaName": "平山县",
              "areaCode": "0311",
              "areaShort": "Pingshan",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.195918,38.247888",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130132,
              "id": 69,
              "areaName": "元氏县",
              "areaCode": "0311",
              "areaShort": "Yuanshi",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.525409,37.766513",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130133,
              "id": 70,
              "areaName": "赵县",
              "areaCode": "0311",
              "areaShort": "Zhaoxian",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.776297,37.756578",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130181,
              "id": 71,
              "areaName": "辛集市",
              "areaCode": "0311",
              "areaShort": "Xinji",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "115.217658,37.943121",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130183,
              "id": 72,
              "areaName": "晋州市",
              "areaCode": "0311",
              "areaShort": "Jinzhou",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "115.044213,38.033671",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130184,
              "id": 73,
              "areaName": "新乐市",
              "areaCode": "0311",
              "areaShort": "Xinle",
              "areaInitials": null,
              "cityCode": "0311",
              "center": "114.683776,38.343319",
              "areaLevel": 3,
              "areaParentId": 130100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130200,
          "id": 74,
          "areaName": "唐山市",
          "areaCode": "0315",
          "areaShort": "Tangshan",
          "areaInitials": null,
          "cityCode": "265",
          "center": "118.180193,39.630867",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130202,
              "id": 75,
              "areaName": "路南区",
              "areaCode": "0315",
              "areaShort": "Lunan",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.154354,39.625058",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130203,
              "id": 76,
              "areaName": "路北区",
              "areaCode": "0315",
              "areaShort": "Lubei",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.200692,39.624437",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130204,
              "id": 77,
              "areaName": "古冶区",
              "areaCode": "0315",
              "areaShort": "Guye",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.447635,39.733578",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130205,
              "id": 78,
              "areaName": "开平区",
              "areaCode": "0315",
              "areaShort": "Kaiping",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.261841,39.671001",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130207,
              "id": 79,
              "areaName": "丰南区",
              "areaCode": "0315",
              "areaShort": "Fengnan",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.085169,39.576031",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130208,
              "id": 80,
              "areaName": "丰润区",
              "areaCode": "0315",
              "areaShort": "Fengrun",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.162215,39.832582",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130209,
              "id": 81,
              "areaName": "曹妃甸区",
              "areaCode": "0315",
              "areaShort": "Caofeidian",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.460379,39.27307",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130223,
              "id": 82,
              "areaName": "滦县",
              "areaCode": "0315",
              "areaShort": "Luanxian",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.703598,39.740593",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130224,
              "id": 83,
              "areaName": "滦南县",
              "areaCode": "0315",
              "areaShort": "Luannan",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.682379,39.518996",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130225,
              "id": 84,
              "areaName": "乐亭县",
              "areaCode": "0315",
              "areaShort": "Laoting",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.912571,39.425608",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130227,
              "id": 85,
              "areaName": "迁西县",
              "areaCode": "0315",
              "areaShort": "Qianxi",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.314715,40.1415",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130229,
              "id": 86,
              "areaName": "玉田县",
              "areaCode": "0315",
              "areaShort": "Yutian",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "117.738658,39.900401",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130281,
              "id": 87,
              "areaName": "遵化市",
              "areaCode": "0315",
              "areaShort": "Zunhua",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "117.965892,40.189201",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130283,
              "id": 88,
              "areaName": "迁安市",
              "areaCode": "0315",
              "areaShort": "Qian'an",
              "areaInitials": null,
              "cityCode": "0315",
              "center": "118.701144,39.999174",
              "areaLevel": 3,
              "areaParentId": 130200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130300,
          "id": 89,
          "areaName": "秦皇岛市",
          "areaCode": "0335",
          "areaShort": "Qinhuangdao",
          "areaInitials": null,
          "cityCode": "148",
          "center": "119.518197,39.888701",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130302,
              "id": 90,
              "areaName": "海港区",
              "areaCode": "0335",
              "areaShort": "Haigang",
              "areaInitials": null,
              "cityCode": "0335",
              "center": "119.564962,39.94756",
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130303,
              "id": 91,
              "areaName": "山海关区",
              "areaCode": "0335",
              "areaShort": "Shanhaiguan",
              "areaInitials": null,
              "cityCode": "0335",
              "center": "119.775799,39.978848",
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130304,
              "id": 92,
              "areaName": "北戴河区",
              "areaCode": "0335",
              "areaShort": "Beidaihe",
              "areaInitials": null,
              "cityCode": "0335",
              "center": "119.484522,39.834596",
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130321,
              "id": 93,
              "areaName": "青龙满族自治县",
              "areaCode": "0335",
              "areaShort": "Qinglong",
              "areaInitials": null,
              "cityCode": "0335",
              "center": "118.949684,40.407578",
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130322,
              "id": 94,
              "areaName": "昌黎县",
              "areaCode": "0335",
              "areaShort": "Changli",
              "areaInitials": null,
              "cityCode": "0335",
              "center": "119.199551,39.700911",
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130323,
              "id": 95,
              "areaName": "抚宁县",
              "areaCode": null,
              "areaShort": "Funing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130324,
              "id": 96,
              "areaName": "卢龙县",
              "areaCode": "0335",
              "areaShort": "Lulong",
              "areaInitials": null,
              "cityCode": "0335",
              "center": "118.892986,39.891946",
              "areaLevel": 3,
              "areaParentId": 130300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130400,
          "id": 97,
          "areaName": "邯郸市",
          "areaCode": "0310",
          "areaShort": "Handan",
          "areaInitials": null,
          "cityCode": "151",
          "center": "114.538959,36.625594",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130402,
              "id": 98,
              "areaName": "邯山区",
              "areaCode": "0310",
              "areaShort": "Hanshan",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.531002,36.594313",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130403,
              "id": 99,
              "areaName": "丛台区",
              "areaCode": "0310",
              "areaShort": "Congtai",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.492896,36.636409",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130404,
              "id": 100,
              "areaName": "复兴区",
              "areaCode": "0310",
              "areaShort": "Fuxing",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.462061,36.639033",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130406,
              "id": 101,
              "areaName": "峰峰矿区",
              "areaCode": "0310",
              "areaShort": "Fengfengkuangqu",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.212802,36.419739",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130421,
              "id": 102,
              "areaName": "邯郸县",
              "areaCode": null,
              "areaShort": "Handan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130423,
              "id": 103,
              "areaName": "临漳县",
              "areaCode": "0310",
              "areaShort": "Linzhang",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.619536,36.335025",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130424,
              "id": 104,
              "areaName": "成安县",
              "areaCode": "0310",
              "areaShort": "Cheng'an",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.670032,36.444317",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130425,
              "id": 105,
              "areaName": "大名县",
              "areaCode": "0310",
              "areaShort": "Daming",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "115.147814,36.285616",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130426,
              "id": 106,
              "areaName": "涉县",
              "areaCode": "0310",
              "areaShort": "Shexian",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "113.6914,36.584994",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130427,
              "id": 107,
              "areaName": "磁县",
              "areaCode": "0310",
              "areaShort": "Cixian",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.373946,36.374011",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130428,
              "id": 108,
              "areaName": "肥乡县",
              "areaCode": null,
              "areaShort": "Feixiang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130429,
              "id": 109,
              "areaName": "永年县",
              "areaCode": null,
              "areaShort": "Yongnian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130430,
              "id": 110,
              "areaName": "邱县",
              "areaCode": "0310",
              "areaShort": "Qiuxian",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "115.200589,36.811148",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130431,
              "id": 111,
              "areaName": "鸡泽县",
              "areaCode": "0310",
              "areaShort": "Jize",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.889376,36.91034",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130432,
              "id": 112,
              "areaName": "广平县",
              "areaCode": "0310",
              "areaShort": "Guangping",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.948606,36.483484",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130433,
              "id": 113,
              "areaName": "馆陶县",
              "areaCode": "0310",
              "areaShort": "Guantao",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "115.282467,36.547556",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130434,
              "id": 114,
              "areaName": "魏县",
              "areaCode": "0310",
              "areaShort": "Weixian",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.93892,36.359868",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130435,
              "id": 115,
              "areaName": "曲周县",
              "areaCode": "0310",
              "areaShort": "Quzhou",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.957504,36.76607",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130481,
              "id": 116,
              "areaName": "武安市",
              "areaCode": "0310",
              "areaShort": "Wu'an",
              "areaInitials": null,
              "cityCode": "0310",
              "center": "114.203697,36.696506",
              "areaLevel": 3,
              "areaParentId": 130400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130500,
          "id": 117,
          "areaName": "邢台市",
          "areaCode": "0319",
          "areaShort": "Xingtai",
          "areaInitials": null,
          "cityCode": "266",
          "center": "114.504677,37.070834",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130502,
              "id": 118,
              "areaName": "桥东区",
              "areaCode": "0319",
              "areaShort": "Qiaodong",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.507058,37.071287",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130503,
              "id": 119,
              "areaName": "桥西区",
              "areaCode": "0319",
              "areaShort": "Qiaoxi",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.468601,37.059827",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130521,
              "id": 120,
              "areaName": "邢台县",
              "areaCode": "0319",
              "areaShort": "Xingtai",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.561132,37.05073",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130522,
              "id": 121,
              "areaName": "临城县",
              "areaCode": "0319",
              "areaShort": "Lincheng",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.498761,37.444498",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130523,
              "id": 122,
              "areaName": "内丘县",
              "areaCode": "0319",
              "areaShort": "Neiqiu",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.512128,37.286669",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130524,
              "id": 123,
              "areaName": "柏乡县",
              "areaCode": "0319",
              "areaShort": "Baixiang",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.693425,37.482422",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130525,
              "id": 124,
              "areaName": "隆尧县",
              "areaCode": "0319",
              "areaShort": "Longyao",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.770419,37.350172",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130526,
              "id": 125,
              "areaName": "任县",
              "areaCode": "0319",
              "areaShort": "Renxian",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.671936,37.120982",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130527,
              "id": 126,
              "areaName": "南和县",
              "areaCode": "0319",
              "areaShort": "Nanhe",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.683863,37.005017",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130528,
              "id": 127,
              "areaName": "宁晋县",
              "areaCode": "0319",
              "areaShort": "Ningjin",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.93992,37.624564",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130529,
              "id": 128,
              "areaName": "巨鹿县",
              "areaCode": "0319",
              "areaShort": "Julu",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.037477,37.221112",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130530,
              "id": 129,
              "areaName": "新河县",
              "areaCode": "0319",
              "areaShort": "Xinhe",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.250907,37.520862",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130531,
              "id": 130,
              "areaName": "广宗县",
              "areaCode": "0319",
              "areaShort": "Guangzong",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.142626,37.074661",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130532,
              "id": 131,
              "areaName": "平乡县",
              "areaCode": "0319",
              "areaShort": "Pingxiang",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.030075,37.063148",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130533,
              "id": 132,
              "areaName": "威县",
              "areaCode": "0319",
              "areaShort": "Weixian",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.266703,36.975478",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130534,
              "id": 133,
              "areaName": "清河县",
              "areaCode": "0319",
              "areaShort": "Qinghe",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.667208,37.039991",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130535,
              "id": 134,
              "areaName": "临西县",
              "areaCode": "0319",
              "areaShort": "Linxi",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.501048,36.870811",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130581,
              "id": 135,
              "areaName": "南宫市",
              "areaCode": "0319",
              "areaShort": "Nangong",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "115.408747,37.359264",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130582,
              "id": 136,
              "areaName": "沙河市",
              "areaCode": "0319",
              "areaShort": "Shahe",
              "areaInitials": null,
              "cityCode": "0319",
              "center": "114.503339,36.854929",
              "areaLevel": 3,
              "areaParentId": 130500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130600,
          "id": 137,
          "areaName": "保定市",
          "areaCode": "0312",
          "areaShort": "Baoding",
          "areaInitials": null,
          "cityCode": "307",
          "center": "115.464589,38.874434",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130602,
              "id": 138,
              "areaName": "新市区",
              "areaCode": "0312",
              "areaShort": "Xinshi",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.45877,38.877449",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130603,
              "id": 139,
              "areaName": "北市区",
              "areaCode": null,
              "areaShort": "Beishi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130604,
              "id": 140,
              "areaName": "南市区",
              "areaCode": null,
              "areaShort": "Nanshi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130621,
              "id": 141,
              "areaName": "满城县",
              "areaCode": null,
              "areaShort": "Mancheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130622,
              "id": 142,
              "areaName": "清苑县",
              "areaCode": null,
              "areaShort": "Qingyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130623,
              "id": 143,
              "areaName": "涞水县",
              "areaCode": "0312",
              "areaShort": "Laishui",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.713904,39.394316",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130624,
              "id": 144,
              "areaName": "阜平县",
              "areaCode": "0312",
              "areaShort": "Fuping",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "114.195104,38.849152",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130625,
              "id": 145,
              "areaName": "徐水县",
              "areaCode": null,
              "areaShort": "Xushui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130626,
              "id": 146,
              "areaName": "定兴县",
              "areaCode": "0312",
              "areaShort": "Dingxing",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.808296,39.263145",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130627,
              "id": 147,
              "areaName": "唐县",
              "areaCode": "0312",
              "areaShort": "Tangxian",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "114.982972,38.748203",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130628,
              "id": 148,
              "areaName": "高阳县",
              "areaCode": "0312",
              "areaShort": "Gaoyang",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.778965,38.700088",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130629,
              "id": 149,
              "areaName": "容城县",
              "areaCode": "0312",
              "areaShort": "Rongcheng",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.861657,39.042784",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130630,
              "id": 150,
              "areaName": "涞源县",
              "areaCode": "0312",
              "areaShort": "Laiyuan",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "114.694283,39.360247",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130631,
              "id": 151,
              "areaName": "望都县",
              "areaCode": "0312",
              "areaShort": "Wangdu",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.155128,38.695842",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130632,
              "id": 152,
              "areaName": "安新县",
              "areaCode": "0312",
              "areaShort": "Anxin",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.935603,38.935369",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130633,
              "id": 153,
              "areaName": "易县",
              "areaCode": "0312",
              "areaShort": "Yixian",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.497457,39.349393",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130634,
              "id": 154,
              "areaName": "曲阳县",
              "areaCode": "0312",
              "areaShort": "Quyang",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "114.745008,38.622248",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130635,
              "id": 155,
              "areaName": "蠡县",
              "areaCode": "0312",
              "areaShort": "Lixian",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.583854,38.488055",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130636,
              "id": 156,
              "areaName": "顺平县",
              "areaCode": "0312",
              "areaShort": "Shunping",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.13547,38.837487",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130637,
              "id": 157,
              "areaName": "博野县",
              "areaCode": "0312",
              "areaShort": "Boye",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.46438,38.457364",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130638,
              "id": 158,
              "areaName": "雄县",
              "areaCode": "0312",
              "areaShort": "Xiongxian",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "116.10865,38.99455",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130681,
              "id": 159,
              "areaName": "涿州市",
              "areaCode": "0312",
              "areaShort": "Zhuozhou",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.974422,39.485282",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130682,
              "id": 160,
              "areaName": "定州市",
              "areaCode": "0312",
              "areaShort": "Dingzhou",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "114.990392,38.516461",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130683,
              "id": 161,
              "areaName": "安国市",
              "areaCode": "0312",
              "areaShort": "Anguo",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.326646,38.418439",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130684,
              "id": 162,
              "areaName": "高碑店市",
              "areaCode": "0312",
              "areaShort": "Gaobeidian",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.873886,39.326839",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130685,
              "id": 3763,
              "areaName": "高开区",
              "areaCode": "0312",
              "areaShort": "Gaokaiqu",
              "areaInitials": null,
              "cityCode": "0312",
              "center": "115.472423,38.898786",
              "areaLevel": 3,
              "areaParentId": 130600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130700,
          "id": 163,
          "areaName": "张家口市",
          "areaCode": "0313",
          "areaShort": "Zhangjiakou",
          "areaInitials": null,
          "cityCode": "264",
          "center": "114.886252,40.768493",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130702,
              "id": 164,
              "areaName": "桥东区",
              "areaCode": "0313",
              "areaShort": "Qiaodong",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.894189,40.788434",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130703,
              "id": 165,
              "areaName": "桥西区",
              "areaCode": "0313",
              "areaShort": "Qiaoxi",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.869657,40.819581",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130705,
              "id": 166,
              "areaName": "宣化区",
              "areaCode": "0313",
              "areaShort": "Xuanhua",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "115.099494,40.608793",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130706,
              "id": 167,
              "areaName": "下花园区",
              "areaCode": "0313",
              "areaShort": "Xiahuayuan",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "115.287352,40.502652",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130721,
              "id": 168,
              "areaName": "宣化县",
              "areaCode": null,
              "areaShort": "Xuanhua",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130722,
              "id": 169,
              "areaName": "张北县",
              "areaCode": "0313",
              "areaShort": "Zhangbei",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.720077,41.158596",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130723,
              "id": 170,
              "areaName": "康保县",
              "areaCode": "0313",
              "areaShort": "Kangbao",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.600404,41.852368",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130724,
              "id": 171,
              "areaName": "沽源县",
              "areaCode": "0313",
              "areaShort": "Guyuan",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "115.688692,41.669668",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130725,
              "id": 172,
              "areaName": "尚义县",
              "areaCode": "0313",
              "areaShort": "Shangyi",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "113.969618,41.076226",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130726,
              "id": 173,
              "areaName": "蔚县",
              "areaCode": "0313",
              "areaShort": "Yuxian",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.588903,39.840842",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130727,
              "id": 174,
              "areaName": "阳原县",
              "areaCode": "0313",
              "areaShort": "Yangyuan",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.150348,40.104663",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130728,
              "id": 175,
              "areaName": "怀安县",
              "areaCode": "0313",
              "areaShort": "Huai'an",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "114.385791,40.674193",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130729,
              "id": 176,
              "areaName": "万全县",
              "areaCode": null,
              "areaShort": "Wanquan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130730,
              "id": 177,
              "areaName": "怀来县",
              "areaCode": "0313",
              "areaShort": "Huailai",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "115.517861,40.415343",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130731,
              "id": 178,
              "areaName": "涿鹿县",
              "areaCode": "0313",
              "areaShort": "Zhuolu",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "115.205345,40.379562",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130732,
              "id": 179,
              "areaName": "赤城县",
              "areaCode": "0313",
              "areaShort": "Chicheng",
              "areaInitials": null,
              "cityCode": "0313",
              "center": "115.831498,40.912921",
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130733,
              "id": 180,
              "areaName": "崇礼县",
              "areaCode": null,
              "areaShort": "Chongli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130800,
          "id": 181,
          "areaName": "承德市",
          "areaCode": "0314",
          "areaShort": "Chengde",
          "areaInitials": null,
          "cityCode": "207",
          "center": "117.962749,40.952942",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130802,
              "id": 182,
              "areaName": "双桥区",
              "areaCode": "0314",
              "areaShort": "Shuangqiao",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.943466,40.974643",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130803,
              "id": 183,
              "areaName": "双滦区",
              "areaCode": "0314",
              "areaShort": "Shuangluan",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.799888,40.959236",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130804,
              "id": 184,
              "areaName": "鹰手营子矿区",
              "areaCode": "0314",
              "areaShort": "Yingshouyingzikuangqu",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.659499,40.546361",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130821,
              "id": 185,
              "areaName": "承德县",
              "areaCode": "0314",
              "areaShort": "Chengde",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "118.173824,40.768238",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130822,
              "id": 186,
              "areaName": "兴隆县",
              "areaCode": "0314",
              "areaShort": "Xinglong",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.500558,40.417358",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130823,
              "id": 187,
              "areaName": "平泉县",
              "areaCode": null,
              "areaShort": "Pingquan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130824,
              "id": 188,
              "areaName": "滦平县",
              "areaCode": "0314",
              "areaShort": "Luanping",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.332801,40.941482",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130825,
              "id": 189,
              "areaName": "隆化县",
              "areaCode": "0314",
              "areaShort": "Longhua",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.738937,41.313791",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130826,
              "id": 190,
              "areaName": "丰宁满族自治县",
              "areaCode": "0314",
              "areaShort": "Fengning",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "116.646051,41.209069",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130827,
              "id": 191,
              "areaName": "宽城满族自治县",
              "areaCode": "0314",
              "areaShort": "Kuancheng",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "118.485313,40.611391",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130828,
              "id": 192,
              "areaName": "围场满族蒙古族自治县",
              "areaCode": "0314",
              "areaShort": "Weichang",
              "areaInitials": null,
              "cityCode": "0314",
              "center": "117.760159,41.938529",
              "areaLevel": 3,
              "areaParentId": 130800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 130900,
          "id": 193,
          "areaName": "沧州市",
          "areaCode": "0317",
          "areaShort": "Cangzhou",
          "areaInitials": null,
          "cityCode": "149",
          "center": "116.838834,38.304477",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 130902,
              "id": 194,
              "areaName": "新华区",
              "areaCode": "0317",
              "areaShort": "Xinhua",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.866284,38.314416",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130903,
              "id": 195,
              "areaName": "运河区",
              "areaCode": "0317",
              "areaShort": "Yunhe",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.843673,38.283749",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130921,
              "id": 196,
              "areaName": "沧县",
              "areaCode": "0317",
              "areaShort": "Cangxian",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "117.007478,38.219856",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130922,
              "id": 197,
              "areaName": "青县",
              "areaCode": "0317",
              "areaShort": "Qingxian",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.804305,38.583021",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130923,
              "id": 198,
              "areaName": "东光县",
              "areaCode": "0317",
              "areaShort": "Dongguang",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.537067,37.888248",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130924,
              "id": 199,
              "areaName": "海兴县",
              "areaCode": "0317",
              "areaShort": "Haixing",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "117.497651,38.143169",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130925,
              "id": 200,
              "areaName": "盐山县",
              "areaCode": "0317",
              "areaShort": "Yanshan",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "117.230602,38.058087",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130926,
              "id": 201,
              "areaName": "肃宁县",
              "areaCode": "0317",
              "areaShort": "Suning",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "115.829758,38.422801",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130927,
              "id": 202,
              "areaName": "南皮县",
              "areaCode": "0317",
              "areaShort": "Nanpi",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.708347,38.038421",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130928,
              "id": 203,
              "areaName": "吴桥县",
              "areaCode": "0317",
              "areaShort": "Wuqiao",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.391508,37.627661",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130929,
              "id": 204,
              "areaName": "献县",
              "areaCode": "0317",
              "areaShort": "Xianxian",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.122725,38.190185",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130930,
              "id": 205,
              "areaName": "孟村回族自治县",
              "areaCode": "0317",
              "areaShort": "Mengcun",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "117.104298,38.053409",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130981,
              "id": 206,
              "areaName": "泊头市",
              "areaCode": "0317",
              "areaShort": "Botou",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.578367,38.083437",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130982,
              "id": 207,
              "areaName": "任丘市",
              "areaCode": "0317",
              "areaShort": "Renqiu",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.082917,38.683591",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130983,
              "id": 208,
              "areaName": "黄骅市",
              "areaCode": "0317",
              "areaShort": "Huanghua",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "117.329949,38.371402",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 130984,
              "id": 209,
              "areaName": "河间市",
              "areaCode": "0317",
              "areaShort": "Hejian",
              "areaInitials": null,
              "cityCode": "0317",
              "center": "116.099517,38.446624",
              "areaLevel": 3,
              "areaParentId": 130900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 131000,
          "id": 210,
          "areaName": "廊坊市",
          "areaCode": "0316",
          "areaShort": "Langfang",
          "areaInitials": null,
          "cityCode": "191",
          "center": "116.683752,39.538047",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 131002,
              "id": 211,
              "areaName": "安次区",
              "areaCode": "0316",
              "areaShort": "Anci",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.694544,39.502569",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131003,
              "id": 212,
              "areaName": "广阳区",
              "areaCode": "0316",
              "areaShort": "Guangyang",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.71069,39.522786",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131022,
              "id": 213,
              "areaName": "固安县",
              "areaCode": "0316",
              "areaShort": "Gu'an",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.298657,39.438214",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131023,
              "id": 214,
              "areaName": "永清县",
              "areaCode": "0316",
              "areaShort": "Yongqing",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.50568,39.330689",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131024,
              "id": 215,
              "areaName": "香河县",
              "areaCode": "0316",
              "areaShort": "Xianghe",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "117.006093,39.761424",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131025,
              "id": 216,
              "areaName": "大城县",
              "areaCode": "0316",
              "areaShort": "Daicheng",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.653793,38.705449",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131026,
              "id": 217,
              "areaName": "文安县",
              "areaCode": "0316",
              "areaShort": "Wen'an",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.457898,38.87292",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131028,
              "id": 218,
              "areaName": "大厂回族自治县",
              "areaCode": "0316",
              "areaShort": "Dachang",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.989574,39.886547",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131081,
              "id": 219,
              "areaName": "霸州市",
              "areaCode": "0316",
              "areaShort": "Bazhou",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "116.391484,39.125744",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131082,
              "id": 220,
              "areaName": "三河市",
              "areaCode": "0316",
              "areaShort": "Sanhe",
              "areaInitials": null,
              "cityCode": "0316",
              "center": "117.078294,39.982718",
              "areaLevel": 3,
              "areaParentId": 131000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 131100,
          "id": 221,
          "areaName": "衡水市",
          "areaCode": "0318",
          "areaShort": "Hengshui",
          "areaInitials": null,
          "cityCode": "208",
          "center": "115.670177,37.73892",
          "areaLevel": 2,
          "areaParentId": 130000,
          "areaDictionaryVOList": [
            {
              "areaId": 131102,
              "id": 222,
              "areaName": "桃城区",
              "areaCode": "0318",
              "areaShort": "Taocheng",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.67545,37.735465",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131121,
              "id": 223,
              "areaName": "枣强县",
              "areaCode": "0318",
              "areaShort": "Zaoqiang",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.724259,37.513417",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131122,
              "id": 224,
              "areaName": "武邑县",
              "areaCode": "0318",
              "areaShort": "Wuyi",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.887531,37.801665",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131123,
              "id": 225,
              "areaName": "武强县",
              "areaCode": "0318",
              "areaShort": "Wuqiang",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.982461,38.041368",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131124,
              "id": 226,
              "areaName": "饶阳县",
              "areaCode": "0318",
              "areaShort": "Raoyang",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.725833,38.235892",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131125,
              "id": 227,
              "areaName": "安平县",
              "areaCode": "0318",
              "areaShort": "Anping",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.519278,38.234501",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131126,
              "id": 228,
              "areaName": "故城县",
              "areaCode": "0318",
              "areaShort": "Gucheng",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.965874,37.347409",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131127,
              "id": 229,
              "areaName": "景县",
              "areaCode": "0318",
              "areaShort": "Jingxian",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "116.270648,37.69229",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131128,
              "id": 230,
              "areaName": "阜城县",
              "areaCode": "0318",
              "areaShort": "Fucheng",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "116.175262,37.862505",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131181,
              "id": 231,
              "areaName": "冀州市",
              "areaCode": null,
              "areaShort": "Jizhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 131182,
              "id": 232,
              "areaName": "深州市",
              "areaCode": "0318",
              "areaShort": "Shenzhou",
              "areaInitials": null,
              "cityCode": "0318",
              "center": "115.559574,38.001535",
              "areaLevel": 3,
              "areaParentId": 131100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 140000,
      "id": 233,
      "areaName": "山西省",
      "areaCode": "[]",
      "areaShort": "Shanxi",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "112.562678,37.873499",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 140100,
          "id": 234,
          "areaName": "太原市",
          "areaCode": "0351",
          "areaShort": "Taiyuan",
          "areaInitials": null,
          "cityCode": "176",
          "center": "112.548879,37.87059",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140105,
              "id": 235,
              "areaName": "小店区",
              "areaCode": "0351",
              "areaShort": "Xiaodian",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.565659,37.736525",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140106,
              "id": 236,
              "areaName": "迎泽区",
              "areaCode": "0351",
              "areaShort": "Yingze",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.5634,37.863451",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140107,
              "id": 237,
              "areaName": "杏花岭区",
              "areaCode": "0351",
              "areaShort": "Xinghualing",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.570604,37.893955",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140108,
              "id": 238,
              "areaName": "尖草坪区",
              "areaCode": "0351",
              "areaShort": "Jiancaoping",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.486691,37.940387",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140109,
              "id": 239,
              "areaName": "万柏林区",
              "areaCode": "0351",
              "areaShort": "Wanbailin",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.515937,37.85958",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140110,
              "id": 240,
              "areaName": "晋源区",
              "areaCode": "0351",
              "areaShort": "Jinyuan",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.47794,37.715193",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140121,
              "id": 241,
              "areaName": "清徐县",
              "areaCode": "0351",
              "areaShort": "Qingxu",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.358667,37.607443",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140122,
              "id": 242,
              "areaName": "阳曲县",
              "areaCode": "0351",
              "areaShort": "Yangqu",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.672952,38.058488",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140123,
              "id": 243,
              "areaName": "娄烦县",
              "areaCode": "0351",
              "areaShort": "Loufan",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "111.797083,38.067932",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140181,
              "id": 244,
              "areaName": "古交市",
              "areaCode": "0351",
              "areaShort": "Gujiao",
              "areaInitials": null,
              "cityCode": "0351",
              "center": "112.175853,37.907129",
              "areaLevel": 3,
              "areaParentId": 140100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140200,
          "id": 245,
          "areaName": "大同市",
          "areaCode": "0352",
          "areaShort": "Datong",
          "areaInitials": null,
          "cityCode": "355",
          "center": "113.300129,40.076763",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140202,
              "id": 246,
              "areaName": "城区",
              "areaCode": "0352",
              "areaShort": "Chengqu",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.298026,40.075666",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140203,
              "id": 247,
              "areaName": "矿区",
              "areaCode": "0352",
              "areaShort": "Kuangqu",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.177206,40.036858",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140211,
              "id": 248,
              "areaName": "南郊区",
              "areaCode": "0352",
              "areaShort": "Nanjiao",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.149693,40.005404",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140212,
              "id": 249,
              "areaName": "新荣区",
              "areaCode": "0352",
              "areaShort": "Xinrong",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.140004,40.255866",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140221,
              "id": 250,
              "areaName": "阳高县",
              "areaCode": "0352",
              "areaShort": "Yanggao",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.748944,40.361059",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140222,
              "id": 251,
              "areaName": "天镇县",
              "areaCode": "0352",
              "areaShort": "Tianzhen",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "114.090867,40.420237",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140223,
              "id": 252,
              "areaName": "广灵县",
              "areaCode": "0352",
              "areaShort": "Guangling",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "114.282758,39.760281",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140224,
              "id": 253,
              "areaName": "灵丘县",
              "areaCode": "0352",
              "areaShort": "Lingqiu",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "114.23435,39.442406",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140225,
              "id": 254,
              "areaName": "浑源县",
              "areaCode": "0352",
              "areaShort": "Hunyuan",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.699475,39.693406",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140226,
              "id": 255,
              "areaName": "左云县",
              "areaCode": "0352",
              "areaShort": "Zuoyun",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "112.703008,40.013442",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140227,
              "id": 256,
              "areaName": "大同县",
              "areaCode": "0352",
              "areaShort": "Datong",
              "areaInitials": null,
              "cityCode": "0352",
              "center": "113.61244,40.040294",
              "areaLevel": 3,
              "areaParentId": 140200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140300,
          "id": 257,
          "areaName": "阳泉市",
          "areaCode": "0353",
          "areaShort": "Yangquan",
          "areaInitials": null,
          "cityCode": "357",
          "center": "113.580519,37.856971",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140302,
              "id": 258,
              "areaName": "城区",
              "areaCode": "0353",
              "areaShort": "Chengqu",
              "areaInitials": null,
              "cityCode": "0353",
              "center": "113.600669,37.847436",
              "areaLevel": 3,
              "areaParentId": 140300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140303,
              "id": 259,
              "areaName": "矿区",
              "areaCode": "0353",
              "areaShort": "Kuangqu",
              "areaInitials": null,
              "cityCode": "0353",
              "center": "113.555279,37.868494",
              "areaLevel": 3,
              "areaParentId": 140300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140311,
              "id": 260,
              "areaName": "郊区",
              "areaCode": "0353",
              "areaShort": "Jiaoqu",
              "areaInitials": null,
              "cityCode": "0353",
              "center": "113.594163,37.944679",
              "areaLevel": 3,
              "areaParentId": 140300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140321,
              "id": 261,
              "areaName": "平定县",
              "areaCode": "0353",
              "areaShort": "Pingding",
              "areaInitials": null,
              "cityCode": "0353",
              "center": "113.630107,37.804988",
              "areaLevel": 3,
              "areaParentId": 140300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140322,
              "id": 262,
              "areaName": "盂县",
              "areaCode": "0353",
              "areaShort": "Yuxian",
              "areaInitials": null,
              "cityCode": "0353",
              "center": "113.41233,38.085619",
              "areaLevel": 3,
              "areaParentId": 140300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140400,
          "id": 263,
          "areaName": "长治市",
          "areaCode": "0355",
          "areaShort": "Changzhi",
          "areaInitials": null,
          "cityCode": "356",
          "center": "113.116404,36.195409",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140402,
              "id": 264,
              "areaName": "城区",
              "areaCode": "0355",
              "areaShort": "Chengqu",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.123088,36.20353",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140411,
              "id": 265,
              "areaName": "郊区",
              "areaCode": "0355",
              "areaShort": "Jiaoqu",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.101211,36.218388",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140421,
              "id": 266,
              "areaName": "长治县",
              "areaCode": "0355",
              "areaShort": "Changzhi",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.051407,36.052858",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140423,
              "id": 267,
              "areaName": "襄垣县",
              "areaCode": "0355",
              "areaShort": "Xiangyuan",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.051491,36.535817",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140424,
              "id": 268,
              "areaName": "屯留县",
              "areaCode": "0355",
              "areaShort": "Tunliu",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "112.891998,36.315663",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140425,
              "id": 269,
              "areaName": "平顺县",
              "areaCode": "0355",
              "areaShort": "Pingshun",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.435961,36.200179",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140426,
              "id": 270,
              "areaName": "黎城县",
              "areaCode": "0355",
              "areaShort": "Licheng",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.387155,36.502328",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140427,
              "id": 271,
              "areaName": "壶关县",
              "areaCode": "0355",
              "areaShort": "Huguan",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.207049,36.115448",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140428,
              "id": 272,
              "areaName": "长子县",
              "areaCode": "0355",
              "areaShort": "Zhangzi",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "112.8779,36.122334",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140429,
              "id": 273,
              "areaName": "武乡县",
              "areaCode": "0355",
              "areaShort": "Wuxiang",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "112.864561,36.837625",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140430,
              "id": 274,
              "areaName": "沁县",
              "areaCode": "0355",
              "areaShort": "Qinxian",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "112.699226,36.756063",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140431,
              "id": 275,
              "areaName": "沁源县",
              "areaCode": "0355",
              "areaShort": "Qinyuan",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "112.337446,36.5002",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140481,
              "id": 276,
              "areaName": "潞城市",
              "areaCode": "0355",
              "areaShort": "Lucheng",
              "areaInitials": null,
              "cityCode": "0355",
              "center": "113.228852,36.334104",
              "areaLevel": 3,
              "areaParentId": 140400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140500,
          "id": 277,
          "areaName": "晋城市",
          "areaCode": "0356",
          "areaShort": "Jincheng",
          "areaInitials": null,
          "cityCode": "290",
          "center": "112.851486,35.490684",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140502,
              "id": 278,
              "areaName": "城区",
              "areaCode": "0356",
              "areaShort": "Chengqu",
              "areaInitials": null,
              "cityCode": "0356",
              "center": "112.853555,35.501571",
              "areaLevel": 3,
              "areaParentId": 140500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140521,
              "id": 279,
              "areaName": "沁水县",
              "areaCode": "0356",
              "areaShort": "Qinshui",
              "areaInitials": null,
              "cityCode": "0356",
              "center": "112.186738,35.690141",
              "areaLevel": 3,
              "areaParentId": 140500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140522,
              "id": 280,
              "areaName": "阳城县",
              "areaCode": "0356",
              "areaShort": "Yangcheng",
              "areaInitials": null,
              "cityCode": "0356",
              "center": "112.414738,35.486029",
              "areaLevel": 3,
              "areaParentId": 140500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140524,
              "id": 281,
              "areaName": "陵川县",
              "areaCode": "0356",
              "areaShort": "Lingchuan",
              "areaInitials": null,
              "cityCode": "0356",
              "center": "113.280688,35.775685",
              "areaLevel": 3,
              "areaParentId": 140500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140525,
              "id": 282,
              "areaName": "泽州县",
              "areaCode": "0356",
              "areaShort": "Zezhou",
              "areaInitials": null,
              "cityCode": "0356",
              "center": "112.899137,35.617221",
              "areaLevel": 3,
              "areaParentId": 140500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140581,
              "id": 283,
              "areaName": "高平市",
              "areaCode": "0356",
              "areaShort": "Gaoping",
              "areaInitials": null,
              "cityCode": "0356",
              "center": "112.92392,35.797997",
              "areaLevel": 3,
              "areaParentId": 140500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140600,
          "id": 284,
          "areaName": "朔州市",
          "areaCode": "0349",
          "areaShort": "Shuozhou",
          "areaInitials": null,
          "cityCode": "237",
          "center": "112.432991,39.331855",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140602,
              "id": 285,
              "areaName": "朔城区",
              "areaCode": "0349",
              "areaShort": "Shuocheng",
              "areaInitials": null,
              "cityCode": "0349",
              "center": "112.432312,39.319519",
              "areaLevel": 3,
              "areaParentId": 140600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140603,
              "id": 286,
              "areaName": "平鲁区",
              "areaCode": "0349",
              "areaShort": "Pinglu",
              "areaInitials": null,
              "cityCode": "0349",
              "center": "112.28833,39.512155",
              "areaLevel": 3,
              "areaParentId": 140600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140621,
              "id": 287,
              "areaName": "山阴县",
              "areaCode": "0349",
              "areaShort": "Shanyin",
              "areaInitials": null,
              "cityCode": "0349",
              "center": "112.816413,39.527893",
              "areaLevel": 3,
              "areaParentId": 140600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140622,
              "id": 288,
              "areaName": "应县",
              "areaCode": "0349",
              "areaShort": "Yingxian",
              "areaInitials": null,
              "cityCode": "0349",
              "center": "113.191098,39.554247",
              "areaLevel": 3,
              "areaParentId": 140600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140623,
              "id": 289,
              "areaName": "右玉县",
              "areaCode": "0349",
              "areaShort": "Youyu",
              "areaInitials": null,
              "cityCode": "0349",
              "center": "112.466989,39.989063",
              "areaLevel": 3,
              "areaParentId": 140600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140624,
              "id": 290,
              "areaName": "怀仁县",
              "areaCode": "0349",
              "areaShort": "Huairen",
              "areaInitials": null,
              "cityCode": "0349",
              "center": "113.131717,39.821627",
              "areaLevel": 3,
              "areaParentId": 140600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140700,
          "id": 291,
          "areaName": "晋中市",
          "areaCode": "0354",
          "areaShort": "Jinzhong",
          "areaInitials": null,
          "cityCode": "238",
          "center": "112.752652,37.687357",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140702,
              "id": 292,
              "areaName": "榆次区",
              "areaCode": "0354",
              "areaShort": "Yuci",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "112.708224,37.697794",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140721,
              "id": 293,
              "areaName": "榆社县",
              "areaCode": "0354",
              "areaShort": "Yushe",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "112.975209,37.070916",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140722,
              "id": 294,
              "areaName": "左权县",
              "areaCode": "0354",
              "areaShort": "Zuoquan",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "113.379403,37.082943",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140723,
              "id": 295,
              "areaName": "和顺县",
              "areaCode": "0354",
              "areaShort": "Heshun",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "113.570415,37.32957",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140724,
              "id": 296,
              "areaName": "昔阳县",
              "areaCode": "0354",
              "areaShort": "Xiyang",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "113.706977,37.61253",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140725,
              "id": 297,
              "areaName": "寿阳县",
              "areaCode": "0354",
              "areaShort": "Shouyang",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "113.176373,37.895191",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140726,
              "id": 298,
              "areaName": "太谷县",
              "areaCode": "0354",
              "areaShort": "Taigu",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "112.551305,37.421307",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140727,
              "id": 299,
              "areaName": "祁县",
              "areaCode": "0354",
              "areaShort": "Qixian",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "112.335542,37.357869",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140728,
              "id": 300,
              "areaName": "平遥县",
              "areaCode": "0354",
              "areaShort": "Pingyao",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "112.176136,37.189421",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140729,
              "id": 301,
              "areaName": "灵石县",
              "areaCode": "0354",
              "areaShort": "Lingshi",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "111.77864,36.847927",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140781,
              "id": 302,
              "areaName": "介休市",
              "areaCode": "0354",
              "areaShort": "Jiexiu",
              "areaInitials": null,
              "cityCode": "0354",
              "center": "111.916711,37.026944",
              "areaLevel": 3,
              "areaParentId": 140700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140800,
          "id": 303,
          "areaName": "运城市",
          "areaCode": "0359",
          "areaShort": "Yuncheng",
          "areaInitials": null,
          "cityCode": "328",
          "center": "111.00746,35.026516",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140802,
              "id": 304,
              "areaName": "盐湖区",
              "areaCode": "0359",
              "areaShort": "Yanhu",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.998272,35.015101",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140821,
              "id": 305,
              "areaName": "临猗县",
              "areaCode": "0359",
              "areaShort": "Linyi",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.774547,35.144277",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140822,
              "id": 306,
              "areaName": "万荣县",
              "areaCode": "0359",
              "areaShort": "Wanrong",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.838024,35.415253",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140823,
              "id": 307,
              "areaName": "闻喜县",
              "areaCode": "0359",
              "areaShort": "Wenxi",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "111.22472,35.356644",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140824,
              "id": 308,
              "areaName": "稷山县",
              "areaCode": "0359",
              "areaShort": "Jishan",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.983333,35.604025",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140825,
              "id": 309,
              "areaName": "新绛县",
              "areaCode": "0359",
              "areaShort": "Xinjiang",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "111.224734,35.616251",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140826,
              "id": 310,
              "areaName": "绛县",
              "areaCode": "0359",
              "areaShort": "Jiangxian",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "111.568236,35.49119",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140827,
              "id": 311,
              "areaName": "垣曲县",
              "areaCode": "0359",
              "areaShort": "Yuanqu",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "111.670108,35.297369",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140828,
              "id": 312,
              "areaName": "夏县",
              "areaCode": "0359",
              "areaShort": "Xiaxian",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "111.220456,35.141363",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140829,
              "id": 313,
              "areaName": "平陆县",
              "areaCode": "0359",
              "areaShort": "Pinglu",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "111.194133,34.82926",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140830,
              "id": 314,
              "areaName": "芮城县",
              "areaCode": "0359",
              "areaShort": "Ruicheng",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.694369,34.693579",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140881,
              "id": 315,
              "areaName": "永济市",
              "areaCode": "0359",
              "areaShort": "Yongji",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.447543,34.8671",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140882,
              "id": 316,
              "areaName": "河津市",
              "areaCode": "0359",
              "areaShort": "Hejin",
              "areaInitials": null,
              "cityCode": "0359",
              "center": "110.712063,35.596383",
              "areaLevel": 3,
              "areaParentId": 140800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 140900,
          "id": 317,
          "areaName": "忻州市",
          "areaCode": "0350",
          "areaShort": "Xinzhou",
          "areaInitials": null,
          "cityCode": "367",
          "center": "112.734174,38.416663",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 140902,
              "id": 318,
              "areaName": "忻府区",
              "areaCode": "0350",
              "areaShort": "Xinfu",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "112.746046,38.404242",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140921,
              "id": 319,
              "areaName": "定襄县",
              "areaCode": "0350",
              "areaShort": "Dingxiang",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "112.957237,38.473506",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140922,
              "id": 320,
              "areaName": "五台县",
              "areaCode": "0350",
              "areaShort": "Wutai",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "113.255309,38.728315",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140923,
              "id": 321,
              "areaName": "代县",
              "areaCode": "0350",
              "areaShort": "Daixian",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "112.960282,39.066917",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140924,
              "id": 322,
              "areaName": "繁峙县",
              "areaCode": "0350",
              "areaShort": "Fanshi",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "113.265563,39.188811",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140925,
              "id": 323,
              "areaName": "宁武县",
              "areaCode": "0350",
              "areaShort": "Ningwu",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "112.304722,39.001524",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140926,
              "id": 324,
              "areaName": "静乐县",
              "areaCode": "0350",
              "areaShort": "Jingle",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "111.939498,38.359306",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140927,
              "id": 325,
              "areaName": "神池县",
              "areaCode": "0350",
              "areaShort": "Shenchi",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "112.211296,39.090552",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140928,
              "id": 326,
              "areaName": "五寨县",
              "areaCode": "0350",
              "areaShort": "Wuzhai",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "111.846904,38.910726",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140929,
              "id": 327,
              "areaName": "岢岚县",
              "areaCode": "0350",
              "areaShort": "Kelan",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "111.57285,38.70418",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140930,
              "id": 328,
              "areaName": "河曲县",
              "areaCode": "0350",
              "areaShort": "Hequ",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "111.138472,39.384482",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140931,
              "id": 329,
              "areaName": "保德县",
              "areaCode": "0350",
              "areaShort": "Baode",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "111.086564,39.022487",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140932,
              "id": 330,
              "areaName": "偏关县",
              "areaCode": "0350",
              "areaShort": "Pianguan",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "111.508831,39.436306",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 140981,
              "id": 331,
              "areaName": "原平市",
              "areaCode": "0350",
              "areaShort": "Yuanping",
              "areaInitials": null,
              "cityCode": "0350",
              "center": "112.711058,38.731402",
              "areaLevel": 3,
              "areaParentId": 140900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 141000,
          "id": 332,
          "areaName": "临汾市",
          "areaCode": "0357",
          "areaShort": "Linfen",
          "areaInitials": null,
          "cityCode": "368",
          "center": "111.518975,36.088005",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 141002,
              "id": 333,
              "areaName": "尧都区",
              "areaCode": "0357",
              "areaShort": "Yaodu",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.579554,36.07884",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141021,
              "id": 334,
              "areaName": "曲沃县",
              "areaCode": "0357",
              "areaShort": "Quwo",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.47586,35.641086",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141022,
              "id": 335,
              "areaName": "翼城县",
              "areaCode": "0357",
              "areaShort": "Yicheng",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.718951,35.738576",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141023,
              "id": 336,
              "areaName": "襄汾县",
              "areaCode": "0357",
              "areaShort": "Xiangfen",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.441725,35.876293",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141024,
              "id": 337,
              "areaName": "洪洞县",
              "areaCode": "0357",
              "areaShort": "Hongtong",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.674965,36.253747",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141025,
              "id": 338,
              "areaName": "古县",
              "areaCode": "0357",
              "areaShort": "Guxian",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.920465,36.266914",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141026,
              "id": 339,
              "areaName": "安泽县",
              "areaCode": "0357",
              "areaShort": "Anze",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "112.250144,36.147787",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141027,
              "id": 340,
              "areaName": "浮山县",
              "areaCode": "0357",
              "areaShort": "Fushan",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.848883,35.968124",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141028,
              "id": 341,
              "areaName": "吉县",
              "areaCode": "0357",
              "areaShort": "Jixian",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "110.681763,36.098188",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141029,
              "id": 342,
              "areaName": "乡宁县",
              "areaCode": "0357",
              "areaShort": "Xiangning",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "110.847021,35.970389",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141030,
              "id": 343,
              "areaName": "大宁县",
              "areaCode": "0357",
              "areaShort": "Daning",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "110.75291,36.465102",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141031,
              "id": 344,
              "areaName": "隰县",
              "areaCode": "0357",
              "areaShort": "Xixian",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "110.940637,36.69333",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141032,
              "id": 345,
              "areaName": "永和县",
              "areaCode": "0357",
              "areaShort": "Yonghe",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "110.632006,36.759507",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141033,
              "id": 346,
              "areaName": "蒲县",
              "areaCode": "0357",
              "areaShort": "Puxian",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.096439,36.411826",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141034,
              "id": 347,
              "areaName": "汾西县",
              "areaCode": "0357",
              "areaShort": "Fenxi",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.56395,36.652854",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141081,
              "id": 348,
              "areaName": "侯马市",
              "areaCode": "0357",
              "areaShort": "Houma",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.372002,35.619105",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141082,
              "id": 349,
              "areaName": "霍州市",
              "areaCode": "0357",
              "areaShort": "Huozhou",
              "areaInitials": null,
              "cityCode": "0357",
              "center": "111.755398,36.56893",
              "areaLevel": 3,
              "areaParentId": 141000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 141100,
          "id": 350,
          "areaName": "吕梁市",
          "areaCode": "0358",
          "areaShort": "Lvliang",
          "areaInitials": null,
          "cityCode": "327",
          "center": "111.144699,37.519126",
          "areaLevel": 2,
          "areaParentId": 140000,
          "areaDictionaryVOList": [
            {
              "areaId": 141102,
              "id": 351,
              "areaName": "离石区",
              "areaCode": "0358",
              "areaShort": "Lishi",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.150695,37.51786",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141121,
              "id": 352,
              "areaName": "文水县",
              "areaCode": "0358",
              "areaShort": "Wenshui",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "112.028866,37.438101",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141122,
              "id": 353,
              "areaName": "交城县",
              "areaCode": "0358",
              "areaShort": "Jiaocheng",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "112.156064,37.551963",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141123,
              "id": 354,
              "areaName": "兴县",
              "areaCode": "0358",
              "areaShort": "Xingxian",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.127667,38.462389",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141124,
              "id": 355,
              "areaName": "临县",
              "areaCode": "0358",
              "areaShort": "Linxian",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "110.992093,37.950758",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141125,
              "id": 356,
              "areaName": "柳林县",
              "areaCode": "0358",
              "areaShort": "Liulin",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "110.889007,37.429772",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141126,
              "id": 357,
              "areaName": "石楼县",
              "areaCode": "0358",
              "areaShort": "Shilou",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "110.834634,36.99857",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141127,
              "id": 358,
              "areaName": "岚县",
              "areaCode": "0358",
              "areaShort": "Lanxian",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.671917,38.279299",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141128,
              "id": 359,
              "areaName": "方山县",
              "areaCode": "0358",
              "areaShort": "Fangshan",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.244098,37.894631",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141129,
              "id": 360,
              "areaName": "中阳县",
              "areaCode": "0358",
              "areaShort": "Zhongyang",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.179657,37.357058",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141130,
              "id": 361,
              "areaName": "交口县",
              "areaCode": "0358",
              "areaShort": "Jiaokou",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.181151,36.982186",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141181,
              "id": 362,
              "areaName": "孝义市",
              "areaCode": "0358",
              "areaShort": "Xiaoyi",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.778818,37.146294",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 141182,
              "id": 363,
              "areaName": "汾阳市",
              "areaCode": "0358",
              "areaShort": "Fenyang",
              "areaInitials": null,
              "cityCode": "0358",
              "center": "111.770477,37.261756",
              "areaLevel": 3,
              "areaParentId": 141100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 150000,
      "id": 364,
      "areaName": "内蒙古自治区",
      "areaCode": "[]",
      "areaShort": "Neimenggu",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "111.76629,40.81739",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 150100,
          "id": 365,
          "areaName": "呼和浩特市",
          "areaCode": "0471",
          "areaShort": "Hohhot",
          "areaInitials": null,
          "cityCode": "321",
          "center": "111.749995,40.842356",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150102,
              "id": 366,
              "areaName": "新城区",
              "areaCode": "0471",
              "areaShort": "Xincheng",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.665544,40.858289",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150103,
              "id": 367,
              "areaName": "回民区",
              "areaCode": "0471",
              "areaShort": "Huimin",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.623692,40.808608",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150104,
              "id": 368,
              "areaName": "玉泉区",
              "areaCode": "0471",
              "areaShort": "Yuquan",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.673881,40.753655",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150105,
              "id": 369,
              "areaName": "赛罕区",
              "areaCode": "0471",
              "areaShort": "Saihan",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.701355,40.792667",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150121,
              "id": 370,
              "areaName": "土默特左旗",
              "areaCode": "0471",
              "areaShort": "Tumotezuoqi",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.163902,40.729572",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150122,
              "id": 371,
              "areaName": "托克托县",
              "areaCode": "0471",
              "areaShort": "Tuoketuo",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.194312,40.277431",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150123,
              "id": 372,
              "areaName": "和林格尔县",
              "areaCode": "0471",
              "areaShort": "Helingeer",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.821843,40.378787",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150124,
              "id": 373,
              "areaName": "清水河县",
              "areaCode": "0471",
              "areaShort": "Qingshuihe",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.647609,39.921095",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150125,
              "id": 374,
              "areaName": "武川县",
              "areaCode": "0471",
              "areaShort": "Wuchuan",
              "areaInitials": null,
              "cityCode": "0471",
              "center": "111.451303,41.096471",
              "areaLevel": 3,
              "areaParentId": 150100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150200,
          "id": 375,
          "areaName": "包头市",
          "areaCode": "0472",
          "areaShort": "Baotou",
          "areaInitials": null,
          "cityCode": "229",
          "center": "109.953504,40.621157",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150202,
              "id": 376,
              "areaName": "东河区",
              "areaCode": "0472",
              "areaShort": "Donghe",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "110.044106,40.576319",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150203,
              "id": 377,
              "areaName": "昆都仑区",
              "areaCode": "0472",
              "areaShort": "Kundulun",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "109.837707,40.642578",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150204,
              "id": 378,
              "areaName": "青山区",
              "areaCode": "0472",
              "areaShort": "Qingshan",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "109.901572,40.643246",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150205,
              "id": 379,
              "areaName": "石拐区",
              "areaCode": "0472",
              "areaShort": "Shiguai",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "110.060254,40.681748",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150206,
              "id": 380,
              "areaName": "白云鄂博矿区",
              "areaCode": "0472",
              "areaShort": "Baiyunebokuangqu",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "109.973803,41.769511",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150207,
              "id": 381,
              "areaName": "九原区",
              "areaCode": "0472",
              "areaShort": "Jiuyuan",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "109.967449,40.610561",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150221,
              "id": 382,
              "areaName": "土默特右旗",
              "areaCode": "0472",
              "areaShort": "Tumoteyouqi",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "110.524262,40.569426",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150222,
              "id": 383,
              "areaName": "固阳县",
              "areaCode": "0472",
              "areaShort": "Guyang",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "110.060514,41.034105",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150223,
              "id": 384,
              "areaName": "达尔罕茂明安联合旗",
              "areaCode": "0472",
              "areaShort": "Damaoqi",
              "areaInitials": null,
              "cityCode": "0472",
              "center": "110.432626,41.698992",
              "areaLevel": 3,
              "areaParentId": 150200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150300,
          "id": 385,
          "areaName": "乌海市",
          "areaCode": "0473",
          "areaShort": "Wuhai",
          "areaInitials": null,
          "cityCode": "123",
          "center": "106.794216,39.655248",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150302,
              "id": 386,
              "areaName": "海勃湾区",
              "areaCode": "0473",
              "areaShort": "Haibowan",
              "areaInitials": null,
              "cityCode": "0473",
              "center": "106.822778,39.691156",
              "areaLevel": 3,
              "areaParentId": 150300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150303,
              "id": 387,
              "areaName": "海南区",
              "areaCode": "0473",
              "areaShort": "Hainan",
              "areaInitials": null,
              "cityCode": "0473",
              "center": "106.891424,39.441364",
              "areaLevel": 3,
              "areaParentId": 150300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150304,
              "id": 388,
              "areaName": "乌达区",
              "areaCode": "0473",
              "areaShort": "Wuda",
              "areaInitials": null,
              "cityCode": "0473",
              "center": "106.726099,39.505925",
              "areaLevel": 3,
              "areaParentId": 150300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150400,
          "id": 389,
          "areaName": "赤峰市",
          "areaCode": "0476",
          "areaShort": "Chifeng",
          "areaInitials": null,
          "cityCode": "297",
          "center": "118.88694,42.257843",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150402,
              "id": 390,
              "areaName": "红山区",
              "areaCode": "0476",
              "areaShort": "Hongshan",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "118.953854,42.296588",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150403,
              "id": 391,
              "areaName": "元宝山区",
              "areaCode": "0476",
              "areaShort": "Yuanbaoshan",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "119.288611,42.038902",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150404,
              "id": 392,
              "areaName": "松山区",
              "areaCode": "0476",
              "areaShort": "Songshan",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "118.916208,42.299798",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150421,
              "id": 393,
              "areaName": "阿鲁科尔沁旗",
              "areaCode": "0476",
              "areaShort": "Alukeerqinqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "120.0657,43.872298",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150422,
              "id": 394,
              "areaName": "巴林左旗",
              "areaCode": "0476",
              "areaShort": "Balinzuoqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "119.362931,43.960889",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150423,
              "id": 395,
              "areaName": "巴林右旗",
              "areaCode": "0476",
              "areaShort": "Balinyouqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "118.66518,43.534414",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150424,
              "id": 396,
              "areaName": "林西县",
              "areaCode": "0476",
              "areaShort": "Linxi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "118.05545,43.61812",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150425,
              "id": 397,
              "areaName": "克什克腾旗",
              "areaCode": "0476",
              "areaShort": "Keshiketengqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "117.545797,43.264988",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150426,
              "id": 398,
              "areaName": "翁牛特旗",
              "areaCode": "0476",
              "areaShort": "Wengniuteqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "119.00658,42.936188",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150428,
              "id": 399,
              "areaName": "喀喇沁旗",
              "areaCode": "0476",
              "areaShort": "Kalaqinqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "118.701937,41.927363",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150429,
              "id": 400,
              "areaName": "宁城县",
              "areaCode": "0476",
              "areaShort": "Ningcheng",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "119.318876,41.601375",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150430,
              "id": 401,
              "areaName": "敖汉旗",
              "areaCode": "0476",
              "areaShort": "Aohanqi",
              "areaInitials": null,
              "cityCode": "0476",
              "center": "119.921603,42.290781",
              "areaLevel": 3,
              "areaParentId": 150400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150500,
          "id": 402,
          "areaName": "通辽市",
          "areaCode": "0475",
          "areaShort": "Tongliao",
          "areaInitials": null,
          "cityCode": "64",
          "center": "122.243444,43.652889",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150502,
              "id": 403,
              "areaName": "科尔沁区",
              "areaCode": "0475",
              "areaShort": "Keerqin",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "122.255671,43.623078",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150521,
              "id": 404,
              "areaName": "科尔沁左翼中旗",
              "areaCode": "0475",
              "areaShort": "Keerqinzuoyizhongqi",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "123.312264,44.126625",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150522,
              "id": 405,
              "areaName": "科尔沁左翼后旗",
              "areaCode": "0475",
              "areaShort": "Keerqinzuoyihouqi",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "122.35677,42.935105",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150523,
              "id": 406,
              "areaName": "开鲁县",
              "areaCode": "0475",
              "areaShort": "Kailu",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "121.319308,43.601244",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150524,
              "id": 407,
              "areaName": "库伦旗",
              "areaCode": "0475",
              "areaShort": "Kulunqi",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "121.8107,42.735656",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150525,
              "id": 408,
              "areaName": "奈曼旗",
              "areaCode": "0475",
              "areaShort": "Naimanqi",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "120.658282,42.867226",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150526,
              "id": 409,
              "areaName": "扎鲁特旗",
              "areaCode": "0475",
              "areaShort": "Zhaluteqi",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "120.911676,44.556389",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150581,
              "id": 410,
              "areaName": "霍林郭勒市",
              "areaCode": "0475",
              "areaShort": "Huolinguole",
              "areaInitials": null,
              "cityCode": "0475",
              "center": "119.68187,45.533962",
              "areaLevel": 3,
              "areaParentId": 150500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150600,
          "id": 411,
          "areaName": "鄂尔多斯市",
          "areaCode": "0477",
          "areaShort": "Ordos",
          "areaInitials": null,
          "cityCode": "283",
          "center": "109.781327,39.608266",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150602,
              "id": 412,
              "areaName": "东胜区",
              "areaCode": "0477",
              "areaShort": "Dongsheng",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "109.963333,39.822593",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150621,
              "id": 413,
              "areaName": "达拉特旗",
              "areaCode": "0477",
              "areaShort": "Dalateqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "110.033833,40.412438",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150622,
              "id": 414,
              "areaName": "准格尔旗",
              "areaCode": "0477",
              "areaShort": "Zhungeerqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "111.240171,39.864361",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150623,
              "id": 415,
              "areaName": "鄂托克前旗",
              "areaCode": "0477",
              "areaShort": "Etuokeqianqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "107.477514,38.182362",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150624,
              "id": 416,
              "areaName": "鄂托克旗",
              "areaCode": "0477",
              "areaShort": "Etuokeqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "107.97616,39.08965",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150625,
              "id": 417,
              "areaName": "杭锦旗",
              "areaCode": "0477",
              "areaShort": "Hangjinqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "108.736208,39.833309",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150626,
              "id": 418,
              "areaName": "乌审旗",
              "areaCode": "0477",
              "areaShort": "Wushenqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "108.817607,38.604136",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150627,
              "id": 419,
              "areaName": "伊金霍洛旗",
              "areaCode": "0477",
              "areaShort": "Yijinhuoluoqi",
              "areaInitials": null,
              "cityCode": "0477",
              "center": "109.74774,39.564659",
              "areaLevel": 3,
              "areaParentId": 150600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150700,
          "id": 420,
          "areaName": "呼伦贝尔市",
          "areaCode": "0470",
          "areaShort": "Hulunber",
          "areaInitials": null,
          "cityCode": "61",
          "center": "119.765558,49.211576",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150702,
              "id": 421,
              "areaName": "海拉尔区",
              "areaCode": "0470",
              "areaShort": "Hailaer",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "119.736176,49.212188",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150703,
              "id": 422,
              "areaName": "扎赉诺尔区",
              "areaCode": "0470",
              "areaShort": "Zhalainuoer",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "117.670248,49.510375",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150721,
              "id": 423,
              "areaName": "阿荣旗",
              "areaCode": "0470",
              "areaShort": "Arongqi",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "123.459049,48.126584",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150722,
              "id": 424,
              "areaName": "莫力达瓦达斡尔族自治旗",
              "areaCode": "0470",
              "areaShort": "Moqi",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "124.519023,48.477728",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150723,
              "id": 425,
              "areaName": "鄂伦春自治旗",
              "areaCode": "0470",
              "areaShort": "Elunchun",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "123.726201,50.591842",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150724,
              "id": 426,
              "areaName": "鄂温克族自治旗",
              "areaCode": "0470",
              "areaShort": "Ewen",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "119.755239,49.146592",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150725,
              "id": 427,
              "areaName": "陈巴尔虎旗",
              "areaCode": "0470",
              "areaShort": "Chenbaerhuqi",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "119.424026,49.328916",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150726,
              "id": 428,
              "areaName": "新巴尔虎左旗",
              "areaCode": "0470",
              "areaShort": "Xinbaerhuzuoqi",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "118.269819,48.218241",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150727,
              "id": 429,
              "areaName": "新巴尔虎右旗",
              "areaCode": "0470",
              "areaShort": "Xinbaerhuyouqi",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "116.82369,48.672101",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150781,
              "id": 430,
              "areaName": "满洲里市",
              "areaCode": "0470",
              "areaShort": "Manzhouli",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "117.378529,49.597841",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150782,
              "id": 431,
              "areaName": "牙克石市",
              "areaCode": "0470",
              "areaShort": "Yakeshi",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "120.711775,49.285629",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150783,
              "id": 432,
              "areaName": "扎兰屯市",
              "areaCode": "0470",
              "areaShort": "Zhalantun",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "122.737467,48.013733",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150784,
              "id": 433,
              "areaName": "额尔古纳市",
              "areaCode": "0470",
              "areaShort": "Eerguna",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "120.180506,50.243102",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150785,
              "id": 434,
              "areaName": "根河市",
              "areaCode": "0470",
              "areaShort": "Genhe",
              "areaInitials": null,
              "cityCode": "0470",
              "center": "121.520388,50.780344",
              "areaLevel": 3,
              "areaParentId": 150700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150800,
          "id": 435,
          "areaName": "巴彦淖尔市",
          "areaCode": "0478",
          "areaShort": "Bayan Nur",
          "areaInitials": null,
          "cityCode": "169",
          "center": "107.387657,40.743213",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150802,
              "id": 436,
              "areaName": "临河区",
              "areaCode": "0478",
              "areaShort": "Linhe",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "107.363918,40.751187",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150821,
              "id": 437,
              "areaName": "五原县",
              "areaCode": "0478",
              "areaShort": "Wuyuan",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "108.267561,41.088421",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150822,
              "id": 438,
              "areaName": "磴口县",
              "areaCode": "0478",
              "areaShort": "Dengkou",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "107.008248,40.330523",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150823,
              "id": 439,
              "areaName": "乌拉特前旗",
              "areaCode": "0478",
              "areaShort": "Wulateqianqi",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "108.652114,40.737018",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150824,
              "id": 440,
              "areaName": "乌拉特中旗",
              "areaCode": "0478",
              "areaShort": "Wulatezhongqi",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "108.513645,41.587732",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150825,
              "id": 441,
              "areaName": "乌拉特后旗",
              "areaCode": "0478",
              "areaShort": "Wulatehouqi",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "107.074621,41.084282",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150826,
              "id": 442,
              "areaName": "杭锦后旗",
              "areaCode": "0478",
              "areaShort": "Hangjinhouqi",
              "areaInitials": null,
              "cityCode": "0478",
              "center": "107.151245,40.88602",
              "areaLevel": 3,
              "areaParentId": 150800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 150900,
          "id": 443,
          "areaName": "乌兰察布市",
          "areaCode": "0474",
          "areaShort": "Ulanqab",
          "areaInitials": null,
          "cityCode": "168",
          "center": "113.132584,40.994785",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 150902,
              "id": 444,
              "areaName": "集宁区",
              "areaCode": "0474",
              "areaShort": "Jining",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "113.116453,41.034134",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150921,
              "id": 445,
              "areaName": "卓资县",
              "areaCode": "0474",
              "areaShort": "Zhuozi",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "112.577528,40.894691",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150922,
              "id": 446,
              "areaName": "化德县",
              "areaCode": "0474",
              "areaShort": "Huade",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "114.010437,41.90456",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150923,
              "id": 447,
              "areaName": "商都县",
              "areaCode": "0474",
              "areaShort": "Shangdu",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "113.577816,41.562113",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150924,
              "id": 448,
              "areaName": "兴和县",
              "areaCode": "0474",
              "areaShort": "Xinghe",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "113.834173,40.872301",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150925,
              "id": 449,
              "areaName": "凉城县",
              "areaCode": "0474",
              "areaShort": "Liangcheng",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "112.503971,40.531555",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150926,
              "id": 450,
              "areaName": "察哈尔右翼前旗",
              "areaCode": "0474",
              "areaShort": "Chayouqianqi",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "113.214733,40.785631",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150927,
              "id": 451,
              "areaName": "察哈尔右翼中旗",
              "areaCode": "0474",
              "areaShort": "Chayouzhongqi",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "112.635577,41.277462",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150928,
              "id": 452,
              "areaName": "察哈尔右翼后旗",
              "areaCode": "0474",
              "areaShort": "Chayouhouqi",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "113.191035,41.436069",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150929,
              "id": 453,
              "areaName": "四子王旗",
              "areaCode": "0474",
              "areaShort": "Siziwangqi",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "111.706617,41.533462",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 150981,
              "id": 454,
              "areaName": "丰镇市",
              "areaCode": "0474",
              "areaShort": "Fengzhen",
              "areaInitials": null,
              "cityCode": "0474",
              "center": "113.109892,40.436983",
              "areaLevel": 3,
              "areaParentId": 150900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 152200,
          "id": 455,
          "areaName": "兴安盟",
          "areaCode": "0482",
          "areaShort": "Hinggan",
          "areaInitials": null,
          "cityCode": "62",
          "center": "122.037657,46.082462",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 152201,
              "id": 456,
              "areaName": "乌兰浩特市",
              "areaCode": "0482",
              "areaShort": "Wulanhaote",
              "areaInitials": null,
              "cityCode": "0482",
              "center": "122.093123,46.072731",
              "areaLevel": 3,
              "areaParentId": 152200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152202,
              "id": 457,
              "areaName": "阿尔山市",
              "areaCode": "0482",
              "areaShort": "Aershan",
              "areaInitials": null,
              "cityCode": "0482",
              "center": "119.943575,47.17744",
              "areaLevel": 3,
              "areaParentId": 152200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152221,
              "id": 458,
              "areaName": "科尔沁右翼前旗",
              "areaCode": "0482",
              "areaShort": "Keyouqianqi",
              "areaInitials": null,
              "cityCode": "0482",
              "center": "121.952621,46.079833",
              "areaLevel": 3,
              "areaParentId": 152200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152222,
              "id": 459,
              "areaName": "科尔沁右翼中旗",
              "areaCode": "0482",
              "areaShort": "Keyouzhongqi",
              "areaInitials": null,
              "cityCode": "0482",
              "center": "121.47653,45.060837",
              "areaLevel": 3,
              "areaParentId": 152200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152223,
              "id": 460,
              "areaName": "扎赉特旗",
              "areaCode": "0482",
              "areaShort": "Zhalaiteqi",
              "areaInitials": null,
              "cityCode": "0482",
              "center": "122.899656,46.723237",
              "areaLevel": 3,
              "areaParentId": 152200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152224,
              "id": 461,
              "areaName": "突泉县",
              "areaCode": "0482",
              "areaShort": "Tuquan",
              "areaInitials": null,
              "cityCode": "0482",
              "center": "121.593799,45.38193",
              "areaLevel": 3,
              "areaParentId": 152200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 152500,
          "id": 462,
          "areaName": "锡林郭勒盟",
          "areaCode": "0479",
          "areaShort": "Xilin Gol",
          "areaInitials": null,
          "cityCode": "63",
          "center": "116.048222,43.933454",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 152501,
              "id": 463,
              "areaName": "二连浩特市",
              "areaCode": "0479",
              "areaShort": "Erlianhaote",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "111.951002,43.6437",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152502,
              "id": 464,
              "areaName": "锡林浩特市",
              "areaCode": "0479",
              "areaShort": "Xilinhaote",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "116.086029,43.933403",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152522,
              "id": 465,
              "areaName": "阿巴嘎旗",
              "areaCode": "0479",
              "areaShort": "Abagaqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "114.950248,44.022995",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152523,
              "id": 466,
              "areaName": "苏尼特左旗",
              "areaCode": "0479",
              "areaShort": "Sunitezuoqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "113.667248,43.85988",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152524,
              "id": 467,
              "areaName": "苏尼特右旗",
              "areaCode": "0479",
              "areaShort": "Suniteyouqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "112.641783,42.742892",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152525,
              "id": 468,
              "areaName": "东乌珠穆沁旗",
              "areaCode": "0479",
              "areaShort": "Dongwuqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "116.974494,45.498221",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152526,
              "id": 469,
              "areaName": "西乌珠穆沁旗",
              "areaCode": "0479",
              "areaShort": "Xiwuqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "117.608911,44.587882",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152527,
              "id": 470,
              "areaName": "太仆寺旗",
              "areaCode": "0479",
              "areaShort": "Taipusiqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "115.282986,41.877135",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152528,
              "id": 471,
              "areaName": "镶黄旗",
              "areaCode": "0479",
              "areaShort": "Xianghuangqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "113.847287,42.232371",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152529,
              "id": 472,
              "areaName": "正镶白旗",
              "areaCode": "0479",
              "areaShort": "Zhengxiangbaiqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "115.029848,42.28747",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152530,
              "id": 473,
              "areaName": "正蓝旗",
              "areaCode": "0479",
              "areaShort": "Zhenglanqi",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "115.99247,42.241638",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152531,
              "id": 474,
              "areaName": "多伦县",
              "areaCode": "0479",
              "areaShort": "Duolun",
              "areaInitials": null,
              "cityCode": "0479",
              "center": "116.485555,42.203591",
              "areaLevel": 3,
              "areaParentId": 152500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 152900,
          "id": 475,
          "areaName": "阿拉善盟",
          "areaCode": "0483",
          "areaShort": "Alxa",
          "areaInitials": null,
          "cityCode": "230",
          "center": "105.728957,38.851921",
          "areaLevel": 2,
          "areaParentId": 150000,
          "areaDictionaryVOList": [
            {
              "areaId": 152921,
              "id": 476,
              "areaName": "阿拉善左旗",
              "areaCode": "0483",
              "areaShort": "Alashanzuoqi",
              "areaInitials": null,
              "cityCode": "0483",
              "center": "105.666275,38.833389",
              "areaLevel": 3,
              "areaParentId": 152900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152922,
              "id": 477,
              "areaName": "阿拉善右旗",
              "areaCode": "0483",
              "areaShort": "Alashanyouqi",
              "areaInitials": null,
              "cityCode": "0483",
              "center": "101.666917,39.216185",
              "areaLevel": 3,
              "areaParentId": 152900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 152923,
              "id": 478,
              "areaName": "额济纳旗",
              "areaCode": "0483",
              "areaShort": "Ejinaqi",
              "areaInitials": null,
              "cityCode": "0483",
              "center": "101.055731,41.95455",
              "areaLevel": 3,
              "areaParentId": 152900,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 210000,
      "id": 479,
      "areaName": "辽宁省",
      "areaCode": "[]",
      "areaShort": "Liaoning",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "123.431382,41.836175",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 210100,
          "id": 480,
          "areaName": "沈阳市",
          "areaCode": "024",
          "areaShort": "Shenyang",
          "areaInitials": null,
          "cityCode": "58",
          "center": "123.465035,41.677284",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210102,
              "id": 481,
              "areaName": "和平区",
              "areaCode": "024",
              "areaShort": "Heping",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.420368,41.789833",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210103,
              "id": 482,
              "areaName": "沈河区",
              "areaCode": "024",
              "areaShort": "Shenhe",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.458691,41.796177",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210104,
              "id": 483,
              "areaName": "大东区",
              "areaCode": "024",
              "areaShort": "Dadong",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.469948,41.805137",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210105,
              "id": 484,
              "areaName": "皇姑区",
              "areaCode": "024",
              "areaShort": "Huanggu",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.442378,41.824516",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210106,
              "id": 485,
              "areaName": "铁西区",
              "areaCode": "024",
              "areaShort": "Tiexi",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.333968,41.820807",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210111,
              "id": 486,
              "areaName": "苏家屯区",
              "areaCode": "024",
              "areaShort": "Sujiatun",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.344062,41.664757",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210112,
              "id": 487,
              "areaName": "浑南区",
              "areaCode": "024",
              "areaShort": "Hunnan",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.449714,41.714914",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210113,
              "id": 488,
              "areaName": "沈北新区",
              "areaCode": "024",
              "areaShort": "Shenbeixinqu",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.583196,41.912487",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210114,
              "id": 489,
              "areaName": "于洪区",
              "areaCode": "024",
              "areaShort": "Yuhong",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.308119,41.793721",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210122,
              "id": 490,
              "areaName": "辽中县",
              "areaCode": null,
              "areaShort": "Liaozhong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210123,
              "id": 491,
              "areaName": "康平县",
              "areaCode": "024",
              "areaShort": "Kangping",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.343699,42.72793",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210124,
              "id": 492,
              "areaName": "法库县",
              "areaCode": "024",
              "areaShort": "Faku",
              "areaInitials": null,
              "cityCode": "024",
              "center": "123.440294,42.50108",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210181,
              "id": 493,
              "areaName": "新民市",
              "areaCode": "024",
              "areaShort": "Xinmin",
              "areaInitials": null,
              "cityCode": "024",
              "center": "122.836723,41.985186",
              "areaLevel": 3,
              "areaParentId": 210100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210200,
          "id": 494,
          "areaName": "大连市",
          "areaCode": "0411",
          "areaShort": "Dalian",
          "areaInitials": null,
          "cityCode": "167",
          "center": "121.614848,38.914086",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210202,
              "id": 495,
              "areaName": "中山区",
              "areaCode": "0411",
              "areaShort": "Zhongshan",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.644926,38.918574",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210203,
              "id": 496,
              "areaName": "西岗区",
              "areaCode": "0411",
              "areaShort": "Xigang",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.612324,38.914687",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210204,
              "id": 497,
              "areaName": "沙河口区",
              "areaCode": "0411",
              "areaShort": "Shahekou",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.594297,38.904788",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210211,
              "id": 498,
              "areaName": "甘井子区",
              "areaCode": "0411",
              "areaShort": "Ganjingzi",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.525466,38.953343",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210212,
              "id": 499,
              "areaName": "旅顺口区",
              "areaCode": "0411",
              "areaShort": "Lvshunkou",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.261953,38.851705",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210213,
              "id": 500,
              "areaName": "金州区",
              "areaCode": "0411",
              "areaShort": "Jinzhou",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.782655,39.050001",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210224,
              "id": 501,
              "areaName": "长海县",
              "areaCode": "0411",
              "areaShort": "Changhai",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "122.588494,39.272728",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210281,
              "id": 502,
              "areaName": "瓦房店市",
              "areaCode": "0411",
              "areaShort": "Wafangdian",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "121.979543,39.626897",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210282,
              "id": 503,
              "areaName": "普兰店市",
              "areaCode": null,
              "areaShort": "Pulandian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210283,
              "id": 504,
              "areaName": "庄河市",
              "areaCode": "0411",
              "areaShort": "Zhuanghe",
              "areaInitials": null,
              "cityCode": "0411",
              "center": "122.967424,39.680843",
              "areaLevel": 3,
              "areaParentId": 210200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210300,
          "id": 505,
          "areaName": "鞍山市",
          "areaCode": "0412",
          "areaShort": "Anshan",
          "areaInitials": null,
          "cityCode": "320",
          "center": "122.994329,41.108647",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210302,
              "id": 506,
              "areaName": "铁东区",
              "areaCode": "0412",
              "areaShort": "Tiedong",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "122.991052,41.089933",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210303,
              "id": 507,
              "areaName": "铁西区",
              "areaCode": "0412",
              "areaShort": "Tiexi",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "122.969629,41.119884",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210304,
              "id": 508,
              "areaName": "立山区",
              "areaCode": "0412",
              "areaShort": "Lishan",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "123.029091,41.150401",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210311,
              "id": 509,
              "areaName": "千山区",
              "areaCode": "0412",
              "areaShort": "Qianshan",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "122.944751,41.068901",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210321,
              "id": 510,
              "areaName": "台安县",
              "areaCode": "0412",
              "areaShort": "Tai'an",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "122.436196,41.412767",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210323,
              "id": 511,
              "areaName": "岫岩满族自治县",
              "areaCode": "0412",
              "areaShort": "Xiuyan",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "123.280935,40.29088",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210381,
              "id": 512,
              "areaName": "海城市",
              "areaCode": "0412",
              "areaShort": "Haicheng",
              "areaInitials": null,
              "cityCode": "0412",
              "center": "122.685217,40.882377",
              "areaLevel": 3,
              "areaParentId": 210300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210400,
          "id": 513,
          "areaName": "抚顺市",
          "areaCode": "0413",
          "areaShort": "Fushun",
          "areaInitials": null,
          "cityCode": "184",
          "center": "123.957208,41.880872",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210402,
              "id": 514,
              "areaName": "新抚区",
              "areaCode": "0413",
              "areaShort": "Xinfu",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "123.912872,41.862026",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210403,
              "id": 515,
              "areaName": "东洲区",
              "areaCode": "0413",
              "areaShort": "Dongzhou",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "124.038685,41.853191",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210404,
              "id": 516,
              "areaName": "望花区",
              "areaCode": "0413",
              "areaShort": "Wanghua",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "123.784225,41.853641",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210411,
              "id": 517,
              "areaName": "顺城区",
              "areaCode": "0413",
              "areaShort": "Shuncheng",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "123.945075,41.883235",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210421,
              "id": 518,
              "areaName": "抚顺县",
              "areaCode": "0413",
              "areaShort": "Fushun",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "124.097978,41.922644",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210422,
              "id": 519,
              "areaName": "新宾满族自治县",
              "areaCode": "0413",
              "areaShort": "Xinbin",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "125.039978,41.734256",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210423,
              "id": 520,
              "areaName": "清原满族自治县",
              "areaCode": "0413",
              "areaShort": "Qingyuan",
              "areaInitials": null,
              "cityCode": "0413",
              "center": "124.924083,42.100538",
              "areaLevel": 3,
              "areaParentId": 210400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210500,
          "id": 521,
          "areaName": "本溪市",
          "areaCode": "0414",
          "areaShort": "Benxi",
          "areaInitials": null,
          "cityCode": "227",
          "center": "123.685142,41.486981",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210502,
              "id": 522,
              "areaName": "平山区",
              "areaCode": "0414",
              "areaShort": "Pingshan",
              "areaInitials": null,
              "cityCode": "0414",
              "center": "123.769088,41.299587",
              "areaLevel": 3,
              "areaParentId": 210500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210503,
              "id": 523,
              "areaName": "溪湖区",
              "areaCode": "0414",
              "areaShort": "Xihu",
              "areaInitials": null,
              "cityCode": "0414",
              "center": "123.767646,41.329219",
              "areaLevel": 3,
              "areaParentId": 210500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210504,
              "id": 524,
              "areaName": "明山区",
              "areaCode": "0414",
              "areaShort": "Mingshan",
              "areaInitials": null,
              "cityCode": "0414",
              "center": "123.817214,41.308719",
              "areaLevel": 3,
              "areaParentId": 210500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210505,
              "id": 525,
              "areaName": "南芬区",
              "areaCode": "0414",
              "areaShort": "Nanfen",
              "areaInitials": null,
              "cityCode": "0414",
              "center": "123.744802,41.100445",
              "areaLevel": 3,
              "areaParentId": 210500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210521,
              "id": 526,
              "areaName": "本溪满族自治县",
              "areaCode": "0414",
              "areaShort": "Benxi",
              "areaInitials": null,
              "cityCode": "0414",
              "center": "124.120635,41.302009",
              "areaLevel": 3,
              "areaParentId": 210500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210522,
              "id": 527,
              "areaName": "桓仁满族自治县",
              "areaCode": "0414",
              "areaShort": "Huanren",
              "areaInitials": null,
              "cityCode": "0414",
              "center": "125.361007,41.267127",
              "areaLevel": 3,
              "areaParentId": 210500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210600,
          "id": 528,
          "areaName": "丹东市",
          "areaCode": "0415",
          "areaShort": "Dandong",
          "areaInitials": null,
          "cityCode": "282",
          "center": "124.35445,40.000787",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210602,
              "id": 529,
              "areaName": "元宝区",
              "areaCode": "0415",
              "areaShort": "Yuanbao",
              "areaInitials": null,
              "cityCode": "0415",
              "center": "124.395661,40.136434",
              "areaLevel": 3,
              "areaParentId": 210600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210603,
              "id": 530,
              "areaName": "振兴区",
              "areaCode": "0415",
              "areaShort": "Zhenxing",
              "areaInitials": null,
              "cityCode": "0415",
              "center": "124.383237,40.129944",
              "areaLevel": 3,
              "areaParentId": 210600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210604,
              "id": 531,
              "areaName": "振安区",
              "areaCode": "0415",
              "areaShort": "Zhen'an",
              "areaInitials": null,
              "cityCode": "0415",
              "center": "124.470034,40.201553",
              "areaLevel": 3,
              "areaParentId": 210600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210624,
              "id": 532,
              "areaName": "宽甸满族自治县",
              "areaCode": "0415",
              "areaShort": "Kuandian",
              "areaInitials": null,
              "cityCode": "0415",
              "center": "124.783659,40.731316",
              "areaLevel": 3,
              "areaParentId": 210600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210681,
              "id": 533,
              "areaName": "东港市",
              "areaCode": "0415",
              "areaShort": "Donggang",
              "areaInitials": null,
              "cityCode": "0415",
              "center": "124.152705,39.863008",
              "areaLevel": 3,
              "areaParentId": 210600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210682,
              "id": 534,
              "areaName": "凤城市",
              "areaCode": "0415",
              "areaShort": "Fengcheng",
              "areaInitials": null,
              "cityCode": "0415",
              "center": "124.066919,40.452297",
              "areaLevel": 3,
              "areaParentId": 210600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210700,
          "id": 535,
          "areaName": "锦州市",
          "areaCode": "0416",
          "areaShort": "Jinzhou",
          "areaInitials": null,
          "cityCode": "166",
          "center": "121.126846,41.095685",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210702,
              "id": 536,
              "areaName": "古塔区",
              "areaCode": "0416",
              "areaShort": "Guta",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "121.128279,41.117245",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210703,
              "id": 537,
              "areaName": "凌河区",
              "areaCode": "0416",
              "areaShort": "Linghe",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "121.150877,41.114989",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210711,
              "id": 538,
              "areaName": "太和区",
              "areaCode": "0416",
              "areaShort": "Taihe",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "121.103892,41.109147",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210726,
              "id": 539,
              "areaName": "黑山县",
              "areaCode": "0416",
              "areaShort": "Heishan",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "122.126292,41.653593",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210727,
              "id": 540,
              "areaName": "义县",
              "areaCode": "0416",
              "areaShort": "Yixian",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "121.23908,41.533086",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210781,
              "id": 541,
              "areaName": "凌海市",
              "areaCode": "0416",
              "areaShort": "Linghai",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "121.35549,41.160567",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210782,
              "id": 542,
              "areaName": "北镇市",
              "areaCode": "0416",
              "areaShort": "Beizhen",
              "areaInitials": null,
              "cityCode": "0416",
              "center": "121.777395,41.58844",
              "areaLevel": 3,
              "areaParentId": 210700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210800,
          "id": 543,
          "areaName": "营口市",
          "areaCode": "0417",
          "areaShort": "Yingkou",
          "areaInitials": null,
          "cityCode": "281",
          "center": "122.219458,40.625364",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210802,
              "id": 544,
              "areaName": "站前区",
              "areaCode": "0417",
              "areaShort": "Zhanqian",
              "areaInitials": null,
              "cityCode": "0417",
              "center": "122.259033,40.672563",
              "areaLevel": 3,
              "areaParentId": 210800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210803,
              "id": 545,
              "areaName": "西市区",
              "areaCode": "0417",
              "areaShort": "Xishi",
              "areaInitials": null,
              "cityCode": "0417",
              "center": "122.206419,40.666213",
              "areaLevel": 3,
              "areaParentId": 210800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210804,
              "id": 546,
              "areaName": "鲅鱼圈区",
              "areaCode": "0417",
              "areaShort": "Bayuquan",
              "areaInitials": null,
              "cityCode": "0417",
              "center": "122.121521,40.226661",
              "areaLevel": 3,
              "areaParentId": 210800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210811,
              "id": 547,
              "areaName": "老边区",
              "areaCode": "0417",
              "areaShort": "Laobian",
              "areaInitials": null,
              "cityCode": "0417",
              "center": "122.380087,40.680191",
              "areaLevel": 3,
              "areaParentId": 210800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210881,
              "id": 548,
              "areaName": "盖州市",
              "areaCode": "0417",
              "areaShort": "Gaizhou",
              "areaInitials": null,
              "cityCode": "0417",
              "center": "122.349012,40.40074",
              "areaLevel": 3,
              "areaParentId": 210800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210882,
              "id": 549,
              "areaName": "大石桥市",
              "areaCode": "0417",
              "areaShort": "Dashiqiao",
              "areaInitials": null,
              "cityCode": "0417",
              "center": "122.509006,40.644482",
              "areaLevel": 3,
              "areaParentId": 210800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 210900,
          "id": 550,
          "areaName": "阜新市",
          "areaCode": "0418",
          "areaShort": "Fuxin",
          "areaInitials": null,
          "cityCode": "59",
          "center": "121.670273,42.021602",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 210902,
              "id": 551,
              "areaName": "海州区",
              "areaCode": "0418",
              "areaShort": "Haizhou",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "121.657638,42.011162",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210903,
              "id": 552,
              "areaName": "新邱区",
              "areaCode": "0418",
              "areaShort": "Xinqiu",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "121.792535,42.087632",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210904,
              "id": 553,
              "areaName": "太平区",
              "areaCode": "0418",
              "areaShort": "Taiping",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "121.678604,42.010669",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210905,
              "id": 554,
              "areaName": "清河门区",
              "areaCode": "0418",
              "areaShort": "Qinghemen",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "121.416105,41.7831",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210911,
              "id": 555,
              "areaName": "细河区",
              "areaCode": "0418",
              "areaShort": "Xihe",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "121.68054,42.025494",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210921,
              "id": 556,
              "areaName": "阜新蒙古族自治县",
              "areaCode": "0418",
              "areaShort": "Fuxin",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "121.757901,42.065175",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 210922,
              "id": 557,
              "areaName": "彰武县",
              "areaCode": "0418",
              "areaShort": "Zhangwu",
              "areaInitials": null,
              "cityCode": "0418",
              "center": "122.538793,42.386543",
              "areaLevel": 3,
              "areaParentId": 210900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 211000,
          "id": 558,
          "areaName": "辽阳市",
          "areaCode": "0419",
          "areaShort": "Liaoyang",
          "areaInitials": null,
          "cityCode": "351",
          "center": "123.236974,41.267794",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 211002,
              "id": 559,
              "areaName": "白塔区",
              "areaCode": "0419",
              "areaShort": "Baita",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.174325,41.270347",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211003,
              "id": 560,
              "areaName": "文圣区",
              "areaCode": "0419",
              "areaShort": "Wensheng",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.231408,41.283754",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211004,
              "id": 561,
              "areaName": "宏伟区",
              "areaCode": "0419",
              "areaShort": "Hongwei",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.196672,41.217649",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211005,
              "id": 562,
              "areaName": "弓长岭区",
              "areaCode": "0419",
              "areaShort": "Gongchangling",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.419803,41.151847",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211011,
              "id": 563,
              "areaName": "太子河区",
              "areaCode": "0419",
              "areaShort": "Taizihe",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.18144,41.295023",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211021,
              "id": 564,
              "areaName": "辽阳县",
              "areaCode": "0419",
              "areaShort": "Liaoyang",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.105694,41.205329",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211081,
              "id": 565,
              "areaName": "灯塔市",
              "areaCode": "0419",
              "areaShort": "Dengta",
              "areaInitials": null,
              "cityCode": "0419",
              "center": "123.339312,41.426372",
              "areaLevel": 3,
              "areaParentId": 211000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 211100,
          "id": 566,
          "areaName": "盘锦市",
          "areaCode": "0427",
          "areaShort": "Panjin",
          "areaInitials": null,
          "cityCode": "228",
          "center": "122.170584,40.719847",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 211102,
              "id": 567,
              "areaName": "双台子区",
              "areaCode": "0427",
              "areaShort": "Shuangtaizi",
              "areaInitials": null,
              "cityCode": "0427",
              "center": "122.039787,41.19965",
              "areaLevel": 3,
              "areaParentId": 211100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211103,
              "id": 568,
              "areaName": "兴隆台区",
              "areaCode": "0427",
              "areaShort": "Xinglongtai",
              "areaInitials": null,
              "cityCode": "0427",
              "center": "122.070769,41.119898",
              "areaLevel": 3,
              "areaParentId": 211100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211121,
              "id": 569,
              "areaName": "大洼县",
              "areaCode": null,
              "areaShort": "Dawa",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 211100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211122,
              "id": 570,
              "areaName": "盘山县",
              "areaCode": "0427",
              "areaShort": "Panshan",
              "areaInitials": null,
              "cityCode": "0427",
              "center": "121.996411,41.242639",
              "areaLevel": 3,
              "areaParentId": 211100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 211200,
          "id": 571,
          "areaName": "铁岭市",
          "areaCode": "0410",
          "areaShort": "Tieling",
          "areaInitials": null,
          "cityCode": "60",
          "center": "123.726035,42.223828",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 211202,
              "id": 572,
              "areaName": "银州区",
              "areaCode": "0410",
              "areaShort": "Yinzhou",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "123.842305,42.286129",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211204,
              "id": 573,
              "areaName": "清河区",
              "areaCode": "0410",
              "areaShort": "Qinghe",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "124.159191,42.546565",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211221,
              "id": 574,
              "areaName": "铁岭县",
              "areaCode": "0410",
              "areaShort": "Tieling",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "123.728933,42.223395",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211223,
              "id": 575,
              "areaName": "西丰县",
              "areaCode": "0410",
              "areaShort": "Xifeng",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "124.727392,42.73803",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211224,
              "id": 576,
              "areaName": "昌图县",
              "areaCode": "0410",
              "areaShort": "Changtu",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "124.111099,42.785791",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211281,
              "id": 577,
              "areaName": "调兵山市",
              "areaCode": "0410",
              "areaShort": "Diaobingshan",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "123.567117,42.467521",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211282,
              "id": 578,
              "areaName": "开原市",
              "areaCode": "0410",
              "areaShort": "Kaiyuan",
              "areaInitials": null,
              "cityCode": "0410",
              "center": "124.038268,42.546307",
              "areaLevel": 3,
              "areaParentId": 211200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 211300,
          "id": 579,
          "areaName": "朝阳市",
          "areaCode": "0421",
          "areaShort": "Chaoyang",
          "areaInitials": null,
          "cityCode": "280",
          "center": "120.450879,41.573762",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 211302,
              "id": 580,
              "areaName": "双塔区",
              "areaCode": "0421",
              "areaShort": "Shuangta",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "120.453744,41.565627",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211303,
              "id": 581,
              "areaName": "龙城区",
              "areaCode": "0421",
              "areaShort": "Longcheng",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "120.413376,41.576749",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211321,
              "id": 582,
              "areaName": "朝阳县",
              "areaCode": "0421",
              "areaShort": "Chaoyang",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "120.389754,41.497825",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211322,
              "id": 583,
              "areaName": "建平县",
              "areaCode": "0421",
              "areaShort": "Jianping",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "119.64328,41.403128",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211324,
              "id": 584,
              "areaName": "喀喇沁左翼蒙古族自治县",
              "areaCode": "0421",
              "areaShort": "Kalaqinzuoyi",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "119.741223,41.12815",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211381,
              "id": 585,
              "areaName": "北票市",
              "areaCode": "0421",
              "areaShort": "Beipiao",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "120.77073,41.800683",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211382,
              "id": 586,
              "areaName": "凌源市",
              "areaCode": "0421",
              "areaShort": "Lingyuan",
              "areaInitials": null,
              "cityCode": "0421",
              "center": "119.401574,41.245445",
              "areaLevel": 3,
              "areaParentId": 211300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 211400,
          "id": 587,
          "areaName": "葫芦岛市",
          "areaCode": "0429",
          "areaShort": "Huludao",
          "areaInitials": null,
          "cityCode": "319",
          "center": "120.836939,40.71104",
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 211402,
              "id": 588,
              "areaName": "连山区",
              "areaCode": "0429",
              "areaShort": "Lianshan",
              "areaInitials": null,
              "cityCode": "0429",
              "center": "120.869231,40.774461",
              "areaLevel": 3,
              "areaParentId": 211400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211403,
              "id": 589,
              "areaName": "龙港区",
              "areaCode": "0429",
              "areaShort": "Longgang",
              "areaInitials": null,
              "cityCode": "0429",
              "center": "120.893786,40.735519",
              "areaLevel": 3,
              "areaParentId": 211400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211404,
              "id": 590,
              "areaName": "南票区",
              "areaCode": "0429",
              "areaShort": "Nanpiao",
              "areaInitials": null,
              "cityCode": "0429",
              "center": "120.749727,41.107107",
              "areaLevel": 3,
              "areaParentId": 211400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211421,
              "id": 591,
              "areaName": "绥中县",
              "areaCode": "0429",
              "areaShort": "Suizhong",
              "areaInitials": null,
              "cityCode": "0429",
              "center": "120.344311,40.32558",
              "areaLevel": 3,
              "areaParentId": 211400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211422,
              "id": 592,
              "areaName": "建昌县",
              "areaCode": "0429",
              "areaShort": "Jianchang",
              "areaInitials": null,
              "cityCode": "0429",
              "center": "119.837124,40.824367",
              "areaLevel": 3,
              "areaParentId": 211400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211481,
              "id": 593,
              "areaName": "兴城市",
              "areaCode": "0429",
              "areaShort": "Xingcheng",
              "areaInitials": null,
              "cityCode": "0429",
              "center": "120.756479,40.609731",
              "areaLevel": 3,
              "areaParentId": 211400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 211500,
          "id": 594,
          "areaName": "金普新区",
          "areaCode": null,
          "areaShort": "Jinpuxinqu",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 210000,
          "areaDictionaryVOList": [
            {
              "areaId": 211501,
              "id": 595,
              "areaName": "金州新区",
              "areaCode": null,
              "areaShort": "Jinzhouxinqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 211500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211502,
              "id": 596,
              "areaName": "普湾新区",
              "areaCode": null,
              "areaShort": "Puwanxinqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 211500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 211503,
              "id": 597,
              "areaName": "保税区",
              "areaCode": null,
              "areaShort": "Baoshuiqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 211500,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 220000,
      "id": 598,
      "areaName": "吉林省",
      "areaCode": "[]",
      "areaShort": "Jilin",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "125.32568,43.897016",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 220100,
          "id": 599,
          "areaName": "长春市",
          "areaCode": "0431",
          "areaShort": "Changchun",
          "areaInitials": null,
          "cityCode": "53",
          "center": "125.323513,43.817251",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220102,
              "id": 600,
              "areaName": "南关区",
              "areaCode": "0431",
              "areaShort": "Nanguan",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.350173,43.863989",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220103,
              "id": 601,
              "areaName": "宽城区",
              "areaCode": "0431",
              "areaShort": "Kuancheng",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.326581,43.943612",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220104,
              "id": 602,
              "areaName": "朝阳区",
              "areaCode": "0431",
              "areaShort": "Chaoyang",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.288254,43.833762",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220105,
              "id": 603,
              "areaName": "二道区",
              "areaCode": "0431",
              "areaShort": "Erdao",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.374327,43.865577",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220106,
              "id": 604,
              "areaName": "绿园区",
              "areaCode": "0431",
              "areaShort": "Lvyuan",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.256135,43.880975",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220112,
              "id": 605,
              "areaName": "双阳区",
              "areaCode": "0431",
              "areaShort": "Shuangyang",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.664662,43.525311",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220113,
              "id": 606,
              "areaName": "九台区",
              "areaCode": "0431",
              "areaShort": "Jiutai",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.839573,44.151742",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220122,
              "id": 607,
              "areaName": "农安县",
              "areaCode": "0431",
              "areaShort": "Nong'an",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.184887,44.432763",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220182,
              "id": 608,
              "areaName": "榆树市",
              "areaCode": "0431",
              "areaShort": "Yushu",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "126.533187,44.840318",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220183,
              "id": 609,
              "areaName": "德惠市",
              "areaCode": "0431",
              "areaShort": "Dehui",
              "areaInitials": null,
              "cityCode": "0431",
              "center": "125.728755,44.522056",
              "areaLevel": 3,
              "areaParentId": 220100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220200,
          "id": 610,
          "areaName": "吉林市",
          "areaCode": "0432",
          "areaShort": "Jilin",
          "areaInitials": null,
          "cityCode": "55",
          "center": "126.549572,43.837883",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220202,
              "id": 611,
              "areaName": "昌邑区",
              "areaCode": "0432",
              "areaShort": "Changyi",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.574709,43.881818",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220203,
              "id": 612,
              "areaName": "龙潭区",
              "areaCode": "0432",
              "areaShort": "Longtan",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.562197,43.910802",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220204,
              "id": 613,
              "areaName": "船营区",
              "areaCode": "0432",
              "areaShort": "Chuanying",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.540966,43.833445",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220211,
              "id": 614,
              "areaName": "丰满区",
              "areaCode": "0432",
              "areaShort": "Fengman",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.562274,43.821601",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220221,
              "id": 615,
              "areaName": "永吉县",
              "areaCode": "0432",
              "areaShort": "Yongji",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.497741,43.672582",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220281,
              "id": 616,
              "areaName": "蛟河市",
              "areaCode": "0432",
              "areaShort": "Jiaohe",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "127.344229,43.724007",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220282,
              "id": 617,
              "areaName": "桦甸市",
              "areaCode": "0432",
              "areaShort": "Huadian",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.746309,42.972096",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220283,
              "id": 618,
              "areaName": "舒兰市",
              "areaCode": "0432",
              "areaShort": "Shulan",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.965607,44.406105",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220284,
              "id": 619,
              "areaName": "磐石市",
              "areaCode": "0432",
              "areaShort": "Panshi",
              "areaInitials": null,
              "cityCode": "0432",
              "center": "126.060427,42.946285",
              "areaLevel": 3,
              "areaParentId": 220200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220300,
          "id": 620,
          "areaName": "四平市",
          "areaCode": "0434",
          "areaShort": "Siping",
          "areaInitials": null,
          "cityCode": "56",
          "center": "124.350398,43.166419",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220302,
              "id": 621,
              "areaName": "铁西区",
              "areaCode": "0434",
              "areaShort": "Tiexi",
              "areaInitials": null,
              "cityCode": "0434",
              "center": "124.345722,43.146155",
              "areaLevel": 3,
              "areaParentId": 220300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220303,
              "id": 622,
              "areaName": "铁东区",
              "areaCode": "0434",
              "areaShort": "Tiedong",
              "areaInitials": null,
              "cityCode": "0434",
              "center": "124.409591,43.162105",
              "areaLevel": 3,
              "areaParentId": 220300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220322,
              "id": 623,
              "areaName": "梨树县",
              "areaCode": "0434",
              "areaShort": "Lishu",
              "areaInitials": null,
              "cityCode": "0434",
              "center": "124.33539,43.30706",
              "areaLevel": 3,
              "areaParentId": 220300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220323,
              "id": 624,
              "areaName": "伊通满族自治县",
              "areaCode": "0434",
              "areaShort": "Yitong",
              "areaInitials": null,
              "cityCode": "0434",
              "center": "125.305393,43.345754",
              "areaLevel": 3,
              "areaParentId": 220300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220381,
              "id": 625,
              "areaName": "公主岭市",
              "areaCode": "0434",
              "areaShort": "Gongzhuling",
              "areaInitials": null,
              "cityCode": "0434",
              "center": "124.822929,43.504676",
              "areaLevel": 3,
              "areaParentId": 220300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220382,
              "id": 626,
              "areaName": "双辽市",
              "areaCode": "0434",
              "areaShort": "Shuangliao",
              "areaInitials": null,
              "cityCode": "0434",
              "center": "123.502723,43.518302",
              "areaLevel": 3,
              "areaParentId": 220300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220400,
          "id": 627,
          "areaName": "辽源市",
          "areaCode": "0437",
          "areaShort": "Liaoyuan",
          "areaInitials": null,
          "cityCode": "183",
          "center": "125.14366,42.887766",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220402,
              "id": 628,
              "areaName": "龙山区",
              "areaCode": "0437",
              "areaShort": "Longshan",
              "areaInitials": null,
              "cityCode": "0437",
              "center": "125.136627,42.90158",
              "areaLevel": 3,
              "areaParentId": 220400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220403,
              "id": 629,
              "areaName": "西安区",
              "areaCode": "0437",
              "areaShort": "Xi'an",
              "areaInitials": null,
              "cityCode": "0437",
              "center": "125.149281,42.927324",
              "areaLevel": 3,
              "areaParentId": 220400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220421,
              "id": 630,
              "areaName": "东丰县",
              "areaCode": "0437",
              "areaShort": "Dongfeng",
              "areaInitials": null,
              "cityCode": "0437",
              "center": "125.531021,42.677371",
              "areaLevel": 3,
              "areaParentId": 220400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220422,
              "id": 631,
              "areaName": "东辽县",
              "areaCode": "0437",
              "areaShort": "Dongliao",
              "areaInitials": null,
              "cityCode": "0437",
              "center": "124.991424,42.92625",
              "areaLevel": 3,
              "areaParentId": 220400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220500,
          "id": 632,
          "areaName": "通化市",
          "areaCode": "0435",
          "areaShort": "Tonghua",
          "areaInitials": null,
          "cityCode": "165",
          "center": "125.939697,41.728401",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220502,
              "id": 633,
              "areaName": "东昌区",
              "areaCode": "0435",
              "areaShort": "Dongchang",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "125.927101,41.702859",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220503,
              "id": 634,
              "areaName": "二道江区",
              "areaCode": "0435",
              "areaShort": "Erdaojiang",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "126.042678,41.774044",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220521,
              "id": 635,
              "areaName": "通化县",
              "areaCode": "0435",
              "areaShort": "Tonghua",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "125.759259,41.679808",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220523,
              "id": 636,
              "areaName": "辉南县",
              "areaCode": "0435",
              "areaShort": "Huinan",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "126.046783,42.684921",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220524,
              "id": 637,
              "areaName": "柳河县",
              "areaCode": "0435",
              "areaShort": "Liuhe",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "125.744735,42.284605",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220581,
              "id": 638,
              "areaName": "梅河口市",
              "areaCode": "0435",
              "areaShort": "Meihekou",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "125.710859,42.539253",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220582,
              "id": 639,
              "areaName": "集安市",
              "areaCode": "0435",
              "areaShort": "Ji'an",
              "areaInitials": null,
              "cityCode": "0435",
              "center": "126.19403,41.125307",
              "areaLevel": 3,
              "areaParentId": 220500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220600,
          "id": 640,
          "areaName": "白山市",
          "areaCode": "0439",
          "areaShort": "Baishan",
          "areaInitials": null,
          "cityCode": "57",
          "center": "126.41473,41.943972",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220602,
              "id": 641,
              "areaName": "浑江区",
              "areaCode": "0439",
              "areaShort": "Hunjiang",
              "areaInitials": null,
              "cityCode": "0439",
              "center": "126.416093,41.945409",
              "areaLevel": 3,
              "areaParentId": 220600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220605,
              "id": 642,
              "areaName": "江源区",
              "areaCode": "0439",
              "areaShort": "Jiangyuan",
              "areaInitials": null,
              "cityCode": "0439",
              "center": "126.591178,42.056747",
              "areaLevel": 3,
              "areaParentId": 220600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220621,
              "id": 643,
              "areaName": "抚松县",
              "areaCode": "0439",
              "areaShort": "Fusong",
              "areaInitials": null,
              "cityCode": "0439",
              "center": "127.449763,42.221207",
              "areaLevel": 3,
              "areaParentId": 220600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220622,
              "id": 644,
              "areaName": "靖宇县",
              "areaCode": "0439",
              "areaShort": "Jingyu",
              "areaInitials": null,
              "cityCode": "0439",
              "center": "126.813583,42.388896",
              "areaLevel": 3,
              "areaParentId": 220600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220623,
              "id": 645,
              "areaName": "长白朝鲜族自治县",
              "areaCode": "0439",
              "areaShort": "Changbai",
              "areaInitials": null,
              "cityCode": "0439",
              "center": "128.200789,41.420018",
              "areaLevel": 3,
              "areaParentId": 220600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220681,
              "id": 646,
              "areaName": "临江市",
              "areaCode": "0439",
              "areaShort": "Linjiang",
              "areaInitials": null,
              "cityCode": "0439",
              "center": "126.918087,41.811979",
              "areaLevel": 3,
              "areaParentId": 220600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220700,
          "id": 647,
          "areaName": "松原市",
          "areaCode": "0438",
          "areaShort": "Songyuan",
          "areaInitials": null,
          "cityCode": "52",
          "center": "124.825042,45.141548",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220702,
              "id": 648,
              "areaName": "宁江区",
              "areaCode": "0438",
              "areaShort": "Ningjiang",
              "areaInitials": null,
              "cityCode": "0438",
              "center": "124.86562,45.209915",
              "areaLevel": 3,
              "areaParentId": 220700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220721,
              "id": 649,
              "areaName": "前郭尔罗斯蒙古族自治县",
              "areaCode": "0438",
              "areaShort": "Qianguoerluosi",
              "areaInitials": null,
              "cityCode": "0438",
              "center": "124.823417,45.118061",
              "areaLevel": 3,
              "areaParentId": 220700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220722,
              "id": 650,
              "areaName": "长岭县",
              "areaCode": "0438",
              "areaShort": "Changling",
              "areaInitials": null,
              "cityCode": "0438",
              "center": "123.967483,44.275895",
              "areaLevel": 3,
              "areaParentId": 220700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220723,
              "id": 651,
              "areaName": "乾安县",
              "areaCode": "0438",
              "areaShort": "Qian'an",
              "areaInitials": null,
              "cityCode": "0438",
              "center": "124.041139,45.003773",
              "areaLevel": 3,
              "areaParentId": 220700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220781,
              "id": 652,
              "areaName": "扶余市",
              "areaCode": "0438",
              "areaShort": "Fuyu",
              "areaInitials": null,
              "cityCode": "0438",
              "center": "126.049803,44.9892",
              "areaLevel": 3,
              "areaParentId": 220700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 220800,
          "id": 653,
          "areaName": "白城市",
          "areaCode": "0436",
          "areaShort": "Baicheng",
          "areaInitials": null,
          "cityCode": "51",
          "center": "122.838714,45.619884",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 220802,
              "id": 654,
              "areaName": "洮北区",
              "areaCode": "0436",
              "areaShort": "Taobei",
              "areaInitials": null,
              "cityCode": "0436",
              "center": "122.851029,45.621716",
              "areaLevel": 3,
              "areaParentId": 220800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220821,
              "id": 655,
              "areaName": "镇赉县",
              "areaCode": "0436",
              "areaShort": "Zhenlai",
              "areaInitials": null,
              "cityCode": "0436",
              "center": "123.199607,45.84835",
              "areaLevel": 3,
              "areaParentId": 220800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220822,
              "id": 656,
              "areaName": "通榆县",
              "areaCode": "0436",
              "areaShort": "Tongyu",
              "areaInitials": null,
              "cityCode": "0436",
              "center": "123.088238,44.81291",
              "areaLevel": 3,
              "areaParentId": 220800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220881,
              "id": 657,
              "areaName": "洮南市",
              "areaCode": "0436",
              "areaShort": "Taonan",
              "areaInitials": null,
              "cityCode": "0436",
              "center": "122.798579,45.356807",
              "areaLevel": 3,
              "areaParentId": 220800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 220882,
              "id": 658,
              "areaName": "大安市",
              "areaCode": "0436",
              "areaShort": "Da'an",
              "areaInitials": null,
              "cityCode": "0436",
              "center": "124.292626,45.506996",
              "areaLevel": 3,
              "areaParentId": 220800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 222400,
          "id": 659,
          "areaName": "延边朝鲜族自治州",
          "areaCode": "1433",
          "areaShort": "Yanbian",
          "areaInitials": null,
          "cityCode": "54",
          "center": "129.471868,42.909408",
          "areaLevel": 2,
          "areaParentId": 220000,
          "areaDictionaryVOList": [
            {
              "areaId": 222401,
              "id": 660,
              "areaName": "延吉市",
              "areaCode": "1433",
              "areaShort": "Yanji",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "129.508804,42.89125",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222402,
              "id": 661,
              "areaName": "图们市",
              "areaCode": "1433",
              "areaShort": "Tumen",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "129.84371,42.968044",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222403,
              "id": 662,
              "areaName": "敦化市",
              "areaCode": "1433",
              "areaShort": "Dunhua",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "128.232131,43.372642",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222404,
              "id": 663,
              "areaName": "珲春市",
              "areaCode": "1433",
              "areaShort": "Hunchun",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "130.366036,42.862821",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222405,
              "id": 664,
              "areaName": "龙井市",
              "areaCode": "1433",
              "areaShort": "Longjing",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "129.427066,42.76631",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222406,
              "id": 665,
              "areaName": "和龙市",
              "areaCode": "1433",
              "areaShort": "Helong",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "129.010106,42.546675",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222424,
              "id": 666,
              "areaName": "汪清县",
              "areaCode": "1433",
              "areaShort": "Wangqing",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "129.771607,43.312522",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 222426,
              "id": 667,
              "areaName": "安图县",
              "areaCode": "1433",
              "areaShort": "Antu",
              "areaInitials": null,
              "cityCode": "1433",
              "center": "128.899772,43.11195",
              "areaLevel": 3,
              "areaParentId": 222400,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 230000,
      "id": 668,
      "areaName": "黑龙江省",
      "areaCode": "[]",
      "areaShort": "Heilongjiang",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "126.661665,45.742366",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 230100,
          "id": 669,
          "areaName": "哈尔滨市",
          "areaCode": "0451",
          "areaShort": "Harbin",
          "areaInitials": null,
          "cityCode": "48",
          "center": "126.534967,45.803775",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230102,
              "id": 670,
              "areaName": "道里区",
              "areaCode": "0451",
              "areaShort": "Daoli",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.616973,45.75577",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230103,
              "id": 671,
              "areaName": "南岗区",
              "areaCode": "0451",
              "areaShort": "Nangang",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.668784,45.760174",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230104,
              "id": 672,
              "areaName": "道外区",
              "areaCode": "0451",
              "areaShort": "Daowai",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.64939,45.792057",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230108,
              "id": 673,
              "areaName": "平房区",
              "areaCode": "0451",
              "areaShort": "Pingfang",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.637611,45.597911",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230109,
              "id": 674,
              "areaName": "松北区",
              "areaCode": "0451",
              "areaShort": "Songbei",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.516914,45.794504",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230110,
              "id": 675,
              "areaName": "香坊区",
              "areaCode": "0451",
              "areaShort": "Xiangfang",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.662593,45.707716",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230111,
              "id": 676,
              "areaName": "呼兰区",
              "areaCode": "0451",
              "areaShort": "Hulan",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.587905,45.889457",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230112,
              "id": 677,
              "areaName": "阿城区",
              "areaCode": "0451",
              "areaShort": "A'cheng",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.958098,45.548669",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230113,
              "id": 678,
              "areaName": "双城区",
              "areaCode": "0451",
              "areaShort": "Shuangcheng",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "126.312624,45.383218",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230123,
              "id": 679,
              "areaName": "依兰县",
              "areaCode": "0451",
              "areaShort": "Yilan",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "129.567877,46.325419",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230124,
              "id": 680,
              "areaName": "方正县",
              "areaCode": "0451",
              "areaShort": "Fangzheng",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "128.829536,45.851694",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230125,
              "id": 681,
              "areaName": "宾县",
              "areaCode": "0451",
              "areaShort": "Binxian",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "127.466634,45.745917",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230126,
              "id": 682,
              "areaName": "巴彦县",
              "areaCode": "0451",
              "areaShort": "Bayan",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "127.403781,46.086549",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230127,
              "id": 683,
              "areaName": "木兰县",
              "areaCode": "0451",
              "areaShort": "Mulan",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "128.043466,45.950582",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230128,
              "id": 684,
              "areaName": "通河县",
              "areaCode": "0451",
              "areaShort": "Tonghe",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "128.746124,45.990205",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230129,
              "id": 685,
              "areaName": "延寿县",
              "areaCode": "0451",
              "areaShort": "Yanshou",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "128.331643,45.451897",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230183,
              "id": 686,
              "areaName": "尚志市",
              "areaCode": "0451",
              "areaShort": "Shangzhi",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "128.009894,45.209586",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230184,
              "id": 687,
              "areaName": "五常市",
              "areaCode": "0451",
              "areaShort": "Wuchang",
              "areaInitials": null,
              "cityCode": "0451",
              "center": "127.167618,44.931991",
              "areaLevel": 3,
              "areaParentId": 230100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230200,
          "id": 688,
          "areaName": "齐齐哈尔市",
          "areaCode": "0452",
          "areaShort": "Qiqihar",
          "areaInitials": null,
          "cityCode": "41",
          "center": "123.918186,47.354348",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230202,
              "id": 689,
              "areaName": "龙沙区",
              "areaCode": "0452",
              "areaShort": "Longsha",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.957531,47.317308",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230203,
              "id": 690,
              "areaName": "建华区",
              "areaCode": "0452",
              "areaShort": "Jianhua",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.955464,47.354364",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230204,
              "id": 691,
              "areaName": "铁锋区",
              "areaCode": "0452",
              "areaShort": "Tiefeng",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.978293,47.340517",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230205,
              "id": 692,
              "areaName": "昂昂溪区",
              "areaCode": "0452",
              "areaShort": "Angangxi",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.8224,47.15516",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230206,
              "id": 693,
              "areaName": "富拉尔基区",
              "areaCode": "0452",
              "areaShort": "Fulaerji",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.629189,47.208843",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230207,
              "id": 694,
              "areaName": "碾子山区",
              "areaCode": "0452",
              "areaShort": "Nianzishan",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "122.887775,47.516872",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230208,
              "id": 695,
              "areaName": "梅里斯达斡尔族区",
              "areaCode": "0452",
              "areaShort": "Meilisi",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.75291,47.309537",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230221,
              "id": 696,
              "areaName": "龙江县",
              "areaCode": "0452",
              "areaShort": "Longjiang",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.205323,47.338665",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230223,
              "id": 697,
              "areaName": "依安县",
              "areaCode": "0452",
              "areaShort": "Yi'an",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "125.306278,47.893548",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230224,
              "id": 698,
              "areaName": "泰来县",
              "areaCode": "0452",
              "areaShort": "Tailai",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.416631,46.393694",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230225,
              "id": 699,
              "areaName": "甘南县",
              "areaCode": "0452",
              "areaShort": "Gannan",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "123.507429,47.922405",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230227,
              "id": 700,
              "areaName": "富裕县",
              "areaCode": "0452",
              "areaShort": "Fuyu",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "124.473793,47.774347",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230229,
              "id": 701,
              "areaName": "克山县",
              "areaCode": "0452",
              "areaShort": "Keshan",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "125.875705,48.037031",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230230,
              "id": 702,
              "areaName": "克东县",
              "areaCode": "0452",
              "areaShort": "Kedong",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "126.24872,48.04206",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230231,
              "id": 703,
              "areaName": "拜泉县",
              "areaCode": "0452",
              "areaShort": "Baiquan",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "126.100213,47.595851",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230281,
              "id": 704,
              "areaName": "讷河市",
              "areaCode": "0452",
              "areaShort": "Nehe",
              "areaInitials": null,
              "cityCode": "0452",
              "center": "124.88287,48.466592",
              "areaLevel": 3,
              "areaParentId": 230200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230300,
          "id": 705,
          "areaName": "鸡西市",
          "areaCode": "0467",
          "areaShort": "Jixi",
          "areaInitials": null,
          "cityCode": "46",
          "center": "130.969333,45.295075",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230302,
              "id": 706,
              "areaName": "鸡冠区",
              "areaCode": "0467",
              "areaShort": "Jiguan",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "130.981185,45.304355",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230303,
              "id": 707,
              "areaName": "恒山区",
              "areaCode": "0467",
              "areaShort": "Hengshan",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "130.904963,45.210668",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230304,
              "id": 708,
              "areaName": "滴道区",
              "areaCode": "0467",
              "areaShort": "Didao",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "130.843613,45.348763",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230305,
              "id": 709,
              "areaName": "梨树区",
              "areaCode": "0467",
              "areaShort": "Lishu",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "130.69699,45.092046",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230306,
              "id": 710,
              "areaName": "城子河区",
              "areaCode": "0467",
              "areaShort": "Chengzihe",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "131.011304,45.33697",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230307,
              "id": 711,
              "areaName": "麻山区",
              "areaCode": "0467",
              "areaShort": "Mashan",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "130.478187,45.212088",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230321,
              "id": 712,
              "areaName": "鸡东县",
              "areaCode": "0467",
              "areaShort": "Jidong",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "131.124079,45.260412",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230381,
              "id": 713,
              "areaName": "虎林市",
              "areaCode": "0467",
              "areaShort": "Hulin",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "132.93721,45.762685",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230382,
              "id": 714,
              "areaName": "密山市",
              "areaCode": "0467",
              "areaShort": "Mishan",
              "areaInitials": null,
              "cityCode": "0467",
              "center": "131.846635,45.529774",
              "areaLevel": 3,
              "areaParentId": 230300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230400,
          "id": 715,
          "areaName": "鹤岗市",
          "areaCode": "0468",
          "areaShort": "Hegang",
          "areaInitials": null,
          "cityCode": "43",
          "center": "130.297943,47.350189",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230402,
              "id": 716,
              "areaName": "向阳区",
              "areaCode": "0468",
              "areaShort": "Xiangyang",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.294235,47.342468",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230403,
              "id": 717,
              "areaName": "工农区",
              "areaCode": "0468",
              "areaShort": "Gongnong",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.274684,47.31878",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230404,
              "id": 718,
              "areaName": "南山区",
              "areaCode": "0468",
              "areaShort": "Nanshan",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.286788,47.315174",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230405,
              "id": 719,
              "areaName": "兴安区",
              "areaCode": "0468",
              "areaShort": "Xing'an",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.239245,47.252849",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230406,
              "id": 720,
              "areaName": "东山区",
              "areaCode": "0468",
              "areaShort": "Dongshan",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.317002,47.338537",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230407,
              "id": 721,
              "areaName": "兴山区",
              "areaCode": "0468",
              "areaShort": "Xingshan",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.303481,47.357702",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230421,
              "id": 722,
              "areaName": "萝北县",
              "areaCode": "0468",
              "areaShort": "Luobei",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "130.85155,47.576444",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230422,
              "id": 723,
              "areaName": "绥滨县",
              "areaCode": "0468",
              "areaShort": "Suibin",
              "areaInitials": null,
              "cityCode": "0468",
              "center": "131.852759,47.289115",
              "areaLevel": 3,
              "areaParentId": 230400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230500,
          "id": 724,
          "areaName": "双鸭山市",
          "areaCode": "0469",
          "areaShort": "Shuangyashan",
          "areaInitials": null,
          "cityCode": "45",
          "center": "131.141195,46.676418",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230502,
              "id": 725,
              "areaName": "尖山区",
              "areaCode": "0469",
              "areaShort": "Jianshan",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "131.158415,46.64635",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230503,
              "id": 726,
              "areaName": "岭东区",
              "areaCode": "0469",
              "areaShort": "Lingdong",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "131.164723,46.592721",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230505,
              "id": 727,
              "areaName": "四方台区",
              "areaCode": "0469",
              "areaShort": "Sifangtai",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "131.337592,46.597264",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230506,
              "id": 728,
              "areaName": "宝山区",
              "areaCode": "0469",
              "areaShort": "Baoshan",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "131.401589,46.577167",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230521,
              "id": 729,
              "areaName": "集贤县",
              "areaCode": "0469",
              "areaShort": "Jixian",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "131.141311,46.728412",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230522,
              "id": 730,
              "areaName": "友谊县",
              "areaCode": "0469",
              "areaShort": "Youyi",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "131.808063,46.767299",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230523,
              "id": 731,
              "areaName": "宝清县",
              "areaCode": "0469",
              "areaShort": "Baoqing",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "132.196853,46.327457",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230524,
              "id": 732,
              "areaName": "饶河县",
              "areaCode": "0469",
              "areaShort": "Raohe",
              "areaInitials": null,
              "cityCode": "0469",
              "center": "134.013872,46.798163",
              "areaLevel": 3,
              "areaParentId": 230500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230600,
          "id": 733,
          "areaName": "大庆市",
          "areaCode": "0459",
          "areaShort": "Daqing",
          "areaInitials": null,
          "cityCode": "50",
          "center": "125.103784,46.589309",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230602,
              "id": 734,
              "areaName": "萨尔图区",
              "areaCode": "0459",
              "areaShort": "Saertu",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "125.135591,46.629092",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230603,
              "id": 735,
              "areaName": "龙凤区",
              "areaCode": "0459",
              "areaShort": "Longfeng",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "125.135326,46.562247",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230604,
              "id": 736,
              "areaName": "让胡路区",
              "areaCode": "0459",
              "areaShort": "Ranghulu",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "124.870596,46.652357",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230605,
              "id": 737,
              "areaName": "红岗区",
              "areaCode": "0459",
              "areaShort": "Honggang",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "124.891039,46.398418",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230606,
              "id": 738,
              "areaName": "大同区",
              "areaCode": "0459",
              "areaShort": "Datong",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "124.812364,46.039827",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230621,
              "id": 739,
              "areaName": "肇州县",
              "areaCode": "0459",
              "areaShort": "Zhaozhou",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "125.268643,45.699066",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230622,
              "id": 740,
              "areaName": "肇源县",
              "areaCode": "0459",
              "areaShort": "Zhaoyuan",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "125.078223,45.51932",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230623,
              "id": 741,
              "areaName": "林甸县",
              "areaCode": "0459",
              "areaShort": "Lindian",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "124.863603,47.171717",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230624,
              "id": 742,
              "areaName": "杜尔伯特蒙古族自治县",
              "areaCode": "0459",
              "areaShort": "Duerbote",
              "areaInitials": null,
              "cityCode": "0459",
              "center": "124.442572,46.862817",
              "areaLevel": 3,
              "areaParentId": 230600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230700,
          "id": 743,
          "areaName": "伊春市",
          "areaCode": "0458",
          "areaShort": "Yichun",
          "areaInitials": null,
          "cityCode": "40",
          "center": "128.841125,47.727535",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230702,
              "id": 744,
              "areaName": "伊春区",
              "areaCode": "0458",
              "areaShort": "Yichun",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "128.907257,47.728237",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230703,
              "id": 745,
              "areaName": "南岔区",
              "areaCode": "0458",
              "areaShort": "Nancha",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.283467,47.138034",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230704,
              "id": 746,
              "areaName": "友好区",
              "areaCode": "0458",
              "areaShort": "Youhao",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "128.836291,47.841032",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230705,
              "id": 747,
              "areaName": "西林区",
              "areaCode": "0458",
              "areaShort": "Xilin",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.312851,47.480735",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230706,
              "id": 748,
              "areaName": "翠峦区",
              "areaCode": "0458",
              "areaShort": "Cuiluan",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "128.669754,47.726394",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230707,
              "id": 749,
              "areaName": "新青区",
              "areaCode": "0458",
              "areaShort": "Xinqing",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.533599,48.290455",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230708,
              "id": 750,
              "areaName": "美溪区",
              "areaCode": "0458",
              "areaShort": "Meixi",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.129314,47.63509",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230709,
              "id": 751,
              "areaName": "金山屯区",
              "areaCode": "0458",
              "areaShort": "Jinshantun",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.429117,47.413074",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230710,
              "id": 752,
              "areaName": "五营区",
              "areaCode": "0458",
              "areaShort": "Wuying",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.245343,48.10791",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230711,
              "id": 753,
              "areaName": "乌马河区",
              "areaCode": "0458",
              "areaShort": "Wumahe",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "128.799477,47.727687",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230712,
              "id": 754,
              "areaName": "汤旺河区",
              "areaCode": "0458",
              "areaShort": "Tangwanghe",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.571108,48.454651",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230713,
              "id": 755,
              "areaName": "带岭区",
              "areaCode": "0458",
              "areaShort": "Dailing",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.020888,47.028379",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230714,
              "id": 756,
              "areaName": "乌伊岭区",
              "areaCode": "0458",
              "areaShort": "Wuyiling",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.43792,48.590322",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230715,
              "id": 757,
              "areaName": "红星区",
              "areaCode": "0458",
              "areaShort": "Hongxing",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.390983,48.239431",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230716,
              "id": 758,
              "areaName": "上甘岭区",
              "areaCode": "0458",
              "areaShort": "Shangganling",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "129.02426,47.974707",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230722,
              "id": 759,
              "areaName": "嘉荫县",
              "areaCode": "0458",
              "areaShort": "Jiayin",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "130.403134,48.888972",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230781,
              "id": 760,
              "areaName": "铁力市",
              "areaCode": "0458",
              "areaShort": "Tieli",
              "areaInitials": null,
              "cityCode": "0458",
              "center": "128.032424,46.986633",
              "areaLevel": 3,
              "areaParentId": 230700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230800,
          "id": 761,
          "areaName": "佳木斯市",
          "areaCode": "0454",
          "areaShort": "Jiamusi",
          "areaInitials": null,
          "cityCode": "42",
          "center": "130.318878,46.799777",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230803,
              "id": 762,
              "areaName": "向阳区",
              "areaCode": "0454",
              "areaShort": "Xiangyang",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "130.365346,46.80779",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230804,
              "id": 763,
              "areaName": "前进区",
              "areaCode": "0454",
              "areaShort": "Qianjin",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "130.375062,46.814102",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230805,
              "id": 764,
              "areaName": "东风区",
              "areaCode": "0454",
              "areaShort": "Dongfeng",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "130.403664,46.822571",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230811,
              "id": 765,
              "areaName": "郊区",
              "areaCode": "0454",
              "areaShort": "Jiaoqu",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "130.327194,46.810085",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230822,
              "id": 766,
              "areaName": "桦南县",
              "areaCode": "0454",
              "areaShort": "Huanan",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "130.553343,46.239184",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230826,
              "id": 767,
              "areaName": "桦川县",
              "areaCode": "0454",
              "areaShort": "Huachuan",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "130.71908,47.023001",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230828,
              "id": 768,
              "areaName": "汤原县",
              "areaCode": "0454",
              "areaShort": "Tangyuan",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "129.905072,46.730706",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230833,
              "id": 769,
              "areaName": "抚远县",
              "areaCode": null,
              "areaShort": "Fuyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230881,
              "id": 770,
              "areaName": "同江市",
              "areaCode": "0454",
              "areaShort": "Tongjiang",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "132.510919,47.642707",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230882,
              "id": 771,
              "areaName": "富锦市",
              "areaCode": "0454",
              "areaShort": "Fujin",
              "areaInitials": null,
              "cityCode": "0454",
              "center": "132.037686,47.250107",
              "areaLevel": 3,
              "areaParentId": 230800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 230900,
          "id": 772,
          "areaName": "七台河市",
          "areaCode": "0464",
          "areaShort": "Qitaihe",
          "areaInitials": null,
          "cityCode": "47",
          "center": "131.003082,45.771396",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 230902,
              "id": 773,
              "areaName": "新兴区",
              "areaCode": "0464",
              "areaShort": "Xinxing",
              "areaInitials": null,
              "cityCode": "0464",
              "center": "130.932143,45.81593",
              "areaLevel": 3,
              "areaParentId": 230900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230903,
              "id": 774,
              "areaName": "桃山区",
              "areaCode": "0464",
              "areaShort": "Taoshan",
              "areaInitials": null,
              "cityCode": "0464",
              "center": "131.020202,45.765705",
              "areaLevel": 3,
              "areaParentId": 230900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230904,
              "id": 775,
              "areaName": "茄子河区",
              "areaCode": "0464",
              "areaShort": "Qiezihe",
              "areaInitials": null,
              "cityCode": "0464",
              "center": "131.068075,45.785215",
              "areaLevel": 3,
              "areaParentId": 230900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 230921,
              "id": 776,
              "areaName": "勃利县",
              "areaCode": "0464",
              "areaShort": "Boli",
              "areaInitials": null,
              "cityCode": "0464",
              "center": "130.59217,45.755063",
              "areaLevel": 3,
              "areaParentId": 230900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 231000,
          "id": 777,
          "areaName": "牡丹江市",
          "areaCode": "0453",
          "areaShort": "Mudanjiang",
          "areaInitials": null,
          "cityCode": "49",
          "center": "129.633168,44.551653",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 231002,
              "id": 778,
              "areaName": "东安区",
              "areaCode": "0453",
              "areaShort": "Dong'an",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "129.626641,44.58136",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231003,
              "id": 779,
              "areaName": "阳明区",
              "areaCode": "0453",
              "areaShort": "Yangming",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "129.635615,44.596104",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231004,
              "id": 780,
              "areaName": "爱民区",
              "areaCode": "0453",
              "areaShort": "Aimin",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "129.591537,44.596042",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231005,
              "id": 781,
              "areaName": "西安区",
              "areaCode": "0453",
              "areaShort": "Xi'an",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "129.616058,44.577625",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231024,
              "id": 782,
              "areaName": "东宁县",
              "areaCode": null,
              "areaShort": "Dongning",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231025,
              "id": 783,
              "areaName": "林口县",
              "areaCode": "0453",
              "areaShort": "Linkou",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "130.284033,45.278046",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231081,
              "id": 784,
              "areaName": "绥芬河市",
              "areaCode": "0453",
              "areaShort": "Suifenhe",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "131.152545,44.412308",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231083,
              "id": 785,
              "areaName": "海林市",
              "areaCode": "0453",
              "areaShort": "Hailin",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "129.380481,44.594213",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231084,
              "id": 786,
              "areaName": "宁安市",
              "areaCode": "0453",
              "areaShort": "Ning'an",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "129.482851,44.34072",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231085,
              "id": 787,
              "areaName": "穆棱市",
              "areaCode": "0453",
              "areaShort": "Muling",
              "areaInitials": null,
              "cityCode": "0453",
              "center": "130.524436,44.918813",
              "areaLevel": 3,
              "areaParentId": 231000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 231100,
          "id": 788,
          "areaName": "黑河市",
          "areaCode": "0456",
          "areaShort": "Heihe",
          "areaInitials": null,
          "cityCode": "39",
          "center": "127.528293,50.245129",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 231102,
              "id": 789,
              "areaName": "爱辉区",
              "areaCode": "0456",
              "areaShort": "Aihui",
              "areaInitials": null,
              "cityCode": "0456",
              "center": "127.50045,50.252105",
              "areaLevel": 3,
              "areaParentId": 231100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231121,
              "id": 790,
              "areaName": "嫩江县",
              "areaCode": "0456",
              "areaShort": "Nenjiang",
              "areaInitials": null,
              "cityCode": "0456",
              "center": "125.221192,49.185766",
              "areaLevel": 3,
              "areaParentId": 231100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231123,
              "id": 791,
              "areaName": "逊克县",
              "areaCode": "0456",
              "areaShort": "Xunke",
              "areaInitials": null,
              "cityCode": "0456",
              "center": "128.478749,49.564252",
              "areaLevel": 3,
              "areaParentId": 231100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231124,
              "id": 792,
              "areaName": "孙吴县",
              "areaCode": "0456",
              "areaShort": "Sunwu",
              "areaInitials": null,
              "cityCode": "0456",
              "center": "127.336303,49.425647",
              "areaLevel": 3,
              "areaParentId": 231100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231181,
              "id": 793,
              "areaName": "北安市",
              "areaCode": "0456",
              "areaShort": "Bei'an",
              "areaInitials": null,
              "cityCode": "0456",
              "center": "126.490864,48.241365",
              "areaLevel": 3,
              "areaParentId": 231100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231182,
              "id": 794,
              "areaName": "五大连池市",
              "areaCode": "0456",
              "areaShort": "Wudalianchi",
              "areaInitials": null,
              "cityCode": "0456",
              "center": "126.205516,48.517257",
              "areaLevel": 3,
              "areaParentId": 231100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 231200,
          "id": 795,
          "areaName": "绥化市",
          "areaCode": "0455",
          "areaShort": "Suihua",
          "areaInitials": null,
          "cityCode": "44",
          "center": "126.968887,46.653845",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 231202,
              "id": 796,
              "areaName": "北林区",
              "areaCode": "0455",
              "areaShort": "Beilin",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "126.985504,46.6375",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231221,
              "id": 797,
              "areaName": "望奎县",
              "areaCode": "0455",
              "areaShort": "Wangkui",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "126.486075,46.832719",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231222,
              "id": 798,
              "areaName": "兰西县",
              "areaCode": "0455",
              "areaShort": "Lanxi",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "126.288117,46.25245",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231223,
              "id": 799,
              "areaName": "青冈县",
              "areaCode": "0455",
              "areaShort": "Qinggang",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "126.099195,46.70391",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231224,
              "id": 800,
              "areaName": "庆安县",
              "areaCode": "0455",
              "areaShort": "Qing'an",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "127.507824,46.880102",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231225,
              "id": 801,
              "areaName": "明水县",
              "areaCode": "0455",
              "areaShort": "Mingshui",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "125.906301,47.173426",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231226,
              "id": 802,
              "areaName": "绥棱县",
              "areaCode": "0455",
              "areaShort": "Suileng",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "127.114832,47.236015",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231281,
              "id": 803,
              "areaName": "安达市",
              "areaCode": "0455",
              "areaShort": "Anda",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "125.346156,46.419633",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231282,
              "id": 804,
              "areaName": "肇东市",
              "areaCode": "0455",
              "areaShort": "Zhaodong",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "125.961814,46.051126",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 231283,
              "id": 805,
              "areaName": "海伦市",
              "areaCode": "0455",
              "areaShort": "Hailun",
              "areaInitials": null,
              "cityCode": "0455",
              "center": "126.930106,47.45117",
              "areaLevel": 3,
              "areaParentId": 231200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 232700,
          "id": 806,
          "areaName": "大兴安岭地区",
          "areaCode": "0457",
          "areaShort": "DaXingAnLing",
          "areaInitials": null,
          "cityCode": "38",
          "center": "124.711526,52.335262",
          "areaLevel": 2,
          "areaParentId": 230000,
          "areaDictionaryVOList": [
            {
              "areaId": 232701,
              "id": 807,
              "areaName": "加格达奇区",
              "areaCode": "0457",
              "areaShort": "Jiagedaqi",
              "areaInitials": null,
              "cityCode": "0457",
              "center": "124.139595,50.408735",
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 232702,
              "id": 808,
              "areaName": "新林区",
              "areaCode": null,
              "areaShort": "Xinlin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 232703,
              "id": 809,
              "areaName": "松岭区",
              "areaCode": null,
              "areaShort": "Songling",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 232704,
              "id": 810,
              "areaName": "呼中区",
              "areaCode": null,
              "areaShort": "Huzhong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 232721,
              "id": 811,
              "areaName": "呼玛县",
              "areaCode": "0457",
              "areaShort": "Huma",
              "areaInitials": null,
              "cityCode": "0457",
              "center": "126.652396,51.726091",
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 232722,
              "id": 812,
              "areaName": "塔河县",
              "areaCode": "0457",
              "areaShort": "Tahe",
              "areaInitials": null,
              "cityCode": "0457",
              "center": "124.709996,52.334456",
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 232723,
              "id": 813,
              "areaName": "漠河县",
              "areaCode": "0457",
              "areaShort": "Mohe",
              "areaInitials": null,
              "cityCode": "0457",
              "center": "122.538591,52.972272",
              "areaLevel": 3,
              "areaParentId": 232700,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 310000,
      "id": 814,
      "areaName": "上海",
      "areaCode": "021",
      "areaShort": "Shanghai",
      "areaInitials": null,
      "cityCode": "021",
      "center": "121.473662,31.230372",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 310100,
          "id": 815,
          "areaName": "上海市",
          "areaCode": "021",
          "areaShort": "Shanghai",
          "areaInitials": null,
          "cityCode": "289",
          "center": "121.473662,31.230372",
          "areaLevel": 2,
          "areaParentId": 310000,
          "areaDictionaryVOList": [
            {
              "areaId": 310101,
              "id": 816,
              "areaName": "黄浦区",
              "areaCode": "021",
              "areaShort": "Huangpu",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.484428,31.231739",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310104,
              "id": 817,
              "areaName": "徐汇区",
              "areaCode": "021",
              "areaShort": "Xuhui",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.436128,31.188464",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310105,
              "id": 818,
              "areaName": "长宁区",
              "areaCode": "021",
              "areaShort": "Changning",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.424622,31.220372",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310106,
              "id": 819,
              "areaName": "静安区",
              "areaCode": "021",
              "areaShort": "Jing'an",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.447453,31.227906",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310107,
              "id": 820,
              "areaName": "普陀区",
              "areaCode": "021",
              "areaShort": "Putuo",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.395514,31.249603",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310108,
              "id": 821,
              "areaName": "闸北区",
              "areaCode": null,
              "areaShort": "Zhabei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310109,
              "id": 822,
              "areaName": "虹口区",
              "areaCode": "021",
              "areaShort": "Hongkou",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.505133,31.2646",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310110,
              "id": 823,
              "areaName": "杨浦区",
              "areaCode": "021",
              "areaShort": "Yangpu",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.525727,31.259822",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310112,
              "id": 824,
              "areaName": "闵行区",
              "areaCode": "021",
              "areaShort": "Minhang",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.380831,31.1129",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310113,
              "id": 825,
              "areaName": "宝山区",
              "areaCode": "021",
              "areaShort": "Baoshan",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.489612,31.405457",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310114,
              "id": 826,
              "areaName": "嘉定区",
              "areaCode": "021",
              "areaShort": "Jiading",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.265374,31.375869",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310115,
              "id": 827,
              "areaName": "浦东新区",
              "areaCode": "021",
              "areaShort": "Pudong",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.544379,31.221517",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310116,
              "id": 828,
              "areaName": "金山区",
              "areaCode": "021",
              "areaShort": "Jinshan",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.342455,30.741798",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310117,
              "id": 829,
              "areaName": "松江区",
              "areaCode": "021",
              "areaShort": "Songjiang",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.227747,31.032243",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310118,
              "id": 830,
              "areaName": "青浦区",
              "areaCode": "021",
              "areaShort": "Qingpu",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.124178,31.150681",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310120,
              "id": 831,
              "areaName": "奉贤区",
              "areaCode": "021",
              "areaShort": "Fengxian",
              "areaInitials": null,
              "cityCode": "021",
              "center": "121.474055,30.917766",
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 310230,
              "id": 832,
              "areaName": "崇明县",
              "areaCode": null,
              "areaShort": "Chongming",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 310100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 320000,
      "id": 833,
      "areaName": "江苏省",
      "areaCode": "[]",
      "areaShort": "Jiangsu",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "118.762765,32.060875",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 320100,
          "id": 834,
          "areaName": "南京市",
          "areaCode": "025",
          "areaShort": "Nanjing",
          "areaInitials": null,
          "cityCode": "315",
          "center": "118.796682,32.05957",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320102,
              "id": 835,
              "areaName": "玄武区",
              "areaCode": "025",
              "areaShort": "Xuanwu",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.797757,32.048498",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320104,
              "id": 836,
              "areaName": "秦淮区",
              "areaCode": "025",
              "areaShort": "Qinhuai",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.79476,32.039113",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320105,
              "id": 837,
              "areaName": "建邺区",
              "areaCode": "025",
              "areaShort": "Jianye",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.731793,32.003731",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320106,
              "id": 838,
              "areaName": "鼓楼区",
              "areaCode": "025",
              "areaShort": "Gulou",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.770182,32.066601",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320111,
              "id": 839,
              "areaName": "浦口区",
              "areaCode": "025",
              "areaShort": "Pukou",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.628003,32.058903",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320113,
              "id": 840,
              "areaName": "栖霞区",
              "areaCode": "025",
              "areaShort": "Qixia",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.909153,32.096388",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320114,
              "id": 841,
              "areaName": "雨花台区",
              "areaCode": "025",
              "areaShort": "Yuhuatai",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.779051,31.99126",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320115,
              "id": 842,
              "areaName": "江宁区",
              "areaCode": "025",
              "areaShort": "Jiangning",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.840015,31.952612",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320116,
              "id": 843,
              "areaName": "六合区",
              "areaCode": "025",
              "areaShort": "Luhe",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.822132,32.323584",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320117,
              "id": 844,
              "areaName": "溧水区",
              "areaCode": "025",
              "areaShort": "Lishui",
              "areaInitials": null,
              "cityCode": "025",
              "center": "119.028288,31.651099",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320118,
              "id": 845,
              "areaName": "高淳区",
              "areaCode": "025",
              "areaShort": "Gaochun",
              "areaInitials": null,
              "cityCode": "025",
              "center": "118.89222,31.327586",
              "areaLevel": 3,
              "areaParentId": 320100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320200,
          "id": 846,
          "areaName": "无锡市",
          "areaCode": "0510",
          "areaShort": "Wuxi",
          "areaInitials": null,
          "cityCode": "317",
          "center": "120.31191,31.491169",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320202,
              "id": 847,
              "areaName": "崇安区",
              "areaCode": null,
              "areaShort": "Chong'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320203,
              "id": 848,
              "areaName": "南长区",
              "areaCode": null,
              "areaShort": "Nanchang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320204,
              "id": 849,
              "areaName": "北塘区",
              "areaCode": null,
              "areaShort": "Beitang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320205,
              "id": 850,
              "areaName": "锡山区",
              "areaCode": "0510",
              "areaShort": "Xishan",
              "areaInitials": null,
              "cityCode": "0510",
              "center": "120.357858,31.589715",
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320206,
              "id": 851,
              "areaName": "惠山区",
              "areaCode": "0510",
              "areaShort": "Huishan",
              "areaInitials": null,
              "cityCode": "0510",
              "center": "120.298433,31.680335",
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320211,
              "id": 852,
              "areaName": "滨湖区",
              "areaCode": "0510",
              "areaShort": "Binhu",
              "areaInitials": null,
              "cityCode": "0510",
              "center": "120.283811,31.527276",
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320281,
              "id": 853,
              "areaName": "江阴市",
              "areaCode": "0510",
              "areaShort": "Jiangyin",
              "areaInitials": null,
              "cityCode": "0510",
              "center": "120.286129,31.921345",
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320282,
              "id": 854,
              "areaName": "宜兴市",
              "areaCode": "0510",
              "areaShort": "Yixing",
              "areaInitials": null,
              "cityCode": "0510",
              "center": "119.823308,31.340637",
              "areaLevel": 3,
              "areaParentId": 320200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320300,
          "id": 855,
          "areaName": "徐州市",
          "areaCode": "0516",
          "areaShort": "Xuzhou",
          "areaInitials": null,
          "cityCode": "316",
          "center": "117.284124,34.205768",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320302,
              "id": 856,
              "areaName": "鼓楼区",
              "areaCode": "0516",
              "areaShort": "Gulou",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "117.185576,34.288646",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320303,
              "id": 857,
              "areaName": "云龙区",
              "areaCode": "0516",
              "areaShort": "Yunlong",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "117.251076,34.253164",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320305,
              "id": 858,
              "areaName": "贾汪区",
              "areaCode": "0516",
              "areaShort": "Jiawang",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "117.464958,34.436936",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320311,
              "id": 859,
              "areaName": "泉山区",
              "areaCode": "0516",
              "areaShort": "Quanshan",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "117.194469,34.225522",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320312,
              "id": 860,
              "areaName": "铜山区",
              "areaCode": "0516",
              "areaShort": "Tongshan",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "117.169461,34.180779",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320321,
              "id": 861,
              "areaName": "丰县",
              "areaCode": "0516",
              "areaShort": "Fengxian",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "116.59539,34.693906",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320322,
              "id": 862,
              "areaName": "沛县",
              "areaCode": "0516",
              "areaShort": "Peixian",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "116.936353,34.760761",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320324,
              "id": 863,
              "areaName": "睢宁县",
              "areaCode": "0516",
              "areaShort": "Suining",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "117.941563,33.912597",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320381,
              "id": 864,
              "areaName": "新沂市",
              "areaCode": "0516",
              "areaShort": "Xinyi",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "118.354537,34.36958",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320382,
              "id": 865,
              "areaName": "邳州市",
              "areaCode": "0516",
              "areaShort": "Pizhou",
              "areaInitials": null,
              "cityCode": "0516",
              "center": "118.012531,34.338888",
              "areaLevel": 3,
              "areaParentId": 320300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320400,
          "id": 866,
          "areaName": "常州市",
          "areaCode": "0519",
          "areaShort": "Changzhou",
          "areaInitials": null,
          "cityCode": "348",
          "center": "119.974061,31.811226",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320402,
              "id": 867,
              "areaName": "天宁区",
              "areaCode": "0519",
              "areaShort": "Tianning",
              "areaInitials": null,
              "cityCode": "0519",
              "center": "119.999219,31.792787",
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320404,
              "id": 868,
              "areaName": "钟楼区",
              "areaCode": "0519",
              "areaShort": "Zhonglou",
              "areaInitials": null,
              "cityCode": "0519",
              "center": "119.902369,31.802089",
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320405,
              "id": 869,
              "areaName": "戚墅堰区",
              "areaCode": null,
              "areaShort": "Qishuyan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320411,
              "id": 870,
              "areaName": "新北区",
              "areaCode": "0519",
              "areaShort": "Xinbei",
              "areaInitials": null,
              "cityCode": "0519",
              "center": "119.971697,31.830427",
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320412,
              "id": 871,
              "areaName": "武进区",
              "areaCode": "0519",
              "areaShort": "Wujin",
              "areaInitials": null,
              "cityCode": "0519",
              "center": "119.942437,31.701187",
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320481,
              "id": 872,
              "areaName": "溧阳市",
              "areaCode": "0519",
              "areaShort": "Liyang",
              "areaInitials": null,
              "cityCode": "0519",
              "center": "119.48421,31.416911",
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320482,
              "id": 873,
              "areaName": "金坛市",
              "areaCode": null,
              "areaShort": "Jintan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320500,
          "id": 874,
          "areaName": "苏州市",
          "areaCode": "0512",
          "areaShort": "Suzhou",
          "areaInitials": null,
          "cityCode": "224",
          "center": "120.585728,31.2974",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320505,
              "id": 875,
              "areaName": "虎丘区",
              "areaCode": "0512",
              "areaShort": "Huqiu",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.434238,31.329601",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320506,
              "id": 876,
              "areaName": "吴中区",
              "areaCode": "0512",
              "areaShort": "Wuzhong",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.632308,31.263183",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320507,
              "id": 877,
              "areaName": "相城区",
              "areaCode": "0512",
              "areaShort": "Xiangcheng",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.642626,31.369089",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320508,
              "id": 878,
              "areaName": "姑苏区",
              "areaCode": "0512",
              "areaShort": "Gusu",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.617369,31.33565",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320509,
              "id": 879,
              "areaName": "吴江区",
              "areaCode": "0512",
              "areaShort": "Wujiang",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.645157,31.138677",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320581,
              "id": 880,
              "areaName": "常熟市",
              "areaCode": "0512",
              "areaShort": "Changshu",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.752481,31.654375",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320582,
              "id": 881,
              "areaName": "张家港市",
              "areaCode": "0512",
              "areaShort": "Zhangjiagang",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.555982,31.875571",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320583,
              "id": 882,
              "areaName": "昆山市",
              "areaCode": "0512",
              "areaShort": "Kunshan",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "120.980736,31.385597",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320585,
              "id": 883,
              "areaName": "太仓市",
              "areaCode": "0512",
              "areaShort": "Taicang",
              "areaInitials": null,
              "cityCode": "0512",
              "center": "121.13055,31.457735",
              "areaLevel": 3,
              "areaParentId": 320500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320600,
          "id": 884,
          "areaName": "南通市",
          "areaCode": "0513",
          "areaShort": "Nantong",
          "areaInitials": null,
          "cityCode": "161",
          "center": "120.894676,31.981143",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320602,
              "id": 885,
              "areaName": "崇川区",
              "areaCode": "0513",
              "areaShort": "Chongchuan",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "120.857434,32.009875",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320611,
              "id": 886,
              "areaName": "港闸区",
              "areaCode": "0513",
              "areaShort": "Gangzha",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "120.818526,32.032441",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320612,
              "id": 887,
              "areaName": "通州区",
              "areaCode": "0513",
              "areaShort": "Tongzhou",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "121.073828,32.06568",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320621,
              "id": 888,
              "areaName": "海安县",
              "areaCode": "0513",
              "areaShort": "Hai'an",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "120.467343,32.533572",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320623,
              "id": 889,
              "areaName": "如东县",
              "areaCode": "0513",
              "areaShort": "Rudong",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "121.185201,32.331765",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320681,
              "id": 890,
              "areaName": "启东市",
              "areaCode": "0513",
              "areaShort": "Qidong",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "121.655432,31.793278",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320682,
              "id": 891,
              "areaName": "如皋市",
              "areaCode": "0513",
              "areaShort": "Rugao",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "120.573803,32.371562",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320684,
              "id": 892,
              "areaName": "海门市",
              "areaCode": "0513",
              "areaShort": "Haimen",
              "areaInitials": null,
              "cityCode": "0513",
              "center": "121.18181,31.869483",
              "areaLevel": 3,
              "areaParentId": 320600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320700,
          "id": 893,
          "areaName": "连云港市",
          "areaCode": "0518",
          "areaShort": "Lianyungang",
          "areaInitials": null,
          "cityCode": "347",
          "center": "119.221611,34.596653",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320703,
              "id": 894,
              "areaName": "连云区",
              "areaCode": "0518",
              "areaShort": "Lianyun",
              "areaInitials": null,
              "cityCode": "0518",
              "center": "119.338788,34.760249",
              "areaLevel": 3,
              "areaParentId": 320700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320706,
              "id": 895,
              "areaName": "海州区",
              "areaCode": "0518",
              "areaShort": "Haizhou",
              "areaInitials": null,
              "cityCode": "0518",
              "center": "119.163509,34.572274",
              "areaLevel": 3,
              "areaParentId": 320700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320707,
              "id": 896,
              "areaName": "赣榆区",
              "areaCode": "0518",
              "areaShort": "Ganyu",
              "areaInitials": null,
              "cityCode": "0518",
              "center": "119.17333,34.841348",
              "areaLevel": 3,
              "areaParentId": 320700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320722,
              "id": 897,
              "areaName": "东海县",
              "areaCode": "0518",
              "areaShort": "Donghai",
              "areaInitials": null,
              "cityCode": "0518",
              "center": "118.752842,34.542308",
              "areaLevel": 3,
              "areaParentId": 320700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320723,
              "id": 898,
              "areaName": "灌云县",
              "areaCode": "0518",
              "areaShort": "Guanyun",
              "areaInitials": null,
              "cityCode": "0518",
              "center": "119.239381,34.284381",
              "areaLevel": 3,
              "areaParentId": 320700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320724,
              "id": 899,
              "areaName": "灌南县",
              "areaCode": "0518",
              "areaShort": "Guannan",
              "areaInitials": null,
              "cityCode": "0518",
              "center": "119.315651,34.087134",
              "areaLevel": 3,
              "areaParentId": 320700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320800,
          "id": 900,
          "areaName": "淮安市",
          "areaCode": "0517",
          "areaShort": "Huai'an",
          "areaInitials": null,
          "cityCode": "162",
          "center": "119.113185,33.551052",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320802,
              "id": 901,
              "areaName": "清河区",
              "areaCode": null,
              "areaShort": "Qinghe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320803,
              "id": 902,
              "areaName": "淮安区",
              "areaCode": "0517",
              "areaShort": "Huai'an",
              "areaInitials": null,
              "cityCode": "0517",
              "center": "119.141099,33.502868",
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320804,
              "id": 903,
              "areaName": "淮阴区",
              "areaCode": "0517",
              "areaShort": "Huaiyin",
              "areaInitials": null,
              "cityCode": "0517",
              "center": "119.034725,33.631892",
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320811,
              "id": 904,
              "areaName": "清浦区",
              "areaCode": null,
              "areaShort": "Qingpu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320826,
              "id": 905,
              "areaName": "涟水县",
              "areaCode": "0517",
              "areaShort": "Lianshui",
              "areaInitials": null,
              "cityCode": "0517",
              "center": "119.260227,33.781331",
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320829,
              "id": 906,
              "areaName": "洪泽县",
              "areaCode": null,
              "areaShort": "Hongze",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320830,
              "id": 907,
              "areaName": "盱眙县",
              "areaCode": "0517",
              "areaShort": "Xuyi",
              "areaInitials": null,
              "cityCode": "0517",
              "center": "118.54436,33.011971",
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320831,
              "id": 908,
              "areaName": "金湖县",
              "areaCode": "0517",
              "areaShort": "Jinhu",
              "areaInitials": null,
              "cityCode": "0517",
              "center": "119.020584,33.025433",
              "areaLevel": 3,
              "areaParentId": 320800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 320900,
          "id": 909,
          "areaName": "盐城市",
          "areaCode": "0515",
          "areaShort": "Yancheng",
          "areaInitials": null,
          "cityCode": "223",
          "center": "120.163107,33.347708",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 320902,
              "id": 910,
              "areaName": "亭湖区",
              "areaCode": "0515",
              "areaShort": "Tinghu",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "120.197358,33.390536",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320903,
              "id": 911,
              "areaName": "盐都区",
              "areaCode": "0515",
              "areaShort": "Yandu",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "120.153712,33.338283",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320921,
              "id": 912,
              "areaName": "响水县",
              "areaCode": "0515",
              "areaShort": "Xiangshui",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "119.578364,34.199479",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320922,
              "id": 913,
              "areaName": "滨海县",
              "areaCode": "0515",
              "areaShort": "Binhai",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "119.82083,33.990334",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320923,
              "id": 914,
              "areaName": "阜宁县",
              "areaCode": "0515",
              "areaShort": "Funing",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "119.802527,33.759325",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320924,
              "id": 915,
              "areaName": "射阳县",
              "areaCode": "0515",
              "areaShort": "Sheyang",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "120.229986,33.758361",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320925,
              "id": 916,
              "areaName": "建湖县",
              "areaCode": "0515",
              "areaShort": "Jianhu",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "119.7886,33.439067",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320981,
              "id": 917,
              "areaName": "东台市",
              "areaCode": "0515",
              "areaShort": "Dongtai",
              "areaInitials": null,
              "cityCode": "0515",
              "center": "120.320328,32.868426",
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 320982,
              "id": 918,
              "areaName": "大丰市",
              "areaCode": null,
              "areaShort": "Dafeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 320900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 321000,
          "id": 919,
          "areaName": "扬州市",
          "areaCode": "0514",
          "areaShort": "Yangzhou",
          "areaInitials": null,
          "cityCode": "346",
          "center": "119.412939,32.394209",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 321002,
              "id": 920,
              "areaName": "广陵区",
              "areaCode": "0514",
              "areaShort": "Guangling",
              "areaInitials": null,
              "cityCode": "0514",
              "center": "119.431849,32.39472",
              "areaLevel": 3,
              "areaParentId": 321000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321003,
              "id": 921,
              "areaName": "邗江区",
              "areaCode": "0514",
              "areaShort": "Hanjiang",
              "areaInitials": null,
              "cityCode": "0514",
              "center": "119.397994,32.377655",
              "areaLevel": 3,
              "areaParentId": 321000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321012,
              "id": 922,
              "areaName": "江都区",
              "areaCode": "0514",
              "areaShort": "Jiangdu",
              "areaInitials": null,
              "cityCode": "0514",
              "center": "119.569989,32.434672",
              "areaLevel": 3,
              "areaParentId": 321000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321023,
              "id": 923,
              "areaName": "宝应县",
              "areaCode": "0514",
              "areaShort": "Baoying",
              "areaInitials": null,
              "cityCode": "0514",
              "center": "119.360729,33.240391",
              "areaLevel": 3,
              "areaParentId": 321000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321081,
              "id": 924,
              "areaName": "仪征市",
              "areaCode": "0514",
              "areaShort": "Yizheng",
              "areaInitials": null,
              "cityCode": "0514",
              "center": "119.184766,32.272258",
              "areaLevel": 3,
              "areaParentId": 321000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321084,
              "id": 925,
              "areaName": "高邮市",
              "areaCode": "0514",
              "areaShort": "Gaoyou",
              "areaInitials": null,
              "cityCode": "0514",
              "center": "119.459161,32.781659",
              "areaLevel": 3,
              "areaParentId": 321000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 321100,
          "id": 926,
          "areaName": "镇江市",
          "areaCode": "0511",
          "areaShort": "Zhenjiang",
          "areaInitials": null,
          "cityCode": "160",
          "center": "119.425836,32.187849",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 321102,
              "id": 927,
              "areaName": "京口区",
              "areaCode": "0511",
              "areaShort": "Jingkou",
              "areaInitials": null,
              "cityCode": "0511",
              "center": "119.47016,32.19828",
              "areaLevel": 3,
              "areaParentId": 321100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321111,
              "id": 928,
              "areaName": "润州区",
              "areaCode": "0511",
              "areaShort": "Runzhou",
              "areaInitials": null,
              "cityCode": "0511",
              "center": "119.411959,32.195264",
              "areaLevel": 3,
              "areaParentId": 321100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321112,
              "id": 929,
              "areaName": "丹徒区",
              "areaCode": "0511",
              "areaShort": "Dantu",
              "areaInitials": null,
              "cityCode": "0511",
              "center": "119.433853,32.131962",
              "areaLevel": 3,
              "areaParentId": 321100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321181,
              "id": 930,
              "areaName": "丹阳市",
              "areaCode": "0511",
              "areaShort": "Danyang",
              "areaInitials": null,
              "cityCode": "0511",
              "center": "119.606439,32.010214",
              "areaLevel": 3,
              "areaParentId": 321100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321182,
              "id": 931,
              "areaName": "扬中市",
              "areaCode": "0511",
              "areaShort": "Yangzhong",
              "areaInitials": null,
              "cityCode": "0511",
              "center": "119.797634,32.23483",
              "areaLevel": 3,
              "areaParentId": 321100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321183,
              "id": 932,
              "areaName": "句容市",
              "areaCode": "0511",
              "areaShort": "Jurong",
              "areaInitials": null,
              "cityCode": "0511",
              "center": "119.168695,31.944998",
              "areaLevel": 3,
              "areaParentId": 321100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 321200,
          "id": 933,
          "areaName": "泰州市",
          "areaCode": "0523",
          "areaShort": "Taizhou",
          "areaInitials": null,
          "cityCode": "276",
          "center": "119.922933,32.455536",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 321202,
              "id": 934,
              "areaName": "海陵区",
              "areaCode": "0523",
              "areaShort": "Hailing",
              "areaInitials": null,
              "cityCode": "0523",
              "center": "119.919424,32.491016",
              "areaLevel": 3,
              "areaParentId": 321200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321203,
              "id": 935,
              "areaName": "高港区",
              "areaCode": "0523",
              "areaShort": "Gaogang",
              "areaInitials": null,
              "cityCode": "0523",
              "center": "119.881717,32.318821",
              "areaLevel": 3,
              "areaParentId": 321200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321204,
              "id": 936,
              "areaName": "姜堰区",
              "areaCode": "0523",
              "areaShort": "Jiangyan",
              "areaInitials": null,
              "cityCode": "0523",
              "center": "120.127934,32.509155",
              "areaLevel": 3,
              "areaParentId": 321200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321281,
              "id": 937,
              "areaName": "兴化市",
              "areaCode": "0523",
              "areaShort": "Xinghua",
              "areaInitials": null,
              "cityCode": "0523",
              "center": "119.852541,32.910459",
              "areaLevel": 3,
              "areaParentId": 321200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321282,
              "id": 938,
              "areaName": "靖江市",
              "areaCode": "0523",
              "areaShort": "Jingjiang",
              "areaInitials": null,
              "cityCode": "0523",
              "center": "120.277138,31.982751",
              "areaLevel": 3,
              "areaParentId": 321200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321283,
              "id": 939,
              "areaName": "泰兴市",
              "areaCode": "0523",
              "areaShort": "Taixing",
              "areaInitials": null,
              "cityCode": "0523",
              "center": "120.051743,32.171853",
              "areaLevel": 3,
              "areaParentId": 321200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 321300,
          "id": 940,
          "areaName": "宿迁市",
          "areaCode": "0527",
          "areaShort": "Suqian",
          "areaInitials": null,
          "cityCode": "277",
          "center": "118.275198,33.963232",
          "areaLevel": 2,
          "areaParentId": 320000,
          "areaDictionaryVOList": [
            {
              "areaId": 321302,
              "id": 941,
              "areaName": "宿城区",
              "areaCode": "0527",
              "areaShort": "Sucheng",
              "areaInitials": null,
              "cityCode": "0527",
              "center": "118.242533,33.963029",
              "areaLevel": 3,
              "areaParentId": 321300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321311,
              "id": 942,
              "areaName": "宿豫区",
              "areaCode": "0527",
              "areaShort": "Suyu",
              "areaInitials": null,
              "cityCode": "0527",
              "center": "118.330781,33.946822",
              "areaLevel": 3,
              "areaParentId": 321300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321322,
              "id": 943,
              "areaName": "沭阳县",
              "areaCode": "0527",
              "areaShort": "Shuyang",
              "areaInitials": null,
              "cityCode": "0527",
              "center": "118.804784,34.111022",
              "areaLevel": 3,
              "areaParentId": 321300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321323,
              "id": 944,
              "areaName": "泗阳县",
              "areaCode": "0527",
              "areaShort": "Siyang",
              "areaInitials": null,
              "cityCode": "0527",
              "center": "118.703424,33.722478",
              "areaLevel": 3,
              "areaParentId": 321300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 321324,
              "id": 945,
              "areaName": "泗洪县",
              "areaCode": "0527",
              "areaShort": "Sihong",
              "areaInitials": null,
              "cityCode": "0527",
              "center": "118.223591,33.476051",
              "areaLevel": 3,
              "areaParentId": 321300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 330000,
      "id": 946,
      "areaName": "浙江省",
      "areaCode": "[]",
      "areaShort": "Zhejiang",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "120.152585,30.266597",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 330100,
          "id": 947,
          "areaName": "杭州市",
          "areaCode": "0571",
          "areaShort": "Hangzhou",
          "areaInitials": null,
          "cityCode": "179",
          "center": "120.209789,30.24692",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330102,
              "id": 948,
              "areaName": "上城区",
              "areaCode": "0571",
              "areaShort": "Shangcheng",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.169312,30.242404",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330103,
              "id": 949,
              "areaName": "下城区",
              "areaCode": "0571",
              "areaShort": "Xiacheng",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.180891,30.281677",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330104,
              "id": 950,
              "areaName": "江干区",
              "areaCode": "0571",
              "areaShort": "Jianggan",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.205001,30.257012",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330105,
              "id": 951,
              "areaName": "拱墅区",
              "areaCode": "0571",
              "areaShort": "Gongshu",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.141406,30.319037",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330106,
              "id": 952,
              "areaName": "西湖区",
              "areaCode": "0571",
              "areaShort": "Xihu",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.130194,30.259463",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330108,
              "id": 953,
              "areaName": "滨江区",
              "areaCode": "0571",
              "areaShort": "Binjiang",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.211623,30.208847",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330109,
              "id": 954,
              "areaName": "萧山区",
              "areaCode": "0571",
              "areaShort": "Xiaoshan",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.264253,30.183806",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330110,
              "id": 955,
              "areaName": "余杭区",
              "areaCode": "0571",
              "areaShort": "Yuhang",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "120.299401,30.419045",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330122,
              "id": 956,
              "areaName": "桐庐县",
              "areaCode": "0571",
              "areaShort": "Tonglu",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "119.691467,29.79299",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330127,
              "id": 957,
              "areaName": "淳安县",
              "areaCode": "0571",
              "areaShort": "Chun'an",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "119.042037,29.608886",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330182,
              "id": 958,
              "areaName": "建德市",
              "areaCode": "0571",
              "areaShort": "Jiande",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "119.281231,29.474759",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330183,
              "id": 959,
              "areaName": "富阳区",
              "areaCode": null,
              "areaShort": "Fuyang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330185,
              "id": 960,
              "areaName": "临安市",
              "areaCode": "0571",
              "areaShort": "Lin'an",
              "areaInitials": null,
              "cityCode": "0571",
              "center": "119.724734,30.233873",
              "areaLevel": 3,
              "areaParentId": 330100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330200,
          "id": 961,
          "areaName": "宁波市",
          "areaCode": "0574",
          "areaShort": "Ningbo",
          "areaInitials": null,
          "cityCode": "180",
          "center": "121.622485,29.859971",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330203,
              "id": 962,
              "areaName": "海曙区",
              "areaCode": "0574",
              "areaShort": "Haishu",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.550752,29.874903",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330204,
              "id": 963,
              "areaName": "江东区",
              "areaCode": null,
              "areaShort": "Jiangdong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330205,
              "id": 964,
              "areaName": "江北区",
              "areaCode": "0574",
              "areaShort": "Jiangbei",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.555081,29.886781",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330206,
              "id": 965,
              "areaName": "北仑区",
              "areaCode": "0574",
              "areaShort": "Beilun",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.844172,29.899778",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330211,
              "id": 966,
              "areaName": "镇海区",
              "areaCode": "0574",
              "areaShort": "Zhenhai",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.596496,29.965203",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330212,
              "id": 967,
              "areaName": "鄞州区",
              "areaCode": "0574",
              "areaShort": "Yinzhou",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.546603,29.816511",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330225,
              "id": 968,
              "areaName": "象山县",
              "areaCode": "0574",
              "areaShort": "Xiangshan",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.869339,29.476705",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330226,
              "id": 969,
              "areaName": "宁海县",
              "areaCode": "0574",
              "areaShort": "Ninghai",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.429477,29.287939",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330281,
              "id": 970,
              "areaName": "余姚市",
              "areaCode": "0574",
              "areaShort": "Yuyao",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.154629,30.037106",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330282,
              "id": 971,
              "areaName": "慈溪市",
              "areaCode": "0574",
              "areaShort": "Cixi",
              "areaInitials": null,
              "cityCode": "0574",
              "center": "121.266561,30.170261",
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330283,
              "id": 972,
              "areaName": "奉化市",
              "areaCode": null,
              "areaShort": "Fenghua",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 330200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330300,
          "id": 973,
          "areaName": "温州市",
          "areaCode": "0577",
          "areaShort": "Wenzhou",
          "areaInitials": null,
          "cityCode": "178",
          "center": "120.699361,27.993828",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330302,
              "id": 974,
              "areaName": "鹿城区",
              "areaCode": "0577",
              "areaShort": "Lucheng",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.655271,28.015737",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330303,
              "id": 975,
              "areaName": "龙湾区",
              "areaCode": "0577",
              "areaShort": "Longwan",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.811213,27.932747",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330304,
              "id": 976,
              "areaName": "瓯海区",
              "areaCode": "0577",
              "areaShort": "Ouhai",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.61491,27.966844",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330322,
              "id": 977,
              "areaName": "洞头县",
              "areaCode": null,
              "areaShort": "Dongtou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330324,
              "id": 978,
              "areaName": "永嘉县",
              "areaCode": "0577",
              "areaShort": "Yongjia",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.692025,28.153607",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330326,
              "id": 979,
              "areaName": "平阳县",
              "areaCode": "0577",
              "areaShort": "Pingyang",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.565793,27.661918",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330327,
              "id": 980,
              "areaName": "苍南县",
              "areaCode": "0577",
              "areaShort": "Cangnan",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.427619,27.519773",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330328,
              "id": 981,
              "areaName": "文成县",
              "areaCode": "0577",
              "areaShort": "Wencheng",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.091498,27.786996",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330329,
              "id": 982,
              "areaName": "泰顺县",
              "areaCode": "0577",
              "areaShort": "Taishun",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "119.717649,27.556884",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330381,
              "id": 983,
              "areaName": "瑞安市",
              "areaCode": "0577",
              "areaShort": "Rui'an",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.655148,27.778657",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330382,
              "id": 984,
              "areaName": "乐清市",
              "areaCode": "0577",
              "areaShort": "Yueqing",
              "areaInitials": null,
              "cityCode": "0577",
              "center": "120.983906,28.113725",
              "areaLevel": 3,
              "areaParentId": 330300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330400,
          "id": 985,
          "areaName": "嘉兴市",
          "areaCode": "0573",
          "areaShort": "Jiaxing",
          "areaInitials": null,
          "cityCode": "334",
          "center": "120.75547,30.746191",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330402,
              "id": 986,
              "areaName": "南湖区",
              "areaCode": "0573",
              "areaShort": "Nanhu",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "120.783024,30.747842",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330411,
              "id": 987,
              "areaName": "秀洲区",
              "areaCode": "0573",
              "areaShort": "Xiuzhou",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "120.710082,30.765188",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330421,
              "id": 988,
              "areaName": "嘉善县",
              "areaCode": "0573",
              "areaShort": "Jiashan",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "120.926028,30.830864",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330424,
              "id": 989,
              "areaName": "海盐县",
              "areaCode": "0573",
              "areaShort": "Haiyan",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "120.946263,30.526435",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330481,
              "id": 990,
              "areaName": "海宁市",
              "areaCode": "0573",
              "areaShort": "Haining",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "120.680239,30.511539",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330482,
              "id": 991,
              "areaName": "平湖市",
              "areaCode": "0573",
              "areaShort": "Pinghu",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "121.015142,30.677233",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330483,
              "id": 992,
              "areaName": "桐乡市",
              "areaCode": "0573",
              "areaShort": "Tongxiang",
              "areaInitials": null,
              "cityCode": "0573",
              "center": "120.565098,30.630173",
              "areaLevel": 3,
              "areaParentId": 330400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330500,
          "id": 993,
          "areaName": "湖州市",
          "areaCode": "0572",
          "areaShort": "Huzhou",
          "areaInitials": null,
          "cityCode": "294",
          "center": "120.086809,30.89441",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330502,
              "id": 994,
              "areaName": "吴兴区",
              "areaCode": "0572",
              "areaShort": "Wuxing",
              "areaInitials": null,
              "cityCode": "0572",
              "center": "120.185838,30.857151",
              "areaLevel": 3,
              "areaParentId": 330500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330503,
              "id": 995,
              "areaName": "南浔区",
              "areaCode": "0572",
              "areaShort": "Nanxun",
              "areaInitials": null,
              "cityCode": "0572",
              "center": "120.418513,30.849689",
              "areaLevel": 3,
              "areaParentId": 330500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330521,
              "id": 996,
              "areaName": "德清县",
              "areaCode": "0572",
              "areaShort": "Deqing",
              "areaInitials": null,
              "cityCode": "0572",
              "center": "119.9774,30.54251",
              "areaLevel": 3,
              "areaParentId": 330500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330522,
              "id": 997,
              "areaName": "长兴县",
              "areaCode": "0572",
              "areaShort": "Changxing",
              "areaInitials": null,
              "cityCode": "0572",
              "center": "119.910952,31.026665",
              "areaLevel": 3,
              "areaParentId": 330500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330523,
              "id": 998,
              "areaName": "安吉县",
              "areaCode": "0572",
              "areaShort": "Anji",
              "areaInitials": null,
              "cityCode": "0572",
              "center": "119.680353,30.638674",
              "areaLevel": 3,
              "areaParentId": 330500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330600,
          "id": 999,
          "areaName": "绍兴市",
          "areaCode": "0575",
          "areaShort": "Shaoxing",
          "areaInitials": null,
          "cityCode": "293",
          "center": "120.580364,30.030192",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330602,
              "id": 1000,
              "areaName": "越城区",
              "areaCode": "0575",
              "areaShort": "Yuecheng",
              "areaInitials": null,
              "cityCode": "0575",
              "center": "120.582633,29.988244",
              "areaLevel": 3,
              "areaParentId": 330600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330603,
              "id": 1001,
              "areaName": "柯桥区",
              "areaCode": "0575",
              "areaShort": "Keqiao ",
              "areaInitials": null,
              "cityCode": "0575",
              "center": "120.495085,30.081929",
              "areaLevel": 3,
              "areaParentId": 330600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330604,
              "id": 1002,
              "areaName": "上虞区",
              "areaCode": "0575",
              "areaShort": "Shangyu",
              "areaInitials": null,
              "cityCode": "0575",
              "center": "120.868122,30.03312",
              "areaLevel": 3,
              "areaParentId": 330600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330624,
              "id": 1003,
              "areaName": "新昌县",
              "areaCode": "0575",
              "areaShort": "Xinchang",
              "areaInitials": null,
              "cityCode": "0575",
              "center": "120.903866,29.499831",
              "areaLevel": 3,
              "areaParentId": 330600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330681,
              "id": 1004,
              "areaName": "诸暨市",
              "areaCode": "0575",
              "areaShort": "Zhuji",
              "areaInitials": null,
              "cityCode": "0575",
              "center": "120.246863,29.708692",
              "areaLevel": 3,
              "areaParentId": 330600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330683,
              "id": 1005,
              "areaName": "嵊州市",
              "areaCode": "0575",
              "areaShort": "Shengzhou",
              "areaInitials": null,
              "cityCode": "0575",
              "center": "120.831025,29.56141",
              "areaLevel": 3,
              "areaParentId": 330600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330700,
          "id": 1006,
          "areaName": "金华市",
          "areaCode": "0579",
          "areaShort": "Jinhua",
          "areaInitials": null,
          "cityCode": "333",
          "center": "119.647229,29.079208",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330702,
              "id": 1007,
              "areaName": "婺城区",
              "areaCode": "0579",
              "areaShort": "Wucheng",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "119.571728,29.0872",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330703,
              "id": 1008,
              "areaName": "金东区",
              "areaCode": "0579",
              "areaShort": "Jindong",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "119.69278,29.099723",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330723,
              "id": 1009,
              "areaName": "武义县",
              "areaCode": "0579",
              "areaShort": "Wuyi",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "119.816562,28.89267",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330726,
              "id": 1010,
              "areaName": "浦江县",
              "areaCode": "0579",
              "areaShort": "Pujiang",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "119.892222,29.452476",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330727,
              "id": 1011,
              "areaName": "磐安县",
              "areaCode": "0579",
              "areaShort": "Pan'an",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "120.450005,29.054548",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330781,
              "id": 1012,
              "areaName": "兰溪市",
              "areaCode": "0579",
              "areaShort": "Lanxi",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "119.460472,29.2084",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330782,
              "id": 1013,
              "areaName": "义乌市",
              "areaCode": "0579",
              "areaShort": "Yiwu",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "120.075106,29.306775",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330783,
              "id": 1014,
              "areaName": "东阳市",
              "areaCode": "0579",
              "areaShort": "Dongyang",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "120.241566,29.289648",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330784,
              "id": 1015,
              "areaName": "永康市",
              "areaCode": "0579",
              "areaShort": "Yongkang",
              "areaInitials": null,
              "cityCode": "0579",
              "center": "120.047651,28.888555",
              "areaLevel": 3,
              "areaParentId": 330700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330800,
          "id": 1016,
          "areaName": "衢州市",
          "areaCode": "0570",
          "areaShort": "Quzhou",
          "areaInitials": null,
          "cityCode": "243",
          "center": "118.859457,28.970079",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330802,
              "id": 1017,
              "areaName": "柯城区",
              "areaCode": "0570",
              "areaShort": "Kecheng",
              "areaInitials": null,
              "cityCode": "0570",
              "center": "118.871516,28.96862",
              "areaLevel": 3,
              "areaParentId": 330800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330803,
              "id": 1018,
              "areaName": "衢江区",
              "areaCode": "0570",
              "areaShort": "Qujiang",
              "areaInitials": null,
              "cityCode": "0570",
              "center": "118.95946,28.97978",
              "areaLevel": 3,
              "areaParentId": 330800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330822,
              "id": 1019,
              "areaName": "常山县",
              "areaCode": "0570",
              "areaShort": "Changshan",
              "areaInitials": null,
              "cityCode": "0570",
              "center": "118.511235,28.901462",
              "areaLevel": 3,
              "areaParentId": 330800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330824,
              "id": 1020,
              "areaName": "开化县",
              "areaCode": "0570",
              "areaShort": "Kaihua",
              "areaInitials": null,
              "cityCode": "0570",
              "center": "118.415495,29.137336",
              "areaLevel": 3,
              "areaParentId": 330800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330825,
              "id": 1021,
              "areaName": "龙游县",
              "areaCode": "0570",
              "areaShort": "Longyou",
              "areaInitials": null,
              "cityCode": "0570",
              "center": "119.172189,29.028439",
              "areaLevel": 3,
              "areaParentId": 330800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330881,
              "id": 1022,
              "areaName": "江山市",
              "areaCode": "0570",
              "areaShort": "Jiangshan",
              "areaInitials": null,
              "cityCode": "0570",
              "center": "118.626991,28.737331",
              "areaLevel": 3,
              "areaParentId": 330800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 330900,
          "id": 1023,
          "areaName": "舟山市",
          "areaCode": "0580",
          "areaShort": "Zhoushan",
          "areaInitials": null,
          "cityCode": "245",
          "center": "122.207106,29.985553",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 330902,
              "id": 1024,
              "areaName": "定海区",
              "areaCode": "0580",
              "areaShort": "Dinghai",
              "areaInitials": null,
              "cityCode": "0580",
              "center": "122.106773,30.019858",
              "areaLevel": 3,
              "areaParentId": 330900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330903,
              "id": 1025,
              "areaName": "普陀区",
              "areaCode": "0580",
              "areaShort": "Putuo",
              "areaInitials": null,
              "cityCode": "0580",
              "center": "122.323867,29.97176",
              "areaLevel": 3,
              "areaParentId": 330900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330921,
              "id": 1026,
              "areaName": "岱山县",
              "areaCode": "0580",
              "areaShort": "Daishan",
              "areaInitials": null,
              "cityCode": "0580",
              "center": "122.226237,30.264139",
              "areaLevel": 3,
              "areaParentId": 330900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 330922,
              "id": 1027,
              "areaName": "嵊泗县",
              "areaCode": "0580",
              "areaShort": "Shengsi",
              "areaInitials": null,
              "cityCode": "0580",
              "center": "122.451382,30.725686",
              "areaLevel": 3,
              "areaParentId": 330900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 331000,
          "id": 1028,
          "areaName": "台州市",
          "areaCode": "0576",
          "areaShort": "Taizhou",
          "areaInitials": null,
          "cityCode": "244",
          "center": "121.42076,28.65638",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 331002,
              "id": 1029,
              "areaName": "椒江区",
              "areaCode": "0576",
              "areaShort": "Jiaojiang",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.442978,28.672981",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331003,
              "id": 1030,
              "areaName": "黄岩区",
              "areaCode": "0576",
              "areaShort": "Huangyan",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.261972,28.650083",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331004,
              "id": 1031,
              "areaName": "路桥区",
              "areaCode": "0576",
              "areaShort": "Luqiao",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.365123,28.582654",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331021,
              "id": 1032,
              "areaName": "玉环县",
              "areaCode": "0576",
              "areaShort": "Yuhuan",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.231805,28.135929",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331022,
              "id": 1033,
              "areaName": "三门县",
              "areaCode": "0576",
              "areaShort": "Sanmen",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.395711,29.104789",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331023,
              "id": 1034,
              "areaName": "天台县",
              "areaCode": "0576",
              "areaShort": "Tiantai",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.006595,29.144064",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331024,
              "id": 1035,
              "areaName": "仙居县",
              "areaCode": "0576",
              "areaShort": "Xianju",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "120.728801,28.846966",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331081,
              "id": 1036,
              "areaName": "温岭市",
              "areaCode": "0576",
              "areaShort": "Wenling",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.385604,28.372506",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331082,
              "id": 1037,
              "areaName": "临海市",
              "areaCode": "0576",
              "areaShort": "Linhai",
              "areaInitials": null,
              "cityCode": "0576",
              "center": "121.144556,28.858881",
              "areaLevel": 3,
              "areaParentId": 331000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 331100,
          "id": 1038,
          "areaName": "丽水市",
          "areaCode": "0578",
          "areaShort": "Lishui",
          "areaInitials": null,
          "cityCode": "292",
          "center": "119.922796,28.46763",
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 331102,
              "id": 1039,
              "areaName": "莲都区",
              "areaCode": "0578",
              "areaShort": "Liandu",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.912626,28.445928",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331121,
              "id": 1040,
              "areaName": "青田县",
              "areaCode": "0578",
              "areaShort": "Qingtian",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "120.289478,28.139837",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331122,
              "id": 1041,
              "areaName": "缙云县",
              "areaCode": "0578",
              "areaShort": "Jinyun",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "120.091572,28.659283",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331123,
              "id": 1042,
              "areaName": "遂昌县",
              "areaCode": "0578",
              "areaShort": "Suichang",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.276103,28.592148",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331124,
              "id": 1043,
              "areaName": "松阳县",
              "areaCode": "0578",
              "areaShort": "Songyang",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.481511,28.448803",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331125,
              "id": 1044,
              "areaName": "云和县",
              "areaCode": "0578",
              "areaShort": "Yunhe",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.573397,28.11579",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331126,
              "id": 1045,
              "areaName": "庆元县",
              "areaCode": "0578",
              "areaShort": "Qingyuan",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.06259,27.61922",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331127,
              "id": 1046,
              "areaName": "景宁畲族自治县",
              "areaCode": "0578",
              "areaShort": "Jingning",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.635739,27.9733",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331181,
              "id": 1047,
              "areaName": "龙泉市",
              "areaCode": "0578",
              "areaShort": "Longquan",
              "areaInitials": null,
              "cityCode": "0578",
              "center": "119.141473,28.074649",
              "areaLevel": 3,
              "areaParentId": 331100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 331200,
          "id": 1048,
          "areaName": "舟山群岛新区",
          "areaCode": null,
          "areaShort": "Zhoushan",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 330000,
          "areaDictionaryVOList": [
            {
              "areaId": 331201,
              "id": 1049,
              "areaName": "金塘岛",
              "areaCode": null,
              "areaShort": "Jintang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331202,
              "id": 1050,
              "areaName": "六横岛",
              "areaCode": null,
              "areaShort": "Liuheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331203,
              "id": 1051,
              "areaName": "衢山岛",
              "areaCode": null,
              "areaShort": "Qushan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331204,
              "id": 1052,
              "areaName": "舟山本岛西北部",
              "areaCode": null,
              "areaShort": "Zhoushan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331205,
              "id": 1053,
              "areaName": "岱山岛西南部",
              "areaCode": null,
              "areaShort": "Daishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331206,
              "id": 1054,
              "areaName": "泗礁岛",
              "areaCode": null,
              "areaShort": "Sijiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331207,
              "id": 1055,
              "areaName": "朱家尖岛",
              "areaCode": null,
              "areaShort": "Zhujiajian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331208,
              "id": 1056,
              "areaName": "洋山岛",
              "areaCode": null,
              "areaShort": "Yangshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331209,
              "id": 1057,
              "areaName": "长涂岛",
              "areaCode": null,
              "areaShort": "Changtu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 331210,
              "id": 1058,
              "areaName": "虾峙岛",
              "areaCode": null,
              "areaShort": "Xiazhi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 331200,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 340000,
      "id": 1059,
      "areaName": "安徽省",
      "areaCode": "[]",
      "areaShort": "Anhui",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "117.329949,31.733806",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 340100,
          "id": 1060,
          "areaName": "合肥市",
          "areaCode": "0551",
          "areaShort": "Hefei",
          "areaInitials": null,
          "cityCode": "127",
          "center": "117.227219,31.820591",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340102,
              "id": 1061,
              "areaName": "瑶海区",
              "areaCode": "0551",
              "areaShort": "Yaohai",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.309546,31.857917",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340103,
              "id": 1062,
              "areaName": "庐阳区",
              "areaCode": "0551",
              "areaShort": "Luyang",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.264786,31.878589",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340104,
              "id": 1063,
              "areaName": "蜀山区",
              "areaCode": "0551",
              "areaShort": "Shushan",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.260521,31.85124",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340111,
              "id": 1064,
              "areaName": "包河区",
              "areaCode": "0551",
              "areaShort": "Baohe",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.309519,31.793859",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340121,
              "id": 1065,
              "areaName": "长丰县",
              "areaCode": "0551",
              "areaShort": "Changfeng",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.167564,32.478018",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340122,
              "id": 1066,
              "areaName": "肥东县",
              "areaCode": "0551",
              "areaShort": "Feidong",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.469382,31.88794",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340123,
              "id": 1067,
              "areaName": "肥西县",
              "areaCode": "0551",
              "areaShort": "Feixi",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.157981,31.706809",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340124,
              "id": 1068,
              "areaName": "庐江县",
              "areaCode": "0551",
              "areaShort": "Lujiang",
              "areaInitials": null,
              "cityCode": "0551",
              "center": "117.2882,31.256524",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340181,
              "id": 1069,
              "areaName": "巢湖市",
              "areaCode": "0551",
              "areaShort": "Chaohu",
              "areaInitials": null,
              "cityCode": "251",
              "center": "117.890354,31.624522",
              "areaLevel": 3,
              "areaParentId": 340100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340200,
          "id": 1070,
          "areaName": "芜湖市",
          "areaCode": "0553",
          "areaShort": "Wuhu",
          "areaInitials": null,
          "cityCode": "129",
          "center": "118.432941,31.352859",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340202,
              "id": 1071,
              "areaName": "镜湖区",
              "areaCode": "0553",
              "areaShort": "Jinghu",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.385009,31.340728",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340203,
              "id": 1072,
              "areaName": "弋江区",
              "areaCode": "0553",
              "areaShort": "Yijiang",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.372655,31.311756",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340207,
              "id": 1073,
              "areaName": "鸠江区",
              "areaCode": "0553",
              "areaShort": "Jiujiang",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.391734,31.369373",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340208,
              "id": 1074,
              "areaName": "三山区",
              "areaCode": "0553",
              "areaShort": "Sanshan",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.268101,31.219568",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340221,
              "id": 1075,
              "areaName": "芜湖县",
              "areaCode": "0553",
              "areaShort": "Wuhu",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.576124,31.134809",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340222,
              "id": 1076,
              "areaName": "繁昌县",
              "areaCode": "0553",
              "areaShort": "Fanchang",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.198703,31.101782",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340223,
              "id": 1077,
              "areaName": "南陵县",
              "areaCode": "0553",
              "areaShort": "Nanling",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "118.334359,30.914922",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340225,
              "id": 1078,
              "areaName": "无为县",
              "areaCode": "0553",
              "areaShort": "Wuwei",
              "areaInitials": null,
              "cityCode": "0553",
              "center": "117.902366,31.303167",
              "areaLevel": 3,
              "areaParentId": 340200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340300,
          "id": 1079,
          "areaName": "蚌埠市",
          "areaCode": "0552",
          "areaShort": "Bengbu",
          "areaInitials": null,
          "cityCode": "126",
          "center": "117.388512,32.91663",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340302,
              "id": 1080,
              "areaName": "龙子湖区",
              "areaCode": "0552",
              "areaShort": "Longzihu",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.379778,32.950611",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340303,
              "id": 1081,
              "areaName": "蚌山区",
              "areaCode": "0552",
              "areaShort": "Bengshan",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.373595,32.917048",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340304,
              "id": 1082,
              "areaName": "禹会区",
              "areaCode": "0552",
              "areaShort": "Yuhui",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.342155,32.929799",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340311,
              "id": 1083,
              "areaName": "淮上区",
              "areaCode": "0552",
              "areaShort": "Huaishang",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.35933,32.965435",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340321,
              "id": 1084,
              "areaName": "怀远县",
              "areaCode": "0552",
              "areaShort": "Huaiyuan",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.205237,32.970031",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340322,
              "id": 1085,
              "areaName": "五河县",
              "areaCode": "0552",
              "areaShort": "Wuhe",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.879486,33.127823",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340323,
              "id": 1086,
              "areaName": "固镇县",
              "areaCode": "0552",
              "areaShort": "Guzhen",
              "areaInitials": null,
              "cityCode": "0552",
              "center": "117.316913,33.31688",
              "areaLevel": 3,
              "areaParentId": 340300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340400,
          "id": 1087,
          "areaName": "淮南市",
          "areaCode": "0554",
          "areaShort": "Huainan",
          "areaInitials": null,
          "cityCode": "250",
          "center": "117.018399,32.587117",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340402,
              "id": 1088,
              "areaName": "大通区",
              "areaCode": "0554",
              "areaShort": "Datong",
              "areaInitials": null,
              "cityCode": "0554",
              "center": "117.053314,32.631519",
              "areaLevel": 3,
              "areaParentId": 340400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340403,
              "id": 1089,
              "areaName": "田家庵区",
              "areaCode": "0554",
              "areaShort": "Tianjiaan",
              "areaInitials": null,
              "cityCode": "0554",
              "center": "117.017349,32.647277",
              "areaLevel": 3,
              "areaParentId": 340400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340404,
              "id": 1090,
              "areaName": "谢家集区",
              "areaCode": "0554",
              "areaShort": "Xiejiaji",
              "areaInitials": null,
              "cityCode": "0554",
              "center": "116.859188,32.600037",
              "areaLevel": 3,
              "areaParentId": 340400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340405,
              "id": 1091,
              "areaName": "八公山区",
              "areaCode": "0554",
              "areaShort": "Bagongshan",
              "areaInitials": null,
              "cityCode": "0554",
              "center": "116.83349,32.631379",
              "areaLevel": 3,
              "areaParentId": 340400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340406,
              "id": 1092,
              "areaName": "潘集区",
              "areaCode": "0554",
              "areaShort": "Panji",
              "areaInitials": null,
              "cityCode": "0554",
              "center": "116.834715,32.77208",
              "areaLevel": 3,
              "areaParentId": 340400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340421,
              "id": 1093,
              "areaName": "凤台县",
              "areaCode": "0554",
              "areaShort": "Fengtai",
              "areaInitials": null,
              "cityCode": "0554",
              "center": "116.71105,32.709444",
              "areaLevel": 3,
              "areaParentId": 340400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340500,
          "id": 1094,
          "areaName": "马鞍山市",
          "areaCode": "0555",
          "areaShort": "Ma'anshan",
          "areaInitials": null,
          "cityCode": "358",
          "center": "118.507011,31.67044",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340503,
              "id": 1095,
              "areaName": "花山区",
              "areaCode": "0555",
              "areaShort": "Huashan",
              "areaInitials": null,
              "cityCode": "0555",
              "center": "118.492565,31.71971",
              "areaLevel": 3,
              "areaParentId": 340500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340504,
              "id": 1096,
              "areaName": "雨山区",
              "areaCode": "0555",
              "areaShort": "Yushan",
              "areaInitials": null,
              "cityCode": "0555",
              "center": "118.498578,31.682132",
              "areaLevel": 3,
              "areaParentId": 340500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340506,
              "id": 1097,
              "areaName": "博望区",
              "areaCode": "0555",
              "areaShort": "Bowang",
              "areaInitials": null,
              "cityCode": "0555",
              "center": "118.844538,31.558471",
              "areaLevel": 3,
              "areaParentId": 340500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340521,
              "id": 1098,
              "areaName": "当涂县",
              "areaCode": "0555",
              "areaShort": "Dangtu",
              "areaInitials": null,
              "cityCode": "0555",
              "center": "118.497972,31.571213",
              "areaLevel": 3,
              "areaParentId": 340500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340522,
              "id": 1099,
              "areaName": "含山县",
              "areaCode": "0555",
              "areaShort": "Hanshan ",
              "areaInitials": null,
              "cityCode": "0555",
              "center": "118.101421,31.735598",
              "areaLevel": 3,
              "areaParentId": 340500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340523,
              "id": 1100,
              "areaName": "和县",
              "areaCode": "0555",
              "areaShort": "Hexian",
              "areaInitials": null,
              "cityCode": "0555",
              "center": "118.353667,31.742293",
              "areaLevel": 3,
              "areaParentId": 340500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340600,
          "id": 1101,
          "areaName": "淮北市",
          "areaCode": "0561",
          "areaShort": "Huaibei",
          "areaInitials": null,
          "cityCode": "253",
          "center": "116.798265,33.955844",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340602,
              "id": 1102,
              "areaName": "杜集区",
              "areaCode": "0561",
              "areaShort": "Duji",
              "areaInitials": null,
              "cityCode": "0561",
              "center": "116.828133,33.991451",
              "areaLevel": 3,
              "areaParentId": 340600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340603,
              "id": 1103,
              "areaName": "相山区",
              "areaCode": "0561",
              "areaShort": "Xiangshan",
              "areaInitials": null,
              "cityCode": "0561",
              "center": "116.794344,33.959892",
              "areaLevel": 3,
              "areaParentId": 340600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340604,
              "id": 1104,
              "areaName": "烈山区",
              "areaCode": "0561",
              "areaShort": "Lieshan",
              "areaInitials": null,
              "cityCode": "0561",
              "center": "116.813042,33.895139",
              "areaLevel": 3,
              "areaParentId": 340600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340621,
              "id": 1105,
              "areaName": "濉溪县",
              "areaCode": "0561",
              "areaShort": "Suixi",
              "areaInitials": null,
              "cityCode": "0561",
              "center": "116.766298,33.915477",
              "areaLevel": 3,
              "areaParentId": 340600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340700,
          "id": 1106,
          "areaName": "铜陵市",
          "areaCode": "0562",
          "areaShort": "Tongling",
          "areaInitials": null,
          "cityCode": "337",
          "center": "117.81154,30.945515",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340702,
              "id": 1107,
              "areaName": "铜官山区",
              "areaCode": null,
              "areaShort": "Tongguanshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 340700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340703,
              "id": 1108,
              "areaName": "狮子山区",
              "areaCode": null,
              "areaShort": "Shizishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 340700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340711,
              "id": 1109,
              "areaName": "郊区",
              "areaCode": "0562",
              "areaShort": "Jiaoqu",
              "areaInitials": null,
              "cityCode": "0562",
              "center": "117.768026,30.821069",
              "areaLevel": 3,
              "areaParentId": 340700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340721,
              "id": 1110,
              "areaName": "铜陵县",
              "areaCode": null,
              "areaShort": "Tongling",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 340700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 340800,
          "id": 1111,
          "areaName": "安庆市",
          "areaCode": "0556",
          "areaShort": "Anqing",
          "areaInitials": null,
          "cityCode": "130",
          "center": "117.115101,30.531919",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 340802,
              "id": 1112,
              "areaName": "迎江区",
              "areaCode": "0556",
              "areaShort": "Yingjiang",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "117.09115,30.511548",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340803,
              "id": 1113,
              "areaName": "大观区",
              "areaCode": "0556",
              "areaShort": "Daguan",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "117.013469,30.553697",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340811,
              "id": 1114,
              "areaName": "宜秀区",
              "areaCode": "0556",
              "areaShort": "Yixiu",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.987542,30.613332",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340822,
              "id": 1115,
              "areaName": "怀宁县",
              "areaCode": "0556",
              "areaShort": "Huaining",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.829475,30.733824",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340823,
              "id": 1116,
              "areaName": "枞阳县",
              "areaCode": null,
              "areaShort": "Zongyang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340824,
              "id": 1117,
              "areaName": "潜山县",
              "areaCode": "0556",
              "areaShort": "Qianshan",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.581371,30.631136",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340825,
              "id": 1118,
              "areaName": "太湖县",
              "areaCode": "0556",
              "areaShort": "Taihu",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.308795,30.45422",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340826,
              "id": 1119,
              "areaName": "宿松县",
              "areaCode": "0556",
              "areaShort": "Susong",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.129105,30.153746",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340827,
              "id": 1120,
              "areaName": "望江县",
              "areaCode": "0556",
              "areaShort": "Wangjiang",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.706498,30.128002",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340828,
              "id": 1121,
              "areaName": "岳西县",
              "areaCode": "0556",
              "areaShort": "Yuexi",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.359692,30.849762",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 340881,
              "id": 1122,
              "areaName": "桐城市",
              "areaCode": "0556",
              "areaShort": "Tongcheng",
              "areaInitials": null,
              "cityCode": "0556",
              "center": "116.936748,31.035848",
              "areaLevel": 3,
              "areaParentId": 340800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341000,
          "id": 1123,
          "areaName": "黄山市",
          "areaCode": "0559",
          "areaShort": "Huangshan",
          "areaInitials": null,
          "cityCode": "252",
          "center": "118.338272,29.715185",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341002,
              "id": 1124,
              "areaName": "屯溪区",
              "areaCode": "0559",
              "areaShort": "Tunxi",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "118.315329,29.696108",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341003,
              "id": 1125,
              "areaName": "黄山区",
              "areaCode": "0559",
              "areaShort": "Huangshan",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "118.141567,30.272942",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341004,
              "id": 1126,
              "areaName": "徽州区",
              "areaCode": "0559",
              "areaShort": "Huizhou",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "118.336743,29.827271",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341021,
              "id": 1127,
              "areaName": "歙县",
              "areaCode": "0559",
              "areaShort": "Shexian",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "118.415345,29.861379",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341022,
              "id": 1128,
              "areaName": "休宁县",
              "areaCode": "0559",
              "areaShort": "Xiuning",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "118.193618,29.784124",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341023,
              "id": 1129,
              "areaName": "黟县",
              "areaCode": "0559",
              "areaShort": "Yixian",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "117.938373,29.924805",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341024,
              "id": 1130,
              "areaName": "祁门县",
              "areaCode": "0559",
              "areaShort": "Qimen",
              "areaInitials": null,
              "cityCode": "0559",
              "center": "117.717396,29.854055",
              "areaLevel": 3,
              "areaParentId": 341000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341100,
          "id": 1131,
          "areaName": "滁州市",
          "areaCode": "0550",
          "areaShort": "Chuzhou",
          "areaInitials": null,
          "cityCode": "189",
          "center": "118.327944,32.255636",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341102,
              "id": 1132,
              "areaName": "琅琊区",
              "areaCode": "0550",
              "areaShort": "Langya",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "118.305961,32.294631",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341103,
              "id": 1133,
              "areaName": "南谯区",
              "areaCode": "0550",
              "areaShort": "Nanqiao",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "118.41697,32.200197",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341122,
              "id": 1134,
              "areaName": "来安县",
              "areaCode": "0550",
              "areaShort": "Lai'an",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "118.435718,32.452199",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341124,
              "id": 1135,
              "areaName": "全椒县",
              "areaCode": "0550",
              "areaShort": "Quanjiao",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "118.274149,32.08593",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341125,
              "id": 1136,
              "areaName": "定远县",
              "areaCode": "0550",
              "areaShort": "Dingyuan",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "117.698562,32.530981",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341126,
              "id": 1137,
              "areaName": "凤阳县",
              "areaCode": "0550",
              "areaShort": "Fengyang",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "117.531622,32.874735",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341181,
              "id": 1138,
              "areaName": "天长市",
              "areaCode": "0550",
              "areaShort": "Tianchang",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "119.004816,32.667571",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341182,
              "id": 1139,
              "areaName": "明光市",
              "areaCode": "0550",
              "areaShort": "Mingguang",
              "areaInitials": null,
              "cityCode": "0550",
              "center": "118.018193,32.78196",
              "areaLevel": 3,
              "areaParentId": 341100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341200,
          "id": 1140,
          "areaName": "阜阳市",
          "areaCode": "1558",
          "areaShort": "Fuyang",
          "areaInitials": null,
          "cityCode": "128",
          "center": "115.814504,32.890479",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341202,
              "id": 1141,
              "areaName": "颍州区",
              "areaCode": "1558",
              "areaShort": "Yingzhou",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.806942,32.883468",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341203,
              "id": 1142,
              "areaName": "颍东区",
              "areaCode": "1558",
              "areaShort": "Yingdong",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.856762,32.912477",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341204,
              "id": 1143,
              "areaName": "颍泉区",
              "areaCode": "1558",
              "areaShort": "Yingquan",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.80835,32.925211",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341221,
              "id": 1144,
              "areaName": "临泉县",
              "areaCode": "1558",
              "areaShort": "Linquan",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.263115,33.039715",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341222,
              "id": 1145,
              "areaName": "太和县",
              "areaCode": "1558",
              "areaShort": "Taihe",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.621941,33.160327",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341225,
              "id": 1146,
              "areaName": "阜南县",
              "areaCode": "1558",
              "areaShort": "Funan",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.595643,32.658297",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341226,
              "id": 1147,
              "areaName": "颍上县",
              "areaCode": "1558",
              "areaShort": "Yingshang",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "116.256772,32.653211",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341282,
              "id": 1148,
              "areaName": "界首市",
              "areaCode": "1558",
              "areaShort": "Jieshou",
              "areaInitials": null,
              "cityCode": "1558",
              "center": "115.374821,33.258244",
              "areaLevel": 3,
              "areaParentId": 341200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341300,
          "id": 1149,
          "areaName": "宿州市",
          "areaCode": "0557",
          "areaShort": "Suzhou",
          "areaInitials": null,
          "cityCode": "370",
          "center": "116.964195,33.647309",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341302,
              "id": 1150,
              "areaName": "埇桥区",
              "areaCode": "0557",
              "areaShort": "Yongqiao",
              "areaInitials": null,
              "cityCode": "0557",
              "center": "116.977203,33.64059",
              "areaLevel": 3,
              "areaParentId": 341300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341321,
              "id": 1151,
              "areaName": "砀山县",
              "areaCode": "0557",
              "areaShort": "Dangshan",
              "areaInitials": null,
              "cityCode": "0557",
              "center": "116.367095,34.442561",
              "areaLevel": 3,
              "areaParentId": 341300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341322,
              "id": 1152,
              "areaName": "萧县",
              "areaCode": "0557",
              "areaShort": "Xiaoxian",
              "areaInitials": null,
              "cityCode": "0557",
              "center": "116.947349,34.188732",
              "areaLevel": 3,
              "areaParentId": 341300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341323,
              "id": 1153,
              "areaName": "灵璧县",
              "areaCode": "0557",
              "areaShort": "Lingbi",
              "areaInitials": null,
              "cityCode": "0557",
              "center": "117.549395,33.554604",
              "areaLevel": 3,
              "areaParentId": 341300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341324,
              "id": 1154,
              "areaName": "泗县",
              "areaCode": "0557",
              "areaShort": "Sixian",
              "areaInitials": null,
              "cityCode": "0557",
              "center": "117.910629,33.482982",
              "areaLevel": 3,
              "areaParentId": 341300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341500,
          "id": 1155,
          "areaName": "六安市",
          "areaCode": "0564",
          "areaShort": "Lu'an",
          "areaInitials": null,
          "cityCode": "298",
          "center": "116.520139,31.735456",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341502,
              "id": 1156,
              "areaName": "金安区",
              "areaCode": "0564",
              "areaShort": "Jin'an",
              "areaInitials": null,
              "cityCode": "0564",
              "center": "116.539173,31.750119",
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341503,
              "id": 1157,
              "areaName": "裕安区",
              "areaCode": "0564",
              "areaShort": "Yu'an",
              "areaInitials": null,
              "cityCode": "0564",
              "center": "116.479829,31.738183",
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341521,
              "id": 1158,
              "areaName": "寿县",
              "areaCode": null,
              "areaShort": "Shouxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341522,
              "id": 1159,
              "areaName": "霍邱县",
              "areaCode": "0564",
              "areaShort": "Huoqiu",
              "areaInitials": null,
              "cityCode": "0564",
              "center": "116.277911,32.353038",
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341523,
              "id": 1160,
              "areaName": "舒城县",
              "areaCode": "0564",
              "areaShort": "Shucheng",
              "areaInitials": null,
              "cityCode": "0564",
              "center": "116.948736,31.462234",
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341524,
              "id": 1161,
              "areaName": "金寨县",
              "areaCode": "0564",
              "areaShort": "Jinzhai",
              "areaInitials": null,
              "cityCode": "0564",
              "center": "115.934366,31.72717",
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341525,
              "id": 1162,
              "areaName": "霍山县",
              "areaCode": "0564",
              "areaShort": "Huoshan",
              "areaInitials": null,
              "cityCode": "0564",
              "center": "116.351892,31.410561",
              "areaLevel": 3,
              "areaParentId": 341500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341600,
          "id": 1163,
          "areaName": "亳州市",
          "areaCode": "0558",
          "areaShort": "Bozhou",
          "areaInitials": null,
          "cityCode": "188",
          "center": "115.77867,33.844592",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341602,
              "id": 1164,
              "areaName": "谯城区",
              "areaCode": "0558",
              "areaShort": "Qiaocheng",
              "areaInitials": null,
              "cityCode": "0558",
              "center": "115.779025,33.876235",
              "areaLevel": 3,
              "areaParentId": 341600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341621,
              "id": 1165,
              "areaName": "涡阳县",
              "areaCode": "0558",
              "areaShort": "Guoyang",
              "areaInitials": null,
              "cityCode": "0558",
              "center": "116.215665,33.492921",
              "areaLevel": 3,
              "areaParentId": 341600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341622,
              "id": 1166,
              "areaName": "蒙城县",
              "areaCode": "0558",
              "areaShort": "Mengcheng",
              "areaInitials": null,
              "cityCode": "0558",
              "center": "116.564247,33.26583",
              "areaLevel": 3,
              "areaParentId": 341600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341623,
              "id": 1167,
              "areaName": "利辛县",
              "areaCode": "0558",
              "areaShort": "Lixin",
              "areaInitials": null,
              "cityCode": "0558",
              "center": "116.208564,33.144515",
              "areaLevel": 3,
              "areaParentId": 341600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341700,
          "id": 1168,
          "areaName": "池州市",
          "areaCode": "0566",
          "areaShort": "Chizhou",
          "areaInitials": null,
          "cityCode": "299",
          "center": "117.491592,30.664779",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341702,
              "id": 1169,
              "areaName": "贵池区",
              "areaCode": "0566",
              "areaShort": "Guichi",
              "areaInitials": null,
              "cityCode": "0566",
              "center": "117.567264,30.687219",
              "areaLevel": 3,
              "areaParentId": 341700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341721,
              "id": 1170,
              "areaName": "东至县",
              "areaCode": "0566",
              "areaShort": "Dongzhi",
              "areaInitials": null,
              "cityCode": "0566",
              "center": "117.027618,30.111163",
              "areaLevel": 3,
              "areaParentId": 341700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341722,
              "id": 1171,
              "areaName": "石台县",
              "areaCode": "0566",
              "areaShort": "Shitai",
              "areaInitials": null,
              "cityCode": "0566",
              "center": "117.486306,30.210313",
              "areaLevel": 3,
              "areaParentId": 341700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341723,
              "id": 1172,
              "areaName": "青阳县",
              "areaCode": "0566",
              "areaShort": "Qingyang",
              "areaInitials": null,
              "cityCode": "0566",
              "center": "117.84743,30.63923",
              "areaLevel": 3,
              "areaParentId": 341700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 341800,
          "id": 1173,
          "areaName": "宣城市",
          "areaCode": "0563",
          "areaShort": "Xuancheng",
          "areaInitials": null,
          "cityCode": "190",
          "center": "118.75868,30.940195",
          "areaLevel": 2,
          "areaParentId": 340000,
          "areaDictionaryVOList": [
            {
              "areaId": 341802,
              "id": 1174,
              "areaName": "宣州区",
              "areaCode": "0563",
              "areaShort": "Xuanzhou",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "118.785561,30.944076",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341821,
              "id": 1175,
              "areaName": "郎溪县",
              "areaCode": "0563",
              "areaShort": "Langxi",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "119.179656,31.126412",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341822,
              "id": 1176,
              "areaName": "广德县",
              "areaCode": "0563",
              "areaShort": "Guangde",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "119.420935,30.877555",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341823,
              "id": 1177,
              "areaName": "泾县",
              "areaCode": "0563",
              "areaShort": "Jingxian",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "118.419859,30.688634",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341824,
              "id": 1178,
              "areaName": "绩溪县",
              "areaCode": "0563",
              "areaShort": "Jixi",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "118.578519,30.067533",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341825,
              "id": 1179,
              "areaName": "旌德县",
              "areaCode": "0563",
              "areaShort": "Jingde",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "118.549861,30.298142",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 341881,
              "id": 1180,
              "areaName": "宁国市",
              "areaCode": "0563",
              "areaShort": "Ningguo",
              "areaInitials": null,
              "cityCode": "0563",
              "center": "118.983171,30.633882",
              "areaLevel": 3,
              "areaParentId": 341800,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 350000,
      "id": 1181,
      "areaName": "福建省",
      "areaCode": "[]",
      "areaShort": "Fujian",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "119.295143,26.100779",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 350100,
          "id": 1182,
          "areaName": "福州市",
          "areaCode": "0591",
          "areaShort": "Fuzhou",
          "areaInitials": null,
          "cityCode": "300",
          "center": "119.296389,26.074268",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350102,
              "id": 1183,
              "areaName": "鼓楼区",
              "areaCode": "0591",
              "areaShort": "Gulou",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.303917,26.081983",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350103,
              "id": 1184,
              "areaName": "台江区",
              "areaCode": "0591",
              "areaShort": "Taijiang",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.314041,26.052843",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350104,
              "id": 1185,
              "areaName": "仓山区",
              "areaCode": "0591",
              "areaShort": "Cangshan",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.273545,26.046743",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350105,
              "id": 1186,
              "areaName": "马尾区",
              "areaCode": "0591",
              "areaShort": "Mawei",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.455588,25.9895",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350111,
              "id": 1187,
              "areaName": "晋安区",
              "areaCode": "0591",
              "areaShort": "Jin'an",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.328521,26.082107",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350121,
              "id": 1188,
              "areaName": "闽侯县",
              "areaCode": "0591",
              "areaShort": "Minhou",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.131724,26.150047",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350122,
              "id": 1189,
              "areaName": "连江县",
              "areaCode": "0591",
              "areaShort": "Lianjiang",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.539704,26.197364",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350123,
              "id": 1190,
              "areaName": "罗源县",
              "areaCode": "0591",
              "areaShort": "Luoyuan",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.549776,26.489558",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350124,
              "id": 1191,
              "areaName": "闽清县",
              "areaCode": "0591",
              "areaShort": "Minqing",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "118.863361,26.221197",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350125,
              "id": 1192,
              "areaName": "永泰县",
              "areaCode": "0591",
              "areaShort": "Yongtai",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "118.932592,25.866694",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350128,
              "id": 1193,
              "areaName": "平潭县",
              "areaCode": "0591",
              "areaShort": "Pingtan",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.790168,25.49872",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350181,
              "id": 1194,
              "areaName": "福清市",
              "areaCode": "0591",
              "areaShort": "Fuqing",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.384201,25.72071",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350182,
              "id": 1195,
              "areaName": "长乐市",
              "areaCode": "0591",
              "areaShort": "Changle",
              "areaInitials": null,
              "cityCode": "0591",
              "center": "119.523266,25.962888",
              "areaLevel": 3,
              "areaParentId": 350100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350200,
          "id": 1196,
          "areaName": "厦门市",
          "areaCode": "0592",
          "areaShort": "Xiamen",
          "areaInitials": null,
          "cityCode": "194",
          "center": "118.089204,24.479664",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350203,
              "id": 1197,
              "areaName": "思明区",
              "areaCode": "0592",
              "areaShort": "Siming",
              "areaInitials": null,
              "cityCode": "0592",
              "center": "118.082649,24.445484",
              "areaLevel": 3,
              "areaParentId": 350200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350205,
              "id": 1198,
              "areaName": "海沧区",
              "areaCode": "0592",
              "areaShort": "Haicang",
              "areaInitials": null,
              "cityCode": "0592",
              "center": "118.032984,24.484685",
              "areaLevel": 3,
              "areaParentId": 350200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350206,
              "id": 1199,
              "areaName": "湖里区",
              "areaCode": "0592",
              "areaShort": "Huli",
              "areaInitials": null,
              "cityCode": "0592",
              "center": "118.146768,24.512904",
              "areaLevel": 3,
              "areaParentId": 350200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350211,
              "id": 1200,
              "areaName": "集美区",
              "areaCode": "0592",
              "areaShort": "Jimei",
              "areaInitials": null,
              "cityCode": "0592",
              "center": "118.097337,24.575969",
              "areaLevel": 3,
              "areaParentId": 350200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350212,
              "id": 1201,
              "areaName": "同安区",
              "areaCode": "0592",
              "areaShort": "Tong'an",
              "areaInitials": null,
              "cityCode": "0592",
              "center": "118.152041,24.723234",
              "areaLevel": 3,
              "areaParentId": 350200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350213,
              "id": 1202,
              "areaName": "翔安区",
              "areaCode": "0592",
              "areaShort": "Xiang'an",
              "areaInitials": null,
              "cityCode": "0592",
              "center": "118.248034,24.618543",
              "areaLevel": 3,
              "areaParentId": 350200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350300,
          "id": 1203,
          "areaName": "莆田市",
          "areaCode": "0594",
          "areaShort": "Putian",
          "areaInitials": null,
          "cityCode": "195",
          "center": "119.007777,25.454084",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350302,
              "id": 1204,
              "areaName": "城厢区",
              "areaCode": "0594",
              "areaShort": "Chengxiang",
              "areaInitials": null,
              "cityCode": "0594",
              "center": "118.993884,25.419319",
              "areaLevel": 3,
              "areaParentId": 350300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350303,
              "id": 1205,
              "areaName": "涵江区",
              "areaCode": "0594",
              "areaShort": "Hanjiang",
              "areaInitials": null,
              "cityCode": "0594",
              "center": "119.116289,25.45872",
              "areaLevel": 3,
              "areaParentId": 350300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350304,
              "id": 1206,
              "areaName": "荔城区",
              "areaCode": "0594",
              "areaShort": "Licheng",
              "areaInitials": null,
              "cityCode": "0594",
              "center": "119.015061,25.431941",
              "areaLevel": 3,
              "areaParentId": 350300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350305,
              "id": 1207,
              "areaName": "秀屿区",
              "areaCode": "0594",
              "areaShort": "Xiuyu",
              "areaInitials": null,
              "cityCode": "0594",
              "center": "119.105494,25.31836",
              "areaLevel": 3,
              "areaParentId": 350300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350322,
              "id": 1208,
              "areaName": "仙游县",
              "areaCode": "0594",
              "areaShort": "Xianyou",
              "areaInitials": null,
              "cityCode": "0594",
              "center": "118.691637,25.362093",
              "areaLevel": 3,
              "areaParentId": 350300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350400,
          "id": 1209,
          "areaName": "三明市",
          "areaCode": "0598",
          "areaShort": "Sanming",
          "areaInitials": null,
          "cityCode": "254",
          "center": "117.638678,26.263406",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350402,
              "id": 1210,
              "areaName": "梅列区",
              "areaCode": "0598",
              "areaShort": "Meilie",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.645855,26.271711",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350403,
              "id": 1211,
              "areaName": "三元区",
              "areaCode": "0598",
              "areaShort": "Sanyuan",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.608044,26.234019",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350421,
              "id": 1212,
              "areaName": "明溪县",
              "areaCode": "0598",
              "areaShort": "Mingxi",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.202226,26.355856",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350423,
              "id": 1213,
              "areaName": "清流县",
              "areaCode": "0598",
              "areaShort": "Qingliu",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "116.816909,26.177796",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350424,
              "id": 1214,
              "areaName": "宁化县",
              "areaCode": "0598",
              "areaShort": "Ninghua",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "116.654365,26.261754",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350425,
              "id": 1215,
              "areaName": "大田县",
              "areaCode": "0598",
              "areaShort": "Datian",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.847115,25.692699",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350426,
              "id": 1216,
              "areaName": "尤溪县",
              "areaCode": "0598",
              "areaShort": "Youxi",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "118.190467,26.170171",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350427,
              "id": 1217,
              "areaName": "沙县",
              "areaCode": "0598",
              "areaShort": "Shaxian",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.792396,26.397199",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350428,
              "id": 1218,
              "areaName": "将乐县",
              "areaCode": "0598",
              "areaShort": "Jiangle",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.471372,26.728952",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350429,
              "id": 1219,
              "areaName": "泰宁县",
              "areaCode": "0598",
              "areaShort": "Taining",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.17574,26.900259",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350430,
              "id": 1220,
              "areaName": "建宁县",
              "areaCode": "0598",
              "areaShort": "Jianning",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "116.848443,26.833588",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350481,
              "id": 1221,
              "areaName": "永安市",
              "areaCode": "0598",
              "areaShort": "Yong'an",
              "areaInitials": null,
              "cityCode": "0598",
              "center": "117.365052,25.941937",
              "areaLevel": 3,
              "areaParentId": 350400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350500,
          "id": 1222,
          "areaName": "泉州市",
          "areaCode": "0595",
          "areaShort": "Quanzhou",
          "areaInitials": null,
          "cityCode": "134",
          "center": "118.675676,24.874132",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350502,
              "id": 1223,
              "areaName": "鲤城区",
              "areaCode": "0595",
              "areaShort": "Licheng",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.587097,24.907424",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350503,
              "id": 1224,
              "areaName": "丰泽区",
              "areaCode": "0595",
              "areaShort": "Fengze",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.613172,24.891173",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350504,
              "id": 1225,
              "areaName": "洛江区",
              "areaCode": "0595",
              "areaShort": "Luojiang",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.671193,24.939796",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350505,
              "id": 1226,
              "areaName": "泉港区",
              "areaCode": "0595",
              "areaShort": "Quangang",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.916309,25.119815",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350521,
              "id": 1227,
              "areaName": "惠安县",
              "areaCode": "0595",
              "areaShort": "Hui'an",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.796607,25.030801",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350524,
              "id": 1228,
              "areaName": "安溪县",
              "areaCode": "0595",
              "areaShort": "Anxi",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.186288,25.055954",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350525,
              "id": 1229,
              "areaName": "永春县",
              "areaCode": "0595",
              "areaShort": "Yongchun",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.294048,25.321565",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350526,
              "id": 1230,
              "areaName": "德化县",
              "areaCode": "0595",
              "areaShort": "Dehua",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.241094,25.491493",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350527,
              "id": 1231,
              "areaName": "金门县",
              "areaCode": "0595",
              "areaShort": "Jinmen",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.323221,24.436417",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350581,
              "id": 1232,
              "areaName": "石狮市",
              "areaCode": "0595",
              "areaShort": "Shishi",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.648066,24.732204",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350582,
              "id": 1233,
              "areaName": "晋江市",
              "areaCode": "0595",
              "areaShort": "Jinjiang",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.551682,24.781636",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350583,
              "id": 1234,
              "areaName": "南安市",
              "areaCode": "0595",
              "areaShort": "Nan'an",
              "areaInitials": null,
              "cityCode": "0595",
              "center": "118.386279,24.960385",
              "areaLevel": 3,
              "areaParentId": 350500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350600,
          "id": 1235,
          "areaName": "漳州市",
          "areaCode": "0596",
          "areaShort": "Zhangzhou",
          "areaInitials": null,
          "cityCode": "255",
          "center": "117.647093,24.513025",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350602,
              "id": 1236,
              "areaName": "芗城区",
              "areaCode": "0596",
              "areaShort": "Xiangcheng",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.653968,24.510787",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350603,
              "id": 1237,
              "areaName": "龙文区",
              "areaCode": "0596",
              "areaShort": "Longwen",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.709754,24.503113",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350622,
              "id": 1238,
              "areaName": "云霄县",
              "areaCode": "0596",
              "areaShort": "Yunxiao",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.339573,23.957936",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350623,
              "id": 1239,
              "areaName": "漳浦县",
              "areaCode": "0596",
              "areaShort": "Zhangpu",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.613808,24.117102",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350624,
              "id": 1240,
              "areaName": "诏安县",
              "areaCode": "0596",
              "areaShort": "Zhao'an",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.175184,23.711579",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350625,
              "id": 1241,
              "areaName": "长泰县",
              "areaCode": "0596",
              "areaShort": "Changtai",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.759153,24.625449",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350626,
              "id": 1242,
              "areaName": "东山县",
              "areaCode": "0596",
              "areaShort": "Dongshan",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.430061,23.701262",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350627,
              "id": 1243,
              "areaName": "南靖县",
              "areaCode": "0596",
              "areaShort": "Nanjing",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.35732,24.514654",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350628,
              "id": 1244,
              "areaName": "平和县",
              "areaCode": "0596",
              "areaShort": "Pinghe",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.315017,24.363508",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350629,
              "id": 1245,
              "areaName": "华安县",
              "areaCode": "0596",
              "areaShort": "Hua'an",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.534103,25.004425",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350681,
              "id": 1246,
              "areaName": "龙海市",
              "areaCode": "0596",
              "areaShort": "Longhai",
              "areaInitials": null,
              "cityCode": "0596",
              "center": "117.818197,24.446706",
              "areaLevel": 3,
              "areaParentId": 350600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350700,
          "id": 1247,
          "areaName": "南平市",
          "areaCode": "0599",
          "areaShort": "Nanping",
          "areaInitials": null,
          "cityCode": "133",
          "center": "118.17771,26.641774",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350702,
              "id": 1248,
              "areaName": "延平区",
              "areaCode": "0599",
              "areaShort": "Yanping",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.182036,26.637438",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350703,
              "id": 1249,
              "areaName": "建阳区",
              "areaCode": "0599",
              "areaShort": "Jianyang",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.120464,27.331876",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350721,
              "id": 1250,
              "areaName": "顺昌县",
              "areaCode": "0599",
              "areaShort": "Shunchang",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "117.810357,26.793288",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350722,
              "id": 1251,
              "areaName": "浦城县",
              "areaCode": "0599",
              "areaShort": "Pucheng",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.541256,27.917263",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350723,
              "id": 1252,
              "areaName": "光泽县",
              "areaCode": "0599",
              "areaShort": "Guangze",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "117.334106,27.540987",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350724,
              "id": 1253,
              "areaName": "松溪县",
              "areaCode": "0599",
              "areaShort": "Songxi",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.785468,27.526232",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350725,
              "id": 1254,
              "areaName": "政和县",
              "areaCode": "0599",
              "areaShort": "Zhenghe",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.857642,27.366104",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350781,
              "id": 1255,
              "areaName": "邵武市",
              "areaCode": "0599",
              "areaShort": "Shaowu",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "117.492533,27.340326",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350782,
              "id": 1256,
              "areaName": "武夷山市",
              "areaCode": "0599",
              "areaShort": "Wuyishan",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.035309,27.756647",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350783,
              "id": 1257,
              "areaName": "建瓯市",
              "areaCode": "0599",
              "areaShort": "Jianou",
              "areaInitials": null,
              "cityCode": "0599",
              "center": "118.304966,27.022774",
              "areaLevel": 3,
              "areaParentId": 350700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350800,
          "id": 1258,
          "areaName": "龙岩市",
          "areaCode": "0597",
          "areaShort": "Longyan",
          "areaInitials": null,
          "cityCode": "193",
          "center": "117.017295,25.075119",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350802,
              "id": 1259,
              "areaName": "新罗区",
              "areaCode": "0597",
              "areaShort": "Xinluo",
              "areaInitials": null,
              "cityCode": "0597",
              "center": "117.037155,25.098312",
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350821,
              "id": 1260,
              "areaName": "长汀县",
              "areaCode": "0597",
              "areaShort": "Changting",
              "areaInitials": null,
              "cityCode": "0597",
              "center": "116.357581,25.833531",
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350822,
              "id": 1261,
              "areaName": "永定区",
              "areaCode": null,
              "areaShort": "Yongding",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350823,
              "id": 1262,
              "areaName": "上杭县",
              "areaCode": "0597",
              "areaShort": "Shanghang",
              "areaInitials": null,
              "cityCode": "0597",
              "center": "116.420098,25.049518",
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350824,
              "id": 1263,
              "areaName": "武平县",
              "areaCode": "0597",
              "areaShort": "Wuping",
              "areaInitials": null,
              "cityCode": "0597",
              "center": "116.100414,25.095386",
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350825,
              "id": 1264,
              "areaName": "连城县",
              "areaCode": "0597",
              "areaShort": "Liancheng",
              "areaInitials": null,
              "cityCode": "0597",
              "center": "116.754472,25.710538",
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350881,
              "id": 1265,
              "areaName": "漳平市",
              "areaCode": "0597",
              "areaShort": "Zhangping",
              "areaInitials": null,
              "cityCode": "0597",
              "center": "117.419998,25.290184",
              "areaLevel": 3,
              "areaParentId": 350800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 350900,
          "id": 1266,
          "areaName": "宁德市",
          "areaCode": "0593",
          "areaShort": "Ningde",
          "areaInitials": null,
          "cityCode": "192",
          "center": "119.547932,26.665617",
          "areaLevel": 2,
          "areaParentId": 350000,
          "areaDictionaryVOList": [
            {
              "areaId": 350902,
              "id": 1267,
              "areaName": "蕉城区",
              "areaCode": "0593",
              "areaShort": "Jiaocheng",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "119.526299,26.66061",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350921,
              "id": 1268,
              "areaName": "霞浦县",
              "areaCode": "0593",
              "areaShort": "Xiapu",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "120.005146,26.885703",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350922,
              "id": 1269,
              "areaName": "古田县",
              "areaCode": "0593",
              "areaShort": "Gutian",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "118.746284,26.577837",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350923,
              "id": 1270,
              "areaName": "屏南县",
              "areaCode": "0593",
              "areaShort": "Pingnan",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "118.985895,26.908276",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350924,
              "id": 1271,
              "areaName": "寿宁县",
              "areaCode": "0593",
              "areaShort": "Shouning",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "119.514986,27.454479",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350925,
              "id": 1272,
              "areaName": "周宁县",
              "areaCode": "0593",
              "areaShort": "Zhouning",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "119.339025,27.104591",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350926,
              "id": 1273,
              "areaName": "柘荣县",
              "areaCode": "0593",
              "areaShort": "Zherong",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "119.900609,27.233933",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350981,
              "id": 1274,
              "areaName": "福安市",
              "areaCode": "0593",
              "areaShort": "Fu'an",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "119.64785,27.08834",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 350982,
              "id": 1275,
              "areaName": "福鼎市",
              "areaCode": "0593",
              "areaShort": "Fuding",
              "areaInitials": null,
              "cityCode": "0593",
              "center": "120.216977,27.324479",
              "areaLevel": 3,
              "areaParentId": 350900,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 360000,
      "id": 1276,
      "areaName": "江西省",
      "areaCode": "[]",
      "areaShort": "Jiangxi",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "115.81635,28.63666",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 360100,
          "id": 1277,
          "areaName": "南昌市",
          "areaCode": "0791",
          "areaShort": "Nanchang",
          "areaInitials": null,
          "cityCode": "163",
          "center": "115.858198,28.682892",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360102,
              "id": 1278,
              "areaName": "东湖区",
              "areaCode": "0791",
              "areaShort": "Donghu",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.903526,28.698731",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360103,
              "id": 1279,
              "areaName": "西湖区",
              "areaCode": "0791",
              "areaShort": "Xihu",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.877233,28.657595",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360104,
              "id": 1280,
              "areaName": "青云谱区",
              "areaCode": "0791",
              "areaShort": "Qingyunpu",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.925749,28.621169",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360105,
              "id": 1281,
              "areaName": "湾里区",
              "areaCode": "0791",
              "areaShort": "Wanli",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.730847,28.714796",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360111,
              "id": 1282,
              "areaName": "青山湖区",
              "areaCode": "0791",
              "areaShort": "Qingshanhu",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.962144,28.682984",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360121,
              "id": 1283,
              "areaName": "南昌县",
              "areaCode": "0791",
              "areaShort": "Nanchang",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.933742,28.558296",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360122,
              "id": 1284,
              "areaName": "新建县",
              "areaCode": null,
              "areaShort": "Xinjian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360123,
              "id": 1285,
              "areaName": "安义县",
              "areaCode": "0791",
              "areaShort": "Anyi",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "115.548658,28.846",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360124,
              "id": 1286,
              "areaName": "进贤县",
              "areaCode": "0791",
              "areaShort": "Jinxian",
              "areaInitials": null,
              "cityCode": "0791",
              "center": "116.241288,28.377343",
              "areaLevel": 3,
              "areaParentId": 360100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360200,
          "id": 1287,
          "areaName": "景德镇市",
          "areaCode": "0798",
          "areaShort": "Jingdezhen",
          "areaInitials": null,
          "cityCode": "225",
          "center": "117.178222,29.268945",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360202,
              "id": 1288,
              "areaName": "昌江区",
              "areaCode": "0798",
              "areaShort": "Changjiang",
              "areaInitials": null,
              "cityCode": "0798",
              "center": "117.18363,29.273565",
              "areaLevel": 3,
              "areaParentId": 360200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360203,
              "id": 1289,
              "areaName": "珠山区",
              "areaCode": "0798",
              "areaShort": "Zhushan",
              "areaInitials": null,
              "cityCode": "0798",
              "center": "117.202919,29.299938",
              "areaLevel": 3,
              "areaParentId": 360200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360222,
              "id": 1290,
              "areaName": "浮梁县",
              "areaCode": "0798",
              "areaShort": "Fuliang",
              "areaInitials": null,
              "cityCode": "0798",
              "center": "117.215066,29.352253",
              "areaLevel": 3,
              "areaParentId": 360200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360281,
              "id": 1291,
              "areaName": "乐平市",
              "areaCode": "0798",
              "areaShort": "Leping",
              "areaInitials": null,
              "cityCode": "0798",
              "center": "117.151796,28.97844",
              "areaLevel": 3,
              "areaParentId": 360200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360300,
          "id": 1292,
          "areaName": "萍乡市",
          "areaCode": "0799",
          "areaShort": "Pingxiang",
          "areaInitials": null,
          "cityCode": "350",
          "center": "113.887083,27.658373",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360302,
              "id": 1293,
              "areaName": "安源区",
              "areaCode": "0799",
              "areaShort": "Anyuan",
              "areaInitials": null,
              "cityCode": "0799",
              "center": "113.870704,27.61511",
              "areaLevel": 3,
              "areaParentId": 360300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360313,
              "id": 1294,
              "areaName": "湘东区",
              "areaCode": "0799",
              "areaShort": "Xiangdong",
              "areaInitials": null,
              "cityCode": "0799",
              "center": "113.733047,27.640075",
              "areaLevel": 3,
              "areaParentId": 360300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360321,
              "id": 1295,
              "areaName": "莲花县",
              "areaCode": "0799",
              "areaShort": "Lianhua",
              "areaInitials": null,
              "cityCode": "0799",
              "center": "113.961488,27.127664",
              "areaLevel": 3,
              "areaParentId": 360300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360322,
              "id": 1296,
              "areaName": "上栗县",
              "areaCode": "0799",
              "areaShort": "Shangli",
              "areaInitials": null,
              "cityCode": "0799",
              "center": "113.795311,27.880301",
              "areaLevel": 3,
              "areaParentId": 360300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360323,
              "id": 1297,
              "areaName": "芦溪县",
              "areaCode": "0799",
              "areaShort": "Luxi",
              "areaInitials": null,
              "cityCode": "0799",
              "center": "114.029827,27.630806",
              "areaLevel": 3,
              "areaParentId": 360300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360400,
          "id": 1298,
          "areaName": "九江市",
          "areaCode": "0792",
          "areaShort": "Jiujiang",
          "areaInitials": null,
          "cityCode": "349",
          "center": "115.952914,29.662117",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360402,
              "id": 1299,
              "areaName": "庐山区",
              "areaCode": "0792",
              "areaShort": "Lushan",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.992842,29.668064",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360403,
              "id": 1300,
              "areaName": "浔阳区",
              "areaCode": "0792",
              "areaShort": "Xunyang",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.990301,29.727593",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360421,
              "id": 1301,
              "areaName": "九江县",
              "areaCode": "0792",
              "areaShort": "Jiujiang",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.911323,29.608431",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360423,
              "id": 1302,
              "areaName": "武宁县",
              "areaCode": "0792",
              "areaShort": "Wuning",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.092757,29.246591",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360424,
              "id": 1303,
              "areaName": "修水县",
              "areaCode": "0792",
              "areaShort": "Xiushui",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "114.546836,29.025726",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360425,
              "id": 1304,
              "areaName": "永修县",
              "areaCode": "0792",
              "areaShort": "Yongxiu",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.831956,29.011871",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360426,
              "id": 1305,
              "areaName": "德安县",
              "areaCode": "0792",
              "areaShort": "De'an",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.767447,29.298696",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360427,
              "id": 1306,
              "areaName": "星子县",
              "areaCode": null,
              "areaShort": "Xingzi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360428,
              "id": 1307,
              "areaName": "都昌县",
              "areaCode": "0792",
              "areaShort": "Duchang",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "116.203979,29.273239",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360429,
              "id": 1308,
              "areaName": "湖口县",
              "areaCode": "0792",
              "areaShort": "Hukou",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "116.251947,29.731101",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360430,
              "id": 1309,
              "areaName": "彭泽县",
              "areaCode": "0792",
              "areaShort": "Pengze",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "116.56438,29.876991",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360481,
              "id": 1310,
              "areaName": "瑞昌市",
              "areaCode": "0792",
              "areaShort": "Ruichang",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.681335,29.675834",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360482,
              "id": 1311,
              "areaName": "共青城市",
              "areaCode": "0792",
              "areaShort": "Gongqingcheng",
              "areaInitials": null,
              "cityCode": "0792",
              "center": "115.808844,29.248316",
              "areaLevel": 3,
              "areaParentId": 360400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360500,
          "id": 1312,
          "areaName": "新余市",
          "areaCode": "0790",
          "areaShort": "Xinyu",
          "areaInitials": null,
          "cityCode": "164",
          "center": "114.917346,27.817808",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360502,
              "id": 1313,
              "areaName": "渝水区",
              "areaCode": "0790",
              "areaShort": "Yushui",
              "areaInitials": null,
              "cityCode": "0790",
              "center": "114.944549,27.800148",
              "areaLevel": 3,
              "areaParentId": 360500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360521,
              "id": 1314,
              "areaName": "分宜县",
              "areaCode": "0790",
              "areaShort": "Fenyi",
              "areaInitials": null,
              "cityCode": "0790",
              "center": "114.692049,27.814757",
              "areaLevel": 3,
              "areaParentId": 360500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360600,
          "id": 1315,
          "areaName": "鹰潭市",
          "areaCode": "0701",
          "areaShort": "Yingtan",
          "areaInitials": null,
          "cityCode": "279",
          "center": "117.042173,28.272537",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360602,
              "id": 1316,
              "areaName": "月湖区",
              "areaCode": "0701",
              "areaShort": "Yuehu",
              "areaInitials": null,
              "cityCode": "0701",
              "center": "117.102475,28.267018",
              "areaLevel": 3,
              "areaParentId": 360600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360622,
              "id": 1317,
              "areaName": "余江县",
              "areaCode": "0701",
              "areaShort": "Yujiang",
              "areaInitials": null,
              "cityCode": "0701",
              "center": "116.85926,28.198652",
              "areaLevel": 3,
              "areaParentId": 360600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360681,
              "id": 1318,
              "areaName": "贵溪市",
              "areaCode": "0701",
              "areaShort": "Guixi",
              "areaInitials": null,
              "cityCode": "0701",
              "center": "117.245497,28.292519",
              "areaLevel": 3,
              "areaParentId": 360600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360700,
          "id": 1319,
          "areaName": "赣州市",
          "areaCode": "0797",
          "areaShort": "Ganzhou",
          "areaInitials": null,
          "cityCode": "365",
          "center": "114.933546,25.830694",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360702,
              "id": 1320,
              "areaName": "章贡区",
              "areaCode": "0797",
              "areaShort": "Zhanggong",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.921171,25.817816",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360703,
              "id": 1321,
              "areaName": "南康区",
              "areaCode": "0797",
              "areaShort": "Nankang",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.765412,25.66145",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360721,
              "id": 1322,
              "areaName": "赣县",
              "areaCode": null,
              "areaShort": "Ganxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360722,
              "id": 1323,
              "areaName": "信丰县",
              "areaCode": "0797",
              "areaShort": "Xinfeng",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.922922,25.386379",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360723,
              "id": 1324,
              "areaName": "大余县",
              "areaCode": "0797",
              "areaShort": "Dayu",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.362112,25.401313",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360724,
              "id": 1325,
              "areaName": "上犹县",
              "areaCode": "0797",
              "areaShort": "Shangyou",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.551138,25.785172",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360725,
              "id": 1326,
              "areaName": "崇义县",
              "areaCode": "0797",
              "areaShort": "Chongyi",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.308267,25.681784",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360726,
              "id": 1327,
              "areaName": "安远县",
              "areaCode": "0797",
              "areaShort": "Anyuan",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "115.393922,25.136927",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360727,
              "id": 1328,
              "areaName": "龙南县",
              "areaCode": "0797",
              "areaShort": "Longnan",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.789873,24.911069",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360728,
              "id": 1329,
              "areaName": "定南县",
              "areaCode": "0797",
              "areaShort": "Dingnan",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "115.027845,24.78441",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360729,
              "id": 1330,
              "areaName": "全南县",
              "areaCode": "0797",
              "areaShort": "Quannan",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "114.530125,24.742403",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360730,
              "id": 1331,
              "areaName": "宁都县",
              "areaCode": "0797",
              "areaShort": "Ningdu",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "116.009472,26.470116",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360731,
              "id": 1332,
              "areaName": "于都县",
              "areaCode": "0797",
              "areaShort": "Yudu",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "115.415508,25.952068",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360732,
              "id": 1333,
              "areaName": "兴国县",
              "areaCode": "0797",
              "areaShort": "Xingguo",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "115.363189,26.337937",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360733,
              "id": 1334,
              "areaName": "会昌县",
              "areaCode": "0797",
              "areaShort": "Huichang",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "115.786056,25.600272",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360734,
              "id": 1335,
              "areaName": "寻乌县",
              "areaCode": "0797",
              "areaShort": "Xunwu",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "115.637933,24.969167",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360735,
              "id": 1336,
              "areaName": "石城县",
              "areaCode": "0797",
              "areaShort": "Shicheng",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "116.346995,26.314775",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360781,
              "id": 1337,
              "areaName": "瑞金市",
              "areaCode": "0797",
              "areaShort": "Ruijin",
              "areaInitials": null,
              "cityCode": "0797",
              "center": "116.027134,25.885555",
              "areaLevel": 3,
              "areaParentId": 360700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360800,
          "id": 1338,
          "areaName": "吉安市",
          "areaCode": "0796",
          "areaShort": "Ji'an",
          "areaInitials": null,
          "cityCode": "318",
          "center": "114.966567,27.090763",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360802,
              "id": 1339,
              "areaName": "吉州区",
              "areaCode": "0796",
              "areaShort": "Jizhou",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.994763,27.143801",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360803,
              "id": 1340,
              "areaName": "青原区",
              "areaCode": "0796",
              "areaShort": "Qingyuan",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "115.014811,27.081977",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360821,
              "id": 1341,
              "areaName": "吉安县",
              "areaCode": "0796",
              "areaShort": "Ji'an",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.907875,27.039787",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360822,
              "id": 1342,
              "areaName": "吉水县",
              "areaCode": "0796",
              "areaShort": "Jishui",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "115.135507,27.229632",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360823,
              "id": 1343,
              "areaName": "峡江县",
              "areaCode": "0796",
              "areaShort": "Xiajiang",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "115.316566,27.582901",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360824,
              "id": 1344,
              "areaName": "新干县",
              "areaCode": "0796",
              "areaShort": "Xingan",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "115.387052,27.740191",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360825,
              "id": 1345,
              "areaName": "永丰县",
              "areaCode": "0796",
              "areaShort": "Yongfeng",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "115.421344,27.316939",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360826,
              "id": 1346,
              "areaName": "泰和县",
              "areaCode": "0796",
              "areaShort": "Taihe",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.92299,26.801628",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360827,
              "id": 1347,
              "areaName": "遂川县",
              "areaCode": "0796",
              "areaShort": "Suichuan",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.520537,26.313737",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360828,
              "id": 1348,
              "areaName": "万安县",
              "areaCode": "0796",
              "areaShort": "Wan'an",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.759364,26.456553",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360829,
              "id": 1349,
              "areaName": "安福县",
              "areaCode": "0796",
              "areaShort": "Anfu",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.619893,27.392873",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360830,
              "id": 1350,
              "areaName": "永新县",
              "areaCode": "0796",
              "areaShort": "Yongxin",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.243072,26.944962",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360881,
              "id": 1351,
              "areaName": "井冈山市",
              "areaCode": "0796",
              "areaShort": "Jinggangshan",
              "areaInitials": null,
              "cityCode": "0796",
              "center": "114.289228,26.748081",
              "areaLevel": 3,
              "areaParentId": 360800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 360900,
          "id": 1352,
          "areaName": "宜春市",
          "areaCode": "0795",
          "areaShort": "Yichun",
          "areaInitials": null,
          "cityCode": "278",
          "center": "114.416785,27.815743",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 360902,
              "id": 1353,
              "areaName": "袁州区",
              "areaCode": "0795",
              "areaShort": "Yuanzhou",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "114.427858,27.797091",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360921,
              "id": 1354,
              "areaName": "奉新县",
              "areaCode": "0795",
              "areaShort": "Fengxin",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "115.400491,28.688423",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360922,
              "id": 1355,
              "areaName": "万载县",
              "areaCode": "0795",
              "areaShort": "Wanzai",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "114.444854,28.105689",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360923,
              "id": 1356,
              "areaName": "上高县",
              "areaCode": "0795",
              "areaShort": "Shanggao",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "114.947683,28.238061",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360924,
              "id": 1357,
              "areaName": "宜丰县",
              "areaCode": "0795",
              "areaShort": "Yifeng",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "114.802852,28.394565",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360925,
              "id": 1358,
              "areaName": "靖安县",
              "areaCode": "0795",
              "areaShort": "Jing'an",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "115.362628,28.861478",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360926,
              "id": 1359,
              "areaName": "铜鼓县",
              "areaCode": "0795",
              "areaShort": "Tonggu",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "114.371172,28.520769",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360981,
              "id": 1360,
              "areaName": "丰城市",
              "areaCode": "0795",
              "areaShort": "Fengcheng",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "115.771093,28.159141",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360982,
              "id": 1361,
              "areaName": "樟树市",
              "areaCode": "0795",
              "areaShort": "Zhangshu",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "115.546152,28.055853",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 360983,
              "id": 1362,
              "areaName": "高安市",
              "areaCode": "0795",
              "areaShort": "Gao'an",
              "areaInitials": null,
              "cityCode": "0795",
              "center": "115.360619,28.441152",
              "areaLevel": 3,
              "areaParentId": 360900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 361000,
          "id": 1363,
          "areaName": "抚州市",
          "areaCode": "0794",
          "areaShort": "Fuzhou",
          "areaInitials": null,
          "cityCode": "226",
          "center": "116.358181,27.949217",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 361002,
              "id": 1364,
              "areaName": "临川区",
              "areaCode": "0794",
              "areaShort": "Linchuan",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.312166,27.934572",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361021,
              "id": 1365,
              "areaName": "南城县",
              "areaCode": "0794",
              "areaShort": "Nancheng",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.63704,27.569678",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361022,
              "id": 1366,
              "areaName": "黎川县",
              "areaCode": "0794",
              "areaShort": "Lichuan",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.907681,27.282333",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361023,
              "id": 1367,
              "areaName": "南丰县",
              "areaCode": "0794",
              "areaShort": "Nanfeng",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.525725,27.218444",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361024,
              "id": 1368,
              "areaName": "崇仁县",
              "areaCode": "0794",
              "areaShort": "Chongren",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.07626,27.754466",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361025,
              "id": 1369,
              "areaName": "乐安县",
              "areaCode": "0794",
              "areaShort": "Le'an",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "115.83048,27.428765",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361026,
              "id": 1370,
              "areaName": "宜黄县",
              "areaCode": "0794",
              "areaShort": "Yihuang",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.236201,27.554886",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361027,
              "id": 1371,
              "areaName": "金溪县",
              "areaCode": "0794",
              "areaShort": "Jinxi",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.755058,27.918959",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361028,
              "id": 1372,
              "areaName": "资溪县",
              "areaCode": "0794",
              "areaShort": "Zixi",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "117.060263,27.706101",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361029,
              "id": 1373,
              "areaName": "东乡县",
              "areaCode": null,
              "areaShort": "Dongxiang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361030,
              "id": 1374,
              "areaName": "广昌县",
              "areaCode": "0794",
              "areaShort": "Guangchang",
              "areaInitials": null,
              "cityCode": "0794",
              "center": "116.335686,26.843684",
              "areaLevel": 3,
              "areaParentId": 361000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 361100,
          "id": 1375,
          "areaName": "上饶市",
          "areaCode": "0793",
          "areaShort": "Shangrao",
          "areaInitials": null,
          "cityCode": "364",
          "center": "117.943433,28.454863",
          "areaLevel": 2,
          "areaParentId": 360000,
          "areaDictionaryVOList": [
            {
              "areaId": 361102,
              "id": 1376,
              "areaName": "信州区",
              "areaCode": "0793",
              "areaShort": "Xinzhou",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.966268,28.431006",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361121,
              "id": 1377,
              "areaName": "上饶县",
              "areaCode": "0793",
              "areaShort": "Shangrao",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.907849,28.448982",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361122,
              "id": 1378,
              "areaName": "广丰县",
              "areaCode": null,
              "areaShort": "Guangfeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361123,
              "id": 1379,
              "areaName": "玉山县",
              "areaCode": "0793",
              "areaShort": "Yushan",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "118.244769,28.682309",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361124,
              "id": 1380,
              "areaName": "铅山县",
              "areaCode": "0793",
              "areaShort": "Yanshan",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.709659,28.315664",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361125,
              "id": 1381,
              "areaName": "横峰县",
              "areaCode": "0793",
              "areaShort": "Hengfeng",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.596452,28.407117",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361126,
              "id": 1382,
              "areaName": "弋阳县",
              "areaCode": "0793",
              "areaShort": "Yiyang",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.449588,28.378044",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361127,
              "id": 1383,
              "areaName": "余干县",
              "areaCode": "0793",
              "areaShort": "Yugan",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "116.695646,28.702302",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361128,
              "id": 1384,
              "areaName": "鄱阳县",
              "areaCode": "0793",
              "areaShort": "Poyang",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "116.70359,29.004847",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361129,
              "id": 1385,
              "areaName": "万年县",
              "areaCode": "0793",
              "areaShort": "Wannian",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.058445,28.694582",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361130,
              "id": 1386,
              "areaName": "婺源县",
              "areaCode": "0793",
              "areaShort": "Wuyuan",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.861797,29.248085",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 361181,
              "id": 1387,
              "areaName": "德兴市",
              "areaCode": "0793",
              "areaShort": "Dexing",
              "areaInitials": null,
              "cityCode": "0793",
              "center": "117.578713,28.946464",
              "areaLevel": 3,
              "areaParentId": 361100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 370000,
      "id": 1388,
      "areaName": "山东省",
      "areaCode": "[]",
      "areaShort": "Shandong",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "117.019915,36.671156",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 370100,
          "id": 1389,
          "areaName": "济南市",
          "areaCode": "0531",
          "areaShort": "Jinan",
          "areaInitials": null,
          "cityCode": "288",
          "center": "117.120098,36.6512",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370102,
              "id": 1390,
              "areaName": "历下区",
              "areaCode": "0531",
              "areaShort": "Lixia",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "117.076441,36.666465",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370103,
              "id": 1391,
              "areaName": "市中区",
              "areaCode": "0531",
              "areaShort": "Shizhongqu",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "116.997845,36.651335",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370104,
              "id": 1392,
              "areaName": "槐荫区",
              "areaCode": "0531",
              "areaShort": "Huaiyin",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "116.901224,36.651441",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370105,
              "id": 1393,
              "areaName": "天桥区",
              "areaCode": "0531",
              "areaShort": "Tianqiao",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "116.987153,36.678589",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370112,
              "id": 1394,
              "areaName": "历城区",
              "areaCode": "0531",
              "areaShort": "Licheng",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "117.06523,36.680259",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370113,
              "id": 1395,
              "areaName": "长清区",
              "areaCode": "0531",
              "areaShort": "Changqing",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "116.751843,36.55371",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370124,
              "id": 1396,
              "areaName": "平阴县",
              "areaCode": "0531",
              "areaShort": "Pingyin",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "116.456006,36.289251",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370125,
              "id": 1397,
              "areaName": "济阳县",
              "areaCode": "0531",
              "areaShort": "Jiyang",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "117.173524,36.978537",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370126,
              "id": 1398,
              "areaName": "商河县",
              "areaCode": "0531",
              "areaShort": "Shanghe",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "117.157232,37.309041",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370127,
              "id": 3762,
              "areaName": "高新区",
              "areaCode": "0531",
              "areaShort": "Gaoxinqu",
              "areaInitials": null,
              "cityCode": "0531",
              "center": "117.076441,36.666465",
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370181,
              "id": 1399,
              "areaName": "章丘市",
              "areaCode": null,
              "areaShort": "Zhangqiu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 370100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370200,
          "id": 1400,
          "areaName": "青岛市",
          "areaCode": "0532",
          "areaShort": "Qingdao",
          "areaInitials": null,
          "cityCode": "236",
          "center": "120.382621,36.067131",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370202,
              "id": 1401,
              "areaName": "市南区",
              "areaCode": "0532",
              "areaShort": "Shinan",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.412392,36.075651",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370203,
              "id": 1402,
              "areaName": "市北区",
              "areaCode": "0532",
              "areaShort": "Shibei",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.374701,36.0876",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370211,
              "id": 1403,
              "areaName": "黄岛区",
              "areaCode": "0532",
              "areaShort": "Huangdao",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.198055,35.960933",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370212,
              "id": 1404,
              "areaName": "崂山区",
              "areaCode": "0532",
              "areaShort": "Laoshan",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.468956,36.107538",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370213,
              "id": 1405,
              "areaName": "李沧区",
              "areaCode": "0532",
              "areaShort": "Licang",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.432922,36.145519",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370214,
              "id": 1406,
              "areaName": "城阳区",
              "areaCode": "0532",
              "areaShort": "Chengyang",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.396256,36.307559",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370281,
              "id": 1407,
              "areaName": "胶州市",
              "areaCode": "0532",
              "areaShort": "Jiaozhou",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.033382,36.26468",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370282,
              "id": 1408,
              "areaName": "即墨市",
              "areaCode": "0532",
              "areaShort": "Jimo",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.447158,36.389408",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370283,
              "id": 1409,
              "areaName": "平度市",
              "areaCode": "0532",
              "areaShort": "Pingdu",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "119.98842,36.776357",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370285,
              "id": 1410,
              "areaName": "莱西市",
              "areaCode": "0532",
              "areaShort": "Laixi",
              "areaInitials": null,
              "cityCode": "0532",
              "center": "120.51769,36.889084",
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370286,
              "id": 1411,
              "areaName": "西海岸新区",
              "areaCode": null,
              "areaShort": "Xihai'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 370200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370300,
          "id": 1412,
          "areaName": "淄博市",
          "areaCode": "0533",
          "areaShort": "Zibo",
          "areaInitials": null,
          "cityCode": "354",
          "center": "118.055019,36.813546",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370302,
              "id": 1413,
              "areaName": "淄川区",
              "areaCode": "0533",
              "areaShort": "Zichuan",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "117.966723,36.643452",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370303,
              "id": 1414,
              "areaName": "张店区",
              "areaCode": "0533",
              "areaShort": "Zhangdian",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "118.017938,36.806669",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370304,
              "id": 1415,
              "areaName": "博山区",
              "areaCode": "0533",
              "areaShort": "Boshan",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "117.861851,36.494701",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370305,
              "id": 1416,
              "areaName": "临淄区",
              "areaCode": "0533",
              "areaShort": "Linzi",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "118.309118,36.826981",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370306,
              "id": 1417,
              "areaName": "周村区",
              "areaCode": "0533",
              "areaShort": "Zhoucun",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "117.869886,36.803072",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370321,
              "id": 1418,
              "areaName": "桓台县",
              "areaCode": "0533",
              "areaShort": "Huantai",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "118.097922,36.959804",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370322,
              "id": 1419,
              "areaName": "高青县",
              "areaCode": "0533",
              "areaShort": "Gaoqing",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "117.826924,37.170979",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370323,
              "id": 1420,
              "areaName": "沂源县",
              "areaCode": "0533",
              "areaShort": "Yiyuan",
              "areaInitials": null,
              "cityCode": "0533",
              "center": "118.170855,36.185038",
              "areaLevel": 3,
              "areaParentId": 370300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370400,
          "id": 1421,
          "areaName": "枣庄市",
          "areaCode": "0632",
          "areaShort": "Zaozhuang",
          "areaInitials": null,
          "cityCode": "172",
          "center": "117.323725,34.810488",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370402,
              "id": 1422,
              "areaName": "市中区",
              "areaCode": "0632",
              "areaShort": "Shizhongqu",
              "areaInitials": null,
              "cityCode": "0632",
              "center": "117.556139,34.863554",
              "areaLevel": 3,
              "areaParentId": 370400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370403,
              "id": 1423,
              "areaName": "薛城区",
              "areaCode": "0632",
              "areaShort": "Xuecheng",
              "areaInitials": null,
              "cityCode": "0632",
              "center": "117.263164,34.795062",
              "areaLevel": 3,
              "areaParentId": 370400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370404,
              "id": 1424,
              "areaName": "峄城区",
              "areaCode": "0632",
              "areaShort": "Yicheng",
              "areaInitials": null,
              "cityCode": "0632",
              "center": "117.590816,34.773263",
              "areaLevel": 3,
              "areaParentId": 370400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370405,
              "id": 1425,
              "areaName": "台儿庄区",
              "areaCode": "0632",
              "areaShort": "Taierzhuang",
              "areaInitials": null,
              "cityCode": "0632",
              "center": "117.734414,34.56244",
              "areaLevel": 3,
              "areaParentId": 370400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370406,
              "id": 1426,
              "areaName": "山亭区",
              "areaCode": "0632",
              "areaShort": "Shanting",
              "areaInitials": null,
              "cityCode": "0632",
              "center": "117.461517,35.099528",
              "areaLevel": 3,
              "areaParentId": 370400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370481,
              "id": 1427,
              "areaName": "滕州市",
              "areaCode": "0632",
              "areaShort": "Tengzhou",
              "areaInitials": null,
              "cityCode": "0632",
              "center": "117.165824,35.114155",
              "areaLevel": 3,
              "areaParentId": 370400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370500,
          "id": 1428,
          "areaName": "东营市",
          "areaCode": "0546",
          "areaShort": "Dongying",
          "areaInitials": null,
          "cityCode": "174",
          "center": "118.674614,37.433963",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370502,
              "id": 1429,
              "areaName": "东营区",
              "areaCode": "0546",
              "areaShort": "Dongying",
              "areaInitials": null,
              "cityCode": "0546",
              "center": "118.582184,37.448964",
              "areaLevel": 3,
              "areaParentId": 370500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370503,
              "id": 1430,
              "areaName": "河口区",
              "areaCode": "0546",
              "areaShort": "Hekou",
              "areaInitials": null,
              "cityCode": "0546",
              "center": "118.525543,37.886162",
              "areaLevel": 3,
              "areaParentId": 370500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370521,
              "id": 1431,
              "areaName": "垦利县",
              "areaCode": null,
              "areaShort": "Kenli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 370500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370522,
              "id": 1432,
              "areaName": "利津县",
              "areaCode": "0546",
              "areaShort": "Lijin",
              "areaInitials": null,
              "cityCode": "0546",
              "center": "118.255287,37.490328",
              "areaLevel": 3,
              "areaParentId": 370500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370523,
              "id": 1433,
              "areaName": "广饶县",
              "areaCode": "0546",
              "areaShort": "Guangrao",
              "areaInitials": null,
              "cityCode": "0546",
              "center": "118.407107,37.053555",
              "areaLevel": 3,
              "areaParentId": 370500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370600,
          "id": 1434,
          "areaName": "烟台市",
          "areaCode": "0535",
          "areaShort": "Yantai",
          "areaInitials": null,
          "cityCode": "326",
          "center": "121.447852,37.464539",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370602,
              "id": 1435,
              "areaName": "芝罘区",
              "areaCode": "0535",
              "areaShort": "Zhifu",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "121.400445,37.541475",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370611,
              "id": 1436,
              "areaName": "福山区",
              "areaCode": "0535",
              "areaShort": "Fushan",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "121.267741,37.498246",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370612,
              "id": 1437,
              "areaName": "牟平区",
              "areaCode": "0535",
              "areaShort": "Muping",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "121.600455,37.387061",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370613,
              "id": 1438,
              "areaName": "莱山区",
              "areaCode": "0535",
              "areaShort": "Laishan",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "121.445301,37.511291",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370634,
              "id": 1439,
              "areaName": "长岛县",
              "areaCode": "0535",
              "areaShort": "Changdao",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "120.73658,37.921368",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370681,
              "id": 1440,
              "areaName": "龙口市",
              "areaCode": "0535",
              "areaShort": "Longkou",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "120.477813,37.646107",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370682,
              "id": 1441,
              "areaName": "莱阳市",
              "areaCode": "0535",
              "areaShort": "Laiyang",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "120.711672,36.978941",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370683,
              "id": 1442,
              "areaName": "莱州市",
              "areaCode": "0535",
              "areaShort": "Laizhou",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "119.942274,37.177129",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370684,
              "id": 1443,
              "areaName": "蓬莱市",
              "areaCode": "0535",
              "areaShort": "Penglai",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "120.758848,37.810661",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370685,
              "id": 1444,
              "areaName": "招远市",
              "areaCode": "0535",
              "areaShort": "Zhaoyuan",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "120.434071,37.355469",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370686,
              "id": 1445,
              "areaName": "栖霞市",
              "areaCode": "0535",
              "areaShort": "Qixia",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "120.849675,37.335123",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370687,
              "id": 1446,
              "areaName": "海阳市",
              "areaCode": "0535",
              "areaShort": "Haiyang",
              "areaInitials": null,
              "cityCode": "0535",
              "center": "121.173793,36.688",
              "areaLevel": 3,
              "areaParentId": 370600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370700,
          "id": 1447,
          "areaName": "潍坊市",
          "areaCode": "0536",
          "areaShort": "Weifang",
          "areaInitials": null,
          "cityCode": "287",
          "center": "119.161748,36.706962",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370702,
              "id": 1448,
              "areaName": "潍城区",
              "areaCode": "0536",
              "areaShort": "Weicheng",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.024835,36.7281",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370703,
              "id": 1449,
              "areaName": "寒亭区",
              "areaCode": "0536",
              "areaShort": "Hanting",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.211157,36.755623",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370704,
              "id": 1450,
              "areaName": "坊子区",
              "areaCode": "0536",
              "areaShort": "Fangzi",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.166485,36.654448",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370705,
              "id": 1451,
              "areaName": "奎文区",
              "areaCode": "0536",
              "areaShort": "Kuiwen",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.132482,36.70759",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370724,
              "id": 1452,
              "areaName": "临朐县",
              "areaCode": "0536",
              "areaShort": "Linqu",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "118.542982,36.512506",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370725,
              "id": 1453,
              "areaName": "昌乐县",
              "areaCode": "0536",
              "areaShort": "Changle",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "118.829992,36.706964",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370781,
              "id": 1454,
              "areaName": "青州市",
              "areaCode": "0536",
              "areaShort": "Qingzhou",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "118.479654,36.684789",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370782,
              "id": 1455,
              "areaName": "诸城市",
              "areaCode": "0536",
              "areaShort": "Zhucheng",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.410103,35.995654",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370783,
              "id": 1456,
              "areaName": "寿光市",
              "areaCode": "0536",
              "areaShort": "Shouguang",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "118.790739,36.85576",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370784,
              "id": 1457,
              "areaName": "安丘市",
              "areaCode": "0536",
              "areaShort": "Anqiu",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.218978,36.478493",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370785,
              "id": 1458,
              "areaName": "高密市",
              "areaCode": "0536",
              "areaShort": "Gaomi",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.755597,36.382594",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370786,
              "id": 1459,
              "areaName": "昌邑市",
              "areaCode": "0536",
              "areaShort": "Changyi",
              "areaInitials": null,
              "cityCode": "0536",
              "center": "119.403069,36.843319",
              "areaLevel": 3,
              "areaParentId": 370700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370800,
          "id": 1460,
          "areaName": "济宁市",
          "areaCode": "0537",
          "areaShort": "Jining",
          "areaInitials": null,
          "cityCode": "286",
          "center": "116.587282,35.414982",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370811,
              "id": 1461,
              "areaName": "任城区",
              "areaCode": "0537",
              "areaShort": "Rencheng",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.606103,35.444028",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370812,
              "id": 1462,
              "areaName": "兖州区",
              "areaCode": "0537",
              "areaShort": "Yanzhou ",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.783833,35.553144",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370826,
              "id": 1463,
              "areaName": "微山县",
              "areaCode": "0537",
              "areaShort": "Weishan",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "117.128827,34.806554",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370827,
              "id": 1464,
              "areaName": "鱼台县",
              "areaCode": "0537",
              "areaShort": "Yutai",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.650608,35.012749",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370828,
              "id": 1465,
              "areaName": "金乡县",
              "areaCode": "0537",
              "areaShort": "Jinxiang",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.311532,35.066619",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370829,
              "id": 1466,
              "areaName": "嘉祥县",
              "areaCode": "0537",
              "areaShort": "Jiaxiang",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.342449,35.408824",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370830,
              "id": 1467,
              "areaName": "汶上县",
              "areaCode": "0537",
              "areaShort": "Wenshang",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.49708,35.712298",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370831,
              "id": 1468,
              "areaName": "泗水县",
              "areaCode": "0537",
              "areaShort": "Sishui",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "117.251195,35.664323",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370832,
              "id": 1469,
              "areaName": "梁山县",
              "areaCode": "0537",
              "areaShort": "Liangshan",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.096044,35.802306",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370881,
              "id": 1470,
              "areaName": "曲阜市",
              "areaCode": "0537",
              "areaShort": "Qufu",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "116.986526,35.581108",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370883,
              "id": 1471,
              "areaName": "邹城市",
              "areaCode": "0537",
              "areaShort": "Zoucheng",
              "areaInitials": null,
              "cityCode": "0537",
              "center": "117.007453,35.40268",
              "areaLevel": 3,
              "areaParentId": 370800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 370900,
          "id": 1472,
          "areaName": "泰安市",
          "areaCode": "0538",
          "areaShort": "Tai'an",
          "areaInitials": null,
          "cityCode": "325",
          "center": "117.087614,36.200252",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 370902,
              "id": 1473,
              "areaName": "泰山区",
              "areaCode": "0538",
              "areaShort": "Taishan",
              "areaInitials": null,
              "cityCode": "0538",
              "center": "117.135354,36.192083",
              "areaLevel": 3,
              "areaParentId": 370900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370911,
              "id": 1474,
              "areaName": "岱岳区",
              "areaCode": "0538",
              "areaShort": "Daiyue",
              "areaInitials": null,
              "cityCode": "0538",
              "center": "117.041581,36.187989",
              "areaLevel": 3,
              "areaParentId": 370900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370921,
              "id": 1475,
              "areaName": "宁阳县",
              "areaCode": "0538",
              "areaShort": "Ningyang",
              "areaInitials": null,
              "cityCode": "0538",
              "center": "116.805796,35.758786",
              "areaLevel": 3,
              "areaParentId": 370900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370923,
              "id": 1476,
              "areaName": "东平县",
              "areaCode": "0538",
              "areaShort": "Dongping",
              "areaInitials": null,
              "cityCode": "0538",
              "center": "116.470304,35.937102",
              "areaLevel": 3,
              "areaParentId": 370900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370982,
              "id": 1477,
              "areaName": "新泰市",
              "areaCode": "0538",
              "areaShort": "Xintai",
              "areaInitials": null,
              "cityCode": "0538",
              "center": "117.767952,35.909032",
              "areaLevel": 3,
              "areaParentId": 370900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 370983,
              "id": 1478,
              "areaName": "肥城市",
              "areaCode": "0538",
              "areaShort": "Feicheng",
              "areaInitials": null,
              "cityCode": "0538",
              "center": "116.768358,36.182571",
              "areaLevel": 3,
              "areaParentId": 370900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371000,
          "id": 1479,
          "areaName": "威海市",
          "areaCode": "0631",
          "areaShort": "Weihai",
          "areaInitials": null,
          "cityCode": "175",
          "center": "122.120282,37.513412",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371002,
              "id": 1480,
              "areaName": "环翠区",
              "areaCode": "0631",
              "areaShort": "Huancui",
              "areaInitials": null,
              "cityCode": "0631",
              "center": "122.123443,37.50199",
              "areaLevel": 3,
              "areaParentId": 371000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371003,
              "id": 1481,
              "areaName": "文登区",
              "areaCode": "0631",
              "areaShort": "Wendeng",
              "areaInitials": null,
              "cityCode": "0631",
              "center": "122.05767,37.193735",
              "areaLevel": 3,
              "areaParentId": 371000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371082,
              "id": 1482,
              "areaName": "荣成市",
              "areaCode": "0631",
              "areaShort": "Rongcheng",
              "areaInitials": null,
              "cityCode": "0631",
              "center": "122.486657,37.16516",
              "areaLevel": 3,
              "areaParentId": 371000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371083,
              "id": 1483,
              "areaName": "乳山市",
              "areaCode": "0631",
              "areaShort": "Rushan",
              "areaInitials": null,
              "cityCode": "0631",
              "center": "121.539764,36.919816",
              "areaLevel": 3,
              "areaParentId": 371000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371100,
          "id": 1484,
          "areaName": "日照市",
          "areaCode": "0633",
          "areaShort": "Rizhao",
          "areaInitials": null,
          "cityCode": "173",
          "center": "119.526925,35.416734",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371102,
              "id": 1485,
              "areaName": "东港区",
              "areaCode": "0633",
              "areaShort": "Donggang",
              "areaInitials": null,
              "cityCode": "0633",
              "center": "119.462267,35.42548",
              "areaLevel": 3,
              "areaParentId": 371100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371103,
              "id": 1486,
              "areaName": "岚山区",
              "areaCode": "0633",
              "areaShort": "Lanshan",
              "areaInitials": null,
              "cityCode": "0633",
              "center": "119.318928,35.121884",
              "areaLevel": 3,
              "areaParentId": 371100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371121,
              "id": 1487,
              "areaName": "五莲县",
              "areaCode": "0633",
              "areaShort": "Wulian",
              "areaInitials": null,
              "cityCode": "0633",
              "center": "119.213619,35.760228",
              "areaLevel": 3,
              "areaParentId": 371100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371122,
              "id": 1488,
              "areaName": "莒县",
              "areaCode": "0633",
              "areaShort": "Juxian",
              "areaInitials": null,
              "cityCode": "0633",
              "center": "118.837063,35.579868",
              "areaLevel": 3,
              "areaParentId": 371100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371200,
          "id": 1489,
          "areaName": "莱芜市",
          "areaCode": "0634",
          "areaShort": "Laiwu",
          "areaInitials": null,
          "cityCode": "124",
          "center": "117.676723,36.213813",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371202,
              "id": 1490,
              "areaName": "莱城区",
              "areaCode": "0634",
              "areaShort": "Laicheng",
              "areaInitials": null,
              "cityCode": "0634",
              "center": "117.659884,36.203179",
              "areaLevel": 3,
              "areaParentId": 371200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371203,
              "id": 1491,
              "areaName": "钢城区",
              "areaCode": "0634",
              "areaShort": "Gangcheng",
              "areaInitials": null,
              "cityCode": "0634",
              "center": "117.811354,36.058572",
              "areaLevel": 3,
              "areaParentId": 371200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371300,
          "id": 1492,
          "areaName": "临沂市",
          "areaCode": "0539",
          "areaShort": "Linyi",
          "areaInitials": null,
          "cityCode": "234",
          "center": "118.356414,35.104673",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371302,
              "id": 1493,
              "areaName": "兰山区",
              "areaCode": "0539",
              "areaShort": "Lanshan",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.347842,35.051804",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371311,
              "id": 1494,
              "areaName": "罗庄区",
              "areaCode": "0539",
              "areaShort": "Luozhuang",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.284786,34.996741",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371312,
              "id": 1495,
              "areaName": "河东区",
              "areaCode": "0539",
              "areaShort": "Hedong",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.402893,35.089916",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371321,
              "id": 1496,
              "areaName": "沂南县",
              "areaCode": "0539",
              "areaShort": "Yinan",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.465221,35.550217",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371322,
              "id": 1497,
              "areaName": "郯城县",
              "areaCode": "0539",
              "areaShort": "Tancheng",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.367215,34.613586",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371323,
              "id": 1498,
              "areaName": "沂水县",
              "areaCode": "0539",
              "areaShort": "Yishui",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.627917,35.79045",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371324,
              "id": 1499,
              "areaName": "兰陵县",
              "areaCode": "0539",
              "areaShort": "Lanling",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.07065,34.857149",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371325,
              "id": 1500,
              "areaName": "费县",
              "areaCode": "0539",
              "areaShort": "Feixian",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "117.977325,35.26596",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371326,
              "id": 1501,
              "areaName": "平邑县",
              "areaCode": "0539",
              "areaShort": "Pingyi",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "117.640352,35.505943",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371327,
              "id": 1502,
              "areaName": "莒南县",
              "areaCode": "0539",
              "areaShort": "Junan",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.835163,35.174846",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371328,
              "id": 1503,
              "areaName": "蒙阴县",
              "areaCode": "0539",
              "areaShort": "Mengyin",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "117.953621,35.719396",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371329,
              "id": 1504,
              "areaName": "临沭县",
              "areaCode": "0539",
              "areaShort": "Linshu",
              "areaInitials": null,
              "cityCode": "0539",
              "center": "118.650781,34.919851",
              "areaLevel": 3,
              "areaParentId": 371300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371400,
          "id": 1505,
          "areaName": "德州市",
          "areaCode": "0534",
          "areaShort": "Dezhou",
          "areaInitials": null,
          "cityCode": "372",
          "center": "116.359381,37.436657",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371402,
              "id": 1506,
              "areaName": "德城区",
              "areaCode": "0534",
              "areaShort": "Decheng",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.29947,37.450804",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371403,
              "id": 1507,
              "areaName": "陵城区",
              "areaCode": "0534",
              "areaShort": "Lingcheng",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.576092,37.335794",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371422,
              "id": 1508,
              "areaName": "宁津县",
              "areaCode": "0534",
              "areaShort": "Ningjin",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.800306,37.652189",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371423,
              "id": 1509,
              "areaName": "庆云县",
              "areaCode": "0534",
              "areaShort": "Qingyun",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "117.385256,37.775349",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371424,
              "id": 1510,
              "areaName": "临邑县",
              "areaCode": "0534",
              "areaShort": "Linyi",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.866799,37.189797",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371425,
              "id": 1511,
              "areaName": "齐河县",
              "areaCode": "0534",
              "areaShort": "Qihe",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.762893,36.784158",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371426,
              "id": 1512,
              "areaName": "平原县",
              "areaCode": "0534",
              "areaShort": "Pingyuan",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.434032,37.165323",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371427,
              "id": 1513,
              "areaName": "夏津县",
              "areaCode": "0534",
              "areaShort": "Xiajin",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.001726,36.948371",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371428,
              "id": 1514,
              "areaName": "武城县",
              "areaCode": "0534",
              "areaShort": "Wucheng",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.069302,37.213311",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371481,
              "id": 1515,
              "areaName": "乐陵市",
              "areaCode": "0534",
              "areaShort": "Leling",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "117.231934,37.729907",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371482,
              "id": 1516,
              "areaName": "禹城市",
              "areaCode": "0534",
              "areaShort": "Yucheng",
              "areaInitials": null,
              "cityCode": "0534",
              "center": "116.638327,36.933812",
              "areaLevel": 3,
              "areaParentId": 371400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371500,
          "id": 1517,
          "areaName": "聊城市",
          "areaCode": "0635",
          "areaShort": "Liaocheng",
          "areaInitials": null,
          "cityCode": "366",
          "center": "115.985389,36.456684",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371502,
              "id": 1518,
              "areaName": "东昌府区",
              "areaCode": "0635",
              "areaShort": "Dongchangfu",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "115.988349,36.434669",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371521,
              "id": 1519,
              "areaName": "阳谷县",
              "areaCode": "0635",
              "areaShort": "Yanggu",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "115.79182,36.114392",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371522,
              "id": 1520,
              "areaName": "莘县",
              "areaCode": "0635",
              "areaShort": "Shenxian",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "115.671191,36.233598",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371523,
              "id": 1521,
              "areaName": "茌平县",
              "areaCode": "0635",
              "areaShort": "Chiping",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "116.25527,36.580688",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371524,
              "id": 1522,
              "areaName": "东阿县",
              "areaCode": "0635",
              "areaShort": "Dong'e",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "116.247579,36.334917",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371525,
              "id": 1523,
              "areaName": "冠县",
              "areaCode": "0635",
              "areaShort": "Guanxian",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "115.442739,36.484009",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371526,
              "id": 1524,
              "areaName": "高唐县",
              "areaCode": "0635",
              "areaShort": "Gaotang",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "116.23016,36.846762",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371581,
              "id": 1525,
              "areaName": "临清市",
              "areaCode": "0635",
              "areaShort": "Linqing",
              "areaInitials": null,
              "cityCode": "0635",
              "center": "115.704881,36.838277",
              "areaLevel": 3,
              "areaParentId": 371500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371600,
          "id": 1526,
          "areaName": "滨州市",
          "areaCode": "0543",
          "areaShort": "Binzhou",
          "areaInitials": null,
          "cityCode": "235",
          "center": "117.970699,37.38198",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371602,
              "id": 1527,
              "areaName": "滨城区",
              "areaCode": "0543",
              "areaShort": "Bincheng",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "118.019326,37.430724",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371603,
              "id": 1528,
              "areaName": "沾化区",
              "areaCode": "0543",
              "areaShort": "Zhanhua",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "118.098902,37.69926",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371621,
              "id": 1529,
              "areaName": "惠民县",
              "areaCode": "0543",
              "areaShort": "Huimin",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "117.509921,37.489877",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371622,
              "id": 1530,
              "areaName": "阳信县",
              "areaCode": "0543",
              "areaShort": "Yangxin",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "117.603339,37.632433",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371623,
              "id": 1531,
              "areaName": "无棣县",
              "areaCode": "0543",
              "areaShort": "Wudi",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "117.625696,37.77026",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371625,
              "id": 1532,
              "areaName": "博兴县",
              "areaCode": "0543",
              "areaShort": "Boxing",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "118.110709,37.15457",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371626,
              "id": 1533,
              "areaName": "邹平县",
              "areaCode": "0543",
              "areaShort": "Zouping",
              "areaInitials": null,
              "cityCode": "0543",
              "center": "117.743109,36.862989",
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371627,
              "id": 1534,
              "areaName": "北海新区",
              "areaCode": null,
              "areaShort": "Beihaixinqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 371600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 371700,
          "id": 1535,
          "areaName": "菏泽市",
          "areaCode": "0530",
          "areaShort": "Heze",
          "areaInitials": null,
          "cityCode": "353",
          "center": "115.480656,35.23375",
          "areaLevel": 2,
          "areaParentId": 370000,
          "areaDictionaryVOList": [
            {
              "areaId": 371702,
              "id": 1536,
              "areaName": "牡丹区",
              "areaCode": "0530",
              "areaShort": "Mudan",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "115.417826,35.252512",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371721,
              "id": 1537,
              "areaName": "曹县",
              "areaCode": "0530",
              "areaShort": "Caoxian",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "115.542328,34.825508",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371722,
              "id": 1538,
              "areaName": "单县",
              "areaCode": "0530",
              "areaShort": "Shanxian",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "116.107428,34.778808",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371723,
              "id": 1539,
              "areaName": "成武县",
              "areaCode": "0530",
              "areaShort": "Chengwu",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "115.889764,34.952459",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371724,
              "id": 1540,
              "areaName": "巨野县",
              "areaCode": "0530",
              "areaShort": "Juye",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "116.062394,35.388925",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371725,
              "id": 1541,
              "areaName": "郓城县",
              "areaCode": "0530",
              "areaShort": "Yuncheng",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "115.9389,35.575135",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371726,
              "id": 1542,
              "areaName": "鄄城县",
              "areaCode": "0530",
              "areaShort": "Juancheng",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "115.510192,35.563408",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371727,
              "id": 1543,
              "areaName": "定陶县",
              "areaCode": null,
              "areaShort": "Dingtao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 371728,
              "id": 1544,
              "areaName": "东明县",
              "areaCode": "0530",
              "areaShort": "Dongming",
              "areaInitials": null,
              "cityCode": "0530",
              "center": "115.107404,35.276162",
              "areaLevel": 3,
              "areaParentId": 371700,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 410000,
      "id": 1545,
      "areaName": "河南省",
      "areaCode": "[]",
      "areaShort": "Henan",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "113.753394,34.765869",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 410100,
          "id": 1546,
          "areaName": "郑州市",
          "areaCode": "0371",
          "areaShort": "Zhengzhou",
          "areaInitials": null,
          "cityCode": "268",
          "center": "113.625328,34.746611",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410102,
              "id": 1547,
              "areaName": "中原区",
              "areaCode": "0371",
              "areaShort": "Zhongyuan",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.613337,34.748256",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410103,
              "id": 1548,
              "areaName": "二七区",
              "areaCode": "0371",
              "areaShort": "Erqi",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.640211,34.724114",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410104,
              "id": 1549,
              "areaName": "管城回族区",
              "areaCode": "0371",
              "areaShort": "Guancheng",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.6775,34.75429",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410105,
              "id": 1550,
              "areaName": "金水区",
              "areaCode": "0371",
              "areaShort": "Jinshui",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.660617,34.800004",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410106,
              "id": 1551,
              "areaName": "上街区",
              "areaCode": "0371",
              "areaShort": "Shangjie",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.30893,34.802752",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410108,
              "id": 1552,
              "areaName": "惠济区",
              "areaCode": "0371",
              "areaShort": "Huiji",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.6169,34.867457",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410122,
              "id": 1553,
              "areaName": "中牟县",
              "areaCode": "0371",
              "areaShort": "Zhongmu",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.976253,34.718936",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410181,
              "id": 1554,
              "areaName": "巩义市",
              "areaCode": "0371",
              "areaShort": "Gongyi",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.022406,34.7481",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410182,
              "id": 1555,
              "areaName": "荥阳市",
              "areaCode": "0371",
              "areaShort": "Xingyang",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.38324,34.786948",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410183,
              "id": 1556,
              "areaName": "新密市",
              "areaCode": "0371",
              "areaShort": "Xinmi",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.391087,34.539376",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410184,
              "id": 1557,
              "areaName": "新郑市",
              "areaCode": "0371",
              "areaShort": "Xinzheng",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.740662,34.395949",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410185,
              "id": 1558,
              "areaName": "登封市",
              "areaCode": "0371",
              "areaShort": "Dengfeng",
              "areaInitials": null,
              "cityCode": "0371",
              "center": "113.050581,34.454443",
              "areaLevel": 3,
              "areaParentId": 410100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410200,
          "id": 1559,
          "areaName": "开封市",
          "areaCode": "0378",
          "areaShort": "Kaifeng",
          "areaInitials": null,
          "cityCode": "210",
          "center": "114.307677,34.797966",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410202,
              "id": 1560,
              "areaName": "龙亭区",
              "areaCode": "0378",
              "areaShort": "Longting",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.356076,34.815565",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410203,
              "id": 1561,
              "areaName": "顺河回族区",
              "areaCode": "0378",
              "areaShort": "Shunhe",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.364875,34.800458",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410204,
              "id": 1562,
              "areaName": "鼓楼区",
              "areaCode": "0378",
              "areaShort": "Gulou",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.348306,34.78856",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410205,
              "id": 1563,
              "areaName": "禹王台区",
              "areaCode": "0378",
              "areaShort": "Yuwangtai",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.34817,34.777104",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410212,
              "id": 1564,
              "areaName": "祥符区",
              "areaCode": "0378",
              "areaShort": "Xiangfu",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.441285,34.756916",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410221,
              "id": 1565,
              "areaName": "杞县",
              "areaCode": "0378",
              "areaShort": "Qixian",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.783139,34.549174",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410222,
              "id": 1566,
              "areaName": "通许县",
              "areaCode": "0378",
              "areaShort": "Tongxu",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.467467,34.480433",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410223,
              "id": 1567,
              "areaName": "尉氏县",
              "areaCode": "0378",
              "areaShort": "Weishi",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.193081,34.411494",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410225,
              "id": 1568,
              "areaName": "兰考县",
              "areaCode": "0378",
              "areaShort": "Lankao",
              "areaInitials": null,
              "cityCode": "0378",
              "center": "114.821348,34.822211",
              "areaLevel": 3,
              "areaParentId": 410200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410300,
          "id": 1569,
          "areaName": "洛阳市",
          "areaCode": "0379",
          "areaShort": "Luoyang",
          "areaInitials": null,
          "cityCode": "153",
          "center": "112.453926,34.620202",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410302,
              "id": 1570,
              "areaName": "老城区",
              "areaCode": "0379",
              "areaShort": "Laocheng",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.469766,34.6842",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410303,
              "id": 1571,
              "areaName": "西工区",
              "areaCode": "0379",
              "areaShort": "Xigong",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.427914,34.660378",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410304,
              "id": 1572,
              "areaName": "瀍河回族区",
              "areaCode": "0379",
              "areaShort": "Chanhe",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.500131,34.679773",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410305,
              "id": 1573,
              "areaName": "涧西区",
              "areaCode": "0379",
              "areaShort": "Jianxi",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.395756,34.658033",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410306,
              "id": 1574,
              "areaName": "吉利区",
              "areaCode": "0379",
              "areaShort": "Jili",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.589112,34.900467",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410311,
              "id": 1575,
              "areaName": "洛龙区",
              "areaCode": "0379",
              "areaShort": "Luolong",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.463833,34.619711",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410322,
              "id": 1576,
              "areaName": "孟津县",
              "areaCode": "0379",
              "areaShort": "Mengjin",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.445354,34.825638",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410323,
              "id": 1577,
              "areaName": "新安县",
              "areaCode": "0379",
              "areaShort": "Xin'an",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.13244,34.728284",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410324,
              "id": 1578,
              "areaName": "栾川县",
              "areaCode": "0379",
              "areaShort": "Luanchuan",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "111.615768,33.785698",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410325,
              "id": 1579,
              "areaName": "嵩县",
              "areaCode": "0379",
              "areaShort": "Songxian",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.085634,34.134516",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410326,
              "id": 1580,
              "areaName": "汝阳县",
              "areaCode": "0379",
              "areaShort": "Ruyang",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.473139,34.153939",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410327,
              "id": 1581,
              "areaName": "宜阳县",
              "areaCode": "0379",
              "areaShort": "Yiyang",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.179238,34.514644",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410328,
              "id": 1582,
              "areaName": "洛宁县",
              "areaCode": "0379",
              "areaShort": "Luoning",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "111.653111,34.389197",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410329,
              "id": 1583,
              "areaName": "伊川县",
              "areaCode": "0379",
              "areaShort": "Yichuan",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.425676,34.421323",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410381,
              "id": 1584,
              "areaName": "偃师市",
              "areaCode": "0379",
              "areaShort": "Yanshi",
              "areaInitials": null,
              "cityCode": "0379",
              "center": "112.789534,34.72722",
              "areaLevel": 3,
              "areaParentId": 410300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410400,
          "id": 1585,
          "areaName": "平顶山市",
          "areaCode": "0375",
          "areaShort": "Pingdingshan",
          "areaInitials": null,
          "cityCode": "213",
          "center": "113.192661,33.766169",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410402,
              "id": 1586,
              "areaName": "新华区",
              "areaCode": "0375",
              "areaShort": "Xinhua",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.293977,33.737251",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410403,
              "id": 1587,
              "areaName": "卫东区",
              "areaCode": "0375",
              "areaShort": "Weidong",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.335192,33.734706",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410404,
              "id": 1588,
              "areaName": "石龙区",
              "areaCode": "0375",
              "areaShort": "Shilong",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "112.898818,33.898713",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410411,
              "id": 1589,
              "areaName": "湛河区",
              "areaCode": "0375",
              "areaShort": "Zhanhe",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.320873,33.725681",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410421,
              "id": 1590,
              "areaName": "宝丰县",
              "areaCode": "0375",
              "areaShort": "Baofeng",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.054801,33.868434",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410422,
              "id": 1591,
              "areaName": "叶县",
              "areaCode": "0375",
              "areaShort": "Yexian",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.357239,33.626731",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410423,
              "id": 1592,
              "areaName": "鲁山县",
              "areaCode": "0375",
              "areaShort": "Lushan",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "112.908202,33.738293",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410425,
              "id": 1593,
              "areaName": "郏县",
              "areaCode": "0375",
              "areaShort": "Jiaxian",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.212609,33.971787",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410481,
              "id": 1594,
              "areaName": "舞钢市",
              "areaCode": "0375",
              "areaShort": "Wugang",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "113.516343,33.314033",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410482,
              "id": 1595,
              "areaName": "汝州市",
              "areaCode": "0375",
              "areaShort": "Ruzhou",
              "areaInitials": null,
              "cityCode": "0375",
              "center": "112.844517,34.167029",
              "areaLevel": 3,
              "areaParentId": 410400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410500,
          "id": 1596,
          "areaName": "安阳市",
          "areaCode": "0372",
          "areaShort": "Anyang",
          "areaInitials": null,
          "cityCode": "267",
          "center": "114.392392,36.097577",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410502,
              "id": 1597,
              "areaName": "文峰区",
              "areaCode": "0372",
              "areaShort": "Wenfeng",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.357082,36.090468",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410503,
              "id": 1598,
              "areaName": "北关区",
              "areaCode": "0372",
              "areaShort": "Beiguan",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.355742,36.10766",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410505,
              "id": 1599,
              "areaName": "殷都区",
              "areaCode": "0372",
              "areaShort": "Yindu",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.303553,36.10989",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410506,
              "id": 1600,
              "areaName": "龙安区",
              "areaCode": "0372",
              "areaShort": "Long'an",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.301331,36.076225",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410522,
              "id": 1601,
              "areaName": "安阳县",
              "areaCode": "0372",
              "areaShort": "Anyang",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.130207,36.130584",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410523,
              "id": 1602,
              "areaName": "汤阴县",
              "areaCode": "0372",
              "areaShort": "Tangyin",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.357763,35.924514",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410526,
              "id": 1603,
              "areaName": "滑县",
              "areaCode": "0372",
              "areaShort": "Huaxian",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.519311,35.575417",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410527,
              "id": 1604,
              "areaName": "内黄县",
              "areaCode": "0372",
              "areaShort": "Neihuang",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "114.901452,35.971704",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410581,
              "id": 1605,
              "areaName": "林州市",
              "areaCode": "0372",
              "areaShort": "Linzhou",
              "areaInitials": null,
              "cityCode": "0372",
              "center": "113.820129,36.083046",
              "areaLevel": 3,
              "areaParentId": 410500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410600,
          "id": 1606,
          "areaName": "鹤壁市",
          "areaCode": "0392",
          "areaShort": "Hebi",
          "areaInitials": null,
          "cityCode": "215",
          "center": "114.297309,35.748325",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410602,
              "id": 1607,
              "areaName": "鹤山区",
              "areaCode": "0392",
              "areaShort": "Heshan",
              "areaInitials": null,
              "cityCode": "0392",
              "center": "114.163258,35.954611",
              "areaLevel": 3,
              "areaParentId": 410600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410603,
              "id": 1608,
              "areaName": "山城区",
              "areaCode": "0392",
              "areaShort": "Shancheng",
              "areaInitials": null,
              "cityCode": "0392",
              "center": "114.184318,35.898033",
              "areaLevel": 3,
              "areaParentId": 410600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410611,
              "id": 1609,
              "areaName": "淇滨区",
              "areaCode": "0392",
              "areaShort": "Qibin",
              "areaInitials": null,
              "cityCode": "0392",
              "center": "114.298789,35.741592",
              "areaLevel": 3,
              "areaParentId": 410600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410621,
              "id": 1610,
              "areaName": "浚县",
              "areaCode": "0392",
              "areaShort": "Xunxian",
              "areaInitials": null,
              "cityCode": "0392",
              "center": "114.55091,35.67636",
              "areaLevel": 3,
              "areaParentId": 410600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410622,
              "id": 1611,
              "areaName": "淇县",
              "areaCode": "0392",
              "areaShort": "Qixian",
              "areaInitials": null,
              "cityCode": "0392",
              "center": "114.208828,35.622507",
              "areaLevel": 3,
              "areaParentId": 410600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410700,
          "id": 1612,
          "areaName": "新乡市",
          "areaCode": "0373",
          "areaShort": "Xinxiang",
          "areaInitials": null,
          "cityCode": "152",
          "center": "113.926763,35.303704",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410702,
              "id": 1613,
              "areaName": "红旗区",
              "areaCode": "0373",
              "areaShort": "Hongqi",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.875245,35.30385",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410703,
              "id": 1614,
              "areaName": "卫滨区",
              "areaCode": "0373",
              "areaShort": "Weibin",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.865663,35.301992",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410704,
              "id": 1615,
              "areaName": "凤泉区",
              "areaCode": "0373",
              "areaShort": "Fengquan",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.915184,35.383978",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410711,
              "id": 1616,
              "areaName": "牧野区",
              "areaCode": "0373",
              "areaShort": "Muye",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.908772,35.315039",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410721,
              "id": 1617,
              "areaName": "新乡县",
              "areaCode": "0373",
              "areaShort": "Xinxiang",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.805205,35.190836",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410724,
              "id": 1618,
              "areaName": "获嘉县",
              "areaCode": "0373",
              "areaShort": "Huojia",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.657433,35.259808",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410725,
              "id": 1619,
              "areaName": "原阳县",
              "areaCode": "0373",
              "areaShort": "Yuanyang",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.940046,35.065587",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410726,
              "id": 1620,
              "areaName": "延津县",
              "areaCode": "0373",
              "areaShort": "Yanjin",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "114.20509,35.141889",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410727,
              "id": 1621,
              "areaName": "封丘县",
              "areaCode": "0373",
              "areaShort": "Fengqiu",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "114.418882,35.041198",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410728,
              "id": 1622,
              "areaName": "长垣县",
              "areaCode": "0373",
              "areaShort": "Changyuan",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "114.668936,35.201548",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410781,
              "id": 1623,
              "areaName": "卫辉市",
              "areaCode": "0373",
              "areaShort": "Weihui",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "114.064907,35.398494",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410782,
              "id": 1624,
              "areaName": "辉县市",
              "areaCode": "0373",
              "areaShort": "Huixian",
              "areaInitials": null,
              "cityCode": "0373",
              "center": "113.805468,35.462312",
              "areaLevel": 3,
              "areaParentId": 410700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410800,
          "id": 1625,
          "areaName": "焦作市",
          "areaCode": "0391",
          "areaShort": "Jiaozuo",
          "areaInitials": null,
          "cityCode": "211",
          "center": "113.241823,35.215893",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410802,
              "id": 1626,
              "areaName": "解放区",
              "areaCode": "0391",
              "areaShort": "Jiefang",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.230816,35.240282",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410803,
              "id": 1627,
              "areaName": "中站区",
              "areaCode": "0391",
              "areaShort": "Zhongzhan",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.182946,35.236819",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410804,
              "id": 1628,
              "areaName": "马村区",
              "areaCode": "0391",
              "areaShort": "Macun",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.322332,35.256108",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410811,
              "id": 1629,
              "areaName": "山阳区",
              "areaCode": "0391",
              "areaShort": "Shanyang",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.254881,35.214507",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410821,
              "id": 1630,
              "areaName": "修武县",
              "areaCode": "0391",
              "areaShort": "Xiuwu",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.447755,35.223514",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410822,
              "id": 1631,
              "areaName": "博爱县",
              "areaCode": "0391",
              "areaShort": "Boai",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.064379,35.171045",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410823,
              "id": 1632,
              "areaName": "武陟县",
              "areaCode": "0391",
              "areaShort": "Wuzhi",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.401679,35.099378",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410825,
              "id": 1633,
              "areaName": "温县",
              "areaCode": "0391",
              "areaShort": "Wenxian",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "113.08053,34.940189",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410882,
              "id": 1634,
              "areaName": "沁阳市",
              "areaCode": "0391",
              "areaShort": "Qinyang",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "112.950716,35.087539",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410883,
              "id": 1635,
              "areaName": "孟州市",
              "areaCode": "0391",
              "areaShort": "Mengzhou",
              "areaInitials": null,
              "cityCode": "0391",
              "center": "112.791401,34.907315",
              "areaLevel": 3,
              "areaParentId": 410800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 410900,
          "id": 1636,
          "areaName": "濮阳市",
          "areaCode": "0393",
          "areaShort": "Puyang",
          "areaInitials": null,
          "cityCode": "209",
          "center": "115.029216,35.761829",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 410902,
              "id": 1637,
              "areaName": "华龙区",
              "areaCode": "0393",
              "areaShort": "Hualong",
              "areaInitials": null,
              "cityCode": "0393",
              "center": "115.074151,35.777346",
              "areaLevel": 3,
              "areaParentId": 410900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410922,
              "id": 1638,
              "areaName": "清丰县",
              "areaCode": "0393",
              "areaShort": "Qingfeng",
              "areaInitials": null,
              "cityCode": "0393",
              "center": "115.104389,35.88518",
              "areaLevel": 3,
              "areaParentId": 410900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410923,
              "id": 1639,
              "areaName": "南乐县",
              "areaCode": "0393",
              "areaShort": "Nanle",
              "areaInitials": null,
              "cityCode": "0393",
              "center": "115.204675,36.069476",
              "areaLevel": 3,
              "areaParentId": 410900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410926,
              "id": 1640,
              "areaName": "范县",
              "areaCode": "0393",
              "areaShort": "Fanxian",
              "areaInitials": null,
              "cityCode": "0393",
              "center": "115.504201,35.851906",
              "areaLevel": 3,
              "areaParentId": 410900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410927,
              "id": 1641,
              "areaName": "台前县",
              "areaCode": "0393",
              "areaShort": "Taiqian",
              "areaInitials": null,
              "cityCode": "0393",
              "center": "115.871906,35.96939",
              "areaLevel": 3,
              "areaParentId": 410900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 410928,
              "id": 1642,
              "areaName": "濮阳县",
              "areaCode": "0393",
              "areaShort": "Puyang",
              "areaInitials": null,
              "cityCode": "0393",
              "center": "115.029078,35.712193",
              "areaLevel": 3,
              "areaParentId": 410900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411000,
          "id": 1643,
          "areaName": "许昌市",
          "areaCode": "0374",
          "areaShort": "Xuchang",
          "areaInitials": null,
          "cityCode": "155",
          "center": "113.852454,34.035771",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411002,
              "id": 1644,
              "areaName": "魏都区",
              "areaCode": "0374",
              "areaShort": "Weidu",
              "areaInitials": null,
              "cityCode": "0374",
              "center": "113.822647,34.025341",
              "areaLevel": 3,
              "areaParentId": 411000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411023,
              "id": 1645,
              "areaName": "许昌县",
              "areaCode": null,
              "areaShort": "Xuchang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 411000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411024,
              "id": 1646,
              "areaName": "鄢陵县",
              "areaCode": "0374",
              "areaShort": "Yanling",
              "areaInitials": null,
              "cityCode": "0374",
              "center": "114.177399,34.102332",
              "areaLevel": 3,
              "areaParentId": 411000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411025,
              "id": 1647,
              "areaName": "襄城县",
              "areaCode": "0374",
              "areaShort": "Xiangcheng",
              "areaInitials": null,
              "cityCode": "0374",
              "center": "113.505874,33.851459",
              "areaLevel": 3,
              "areaParentId": 411000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411081,
              "id": 1648,
              "areaName": "禹州市",
              "areaCode": "0374",
              "areaShort": "Yuzhou",
              "areaInitials": null,
              "cityCode": "0374",
              "center": "113.488478,34.140701",
              "areaLevel": 3,
              "areaParentId": 411000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411082,
              "id": 1649,
              "areaName": "长葛市",
              "areaCode": "0374",
              "areaShort": "Changge",
              "areaInitials": null,
              "cityCode": "0374",
              "center": "113.813714,34.19592",
              "areaLevel": 3,
              "areaParentId": 411000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411100,
          "id": 1650,
          "areaName": "漯河市",
          "areaCode": "0395",
          "areaShort": "Luohe",
          "areaInitials": null,
          "cityCode": "344",
          "center": "114.016536,33.580873",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411102,
              "id": 1651,
              "areaName": "源汇区",
              "areaCode": "0395",
              "areaShort": "Yuanhui",
              "areaInitials": null,
              "cityCode": "0395",
              "center": "114.017948,33.565441",
              "areaLevel": 3,
              "areaParentId": 411100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411103,
              "id": 1652,
              "areaName": "郾城区",
              "areaCode": "0395",
              "areaShort": "Yancheng",
              "areaInitials": null,
              "cityCode": "0395",
              "center": "114.006943,33.587409",
              "areaLevel": 3,
              "areaParentId": 411100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411104,
              "id": 1653,
              "areaName": "召陵区",
              "areaCode": "0395",
              "areaShort": "Zhaoling",
              "areaInitials": null,
              "cityCode": "0395",
              "center": "114.093902,33.586565",
              "areaLevel": 3,
              "areaParentId": 411100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411121,
              "id": 1654,
              "areaName": "舞阳县",
              "areaCode": "0395",
              "areaShort": "Wuyang",
              "areaInitials": null,
              "cityCode": "0395",
              "center": "113.609286,33.437876",
              "areaLevel": 3,
              "areaParentId": 411100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411122,
              "id": 1655,
              "areaName": "临颍县",
              "areaCode": "0395",
              "areaShort": "Linying",
              "areaInitials": null,
              "cityCode": "0395",
              "center": "113.931261,33.828042",
              "areaLevel": 3,
              "areaParentId": 411100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411200,
          "id": 1656,
          "areaName": "三门峡市",
          "areaCode": "0398",
          "areaShort": "Sanmenxia",
          "areaInitials": null,
          "cityCode": "212",
          "center": "111.200367,34.772792",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411202,
              "id": 1657,
              "areaName": "湖滨区",
              "areaCode": "0398",
              "areaShort": "Hubin",
              "areaInitials": null,
              "cityCode": "0398",
              "center": "111.188397,34.770886",
              "areaLevel": 3,
              "areaParentId": 411200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411221,
              "id": 1658,
              "areaName": "渑池县",
              "areaCode": "0398",
              "areaShort": "Mianchi",
              "areaInitials": null,
              "cityCode": "0398",
              "center": "111.761797,34.767951",
              "areaLevel": 3,
              "areaParentId": 411200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411222,
              "id": 1659,
              "areaName": "陕县",
              "areaCode": null,
              "areaShort": "Shanxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 411200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411224,
              "id": 1660,
              "areaName": "卢氏县",
              "areaCode": "0398",
              "areaShort": "Lushi",
              "areaInitials": null,
              "cityCode": "0398",
              "center": "111.047858,34.054324",
              "areaLevel": 3,
              "areaParentId": 411200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411281,
              "id": 1661,
              "areaName": "义马市",
              "areaCode": "0398",
              "areaShort": "Yima",
              "areaInitials": null,
              "cityCode": "0398",
              "center": "111.87448,34.7474",
              "areaLevel": 3,
              "areaParentId": 411200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411282,
              "id": 1662,
              "areaName": "灵宝市",
              "areaCode": "0398",
              "areaShort": "Lingbao",
              "areaInitials": null,
              "cityCode": "0398",
              "center": "110.89422,34.516828",
              "areaLevel": 3,
              "areaParentId": 411200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411300,
          "id": 1663,
          "areaName": "南阳市",
          "areaCode": "0377",
          "areaShort": "Nanyang",
          "areaInitials": null,
          "cityCode": "309",
          "center": "112.528308,32.990664",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411302,
              "id": 1664,
              "areaName": "宛城区",
              "areaCode": "0377",
              "areaShort": "Wancheng",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.539558,33.003784",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411303,
              "id": 1665,
              "areaName": "卧龙区",
              "areaCode": "0377",
              "areaShort": "Wolong",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.528789,32.989877",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411321,
              "id": 1666,
              "areaName": "南召县",
              "areaCode": "0377",
              "areaShort": "Nanzhao",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.429133,33.489877",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411322,
              "id": 1667,
              "areaName": "方城县",
              "areaCode": "0377",
              "areaShort": "Fangcheng",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "113.012494,33.254391",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411323,
              "id": 1668,
              "areaName": "西峡县",
              "areaCode": "0377",
              "areaShort": "Xixia",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "111.47353,33.307294",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411324,
              "id": 1669,
              "areaName": "镇平县",
              "areaCode": "0377",
              "areaShort": "Zhenping",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.234697,33.03411",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411325,
              "id": 1670,
              "areaName": "内乡县",
              "areaCode": "0377",
              "areaShort": "Neixiang",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "111.849392,33.044864",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411326,
              "id": 1671,
              "areaName": "淅川县",
              "areaCode": "0377",
              "areaShort": "Xichuan",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "111.490964,33.13782",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411327,
              "id": 1672,
              "areaName": "社旗县",
              "areaCode": "0377",
              "areaShort": "Sheqi",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.948245,33.056109",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411328,
              "id": 1673,
              "areaName": "唐河县",
              "areaCode": "0377",
              "areaShort": "Tanghe",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.807636,32.681335",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411329,
              "id": 1674,
              "areaName": "新野县",
              "areaCode": "0377",
              "areaShort": "Xinye",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.360026,32.520805",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411330,
              "id": 1675,
              "areaName": "桐柏县",
              "areaCode": "0377",
              "areaShort": "Tongbai",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "113.428287,32.380073",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411381,
              "id": 1676,
              "areaName": "邓州市",
              "areaCode": "0377",
              "areaShort": "Dengzhou",
              "areaInitials": null,
              "cityCode": "0377",
              "center": "112.087493,32.68758",
              "areaLevel": 3,
              "areaParentId": 411300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411400,
          "id": 1677,
          "areaName": "商丘市",
          "areaCode": "0370",
          "areaShort": "Shangqiu",
          "areaInitials": null,
          "cityCode": "154",
          "center": "115.656339,34.414961",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411402,
              "id": 1678,
              "areaName": "梁园区",
              "areaCode": "0370",
              "areaShort": "Liangyuan",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.613965,34.443893",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411403,
              "id": 1679,
              "areaName": "睢阳区",
              "areaCode": "0370",
              "areaShort": "Suiyang",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.653301,34.388389",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411421,
              "id": 1680,
              "areaName": "民权县",
              "areaCode": "0370",
              "areaShort": "Minquan",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.173971,34.648191",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411422,
              "id": 1681,
              "areaName": "睢县",
              "areaCode": "0370",
              "areaShort": "Suixian",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.071879,34.445655",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411423,
              "id": 1682,
              "areaName": "宁陵县",
              "areaCode": "0370",
              "areaShort": "Ningling",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.313743,34.460399",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411424,
              "id": 1683,
              "areaName": "柘城县",
              "areaCode": "0370",
              "areaShort": "Zhecheng",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.305708,34.091082",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411425,
              "id": 1684,
              "areaName": "虞城县",
              "areaCode": "0370",
              "areaShort": "Yucheng",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "115.828319,34.400835",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411426,
              "id": 1685,
              "areaName": "夏邑县",
              "areaCode": "0370",
              "areaShort": "Xiayi",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "116.131447,34.237553",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411481,
              "id": 1686,
              "areaName": "永城市",
              "areaCode": "0370",
              "areaShort": "Yongcheng",
              "areaInitials": null,
              "cityCode": "0370",
              "center": "116.4495,33.929291",
              "areaLevel": 3,
              "areaParentId": 411400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411500,
          "id": 1687,
          "areaName": "信阳市",
          "areaCode": "0376",
          "areaShort": "Xinyang",
          "areaInitials": null,
          "cityCode": "214",
          "center": "114.091193,32.147679",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411502,
              "id": 1688,
              "areaName": "浉河区",
              "areaCode": "0376",
              "areaShort": "Shihe",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "114.058713,32.116803",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411503,
              "id": 1689,
              "areaName": "平桥区",
              "areaCode": "0376",
              "areaShort": "Pingqiao",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "114.125656,32.101031",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411521,
              "id": 1690,
              "areaName": "罗山县",
              "areaCode": "0376",
              "areaShort": "Luoshan",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "114.512872,32.203883",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411522,
              "id": 1691,
              "areaName": "光山县",
              "areaCode": "0376",
              "areaShort": "Guangshan",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "114.919152,32.010002",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411523,
              "id": 1692,
              "areaName": "新县",
              "areaCode": "0376",
              "areaShort": "Xinxian",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "114.879239,31.643918",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411524,
              "id": 1693,
              "areaName": "商城县",
              "areaCode": "0376",
              "areaShort": "Shangcheng",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "115.406862,31.798377",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411525,
              "id": 1694,
              "areaName": "固始县",
              "areaCode": "0376",
              "areaShort": "Gushi",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "115.654481,32.168137",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411526,
              "id": 1695,
              "areaName": "潢川县",
              "areaCode": "0376",
              "areaShort": "Huangchuan",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "115.051908,32.131522",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411527,
              "id": 1696,
              "areaName": "淮滨县",
              "areaCode": "0376",
              "areaShort": "Huaibin",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "115.419537,32.473258",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411528,
              "id": 1697,
              "areaName": "息县",
              "areaCode": "0376",
              "areaShort": "Xixian",
              "areaInitials": null,
              "cityCode": "0376",
              "center": "114.740456,32.342792",
              "areaLevel": 3,
              "areaParentId": 411500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411600,
          "id": 1698,
          "areaName": "周口市",
          "areaCode": "0394",
          "areaShort": "Zhoukou",
          "areaInitials": null,
          "cityCode": "308",
          "center": "114.69695,33.626149",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411602,
              "id": 1699,
              "areaName": "川汇区",
              "areaCode": "0394",
              "areaShort": "Chuanhui",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.650628,33.647598",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411621,
              "id": 1700,
              "areaName": "扶沟县",
              "areaCode": "0394",
              "areaShort": "Fugou",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.394821,34.059968",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411622,
              "id": 1701,
              "areaName": "西华县",
              "areaCode": "0394",
              "areaShort": "Xihua",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.529756,33.767407",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411623,
              "id": 1702,
              "areaName": "商水县",
              "areaCode": "0394",
              "areaShort": "Shangshui",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.611651,33.542138",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411624,
              "id": 1703,
              "areaName": "沈丘县",
              "areaCode": "0394",
              "areaShort": "Shenqiu",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "115.098583,33.409369",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411625,
              "id": 1704,
              "areaName": "郸城县",
              "areaCode": "0394",
              "areaShort": "Dancheng",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "115.177188,33.644743",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411626,
              "id": 1705,
              "areaName": "淮阳县",
              "areaCode": "0394",
              "areaShort": "Huaiyang",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.886153,33.731561",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411627,
              "id": 1706,
              "areaName": "太康县",
              "areaCode": "0394",
              "areaShort": "Taikang",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.837888,34.064463",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411628,
              "id": 1707,
              "areaName": "鹿邑县",
              "areaCode": "0394",
              "areaShort": "Luyi",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "115.484454,33.86",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411681,
              "id": 1708,
              "areaName": "项城市",
              "areaCode": "0394",
              "areaShort": "Xiangcheng",
              "areaInitials": null,
              "cityCode": "0394",
              "center": "114.875333,33.465838",
              "areaLevel": 3,
              "areaParentId": 411600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 411700,
          "id": 1709,
          "areaName": "驻马店市",
          "areaCode": "0396",
          "areaShort": "Zhumadian",
          "areaInitials": null,
          "cityCode": "269",
          "center": "114.022247,33.012885",
          "areaLevel": 2,
          "areaParentId": 410000,
          "areaDictionaryVOList": [
            {
              "areaId": 411702,
              "id": 1710,
              "areaName": "驿城区",
              "areaCode": "0396",
              "areaShort": "Yicheng",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "113.993914,32.973054",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411721,
              "id": 1711,
              "areaName": "西平县",
              "areaCode": "0396",
              "areaShort": "Xiping",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.021538,33.387684",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411722,
              "id": 1712,
              "areaName": "上蔡县",
              "areaCode": "0396",
              "areaShort": "Shangcai",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.264381,33.262439",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411723,
              "id": 1713,
              "areaName": "平舆县",
              "areaCode": "0396",
              "areaShort": "Pingyu",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.619159,32.96271",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411724,
              "id": 1714,
              "areaName": "正阳县",
              "areaCode": "0396",
              "areaShort": "Zhengyang",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.392773,32.605697",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411725,
              "id": 1715,
              "areaName": "确山县",
              "areaCode": "0396",
              "areaShort": "Queshan",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.026429,32.802064",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411726,
              "id": 1716,
              "areaName": "泌阳县",
              "areaCode": "0396",
              "areaShort": "Biyang",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "113.327144,32.723975",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411727,
              "id": 1717,
              "areaName": "汝南县",
              "areaCode": "0396",
              "areaShort": "Runan",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.362379,33.006729",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411728,
              "id": 1718,
              "areaName": "遂平县",
              "areaCode": "0396",
              "areaShort": "Suiping",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.013182,33.145649",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 411729,
              "id": 1719,
              "areaName": "新蔡县",
              "areaCode": "0396",
              "areaShort": "Xincai",
              "areaInitials": null,
              "cityCode": "0396",
              "center": "114.96547,32.744896",
              "areaLevel": 3,
              "areaParentId": 411700,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 420000,
      "id": 1722,
      "areaName": "湖北省",
      "areaCode": "[]",
      "areaShort": "Hubei",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "114.341745,30.546557",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 420100,
          "id": 1723,
          "areaName": "武汉市",
          "areaCode": "027",
          "areaShort": "Wuhan",
          "areaInitials": null,
          "cityCode": "218",
          "center": "114.305469,30.593175",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420102,
              "id": 1724,
              "areaName": "江岸区",
              "areaCode": "027",
              "areaShort": "Jiang'an",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.30911,30.600052",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420103,
              "id": 1725,
              "areaName": "江汉区",
              "areaCode": "027",
              "areaShort": "Jianghan",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.270867,30.601475",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420104,
              "id": 1726,
              "areaName": "硚口区",
              "areaCode": "027",
              "areaShort": "Qiaokou",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.21492,30.582202",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420105,
              "id": 1727,
              "areaName": "汉阳区",
              "areaCode": "027",
              "areaShort": "Hanyang",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.21861,30.553983",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420106,
              "id": 1728,
              "areaName": "武昌区",
              "areaCode": "027",
              "areaShort": "Wuchang",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.31665,30.554408",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420107,
              "id": 1729,
              "areaName": "青山区",
              "areaCode": "027",
              "areaShort": "Qingshan",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.384968,30.640191",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420111,
              "id": 1730,
              "areaName": "洪山区",
              "areaCode": "027",
              "areaShort": "Hongshan",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.343796,30.500247",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420112,
              "id": 1731,
              "areaName": "东西湖区",
              "areaCode": "027",
              "areaShort": "Dongxihu",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.137116,30.619917",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420113,
              "id": 1732,
              "areaName": "汉南区",
              "areaCode": "027",
              "areaShort": "Hannan",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.084597,30.308829",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420114,
              "id": 1733,
              "areaName": "蔡甸区",
              "areaCode": "027",
              "areaShort": "Caidian",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.087285,30.536454",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420115,
              "id": 1734,
              "areaName": "江夏区",
              "areaCode": "027",
              "areaShort": "Jiangxia",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.319097,30.376308",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420116,
              "id": 1735,
              "areaName": "黄陂区",
              "areaCode": "027",
              "areaShort": "Huangpi",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.375725,30.882174",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420117,
              "id": 1736,
              "areaName": "新洲区",
              "areaCode": "027",
              "areaShort": "Xinzhou",
              "areaInitials": null,
              "cityCode": "027",
              "center": "114.801096,30.841425",
              "areaLevel": 3,
              "areaParentId": 420100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 420200,
          "id": 1737,
          "areaName": "黄石市",
          "areaCode": "0714",
          "areaShort": "Huangshi",
          "areaInitials": null,
          "cityCode": "311",
          "center": "115.038962,30.201038",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420202,
              "id": 1738,
              "areaName": "黄石港区",
              "areaCode": "0714",
              "areaShort": "Huangshigang",
              "areaInitials": null,
              "cityCode": "0714",
              "center": "115.065849,30.222938",
              "areaLevel": 3,
              "areaParentId": 420200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420203,
              "id": 1739,
              "areaName": "西塞山区",
              "areaCode": "0714",
              "areaShort": "Xisaishan",
              "areaInitials": null,
              "cityCode": "0714",
              "center": "115.109955,30.204924",
              "areaLevel": 3,
              "areaParentId": 420200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420204,
              "id": 1740,
              "areaName": "下陆区",
              "areaCode": "0714",
              "areaShort": "Xialu",
              "areaInitials": null,
              "cityCode": "0714",
              "center": "114.961327,30.173912",
              "areaLevel": 3,
              "areaParentId": 420200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420205,
              "id": 1741,
              "areaName": "铁山区",
              "areaCode": "0714",
              "areaShort": "Tieshan",
              "areaInitials": null,
              "cityCode": "0714",
              "center": "114.891605,30.203118",
              "areaLevel": 3,
              "areaParentId": 420200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420222,
              "id": 1742,
              "areaName": "阳新县",
              "areaCode": "0714",
              "areaShort": "Yangxin",
              "areaInitials": null,
              "cityCode": "0714",
              "center": "115.215227,29.830257",
              "areaLevel": 3,
              "areaParentId": 420200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420281,
              "id": 1743,
              "areaName": "大冶市",
              "areaCode": "0714",
              "areaShort": "Daye",
              "areaInitials": null,
              "cityCode": "0714",
              "center": "114.980424,30.096147",
              "areaLevel": 3,
              "areaParentId": 420200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 420300,
          "id": 1744,
          "areaName": "十堰市",
          "areaCode": "0719",
          "areaShort": "Shiyan",
          "areaInitials": null,
          "cityCode": "216",
          "center": "110.799291,32.629462",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420302,
              "id": 1745,
              "areaName": "茅箭区",
              "areaCode": "0719",
              "areaShort": "Maojian",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "110.813719,32.591904",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420303,
              "id": 1746,
              "areaName": "张湾区",
              "areaCode": "0719",
              "areaShort": "Zhangwan",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "110.769132,32.652297",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420304,
              "id": 1747,
              "areaName": "郧阳区",
              "areaCode": "0719",
              "areaShort": "Yunyang",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "110.81205,32.834775",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420322,
              "id": 1748,
              "areaName": "郧西县",
              "areaCode": "0719",
              "areaShort": "Yunxi",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "110.425983,32.993182",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420323,
              "id": 1749,
              "areaName": "竹山县",
              "areaCode": "0719",
              "areaShort": "Zhushan",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "110.228747,32.224808",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420324,
              "id": 1750,
              "areaName": "竹溪县",
              "areaCode": "0719",
              "areaShort": "Zhuxi",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "109.715304,32.318255",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420325,
              "id": 1751,
              "areaName": "房县",
              "areaCode": "0719",
              "areaShort": "Fangxian",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "110.733181,32.050378",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420381,
              "id": 1752,
              "areaName": "丹江口市",
              "areaCode": "0719",
              "areaShort": "Danjiangkou",
              "areaInitials": null,
              "cityCode": "0719",
              "center": "111.513127,32.540157",
              "areaLevel": 3,
              "areaParentId": 420300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 420500,
          "id": 1753,
          "areaName": "宜昌市",
          "areaCode": "0717",
          "areaShort": "Yichang",
          "areaInitials": null,
          "cityCode": "270",
          "center": "111.286445,30.691865",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420502,
              "id": 1754,
              "areaName": "西陵区",
              "areaCode": "0717",
              "areaShort": "Xiling",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.285646,30.710781",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420503,
              "id": 1755,
              "areaName": "伍家岗区",
              "areaCode": "0717",
              "areaShort": "Wujiagang",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.361037,30.644334",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420504,
              "id": 1756,
              "areaName": "点军区",
              "areaCode": "0717",
              "areaShort": "Dianjun",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.268119,30.693247",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420505,
              "id": 1757,
              "areaName": "猇亭区",
              "areaCode": "0717",
              "areaShort": "Xiaoting",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.43462,30.530903",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420506,
              "id": 1758,
              "areaName": "夷陵区",
              "areaCode": "0717",
              "areaShort": "Yiling",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.32638,30.770006",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420525,
              "id": 1759,
              "areaName": "远安县",
              "areaCode": "0717",
              "areaShort": "Yuan'an",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.640508,31.060869",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420526,
              "id": 1760,
              "areaName": "兴山县",
              "areaCode": "0717",
              "areaShort": "Xingshan",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "110.746804,31.348196",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420527,
              "id": 1761,
              "areaName": "秭归县",
              "areaCode": "0717",
              "areaShort": "Zigui",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "110.977711,30.825897",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420528,
              "id": 1762,
              "areaName": "长阳土家族自治县",
              "areaCode": "0717",
              "areaShort": "Changyang",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.207242,30.472763",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420529,
              "id": 1763,
              "areaName": "五峰土家族自治县",
              "areaCode": "0717",
              "areaShort": "Wufeng",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.07374,30.156741",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420581,
              "id": 1764,
              "areaName": "宜都市",
              "areaCode": "0717",
              "areaShort": "Yidu",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.450096,30.378299",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420582,
              "id": 1765,
              "areaName": "当阳市",
              "areaCode": "0717",
              "areaShort": "Dangyang",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.788312,30.821266",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420583,
              "id": 1766,
              "areaName": "枝江市",
              "areaCode": "0717",
              "areaShort": "Zhijiang",
              "areaInitials": null,
              "cityCode": "0717",
              "center": "111.76053,30.42594",
              "areaLevel": 3,
              "areaParentId": 420500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 420601,
          "id": 1767,
          "areaName": "襄阳市",
          "areaCode": "0710",
          "areaShort": "Xiangyang",
          "areaInitials": null,
          "cityCode": "0710",
          "center": "112.122426,32.009016",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": []
        },
        {
          "areaId": 420700,
          "id": 1777,
          "areaName": "鄂州市",
          "areaCode": "0711",
          "areaShort": "Ezhou",
          "areaInitials": null,
          "cityCode": "122",
          "center": "114.894935,30.391141",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420702,
              "id": 1778,
              "areaName": "梁子湖区",
              "areaCode": "0711",
              "areaShort": "Liangzihu",
              "areaInitials": null,
              "cityCode": "0711",
              "center": "114.684731,30.100141",
              "areaLevel": 3,
              "areaParentId": 420700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420703,
              "id": 1779,
              "areaName": "华容区",
              "areaCode": "0711",
              "areaShort": "Huarong",
              "areaInitials": null,
              "cityCode": "0711",
              "center": "114.729878,30.534309",
              "areaLevel": 3,
              "areaParentId": 420700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420704,
              "id": 1780,
              "areaName": "鄂城区",
              "areaCode": "0711",
              "areaShort": "Echeng",
              "areaInitials": null,
              "cityCode": "0711",
              "center": "114.891586,30.400651",
              "areaLevel": 3,
              "areaParentId": 420700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 420800,
          "id": 1781,
          "areaName": "荆门市",
          "areaCode": "0724",
          "areaShort": "Jingmen",
          "areaInitials": null,
          "cityCode": "217",
          "center": "112.199427,31.035395",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420802,
              "id": 1782,
              "areaName": "东宝区",
              "areaCode": "0724",
              "areaShort": "Dongbao",
              "areaInitials": null,
              "cityCode": "0724",
              "center": "112.201493,31.051852",
              "areaLevel": 3,
              "areaParentId": 420800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420804,
              "id": 1783,
              "areaName": "掇刀区",
              "areaCode": "0724",
              "areaShort": "Duodao",
              "areaInitials": null,
              "cityCode": "0724",
              "center": "112.207962,30.973451",
              "areaLevel": 3,
              "areaParentId": 420800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420821,
              "id": 1784,
              "areaName": "京山县",
              "areaCode": "0724",
              "areaShort": "Jingshan",
              "areaInitials": null,
              "cityCode": "0724",
              "center": "113.119566,31.018457",
              "areaLevel": 3,
              "areaParentId": 420800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420822,
              "id": 1785,
              "areaName": "沙洋县",
              "areaCode": "0724",
              "areaShort": "Shayang",
              "areaInitials": null,
              "cityCode": "0724",
              "center": "112.588581,30.709221",
              "areaLevel": 3,
              "areaParentId": 420800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420881,
              "id": 1786,
              "areaName": "钟祥市",
              "areaCode": "0724",
              "areaShort": "Zhongxiang",
              "areaInitials": null,
              "cityCode": "0724",
              "center": "112.58812,31.167819",
              "areaLevel": 3,
              "areaParentId": 420800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 420900,
          "id": 1787,
          "areaName": "孝感市",
          "areaCode": "0712",
          "areaShort": "Xiaogan",
          "areaInitials": null,
          "cityCode": "310",
          "center": "113.957037,30.917766",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 420902,
              "id": 1788,
              "areaName": "孝南区",
              "areaCode": "0712",
              "areaShort": "Xiaonan",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "113.910705,30.916812",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420921,
              "id": 1789,
              "areaName": "孝昌县",
              "areaCode": "0712",
              "areaShort": "Xiaochang",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "113.998009,31.258159",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420922,
              "id": 1790,
              "areaName": "大悟县",
              "areaCode": "0712",
              "areaShort": "Dawu",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "114.127022,31.561164",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420923,
              "id": 1791,
              "areaName": "云梦县",
              "areaCode": "0712",
              "areaShort": "Yunmeng",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "113.753554,31.020983",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420981,
              "id": 1792,
              "areaName": "应城市",
              "areaCode": "0712",
              "areaShort": "Yingcheng",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "113.572707,30.92837",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420982,
              "id": 1793,
              "areaName": "安陆市",
              "areaCode": "0712",
              "areaShort": "Anlu",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "113.688941,31.25561",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 420984,
              "id": 1794,
              "areaName": "汉川市",
              "areaCode": "0712",
              "areaShort": "Hanchuan",
              "areaInitials": null,
              "cityCode": "0712",
              "center": "113.839149,30.661243",
              "areaLevel": 3,
              "areaParentId": 420900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 421000,
          "id": 1795,
          "areaName": "荆州市",
          "areaCode": "0716",
          "areaShort": "Jingzhou",
          "areaInitials": null,
          "cityCode": "157",
          "center": "112.239746,30.335184",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 421002,
              "id": 1796,
              "areaName": "沙市区",
              "areaCode": "0716",
              "areaShort": "Shashi",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "112.25193,30.326009",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421003,
              "id": 1797,
              "areaName": "荆州区",
              "areaCode": "0716",
              "areaShort": "Jingzhou",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "112.190185,30.352853",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421022,
              "id": 1798,
              "areaName": "公安县",
              "areaCode": "0716",
              "areaShort": "Gong'an",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "112.229648,30.058336",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421023,
              "id": 1799,
              "areaName": "监利县",
              "areaCode": "0716",
              "areaShort": "Jianli",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "112.904788,29.840179",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421024,
              "id": 1800,
              "areaName": "江陵县",
              "areaCode": "0716",
              "areaShort": "Jiangling",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "112.424664,30.041822",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421081,
              "id": 1801,
              "areaName": "石首市",
              "areaCode": "0716",
              "areaShort": "Shishou",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "112.425454,29.720938",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421083,
              "id": 1802,
              "areaName": "洪湖市",
              "areaCode": "0716",
              "areaShort": "Honghu",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "113.475801,29.826916",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421087,
              "id": 1803,
              "areaName": "松滋市",
              "areaCode": "0716",
              "areaShort": "Songzi",
              "areaInitials": null,
              "cityCode": "0716",
              "center": "111.756781,30.174529",
              "areaLevel": 3,
              "areaParentId": 421000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 421100,
          "id": 1804,
          "areaName": "黄冈市",
          "areaCode": "0713",
          "areaShort": "Huanggang",
          "areaInitials": null,
          "cityCode": "271",
          "center": "114.872199,30.453667",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 421102,
              "id": 1805,
              "areaName": "黄州区",
              "areaCode": "0713",
              "areaShort": "Huangzhou",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "114.880104,30.434354",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421121,
              "id": 1806,
              "areaName": "团风县",
              "areaCode": "0713",
              "areaShort": "Tuanfeng",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "114.872191,30.643569",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421122,
              "id": 1807,
              "areaName": "红安县",
              "areaCode": "0713",
              "areaShort": "Hong'an",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "114.618236,31.288153",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421123,
              "id": 1808,
              "areaName": "罗田县",
              "areaCode": "0713",
              "areaShort": "Luotian",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.399222,30.78429",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421124,
              "id": 1809,
              "areaName": "英山县",
              "areaCode": "0713",
              "areaShort": "Yingshan",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.681359,30.735157",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421125,
              "id": 1810,
              "areaName": "浠水县",
              "areaCode": "0713",
              "areaShort": "Xishui",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.265355,30.452115",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421126,
              "id": 1811,
              "areaName": "蕲春县",
              "areaCode": "0713",
              "areaShort": "Qichun",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.437007,30.225964",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421127,
              "id": 1812,
              "areaName": "黄梅县",
              "areaCode": "0713",
              "areaShort": "Huangmei",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.944219,30.070453",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421181,
              "id": 1813,
              "areaName": "麻城市",
              "areaCode": "0713",
              "areaShort": "Macheng",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.008163,31.172739",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421182,
              "id": 1814,
              "areaName": "武穴市",
              "areaCode": "0713",
              "areaShort": "Wuxue",
              "areaInitials": null,
              "cityCode": "0713",
              "center": "115.561217,29.844107",
              "areaLevel": 3,
              "areaParentId": 421100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 421200,
          "id": 1815,
          "areaName": "咸宁市",
          "areaCode": "0715",
          "areaShort": "Xianning",
          "areaInitials": null,
          "cityCode": "362",
          "center": "114.322616,29.841362",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 421202,
              "id": 1816,
              "areaName": "咸安区",
              "areaCode": "0715",
              "areaShort": "Xian'an",
              "areaInitials": null,
              "cityCode": "0715",
              "center": "114.298711,29.852891",
              "areaLevel": 3,
              "areaParentId": 421200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421221,
              "id": 1817,
              "areaName": "嘉鱼县",
              "areaCode": "0715",
              "areaShort": "Jiayu",
              "areaInitials": null,
              "cityCode": "0715",
              "center": "113.939271,29.970676",
              "areaLevel": 3,
              "areaParentId": 421200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421222,
              "id": 1818,
              "areaName": "通城县",
              "areaCode": "0715",
              "areaShort": "Tongcheng",
              "areaInitials": null,
              "cityCode": "0715",
              "center": "113.816966,29.245269",
              "areaLevel": 3,
              "areaParentId": 421200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421223,
              "id": 1819,
              "areaName": "崇阳县",
              "areaCode": "0715",
              "areaShort": "Chongyang",
              "areaInitials": null,
              "cityCode": "0715",
              "center": "114.039523,29.556688",
              "areaLevel": 3,
              "areaParentId": 421200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421224,
              "id": 1820,
              "areaName": "通山县",
              "areaCode": "0715",
              "areaShort": "Tongshan",
              "areaInitials": null,
              "cityCode": "0715",
              "center": "114.482622,29.606372",
              "areaLevel": 3,
              "areaParentId": 421200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421281,
              "id": 1821,
              "areaName": "赤壁市",
              "areaCode": "0715",
              "areaShort": "Chibi",
              "areaInitials": null,
              "cityCode": "0715",
              "center": "113.90038,29.725184",
              "areaLevel": 3,
              "areaParentId": 421200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 421300,
          "id": 1822,
          "areaName": "随州市",
          "areaCode": "0722",
          "areaShort": "Suizhou",
          "areaInitials": null,
          "cityCode": "371",
          "center": "113.382515,31.690191",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 421303,
              "id": 1823,
              "areaName": "曾都区",
              "areaCode": "0722",
              "areaShort": "Zengdu",
              "areaInitials": null,
              "cityCode": "0722",
              "center": "113.37112,31.71628",
              "areaLevel": 3,
              "areaParentId": 421300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421321,
              "id": 1824,
              "areaName": "随县",
              "areaCode": "0722",
              "areaShort": "Suixian",
              "areaInitials": null,
              "cityCode": "0722",
              "center": "113.290634,31.883739",
              "areaLevel": 3,
              "areaParentId": 421300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 421381,
              "id": 1825,
              "areaName": "广水市",
              "areaCode": "0722",
              "areaShort": "Guangshui",
              "areaInitials": null,
              "cityCode": "0722",
              "center": "113.825889,31.616853",
              "areaLevel": 3,
              "areaParentId": 421300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 422800,
          "id": 1826,
          "areaName": "恩施土家族苗族自治州",
          "areaCode": "0718",
          "areaShort": "Enshi",
          "areaInitials": null,
          "cityCode": "373",
          "center": "109.488172,30.272156",
          "areaLevel": 2,
          "areaParentId": 420000,
          "areaDictionaryVOList": [
            {
              "areaId": 422801,
              "id": 1827,
              "areaName": "恩施市",
              "areaCode": "0718",
              "areaShort": "Enshi",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "109.479664,30.29468",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422802,
              "id": 1828,
              "areaName": "利川市",
              "areaCode": "0718",
              "areaShort": "Lichuan",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "108.936452,30.29098",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422822,
              "id": 1829,
              "areaName": "建始县",
              "areaCode": "0718",
              "areaShort": "Jianshi",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "109.722109,30.602129",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422823,
              "id": 1830,
              "areaName": "巴东县",
              "areaCode": "0718",
              "areaShort": "Badong",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "110.340756,31.042324",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422825,
              "id": 1831,
              "areaName": "宣恩县",
              "areaCode": "0718",
              "areaShort": "Xuanen",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "109.489926,29.98692",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422826,
              "id": 1832,
              "areaName": "咸丰县",
              "areaCode": "0718",
              "areaShort": "Xianfeng",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "109.139726,29.665202",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422827,
              "id": 1833,
              "areaName": "来凤县",
              "areaCode": "0718",
              "areaShort": "Laifeng",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "109.407828,29.493484",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 422828,
              "id": 1834,
              "areaName": "鹤峰县",
              "areaCode": "0718",
              "areaShort": "Hefeng",
              "areaInitials": null,
              "cityCode": "0718",
              "center": "110.033662,29.890171",
              "areaLevel": 3,
              "areaParentId": 422800,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 430000,
      "id": 1840,
      "areaName": "湖南省",
      "areaCode": "[]",
      "areaShort": "Hunan",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "112.9836,28.112743",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 430100,
          "id": 1841,
          "areaName": "长沙市",
          "areaCode": "0731",
          "areaShort": "Changsha",
          "areaInitials": null,
          "cityCode": "158",
          "center": "112.938884,28.22808",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430102,
              "id": 1842,
              "areaName": "芙蓉区",
              "areaCode": "0731",
              "areaShort": "Furong",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "113.032539,28.185389",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430103,
              "id": 1843,
              "areaName": "天心区",
              "areaCode": "0731",
              "areaShort": "Tianxin",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "112.989897,28.114526",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430104,
              "id": 1844,
              "areaName": "岳麓区",
              "areaCode": "0731",
              "areaShort": "Yuelu",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "112.93132,28.234538",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430105,
              "id": 1845,
              "areaName": "开福区",
              "areaCode": "0731",
              "areaShort": "Kaifu",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "112.985884,28.256298",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430111,
              "id": 1846,
              "areaName": "雨花区",
              "areaCode": "0731",
              "areaShort": "Yuhua",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "113.03826,28.135722",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430112,
              "id": 1847,
              "areaName": "望城区",
              "areaCode": "0731",
              "areaShort": "Wangcheng",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "112.831176,28.353434",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430121,
              "id": 1848,
              "areaName": "长沙县",
              "areaCode": "0731",
              "areaShort": "Changsha",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "113.081097,28.246918",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430124,
              "id": 1849,
              "areaName": "宁乡县",
              "areaCode": "0731",
              "areaShort": "Ningxiang",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "112.551885,28.277483",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430181,
              "id": 1850,
              "areaName": "浏阳市",
              "areaCode": "0731",
              "areaShort": "Liuyang",
              "areaInitials": null,
              "cityCode": "0731",
              "center": "113.643076,28.162833",
              "areaLevel": 3,
              "areaParentId": 430100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430200,
          "id": 1851,
          "areaName": "株洲市",
          "areaCode": "0733",
          "areaShort": "Zhuzhou",
          "areaInitials": null,
          "cityCode": "222",
          "center": "113.133853,27.827986",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430202,
              "id": 1852,
              "areaName": "荷塘区",
              "areaCode": "0733",
              "areaShort": "Hetang",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.173487,27.855928",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430203,
              "id": 1853,
              "areaName": "芦淞区",
              "areaCode": "0733",
              "areaShort": "Lusong",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.152724,27.78507",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430204,
              "id": 1854,
              "areaName": "石峰区",
              "areaCode": "0733",
              "areaShort": "Shifeng",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.117731,27.875445",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430211,
              "id": 1855,
              "areaName": "天元区",
              "areaCode": "0733",
              "areaShort": "Tianyuan",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.082216,27.826866",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430221,
              "id": 1856,
              "areaName": "株洲县",
              "areaCode": "0733",
              "areaShort": "Zhuzhou",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.144109,27.699232",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430223,
              "id": 1857,
              "areaName": "攸县",
              "areaCode": "0733",
              "areaShort": "Youxian",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.396385,27.014583",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430224,
              "id": 1858,
              "areaName": "茶陵县",
              "areaCode": "0733",
              "areaShort": "Chaling",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.539094,26.777521",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430225,
              "id": 1859,
              "areaName": "炎陵县",
              "areaCode": "0733",
              "areaShort": "Yanling",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.772655,26.489902",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430281,
              "id": 1860,
              "areaName": "醴陵市",
              "areaCode": "0733",
              "areaShort": "Liling",
              "areaInitials": null,
              "cityCode": "0733",
              "center": "113.496999,27.646096",
              "areaLevel": 3,
              "areaParentId": 430200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430300,
          "id": 1861,
          "areaName": "湘潭市",
          "areaCode": "0732",
          "areaShort": "Xiangtan",
          "areaInitials": null,
          "cityCode": "313",
          "center": "112.944026,27.829795",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430302,
              "id": 1862,
              "areaName": "雨湖区",
              "areaCode": "0732",
              "areaShort": "Yuhu",
              "areaInitials": null,
              "cityCode": "0732",
              "center": "112.907162,27.856325",
              "areaLevel": 3,
              "areaParentId": 430300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430304,
              "id": 1863,
              "areaName": "岳塘区",
              "areaCode": "0732",
              "areaShort": "Yuetang",
              "areaInitials": null,
              "cityCode": "0732",
              "center": "112.969479,27.872028",
              "areaLevel": 3,
              "areaParentId": 430300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430321,
              "id": 1864,
              "areaName": "湘潭县",
              "areaCode": "0732",
              "areaShort": "Xiangtan",
              "areaInitials": null,
              "cityCode": "0732",
              "center": "112.950831,27.778958",
              "areaLevel": 3,
              "areaParentId": 430300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430381,
              "id": 1865,
              "areaName": "湘乡市",
              "areaCode": "0732",
              "areaShort": "Xiangxiang",
              "areaInitials": null,
              "cityCode": "0732",
              "center": "112.550205,27.718549",
              "areaLevel": 3,
              "areaParentId": 430300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430382,
              "id": 1866,
              "areaName": "韶山市",
              "areaCode": "0732",
              "areaShort": "Shaoshan",
              "areaInitials": null,
              "cityCode": "0732",
              "center": "112.52667,27.915008",
              "areaLevel": 3,
              "areaParentId": 430300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430400,
          "id": 1867,
          "areaName": "衡阳市",
          "areaCode": "0734",
          "areaShort": "Hengyang",
          "areaInitials": null,
          "cityCode": "159",
          "center": "112.572018,26.893368",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430405,
              "id": 1868,
              "areaName": "珠晖区",
              "areaCode": "0734",
              "areaShort": "Zhuhui",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.620209,26.894765",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430406,
              "id": 1869,
              "areaName": "雁峰区",
              "areaCode": "0734",
              "areaShort": "Yanfeng",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.6154,26.840602",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430407,
              "id": 1870,
              "areaName": "石鼓区",
              "areaCode": "0734",
              "areaShort": "Shigu",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.597992,26.943755",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430408,
              "id": 1871,
              "areaName": "蒸湘区",
              "areaCode": "0734",
              "areaShort": "Zhengxiang",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.567107,26.911854",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430412,
              "id": 1872,
              "areaName": "南岳区",
              "areaCode": "0734",
              "areaShort": "Nanyue",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.738604,27.232443",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430421,
              "id": 1873,
              "areaName": "衡阳县",
              "areaCode": "0734",
              "areaShort": "Hengyang",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.370546,26.969577",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430422,
              "id": 1874,
              "areaName": "衡南县",
              "areaCode": "0734",
              "areaShort": "Hengnan",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.677877,26.738247",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430423,
              "id": 1875,
              "areaName": "衡山县",
              "areaCode": "0734",
              "areaShort": "Hengshan",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.868268,27.23029",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430424,
              "id": 1876,
              "areaName": "衡东县",
              "areaCode": "0734",
              "areaShort": "Hengdong",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.953168,27.08117",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430426,
              "id": 1877,
              "areaName": "祁东县",
              "areaCode": "0734",
              "areaShort": "Qidong",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.090356,26.799896",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430481,
              "id": 1878,
              "areaName": "耒阳市",
              "areaCode": "0734",
              "areaShort": "Leiyang",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.859759,26.422277",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430482,
              "id": 1879,
              "areaName": "常宁市",
              "areaCode": "0734",
              "areaShort": "Changning",
              "areaInitials": null,
              "cityCode": "0734",
              "center": "112.399878,26.421956",
              "areaLevel": 3,
              "areaParentId": 430400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430500,
          "id": 1880,
          "areaName": "邵阳市",
          "areaCode": "0739",
          "areaShort": "Shaoyang",
          "areaInitials": null,
          "cityCode": "273",
          "center": "111.467674,27.23895",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430502,
              "id": 1881,
              "areaName": "双清区",
              "areaCode": "0739",
              "areaShort": "Shuangqing",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.496341,27.232708",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430503,
              "id": 1882,
              "areaName": "大祥区",
              "areaCode": "0739",
              "areaShort": "Daxiang",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.439091,27.221452",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430511,
              "id": 1883,
              "areaName": "北塔区",
              "areaCode": "0739",
              "areaShort": "Beita",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.452196,27.246489",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430521,
              "id": 1884,
              "areaName": "邵东县",
              "areaCode": "0739",
              "areaShort": "Shaodong",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.74427,27.258987",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430522,
              "id": 1885,
              "areaName": "新邵县",
              "areaCode": "0739",
              "areaShort": "Xinshao",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.458656,27.320917",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430523,
              "id": 1886,
              "areaName": "邵阳县",
              "areaCode": "0739",
              "areaShort": "Shaoyang",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.273805,26.990637",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430524,
              "id": 1887,
              "areaName": "隆回县",
              "areaCode": "0739",
              "areaShort": "Longhui",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "111.032437,27.113978",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430525,
              "id": 1888,
              "areaName": "洞口县",
              "areaCode": "0739",
              "areaShort": "Dongkou",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "110.575846,27.06032",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430527,
              "id": 1889,
              "areaName": "绥宁县",
              "areaCode": "0739",
              "areaShort": "Suining",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "110.155655,26.581954",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430528,
              "id": 1890,
              "areaName": "新宁县",
              "areaCode": "0739",
              "areaShort": "Xinning",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "110.856988,26.433367",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430529,
              "id": 1891,
              "areaName": "城步苗族自治县",
              "areaCode": "0739",
              "areaShort": "Chengbu",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "110.322239,26.390598",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430581,
              "id": 1892,
              "areaName": "武冈市",
              "areaCode": "0739",
              "areaShort": "Wugang",
              "areaInitials": null,
              "cityCode": "0739",
              "center": "110.631884,26.726599",
              "areaLevel": 3,
              "areaParentId": 430500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430600,
          "id": 1893,
          "areaName": "岳阳市",
          "areaCode": "0730",
          "areaShort": "Yueyang",
          "areaInitials": null,
          "cityCode": "220",
          "center": "113.12873,29.356803",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430602,
              "id": 1894,
              "areaName": "岳阳楼区",
              "areaCode": "0730",
              "areaShort": "Yueyanglou",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.129684,29.371814",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430603,
              "id": 1895,
              "areaName": "云溪区",
              "areaCode": "0730",
              "areaShort": "Yunxi",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.272312,29.472745",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430611,
              "id": 1896,
              "areaName": "君山区",
              "areaCode": "0730",
              "areaShort": "Junshan",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.006435,29.461106",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430621,
              "id": 1897,
              "areaName": "岳阳县",
              "areaCode": "0730",
              "areaShort": "Yueyang",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.116418,29.144066",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430623,
              "id": 1898,
              "areaName": "华容县",
              "areaCode": "0730",
              "areaShort": "Huarong",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "112.540463,29.531057",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430624,
              "id": 1899,
              "areaName": "湘阴县",
              "areaCode": "0730",
              "areaShort": "Xiangyin",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "112.909426,28.689104",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430626,
              "id": 1900,
              "areaName": "平江县",
              "areaCode": "0730",
              "areaShort": "Pingjiang",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.581234,28.701868",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430681,
              "id": 1901,
              "areaName": "汨罗市",
              "areaCode": "0730",
              "areaShort": "Miluo",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.067251,28.806881",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430682,
              "id": 1902,
              "areaName": "临湘市",
              "areaCode": "0730",
              "areaShort": "Linxiang",
              "areaInitials": null,
              "cityCode": "0730",
              "center": "113.450423,29.476849",
              "areaLevel": 3,
              "areaParentId": 430600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430700,
          "id": 1903,
          "areaName": "常德市",
          "areaCode": "0736",
          "areaShort": "Changde",
          "areaInitials": null,
          "cityCode": "219",
          "center": "111.698784,29.031654",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430702,
              "id": 1904,
              "areaName": "武陵区",
              "areaCode": "0736",
              "areaShort": "Wuling",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.683153,29.055163",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430703,
              "id": 1905,
              "areaName": "鼎城区",
              "areaCode": "0736",
              "areaShort": "Dingcheng",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.680783,29.018593",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430721,
              "id": 1906,
              "areaName": "安乡县",
              "areaCode": "0736",
              "areaShort": "Anxiang",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "112.171131,29.411309",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430722,
              "id": 1907,
              "areaName": "汉寿县",
              "areaCode": "0736",
              "areaShort": "Hanshou",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.970514,28.906106",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430723,
              "id": 1908,
              "areaName": "澧县",
              "areaCode": "0736",
              "areaShort": "Lixian",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.758702,29.633236",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430724,
              "id": 1909,
              "areaName": "临澧县",
              "areaCode": "0736",
              "areaShort": "Linli",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.647517,29.440793",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430725,
              "id": 1910,
              "areaName": "桃源县",
              "areaCode": "0736",
              "areaShort": "Taoyuan",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.488925,28.902503",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430726,
              "id": 1911,
              "areaName": "石门县",
              "areaCode": "0736",
              "areaShort": "Shimen",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.380014,29.584292",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430781,
              "id": 1912,
              "areaName": "津市市",
              "areaCode": "0736",
              "areaShort": "Jinshi",
              "areaInitials": null,
              "cityCode": "0736",
              "center": "111.877499,29.60548",
              "areaLevel": 3,
              "areaParentId": 430700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430800,
          "id": 1913,
          "areaName": "张家界市",
          "areaCode": "0744",
          "areaShort": "Zhangjiajie",
          "areaInitials": null,
          "cityCode": "312",
          "center": "110.479148,29.117013",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430802,
              "id": 1914,
              "areaName": "永定区",
              "areaCode": "0744",
              "areaShort": "Yongding",
              "areaInitials": null,
              "cityCode": "0744",
              "center": "110.537138,29.119855",
              "areaLevel": 3,
              "areaParentId": 430800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430811,
              "id": 1915,
              "areaName": "武陵源区",
              "areaCode": "0744",
              "areaShort": "Wulingyuan",
              "areaInitials": null,
              "cityCode": "0744",
              "center": "110.550433,29.34573",
              "areaLevel": 3,
              "areaParentId": 430800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430821,
              "id": 1916,
              "areaName": "慈利县",
              "areaCode": "0744",
              "areaShort": "Cili",
              "areaInitials": null,
              "cityCode": "0744",
              "center": "111.139775,29.429999",
              "areaLevel": 3,
              "areaParentId": 430800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430822,
              "id": 1917,
              "areaName": "桑植县",
              "areaCode": "0744",
              "areaShort": "Sangzhi",
              "areaInitials": null,
              "cityCode": "0744",
              "center": "110.204652,29.414111",
              "areaLevel": 3,
              "areaParentId": 430800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 430900,
          "id": 1918,
          "areaName": "益阳市",
          "areaCode": "0737",
          "areaShort": "Yiyang",
          "areaInitials": null,
          "cityCode": "272",
          "center": "112.355129,28.554349",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 430902,
              "id": 1919,
              "areaName": "资阳区",
              "areaCode": "0737",
              "areaShort": "Ziyang",
              "areaInitials": null,
              "cityCode": "0737",
              "center": "112.324272,28.59111",
              "areaLevel": 3,
              "areaParentId": 430900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430903,
              "id": 1920,
              "areaName": "赫山区",
              "areaCode": "0737",
              "areaShort": "Heshan",
              "areaInitials": null,
              "cityCode": "0737",
              "center": "112.374145,28.579494",
              "areaLevel": 3,
              "areaParentId": 430900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430921,
              "id": 1921,
              "areaName": "南县",
              "areaCode": "0737",
              "areaShort": "Nanxian",
              "areaInitials": null,
              "cityCode": "0737",
              "center": "112.396337,29.362275",
              "areaLevel": 3,
              "areaParentId": 430900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430922,
              "id": 1922,
              "areaName": "桃江县",
              "areaCode": "0737",
              "areaShort": "Taojiang",
              "areaInitials": null,
              "cityCode": "0737",
              "center": "112.155822,28.518084",
              "areaLevel": 3,
              "areaParentId": 430900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430923,
              "id": 1923,
              "areaName": "安化县",
              "areaCode": "0737",
              "areaShort": "Anhua",
              "areaInitials": null,
              "cityCode": "0737",
              "center": "111.212846,28.374107",
              "areaLevel": 3,
              "areaParentId": 430900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 430981,
              "id": 1924,
              "areaName": "沅江市",
              "areaCode": "0737",
              "areaShort": "Yuanjiang",
              "areaInitials": null,
              "cityCode": "0737",
              "center": "112.355954,28.847045",
              "areaLevel": 3,
              "areaParentId": 430900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 431000,
          "id": 1925,
          "areaName": "郴州市",
          "areaCode": "0735",
          "areaShort": "Chenzhou",
          "areaInitials": null,
          "cityCode": "275",
          "center": "113.014984,25.770532",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 431002,
              "id": 1926,
              "areaName": "北湖区",
              "areaCode": "0735",
              "areaShort": "Beihu",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.011035,25.784054",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431003,
              "id": 1927,
              "areaName": "苏仙区",
              "areaCode": "0735",
              "areaShort": "Suxian",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.112105,25.797013",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431021,
              "id": 1928,
              "areaName": "桂阳县",
              "areaCode": "0735",
              "areaShort": "Guiyang",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "112.734173,25.754172",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431022,
              "id": 1929,
              "areaName": "宜章县",
              "areaCode": "0735",
              "areaShort": "Yizhang",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "112.948712,25.399938",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431023,
              "id": 1930,
              "areaName": "永兴县",
              "areaCode": "0735",
              "areaShort": "Yongxing",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.116527,26.12715",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431024,
              "id": 1931,
              "areaName": "嘉禾县",
              "areaCode": "0735",
              "areaShort": "Jiahe",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "112.36902,25.587519",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431025,
              "id": 1932,
              "areaName": "临武县",
              "areaCode": "0735",
              "areaShort": "Linwu",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "112.563456,25.27556",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431026,
              "id": 1933,
              "areaName": "汝城县",
              "areaCode": "0735",
              "areaShort": "Rucheng",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.684727,25.532816",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431027,
              "id": 1934,
              "areaName": "桂东县",
              "areaCode": "0735",
              "areaShort": "Guidong",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.944614,26.077616",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431028,
              "id": 1935,
              "areaName": "安仁县",
              "areaCode": "0735",
              "areaShort": "Anren",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.26932,26.709061",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431081,
              "id": 1936,
              "areaName": "资兴市",
              "areaCode": "0735",
              "areaShort": "Zixing",
              "areaInitials": null,
              "cityCode": "0735",
              "center": "113.236146,25.976243",
              "areaLevel": 3,
              "areaParentId": 431000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 431100,
          "id": 1937,
          "areaName": "永州市",
          "areaCode": "0746",
          "areaShort": "Yongzhou",
          "areaInitials": null,
          "cityCode": "314",
          "center": "111.613418,26.419641",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 431102,
              "id": 1938,
              "areaName": "零陵区",
              "areaCode": "0746",
              "areaShort": "Lingling",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.631109,26.221936",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431103,
              "id": 1939,
              "areaName": "冷水滩区",
              "areaCode": "0746",
              "areaShort": "Lengshuitan",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.592343,26.46128",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431121,
              "id": 1940,
              "areaName": "祁阳县",
              "areaCode": "0746",
              "areaShort": "Qiyang",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.840657,26.58012",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431122,
              "id": 1941,
              "areaName": "东安县",
              "areaCode": "0746",
              "areaShort": "Dong'an",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.316464,26.392183",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431123,
              "id": 1942,
              "areaName": "双牌县",
              "areaCode": "0746",
              "areaShort": "Shuangpai",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.659967,25.961909",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431124,
              "id": 1943,
              "areaName": "道县",
              "areaCode": "0746",
              "areaShort": "Daoxian",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.600795,25.526437",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431125,
              "id": 1944,
              "areaName": "江永县",
              "areaCode": "0746",
              "areaShort": "Jiangyong",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.343911,25.273539",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431126,
              "id": 1945,
              "areaName": "宁远县",
              "areaCode": "0746",
              "areaShort": "Ningyuan",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.945844,25.570888",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431127,
              "id": 1946,
              "areaName": "蓝山县",
              "areaCode": "0746",
              "areaShort": "Lanshan",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "112.196567,25.369725",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431128,
              "id": 1947,
              "areaName": "新田县",
              "areaCode": "0746",
              "areaShort": "Xintian",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "112.203287,25.904305",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431129,
              "id": 1948,
              "areaName": "江华瑶族自治县",
              "areaCode": "0746",
              "areaShort": "Jianghua",
              "areaInitials": null,
              "cityCode": "0746",
              "center": "111.579535,25.185809",
              "areaLevel": 3,
              "areaParentId": 431100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 431200,
          "id": 1949,
          "areaName": "怀化市",
          "areaCode": "0745",
          "areaShort": "Huaihua",
          "areaInitials": null,
          "cityCode": "363",
          "center": "110.001923,27.569517",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 431202,
              "id": 1950,
              "areaName": "鹤城区",
              "areaCode": "0745",
              "areaShort": "Hecheng",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "110.040315,27.578926",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431221,
              "id": 1951,
              "areaName": "中方县",
              "areaCode": "0745",
              "areaShort": "Zhongfang",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.944711,27.440138",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431222,
              "id": 1952,
              "areaName": "沅陵县",
              "areaCode": "0745",
              "areaShort": "Yuanling",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "110.393844,28.452686",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431223,
              "id": 1953,
              "areaName": "辰溪县",
              "areaCode": "0745",
              "areaShort": "Chenxi",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "110.183917,28.006336",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431224,
              "id": 1954,
              "areaName": "溆浦县",
              "areaCode": "0745",
              "areaShort": "Xupu",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "110.594879,27.908267",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431225,
              "id": 1955,
              "areaName": "会同县",
              "areaCode": "0745",
              "areaShort": "Huitong",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.735661,26.887238",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431226,
              "id": 1956,
              "areaName": "麻阳苗族自治县",
              "areaCode": "0745",
              "areaShort": "Mayang",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.81701,27.857569",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431227,
              "id": 1957,
              "areaName": "新晃侗族自治县",
              "areaCode": "0745",
              "areaShort": "Xinhuang",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.174932,27.352673",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431228,
              "id": 1958,
              "areaName": "芷江侗族自治县",
              "areaCode": "0745",
              "areaShort": "Zhijiang",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.684629,27.443499",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431229,
              "id": 1959,
              "areaName": "靖州苗族侗族自治县",
              "areaCode": "0745",
              "areaShort": "Jingzhou",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.696273,26.575107",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431230,
              "id": 1960,
              "areaName": "通道侗族自治县",
              "areaCode": "0745",
              "areaShort": "Tongdao",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.784412,26.158054",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431281,
              "id": 1961,
              "areaName": "洪江市",
              "areaCode": "0745",
              "areaShort": "Hongjiang",
              "areaInitials": null,
              "cityCode": "0745",
              "center": "109.836669,27.208609",
              "areaLevel": 3,
              "areaParentId": 431200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 431300,
          "id": 1962,
          "areaName": "娄底市",
          "areaCode": "0738",
          "areaShort": "Loudi",
          "areaInitials": null,
          "cityCode": "221",
          "center": "111.994482,27.70027",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 431302,
              "id": 1963,
              "areaName": "娄星区",
              "areaCode": "0738",
              "areaShort": "Louxing",
              "areaInitials": null,
              "cityCode": "0738",
              "center": "112.001914,27.729863",
              "areaLevel": 3,
              "areaParentId": 431300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431321,
              "id": 1964,
              "areaName": "双峰县",
              "areaCode": "0738",
              "areaShort": "Shuangfeng",
              "areaInitials": null,
              "cityCode": "0738",
              "center": "112.175163,27.457172",
              "areaLevel": 3,
              "areaParentId": 431300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431322,
              "id": 1965,
              "areaName": "新化县",
              "areaCode": "0738",
              "areaShort": "Xinhua",
              "areaInitials": null,
              "cityCode": "0738",
              "center": "111.327412,27.726514",
              "areaLevel": 3,
              "areaParentId": 431300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431381,
              "id": 1966,
              "areaName": "冷水江市",
              "areaCode": "0738",
              "areaShort": "Lengshuijiang",
              "areaInitials": null,
              "cityCode": "0738",
              "center": "111.434984,27.686251",
              "areaLevel": 3,
              "areaParentId": 431300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 431382,
              "id": 1967,
              "areaName": "涟源市",
              "areaCode": "0738",
              "areaShort": "Lianyuan",
              "areaInitials": null,
              "cityCode": "0738",
              "center": "111.664329,27.692577",
              "areaLevel": 3,
              "areaParentId": 431300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 433100,
          "id": 1968,
          "areaName": "湘西土家族苗族自治州",
          "areaCode": "0743",
          "areaShort": "Xiangxi",
          "areaInitials": null,
          "cityCode": "274",
          "center": "109.738906,28.31195",
          "areaLevel": 2,
          "areaParentId": 430000,
          "areaDictionaryVOList": [
            {
              "areaId": 433101,
              "id": 1969,
              "areaName": "吉首市",
              "areaCode": "0743",
              "areaShort": "Jishou",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.698015,28.262376",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433122,
              "id": 1970,
              "areaName": "泸溪县",
              "areaCode": "0743",
              "areaShort": "Luxi",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "110.21961,28.216641",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433123,
              "id": 1971,
              "areaName": "凤凰县",
              "areaCode": "0743",
              "areaShort": "Fenghuang",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.581083,27.958081",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433124,
              "id": 1972,
              "areaName": "花垣县",
              "areaCode": "0743",
              "areaShort": "Huayuan",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.482078,28.572029",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433125,
              "id": 1973,
              "areaName": "保靖县",
              "areaCode": "0743",
              "areaShort": "Baojing",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.660559,28.699878",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433126,
              "id": 1974,
              "areaName": "古丈县",
              "areaCode": "0743",
              "areaShort": "Guzhang",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.950728,28.616935",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433127,
              "id": 1975,
              "areaName": "永顺县",
              "areaCode": "0743",
              "areaShort": "Yongshun",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.856933,28.979955",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 433130,
              "id": 1976,
              "areaName": "龙山县",
              "areaCode": "0743",
              "areaShort": "Longshan",
              "areaInitials": null,
              "cityCode": "0743",
              "center": "109.443938,29.457663",
              "areaLevel": 3,
              "areaParentId": 433100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 440000,
      "id": 1977,
      "areaName": "广东省",
      "areaCode": "[]",
      "areaShort": "Guangdong",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "113.26641,23.132324",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 440100,
          "id": 1978,
          "areaName": "广州市",
          "areaCode": "020",
          "areaShort": "Guangzhou",
          "areaInitials": null,
          "cityCode": "257",
          "center": "113.264385,23.12911",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440103,
              "id": 1979,
              "areaName": "荔湾区",
              "areaCode": "020",
              "areaShort": "Liwan",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.244258,23.125863",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440104,
              "id": 1980,
              "areaName": "越秀区",
              "areaCode": "020",
              "areaShort": "Yuexiu",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.266835,23.128537",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440105,
              "id": 1981,
              "areaName": "海珠区",
              "areaCode": "020",
              "areaShort": "Haizhu",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.317443,23.083788",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440106,
              "id": 1982,
              "areaName": "天河区",
              "areaCode": "020",
              "areaShort": "Tianhe",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.361575,23.124807",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440111,
              "id": 1983,
              "areaName": "白云区",
              "areaCode": "020",
              "areaShort": "Baiyun",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.273238,23.157367",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440112,
              "id": 1984,
              "areaName": "黄埔区",
              "areaCode": "020",
              "areaShort": "Huangpu",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.480541,23.181706",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440113,
              "id": 1985,
              "areaName": "番禺区",
              "areaCode": "020",
              "areaShort": "Panyu",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.384152,22.937556",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440114,
              "id": 1986,
              "areaName": "花都区",
              "areaCode": "020",
              "areaShort": "Huadu",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.220463,23.403744",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440115,
              "id": 1987,
              "areaName": "南沙区",
              "areaCode": "020",
              "areaShort": "Nansha",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.525165,22.801624",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440117,
              "id": 1988,
              "areaName": "从化区",
              "areaCode": "020",
              "areaShort": "Conghua",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.586679,23.548748",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440118,
              "id": 1989,
              "areaName": "增城区",
              "areaCode": "020",
              "areaShort": "Zengcheng",
              "areaInitials": null,
              "cityCode": "020",
              "center": "113.810627,23.261465",
              "areaLevel": 3,
              "areaParentId": 440100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440200,
          "id": 1990,
          "areaName": "韶关市",
          "areaCode": "0751",
          "areaShort": "Shaoguan",
          "areaInitials": null,
          "cityCode": "137",
          "center": "113.59762,24.810879",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440203,
              "id": 1991,
              "areaName": "武江区",
              "areaCode": "0751",
              "areaShort": "Wujiang",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "113.587756,24.792926",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440204,
              "id": 1992,
              "areaName": "浈江区",
              "areaCode": "0751",
              "areaShort": "Zhenjiang",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "113.611098,24.804381",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440205,
              "id": 1993,
              "areaName": "曲江区",
              "areaCode": "0751",
              "areaShort": "Qujiang",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "113.604535,24.682501",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440222,
              "id": 1994,
              "areaName": "始兴县",
              "areaCode": "0751",
              "areaShort": "Shixing",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "114.061789,24.952976",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440224,
              "id": 1995,
              "areaName": "仁化县",
              "areaCode": "0751",
              "areaShort": "Renhua",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "113.749027,25.085621",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440229,
              "id": 1996,
              "areaName": "翁源县",
              "areaCode": "0751",
              "areaShort": "Wengyuan",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "114.130342,24.350346",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440232,
              "id": 1997,
              "areaName": "乳源瑶族自治县",
              "areaCode": "0751",
              "areaShort": "Ruyuan",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "113.275883,24.776078",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440233,
              "id": 1998,
              "areaName": "新丰县",
              "areaCode": "0751",
              "areaShort": "Xinfeng",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "114.206867,24.05976",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440281,
              "id": 1999,
              "areaName": "乐昌市",
              "areaCode": "0751",
              "areaShort": "Lechang",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "113.347545,25.130602",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440282,
              "id": 2000,
              "areaName": "南雄市",
              "areaCode": "0751",
              "areaShort": "Nanxiong",
              "areaInitials": null,
              "cityCode": "0751",
              "center": "114.311982,25.117753",
              "areaLevel": 3,
              "areaParentId": 440200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440300,
          "id": 2001,
          "areaName": "深圳市",
          "areaCode": "0755",
          "areaShort": "Shenzhen",
          "areaInitials": null,
          "cityCode": "340",
          "center": "114.057939,22.543527",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440303,
              "id": 2002,
              "areaName": "罗湖区",
              "areaCode": "0755",
              "areaShort": "Luohu",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "114.131459,22.548389",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440304,
              "id": 2003,
              "areaName": "福田区",
              "areaCode": "0755",
              "areaShort": "Futian",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "114.055072,22.521521",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440305,
              "id": 2004,
              "areaName": "南山区",
              "areaCode": "0755",
              "areaShort": "Nanshan",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "113.930413,22.533287",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440306,
              "id": 2005,
              "areaName": "宝安区",
              "areaCode": "0755",
              "areaShort": "Bao'an",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "113.883802,22.554996",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440307,
              "id": 2006,
              "areaName": "龙岗区",
              "areaCode": "0755",
              "areaShort": "Longgang",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "114.246899,22.720974",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440308,
              "id": 2007,
              "areaName": "盐田区",
              "areaCode": "0755",
              "areaShort": "Yantian",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "114.236739,22.557001",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440309,
              "id": 2008,
              "areaName": "光明新区",
              "areaCode": "0755",
              "areaShort": "Guangmingxinqu",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "114.045422,22.696667",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440310,
              "id": 2009,
              "areaName": "坪山新区",
              "areaCode": "0755",
              "areaShort": "Pingshanxinqu",
              "areaInitials": null,
              "cityCode": "0755",
              "center": "114.350584,22.708881",
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440311,
              "id": 2010,
              "areaName": "大鹏新区",
              "areaCode": null,
              "areaShort": "Dapengxinqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440312,
              "id": 2011,
              "areaName": "龙华新区",
              "areaCode": null,
              "areaShort": "Longhuaxinqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 440300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440400,
          "id": 2012,
          "areaName": "珠海市",
          "areaCode": "0756",
          "areaShort": "Zhuhai",
          "areaInitials": null,
          "cityCode": "140",
          "center": "113.576677,22.270978",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440402,
              "id": 2013,
              "areaName": "香洲区",
              "areaCode": "0756",
              "areaShort": "Xiangzhou",
              "areaInitials": null,
              "cityCode": "0756",
              "center": "113.543784,22.265811",
              "areaLevel": 3,
              "areaParentId": 440400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440403,
              "id": 2014,
              "areaName": "斗门区",
              "areaCode": "0756",
              "areaShort": "Doumen",
              "areaInitials": null,
              "cityCode": "0756",
              "center": "113.296467,22.2092",
              "areaLevel": 3,
              "areaParentId": 440400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440404,
              "id": 2015,
              "areaName": "金湾区",
              "areaCode": "0756",
              "areaShort": "Jinwan",
              "areaInitials": null,
              "cityCode": "0756",
              "center": "113.362656,22.147471",
              "areaLevel": 3,
              "areaParentId": 440400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440500,
          "id": 2016,
          "areaName": "汕头市",
          "areaCode": "0754",
          "areaShort": "Shantou",
          "areaInitials": null,
          "cityCode": "303",
          "center": "116.681972,23.354091",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440507,
              "id": 2017,
              "areaName": "龙湖区",
              "areaCode": "0754",
              "areaShort": "Longhu",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "116.716446,23.372254",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440511,
              "id": 2018,
              "areaName": "金平区",
              "areaCode": "0754",
              "areaShort": "Jinping",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "116.70345,23.365556",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440512,
              "id": 2019,
              "areaName": "濠江区",
              "areaCode": "0754",
              "areaShort": "Haojiang",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "116.726973,23.286079",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440513,
              "id": 2020,
              "areaName": "潮阳区",
              "areaCode": "0754",
              "areaShort": "Chaoyang",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "116.601509,23.265356",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440514,
              "id": 2021,
              "areaName": "潮南区",
              "areaCode": "0754",
              "areaShort": "Chaonan",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "116.439178,23.23865",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440515,
              "id": 2022,
              "areaName": "澄海区",
              "areaCode": "0754",
              "areaShort": "Chenghai",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "116.755992,23.466709",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440523,
              "id": 2023,
              "areaName": "南澳县",
              "areaCode": "0754",
              "areaShort": "Nanao",
              "areaInitials": null,
              "cityCode": "0754",
              "center": "117.023374,23.421724",
              "areaLevel": 3,
              "areaParentId": 440500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440600,
          "id": 2024,
          "areaName": "佛山市",
          "areaCode": "0757",
          "areaShort": "Foshan",
          "areaInitials": null,
          "cityCode": "138",
          "center": "113.121435,23.021478",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440604,
              "id": 2025,
              "areaName": "禅城区",
              "areaCode": "0757",
              "areaShort": "Chancheng",
              "areaInitials": null,
              "cityCode": "0757",
              "center": "113.122421,23.009551",
              "areaLevel": 3,
              "areaParentId": 440600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440605,
              "id": 2026,
              "areaName": "南海区",
              "areaCode": "0757",
              "areaShort": "Nanhai",
              "areaInitials": null,
              "cityCode": "0757",
              "center": "113.143441,23.028956",
              "areaLevel": 3,
              "areaParentId": 440600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440606,
              "id": 2027,
              "areaName": "顺德区",
              "areaCode": "0757",
              "areaShort": "Shunde",
              "areaInitials": null,
              "cityCode": "0757",
              "center": "113.293359,22.80524",
              "areaLevel": 3,
              "areaParentId": 440600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440607,
              "id": 2028,
              "areaName": "三水区",
              "areaCode": "0757",
              "areaShort": "Sanshui",
              "areaInitials": null,
              "cityCode": "0757",
              "center": "112.896685,23.155931",
              "areaLevel": 3,
              "areaParentId": 440600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440608,
              "id": 2029,
              "areaName": "高明区",
              "areaCode": "0757",
              "areaShort": "Gaoming",
              "areaInitials": null,
              "cityCode": "0757",
              "center": "112.892585,22.900139",
              "areaLevel": 3,
              "areaParentId": 440600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440700,
          "id": 2030,
          "areaName": "江门市",
          "areaCode": "0750",
          "areaShort": "Jiangmen",
          "areaInitials": null,
          "cityCode": "302",
          "center": "113.081542,22.57899",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440703,
              "id": 2031,
              "areaName": "蓬江区",
              "areaCode": "0750",
              "areaShort": "Pengjiang",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "113.078521,22.595149",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440704,
              "id": 2032,
              "areaName": "江海区",
              "areaCode": "0750",
              "areaShort": "Jianghai",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "113.111612,22.560473",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440705,
              "id": 2033,
              "areaName": "新会区",
              "areaCode": "0750",
              "areaShort": "Xinhui",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "113.034187,22.4583",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440781,
              "id": 2034,
              "areaName": "台山市",
              "areaCode": "0750",
              "areaShort": "Taishan",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "112.794065,22.251924",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440783,
              "id": 2035,
              "areaName": "开平市",
              "areaCode": "0750",
              "areaShort": "Kaiping",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "112.698545,22.376395",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440784,
              "id": 2036,
              "areaName": "鹤山市",
              "areaCode": "0750",
              "areaShort": "Heshan",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "112.964252,22.76545",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440785,
              "id": 2037,
              "areaName": "恩平市",
              "areaCode": "0750",
              "areaShort": "Enping",
              "areaInitials": null,
              "cityCode": "0750",
              "center": "112.305145,22.183206",
              "areaLevel": 3,
              "areaParentId": 440700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440800,
          "id": 2038,
          "areaName": "湛江市",
          "areaCode": "0759",
          "areaShort": "Zhanjiang",
          "areaInitials": null,
          "cityCode": "198",
          "center": "110.356639,21.270145",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440802,
              "id": 2039,
              "areaName": "赤坎区",
              "areaCode": "0759",
              "areaShort": "Chikan",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.365899,21.266119",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440803,
              "id": 2040,
              "areaName": "霞山区",
              "areaCode": "0759",
              "areaShort": "Xiashan",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.397656,21.192457",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440804,
              "id": 2041,
              "areaName": "坡头区",
              "areaCode": "0759",
              "areaShort": "Potou",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.455332,21.244721",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440811,
              "id": 2042,
              "areaName": "麻章区",
              "areaCode": "0759",
              "areaShort": "Mazhang",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.334387,21.263442",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440823,
              "id": 2043,
              "areaName": "遂溪县",
              "areaCode": "0759",
              "areaShort": "Suixi",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.250123,21.377246",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440825,
              "id": 2044,
              "areaName": "徐闻县",
              "areaCode": "0759",
              "areaShort": "Xuwen",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.176749,20.325489",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440881,
              "id": 2045,
              "areaName": "廉江市",
              "areaCode": "0759",
              "areaShort": "Lianjiang",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.286208,21.6097",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440882,
              "id": 2046,
              "areaName": "雷州市",
              "areaCode": "0759",
              "areaShort": "Leizhou",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.096586,20.914178",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440883,
              "id": 2047,
              "areaName": "吴川市",
              "areaCode": "0759",
              "areaShort": "Wuchuan",
              "areaInitials": null,
              "cityCode": "0759",
              "center": "110.778411,21.441808",
              "areaLevel": 3,
              "areaParentId": 440800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 440900,
          "id": 2048,
          "areaName": "茂名市",
          "areaCode": "0668",
          "areaShort": "Maoming",
          "areaInitials": null,
          "cityCode": "139",
          "center": "110.925439,21.662991",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 440902,
              "id": 2049,
              "areaName": "茂南区",
              "areaCode": "0668",
              "areaShort": "Maonan",
              "areaInitials": null,
              "cityCode": "0668",
              "center": "110.918026,21.641337",
              "areaLevel": 3,
              "areaParentId": 440900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440904,
              "id": 2050,
              "areaName": "电白区",
              "areaCode": "0668",
              "areaShort": "Dianbai",
              "areaInitials": null,
              "cityCode": "0668",
              "center": "111.013556,21.514163",
              "areaLevel": 3,
              "areaParentId": 440900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440981,
              "id": 2051,
              "areaName": "高州市",
              "areaCode": "0668",
              "areaShort": "Gaozhou",
              "areaInitials": null,
              "cityCode": "0668",
              "center": "110.853299,21.918203",
              "areaLevel": 3,
              "areaParentId": 440900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440982,
              "id": 2052,
              "areaName": "化州市",
              "areaCode": "0668",
              "areaShort": "Huazhou",
              "areaInitials": null,
              "cityCode": "0668",
              "center": "110.639565,21.66463",
              "areaLevel": 3,
              "areaParentId": 440900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 440983,
              "id": 2053,
              "areaName": "信宜市",
              "areaCode": "0668",
              "areaShort": "Xinyi",
              "areaInitials": null,
              "cityCode": "0668",
              "center": "110.947043,22.354385",
              "areaLevel": 3,
              "areaParentId": 440900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441200,
          "id": 2054,
          "areaName": "肇庆市",
          "areaCode": "0758",
          "areaShort": "Zhaoqing",
          "areaInitials": null,
          "cityCode": "338",
          "center": "112.465091,23.047191",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441202,
              "id": 2055,
              "areaName": "端州区",
              "areaCode": "0758",
              "areaShort": "Duanzhou",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "112.484848,23.052101",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441203,
              "id": 2056,
              "areaName": "鼎湖区",
              "areaCode": "0758",
              "areaShort": "Dinghu",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "112.567588,23.158447",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441223,
              "id": 2057,
              "areaName": "广宁县",
              "areaCode": "0758",
              "areaShort": "Guangning",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "112.44069,23.634675",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441224,
              "id": 2058,
              "areaName": "怀集县",
              "areaCode": "0758",
              "areaShort": "Huaiji",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "112.167742,23.92035",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441225,
              "id": 2059,
              "areaName": "封开县",
              "areaCode": "0758",
              "areaShort": "Fengkai",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "111.512343,23.424033",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441226,
              "id": 2060,
              "areaName": "德庆县",
              "areaCode": "0758",
              "areaShort": "Deqing",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "111.785937,23.143722",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441283,
              "id": 2061,
              "areaName": "高要市",
              "areaCode": null,
              "areaShort": "Gaoyao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441284,
              "id": 2062,
              "areaName": "四会市",
              "areaCode": "0758",
              "areaShort": "Sihui",
              "areaInitials": null,
              "cityCode": "0758",
              "center": "112.734103,23.327001",
              "areaLevel": 3,
              "areaParentId": 441200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441300,
          "id": 2063,
          "areaName": "惠州市",
          "areaCode": "0752",
          "areaShort": "Huizhou",
          "areaInitials": null,
          "cityCode": "301",
          "center": "114.415612,23.112381",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441302,
              "id": 2064,
              "areaName": "惠城区",
              "areaCode": "0752",
              "areaShort": "Huicheng",
              "areaInitials": null,
              "cityCode": "0752",
              "center": "114.382474,23.084137",
              "areaLevel": 3,
              "areaParentId": 441300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441303,
              "id": 2065,
              "areaName": "惠阳区",
              "areaCode": "0752",
              "areaShort": "Huiyang",
              "areaInitials": null,
              "cityCode": "0752",
              "center": "114.456176,22.789788",
              "areaLevel": 3,
              "areaParentId": 441300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441322,
              "id": 2066,
              "areaName": "博罗县",
              "areaCode": "0752",
              "areaShort": "Boluo",
              "areaInitials": null,
              "cityCode": "0752",
              "center": "114.289528,23.172771",
              "areaLevel": 3,
              "areaParentId": 441300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441323,
              "id": 2067,
              "areaName": "惠东县",
              "areaCode": "0752",
              "areaShort": "Huidong",
              "areaInitials": null,
              "cityCode": "0752",
              "center": "114.719988,22.985014",
              "areaLevel": 3,
              "areaParentId": 441300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441324,
              "id": 2068,
              "areaName": "龙门县",
              "areaCode": "0752",
              "areaShort": "Longmen",
              "areaInitials": null,
              "cityCode": "0752",
              "center": "114.254863,23.727737",
              "areaLevel": 3,
              "areaParentId": 441300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441400,
          "id": 2069,
          "areaName": "梅州市",
          "areaCode": "0753",
          "areaShort": "Meizhou",
          "areaInitials": null,
          "cityCode": "141",
          "center": "116.122523,24.288578",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441402,
              "id": 2070,
              "areaName": "梅江区",
              "areaCode": "0753",
              "areaShort": "Meijiang",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "116.116695,24.31049",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441403,
              "id": 2071,
              "areaName": "梅县区",
              "areaCode": "0753",
              "areaShort": "Meixian",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "116.081656,24.265926",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441422,
              "id": 2072,
              "areaName": "大埔县",
              "areaCode": "0753",
              "areaShort": "Dabu",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "116.695195,24.347782",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441423,
              "id": 2073,
              "areaName": "丰顺县",
              "areaCode": "0753",
              "areaShort": "Fengshun",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "116.181691,23.739343",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441424,
              "id": 2074,
              "areaName": "五华县",
              "areaCode": "0753",
              "areaShort": "Wuhua",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "115.775788,23.932409",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441426,
              "id": 2075,
              "areaName": "平远县",
              "areaCode": "0753",
              "areaShort": "Pingyuan",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "115.891638,24.567261",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441427,
              "id": 2076,
              "areaName": "蕉岭县",
              "areaCode": "0753",
              "areaShort": "Jiaoling",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "116.171355,24.658699",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441481,
              "id": 2077,
              "areaName": "兴宁市",
              "areaCode": "0753",
              "areaShort": "Xingning",
              "areaInitials": null,
              "cityCode": "0753",
              "center": "115.731167,24.136708",
              "areaLevel": 3,
              "areaParentId": 441400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441500,
          "id": 2078,
          "areaName": "汕尾市",
          "areaCode": "0660",
          "areaShort": "Shanwei",
          "areaInitials": null,
          "cityCode": "339",
          "center": "115.375431,22.78705",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441502,
              "id": 2079,
              "areaName": "城区",
              "areaCode": "0660",
              "areaShort": "Chengqu",
              "areaInitials": null,
              "cityCode": "0660",
              "center": "115.365058,22.779207",
              "areaLevel": 3,
              "areaParentId": 441500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441521,
              "id": 2080,
              "areaName": "海丰县",
              "areaCode": "0660",
              "areaShort": "Haifeng",
              "areaInitials": null,
              "cityCode": "0660",
              "center": "115.323436,22.966585",
              "areaLevel": 3,
              "areaParentId": 441500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441523,
              "id": 2081,
              "areaName": "陆河县",
              "areaCode": "0660",
              "areaShort": "Luhe",
              "areaInitials": null,
              "cityCode": "0660",
              "center": "115.660143,23.301616",
              "areaLevel": 3,
              "areaParentId": 441500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441581,
              "id": 2082,
              "areaName": "陆丰市",
              "areaCode": "0660",
              "areaShort": "Lufeng",
              "areaInitials": null,
              "cityCode": "0660",
              "center": "115.652151,22.919228",
              "areaLevel": 3,
              "areaParentId": 441500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441600,
          "id": 2083,
          "areaName": "河源市",
          "areaCode": "0762",
          "areaShort": "Heyuan",
          "areaInitials": null,
          "cityCode": "200",
          "center": "114.700961,23.743686",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441602,
              "id": 2084,
              "areaName": "源城区",
              "areaCode": "0762",
              "areaShort": "Yuancheng",
              "areaInitials": null,
              "cityCode": "0762",
              "center": "114.702517,23.733969",
              "areaLevel": 3,
              "areaParentId": 441600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441621,
              "id": 2085,
              "areaName": "紫金县",
              "areaCode": "0762",
              "areaShort": "Zijin",
              "areaInitials": null,
              "cityCode": "0762",
              "center": "115.184107,23.635745",
              "areaLevel": 3,
              "areaParentId": 441600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441622,
              "id": 2086,
              "areaName": "龙川县",
              "areaCode": "0762",
              "areaShort": "Longchuan",
              "areaInitials": null,
              "cityCode": "0762",
              "center": "115.259871,24.100066",
              "areaLevel": 3,
              "areaParentId": 441600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441623,
              "id": 2087,
              "areaName": "连平县",
              "areaCode": "0762",
              "areaShort": "Lianping",
              "areaInitials": null,
              "cityCode": "0762",
              "center": "114.488556,24.369583",
              "areaLevel": 3,
              "areaParentId": 441600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441624,
              "id": 2088,
              "areaName": "和平县",
              "areaCode": "0762",
              "areaShort": "Heping",
              "areaInitials": null,
              "cityCode": "0762",
              "center": "114.938684,24.44218",
              "areaLevel": 3,
              "areaParentId": 441600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441625,
              "id": 2089,
              "areaName": "东源县",
              "areaCode": "0762",
              "areaShort": "Dongyuan",
              "areaInitials": null,
              "cityCode": "0762",
              "center": "114.746344,23.788189",
              "areaLevel": 3,
              "areaParentId": 441600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441700,
          "id": 2090,
          "areaName": "阳江市",
          "areaCode": "0662",
          "areaShort": "Yangjiang",
          "areaInitials": null,
          "cityCode": "199",
          "center": "111.982589,21.857887",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441702,
              "id": 2091,
              "areaName": "江城区",
              "areaCode": "0662",
              "areaShort": "Jiangcheng",
              "areaInitials": null,
              "cityCode": "0662",
              "center": "111.955058,21.861786",
              "areaLevel": 3,
              "areaParentId": 441700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441704,
              "id": 2092,
              "areaName": "阳东区",
              "areaCode": "0662",
              "areaShort": "Yangdong",
              "areaInitials": null,
              "cityCode": "0662",
              "center": "112.006363,21.868337",
              "areaLevel": 3,
              "areaParentId": 441700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441721,
              "id": 2093,
              "areaName": "阳西县",
              "areaCode": "0662",
              "areaShort": "Yangxi",
              "areaInitials": null,
              "cityCode": "0662",
              "center": "111.61766,21.752771",
              "areaLevel": 3,
              "areaParentId": 441700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441781,
              "id": 2094,
              "areaName": "阳春市",
              "areaCode": "0662",
              "areaShort": "Yangchun",
              "areaInitials": null,
              "cityCode": "0662",
              "center": "111.791587,22.17041",
              "areaLevel": 3,
              "areaParentId": 441700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441800,
          "id": 2095,
          "areaName": "清远市",
          "areaCode": "0763",
          "areaShort": "Qingyuan",
          "areaInitials": null,
          "cityCode": "197",
          "center": "113.056042,23.681774",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441802,
              "id": 2096,
              "areaName": "清城区",
              "areaCode": "0763",
              "areaShort": "Qingcheng",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "113.062692,23.697899",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441803,
              "id": 2097,
              "areaName": "清新区",
              "areaCode": "0763",
              "areaShort": "Qingxin",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "113.017747,23.734677",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441821,
              "id": 2098,
              "areaName": "佛冈县",
              "areaCode": "0763",
              "areaShort": "Fogang",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "113.531607,23.879192",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441823,
              "id": 2099,
              "areaName": "阳山县",
              "areaCode": "0763",
              "areaShort": "Yangshan",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "112.641363,24.465359",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441825,
              "id": 2100,
              "areaName": "连山壮族瑶族自治县",
              "areaCode": "0763",
              "areaShort": "Lianshan",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "112.093617,24.570491",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441826,
              "id": 2101,
              "areaName": "连南瑶族自治县",
              "areaCode": "0763",
              "areaShort": "Liannan",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "112.287012,24.726017",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441881,
              "id": 2102,
              "areaName": "英德市",
              "areaCode": "0763",
              "areaShort": "Yingde",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "113.401701,24.206986",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441882,
              "id": 2103,
              "areaName": "连州市",
              "areaCode": "0763",
              "areaShort": "Lianzhou",
              "areaInitials": null,
              "cityCode": "0763",
              "center": "112.377361,24.780966",
              "areaLevel": 3,
              "areaParentId": 441800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 441900,
          "id": 2104,
          "areaName": "东莞市",
          "areaCode": "0769",
          "areaShort": "Dongguan",
          "areaInitials": null,
          "cityCode": "119",
          "center": "113.751799,23.020673",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 441901,
              "id": 2105,
              "areaName": "莞城区",
              "areaCode": null,
              "areaShort": "Guancheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441902,
              "id": 2106,
              "areaName": "南城区",
              "areaCode": null,
              "areaShort": "Nancheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441904,
              "id": 2107,
              "areaName": "万江区",
              "areaCode": null,
              "areaShort": "Wanjiang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441905,
              "id": 2108,
              "areaName": "石碣镇",
              "areaCode": null,
              "areaShort": "Shijie",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441906,
              "id": 2109,
              "areaName": "石龙镇",
              "areaCode": null,
              "areaShort": "Shilong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441907,
              "id": 2110,
              "areaName": "茶山镇",
              "areaCode": null,
              "areaShort": "Chashan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441908,
              "id": 2111,
              "areaName": "石排镇",
              "areaCode": null,
              "areaShort": "Shipai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441909,
              "id": 2112,
              "areaName": "企石镇",
              "areaCode": null,
              "areaShort": "Qishi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441910,
              "id": 2113,
              "areaName": "横沥镇",
              "areaCode": null,
              "areaShort": "Hengli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441911,
              "id": 2114,
              "areaName": "桥头镇",
              "areaCode": null,
              "areaShort": "Qiaotou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441912,
              "id": 2115,
              "areaName": "谢岗镇",
              "areaCode": null,
              "areaShort": "Xiegang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441913,
              "id": 2116,
              "areaName": "东坑镇",
              "areaCode": null,
              "areaShort": "Dongkeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441914,
              "id": 2117,
              "areaName": "常平镇",
              "areaCode": null,
              "areaShort": "Changping",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441915,
              "id": 2118,
              "areaName": "寮步镇",
              "areaCode": null,
              "areaShort": "Liaobu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441916,
              "id": 2119,
              "areaName": "大朗镇",
              "areaCode": null,
              "areaShort": "Dalang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441917,
              "id": 2120,
              "areaName": "麻涌镇",
              "areaCode": null,
              "areaShort": "Machong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441918,
              "id": 2121,
              "areaName": "中堂镇",
              "areaCode": null,
              "areaShort": "Zhongtang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441919,
              "id": 2122,
              "areaName": "高埗镇",
              "areaCode": null,
              "areaShort": "Gaobu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441920,
              "id": 2123,
              "areaName": "樟木头镇",
              "areaCode": null,
              "areaShort": "Zhangmutou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441921,
              "id": 2124,
              "areaName": "大岭山镇",
              "areaCode": null,
              "areaShort": "Dalingshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441922,
              "id": 2125,
              "areaName": "望牛墩镇",
              "areaCode": null,
              "areaShort": "Wangniudun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441923,
              "id": 2126,
              "areaName": "黄江镇",
              "areaCode": null,
              "areaShort": "Huangjiang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441924,
              "id": 2127,
              "areaName": "洪梅镇",
              "areaCode": null,
              "areaShort": "Hongmei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441925,
              "id": 2128,
              "areaName": "清溪镇",
              "areaCode": null,
              "areaShort": "Qingxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441926,
              "id": 2129,
              "areaName": "沙田镇",
              "areaCode": null,
              "areaShort": "Shatian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441927,
              "id": 2130,
              "areaName": "道滘镇",
              "areaCode": null,
              "areaShort": "Daojiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441928,
              "id": 2131,
              "areaName": "塘厦镇",
              "areaCode": null,
              "areaShort": "Tangxia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441929,
              "id": 2132,
              "areaName": "虎门镇",
              "areaCode": null,
              "areaShort": "Humen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441930,
              "id": 2133,
              "areaName": "厚街镇",
              "areaCode": null,
              "areaShort": "Houjie",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441931,
              "id": 2134,
              "areaName": "凤岗镇",
              "areaCode": null,
              "areaShort": "Fenggang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 441932,
              "id": 2135,
              "areaName": "长安镇",
              "areaCode": null,
              "areaShort": "Chang'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 441900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 442000,
          "id": 2136,
          "areaName": "中山市",
          "areaCode": "0760",
          "areaShort": "Zhongshan",
          "areaInitials": null,
          "cityCode": "187",
          "center": "113.39277,22.517585",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 442001,
              "id": 2137,
              "areaName": "石岐区",
              "areaCode": null,
              "areaShort": "Shiqi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442004,
              "id": 2138,
              "areaName": "南区",
              "areaCode": null,
              "areaShort": "Nanqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442005,
              "id": 2139,
              "areaName": "五桂山区",
              "areaCode": null,
              "areaShort": "Wuguishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442006,
              "id": 2140,
              "areaName": "火炬开发区",
              "areaCode": null,
              "areaShort": "Huoju",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442007,
              "id": 2141,
              "areaName": "黄圃镇",
              "areaCode": null,
              "areaShort": "Huangpu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442008,
              "id": 2142,
              "areaName": "南头镇",
              "areaCode": null,
              "areaShort": "Nantou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442009,
              "id": 2143,
              "areaName": "东凤镇",
              "areaCode": null,
              "areaShort": "Dongfeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442010,
              "id": 2144,
              "areaName": "阜沙镇",
              "areaCode": null,
              "areaShort": "Fusha",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442011,
              "id": 2145,
              "areaName": "小榄镇",
              "areaCode": null,
              "areaShort": "Xiaolan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442012,
              "id": 2146,
              "areaName": "东升镇",
              "areaCode": null,
              "areaShort": "Dongsheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442013,
              "id": 2147,
              "areaName": "古镇镇",
              "areaCode": null,
              "areaShort": "Guzhen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442014,
              "id": 2148,
              "areaName": "横栏镇",
              "areaCode": null,
              "areaShort": "Henglan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442015,
              "id": 2149,
              "areaName": "三角镇",
              "areaCode": null,
              "areaShort": "Sanjiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442016,
              "id": 2150,
              "areaName": "民众镇",
              "areaCode": null,
              "areaShort": "Minzhong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442017,
              "id": 2151,
              "areaName": "南朗镇",
              "areaCode": null,
              "areaShort": "Nanlang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442018,
              "id": 2152,
              "areaName": "港口镇",
              "areaCode": null,
              "areaShort": "Gangkou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442019,
              "id": 2153,
              "areaName": "大涌镇",
              "areaCode": null,
              "areaShort": "Dayong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442020,
              "id": 2154,
              "areaName": "沙溪镇",
              "areaCode": null,
              "areaShort": "Shaxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442021,
              "id": 2155,
              "areaName": "三乡镇",
              "areaCode": null,
              "areaShort": "Sanxiang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442022,
              "id": 2156,
              "areaName": "板芙镇",
              "areaCode": null,
              "areaShort": "Banfu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442023,
              "id": 2157,
              "areaName": "神湾镇",
              "areaCode": null,
              "areaShort": "Shenwan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 442024,
              "id": 2158,
              "areaName": "坦洲镇",
              "areaCode": null,
              "areaShort": "Tanzhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 442000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 445100,
          "id": 2159,
          "areaName": "潮州市",
          "areaCode": "0768",
          "areaShort": "Chaozhou",
          "areaInitials": null,
          "cityCode": "201",
          "center": "116.622444,23.657262",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 445102,
              "id": 2160,
              "areaName": "湘桥区",
              "areaCode": "0768",
              "areaShort": "Xiangqiao",
              "areaInitials": null,
              "cityCode": "0768",
              "center": "116.628627,23.674387",
              "areaLevel": 3,
              "areaParentId": 445100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445103,
              "id": 2161,
              "areaName": "潮安区",
              "areaCode": "0768",
              "areaShort": "Chao'an",
              "areaInitials": null,
              "cityCode": "0768",
              "center": "116.678203,23.462613",
              "areaLevel": 3,
              "areaParentId": 445100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445122,
              "id": 2162,
              "areaName": "饶平县",
              "areaCode": "0768",
              "areaShort": "Raoping",
              "areaInitials": null,
              "cityCode": "0768",
              "center": "117.0039,23.663824",
              "areaLevel": 3,
              "areaParentId": 445100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 445200,
          "id": 2163,
          "areaName": "揭阳市",
          "areaCode": "0663",
          "areaShort": "Jieyang",
          "areaInitials": null,
          "cityCode": "259",
          "center": "116.372708,23.549701",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 445202,
              "id": 2164,
              "areaName": "榕城区",
              "areaCode": "0663",
              "areaShort": "Rongcheng",
              "areaInitials": null,
              "cityCode": "0663",
              "center": "116.367012,23.525382",
              "areaLevel": 3,
              "areaParentId": 445200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445203,
              "id": 2165,
              "areaName": "揭东区",
              "areaCode": "0663",
              "areaShort": "Jiedong",
              "areaInitials": null,
              "cityCode": "0663",
              "center": "116.412015,23.566126",
              "areaLevel": 3,
              "areaParentId": 445200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445222,
              "id": 2166,
              "areaName": "揭西县",
              "areaCode": "0663",
              "areaShort": "Jiexi",
              "areaInitials": null,
              "cityCode": "0663",
              "center": "115.841837,23.431294",
              "areaLevel": 3,
              "areaParentId": 445200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445224,
              "id": 2167,
              "areaName": "惠来县",
              "areaCode": "0663",
              "areaShort": "Huilai",
              "areaInitials": null,
              "cityCode": "0663",
              "center": "116.29515,23.033266",
              "areaLevel": 3,
              "areaParentId": 445200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445281,
              "id": 2168,
              "areaName": "普宁市",
              "areaCode": "0663",
              "areaShort": "Puning",
              "areaInitials": null,
              "cityCode": "0663",
              "center": "116.165777,23.297493",
              "areaLevel": 3,
              "areaParentId": 445200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 445300,
          "id": 2169,
          "areaName": "云浮市",
          "areaCode": "0766",
          "areaShort": "Yunfu",
          "areaInitials": null,
          "cityCode": "258",
          "center": "112.044491,22.915094",
          "areaLevel": 2,
          "areaParentId": 440000,
          "areaDictionaryVOList": [
            {
              "areaId": 445302,
              "id": 2170,
              "areaName": "云城区",
              "areaCode": "0766",
              "areaShort": "Yuncheng",
              "areaInitials": null,
              "cityCode": "0766",
              "center": "112.043945,22.92815",
              "areaLevel": 3,
              "areaParentId": 445300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445303,
              "id": 2171,
              "areaName": "云安区",
              "areaCode": "0766",
              "areaShort": "Yun'an",
              "areaInitials": null,
              "cityCode": "0766",
              "center": "112.003208,23.071019",
              "areaLevel": 3,
              "areaParentId": 445300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445321,
              "id": 2172,
              "areaName": "新兴县",
              "areaCode": "0766",
              "areaShort": "Xinxing",
              "areaInitials": null,
              "cityCode": "0766",
              "center": "112.225334,22.69569",
              "areaLevel": 3,
              "areaParentId": 445300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445322,
              "id": 2173,
              "areaName": "郁南县",
              "areaCode": "0766",
              "areaShort": "Yunan",
              "areaInitials": null,
              "cityCode": "0766",
              "center": "111.535285,23.23456",
              "areaLevel": 3,
              "areaParentId": 445300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 445381,
              "id": 2174,
              "areaName": "罗定市",
              "areaCode": "0766",
              "areaShort": "Luoding",
              "areaInitials": null,
              "cityCode": "0766",
              "center": "111.569892,22.768285",
              "areaLevel": 3,
              "areaParentId": 445300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 450000,
      "id": 2175,
      "areaName": "广西壮族自治区",
      "areaCode": "[]",
      "areaShort": "Guangxi",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "108.327546,22.815478",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 450100,
          "id": 2176,
          "areaName": "南宁市",
          "areaCode": "0771",
          "areaShort": "Nanning",
          "areaInitials": null,
          "cityCode": "261",
          "center": "108.366543,22.817002",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450102,
              "id": 2177,
              "areaName": "兴宁区",
              "areaCode": "0771",
              "areaShort": "Xingning",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.368871,22.854021",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450103,
              "id": 2178,
              "areaName": "青秀区",
              "areaCode": "0771",
              "areaShort": "Qingxiu",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.494024,22.785879",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450105,
              "id": 2179,
              "areaName": "江南区",
              "areaCode": "0771",
              "areaShort": "Jiangnan",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.273133,22.78136",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450107,
              "id": 2180,
              "areaName": "西乡塘区",
              "areaCode": "0771",
              "areaShort": "Xixiangtang",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.313494,22.833928",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450108,
              "id": 2181,
              "areaName": "良庆区",
              "areaCode": "0771",
              "areaShort": "Liangqing",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.39301,22.752997",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450109,
              "id": 2182,
              "areaName": "邕宁区",
              "areaCode": "0771",
              "areaShort": "Yongning",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.487368,22.75839",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450122,
              "id": 2183,
              "areaName": "武鸣县",
              "areaCode": null,
              "areaShort": "Wuming",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450123,
              "id": 2184,
              "areaName": "隆安县",
              "areaCode": "0771",
              "areaShort": "Long'an",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "107.696153,23.166028",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450124,
              "id": 2185,
              "areaName": "马山县",
              "areaCode": "0771",
              "areaShort": "Mashan",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.177019,23.708321",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450125,
              "id": 2186,
              "areaName": "上林县",
              "areaCode": "0771",
              "areaShort": "Shanglin",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.602846,23.431908",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450126,
              "id": 2187,
              "areaName": "宾阳县",
              "areaCode": "0771",
              "areaShort": "Binyang",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "108.810326,23.217786",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450127,
              "id": 2188,
              "areaName": "横县",
              "areaCode": "0771",
              "areaShort": "Hengxian",
              "areaInitials": null,
              "cityCode": "0771",
              "center": "109.261384,22.679931",
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450128,
              "id": 2189,
              "areaName": "埌东新区",
              "areaCode": null,
              "areaShort": "Langdong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 450100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450200,
          "id": 2190,
          "areaName": "柳州市",
          "areaCode": "0772",
          "areaShort": "Liuzhou",
          "areaInitials": null,
          "cityCode": "305",
          "center": "109.428608,24.326291",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450202,
              "id": 2191,
              "areaName": "城中区",
              "areaCode": "0772",
              "areaShort": "Chengzhong",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.4273,24.366",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450203,
              "id": 2192,
              "areaName": "鱼峰区",
              "areaCode": "0772",
              "areaShort": "Yufeng",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.452442,24.318516",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450204,
              "id": 2193,
              "areaName": "柳南区",
              "areaCode": "0772",
              "areaShort": "Liunan",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.385518,24.336229",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450205,
              "id": 2194,
              "areaName": "柳北区",
              "areaCode": "0772",
              "areaShort": "Liubei",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.402049,24.362691",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450221,
              "id": 2195,
              "areaName": "柳江县",
              "areaCode": null,
              "areaShort": "Liujiang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450222,
              "id": 2196,
              "areaName": "柳城县",
              "areaCode": "0772",
              "areaShort": "Liucheng",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.24473,24.651518",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450223,
              "id": 2197,
              "areaName": "鹿寨县",
              "areaCode": "0772",
              "areaShort": "Luzhai",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.750638,24.472897",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450224,
              "id": 2198,
              "areaName": "融安县",
              "areaCode": "0772",
              "areaShort": "Rong'an",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.397538,25.224549",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450225,
              "id": 2199,
              "areaName": "融水苗族自治县",
              "areaCode": "0772",
              "areaShort": "Rongshui",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.256334,25.065934",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450226,
              "id": 2200,
              "areaName": "三江侗族自治县",
              "areaCode": "0772",
              "areaShort": "Sanjiang",
              "areaInitials": null,
              "cityCode": "0772",
              "center": "109.607675,25.783198",
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450227,
              "id": 2201,
              "areaName": "柳东新区",
              "areaCode": null,
              "areaShort": "Liudong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 450200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450300,
          "id": 2202,
          "areaName": "桂林市",
          "areaCode": "0773",
          "areaShort": "Guilin",
          "areaInitials": null,
          "cityCode": "142",
          "center": "110.179953,25.234479",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450302,
              "id": 2203,
              "areaName": "秀峰区",
              "areaCode": "0773",
              "areaShort": "Xiufeng",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.264183,25.273625",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450303,
              "id": 2204,
              "areaName": "叠彩区",
              "areaCode": "0773",
              "areaShort": "Diecai",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.301723,25.314",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450304,
              "id": 2205,
              "areaName": "象山区",
              "areaCode": "0773",
              "areaShort": "Xiangshan",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.281082,25.261686",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450305,
              "id": 2206,
              "areaName": "七星区",
              "areaCode": "0773",
              "areaShort": "Qixing",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.317826,25.252701",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450311,
              "id": 2207,
              "areaName": "雁山区",
              "areaCode": "0773",
              "areaShort": "Yanshan",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.28669,25.101934",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450312,
              "id": 2208,
              "areaName": "临桂区",
              "areaCode": "0773",
              "areaShort": "Lingui",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.212463,25.238628",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450321,
              "id": 2209,
              "areaName": "阳朔县",
              "areaCode": "0773",
              "areaShort": "Yangshuo",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.496593,24.77848",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450323,
              "id": 2210,
              "areaName": "灵川县",
              "areaCode": "0773",
              "areaShort": "Lingchuan",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.319897,25.394781",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450324,
              "id": 2211,
              "areaName": "全州县",
              "areaCode": "0773",
              "areaShort": "Quanzhou",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "111.072946,25.928387",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450325,
              "id": 2212,
              "areaName": "兴安县",
              "areaCode": "0773",
              "areaShort": "Xing'an",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.67167,25.611704",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450326,
              "id": 2213,
              "areaName": "永福县",
              "areaCode": "0773",
              "areaShort": "Yongfu",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "109.983076,24.979855",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450327,
              "id": 2214,
              "areaName": "灌阳县",
              "areaCode": "0773",
              "areaShort": "Guanyang",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "111.160851,25.489383",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450328,
              "id": 2215,
              "areaName": "龙胜各族自治县",
              "areaCode": "0773",
              "areaShort": "Longsheng",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.011238,25.797931",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450329,
              "id": 2216,
              "areaName": "资源县",
              "areaCode": "0773",
              "areaShort": "Ziyuan",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.6527,26.042443",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450330,
              "id": 2217,
              "areaName": "平乐县",
              "areaCode": "0773",
              "areaShort": "Pingle",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.643305,24.633362",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450331,
              "id": 2218,
              "areaName": "荔浦县",
              "areaCode": "0773",
              "areaShort": "Lipu",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.395104,24.488342",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450332,
              "id": 2219,
              "areaName": "恭城瑶族自治县",
              "areaCode": "0773",
              "areaShort": "Gongcheng",
              "areaInitials": null,
              "cityCode": "0773",
              "center": "110.828409,24.831682",
              "areaLevel": 3,
              "areaParentId": 450300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450400,
          "id": 2220,
          "areaName": "梧州市",
          "areaCode": "0774",
          "areaShort": "Wuzhou",
          "areaInitials": null,
          "cityCode": "304",
          "center": "111.279115,23.476962",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450403,
              "id": 2221,
              "areaName": "万秀区",
              "areaCode": "0774",
              "areaShort": "Wanxiu",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "111.320518,23.472991",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450405,
              "id": 2222,
              "areaName": "长洲区",
              "areaCode": "0774",
              "areaShort": "Changzhou",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "111.274673,23.485944",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450406,
              "id": 2223,
              "areaName": "龙圩区",
              "areaCode": "0774",
              "areaShort": "Longxu",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "111.246606,23.404772",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450421,
              "id": 2224,
              "areaName": "苍梧县",
              "areaCode": "0774",
              "areaShort": "Cangwu",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "111.544007,23.845097",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450422,
              "id": 2225,
              "areaName": "藤县",
              "areaCode": "0774",
              "areaShort": "Tengxian",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "110.914849,23.374983",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450423,
              "id": 2226,
              "areaName": "蒙山县",
              "areaCode": "0774",
              "areaShort": "Mengshan",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "110.525003,24.19357",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450481,
              "id": 2227,
              "areaName": "岑溪市",
              "areaCode": "0774",
              "areaShort": "Cenxi",
              "areaInitials": null,
              "cityCode": "0774",
              "center": "110.994913,22.91835",
              "areaLevel": 3,
              "areaParentId": 450400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450500,
          "id": 2228,
          "areaName": "北海市",
          "areaCode": "0779",
          "areaShort": "Beihai",
          "areaInitials": null,
          "cityCode": "295",
          "center": "109.120161,21.481291",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450502,
              "id": 2229,
              "areaName": "海城区",
              "areaCode": "0779",
              "areaShort": "Haicheng",
              "areaInitials": null,
              "cityCode": "0779",
              "center": "109.117209,21.475004",
              "areaLevel": 3,
              "areaParentId": 450500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450503,
              "id": 2230,
              "areaName": "银海区",
              "areaCode": "0779",
              "areaShort": "Yinhai",
              "areaInitials": null,
              "cityCode": "0779",
              "center": "109.139862,21.449308",
              "areaLevel": 3,
              "areaParentId": 450500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450512,
              "id": 2231,
              "areaName": "铁山港区",
              "areaCode": "0779",
              "areaShort": "Tieshangang",
              "areaInitials": null,
              "cityCode": "0779",
              "center": "109.42158,21.529127",
              "areaLevel": 3,
              "areaParentId": 450500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450521,
              "id": 2232,
              "areaName": "合浦县",
              "areaCode": "0779",
              "areaShort": "Hepu",
              "areaInitials": null,
              "cityCode": "0779",
              "center": "109.207335,21.660935",
              "areaLevel": 3,
              "areaParentId": 450500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450600,
          "id": 2233,
          "areaName": "防城港市",
          "areaCode": "0770",
          "areaShort": "Fangchenggang",
          "areaInitials": null,
          "cityCode": "204",
          "center": "108.353846,21.68686",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450602,
              "id": 2234,
              "areaName": "港口区",
              "areaCode": "0770",
              "areaShort": "Gangkou",
              "areaInitials": null,
              "cityCode": "0770",
              "center": "108.380143,21.643383",
              "areaLevel": 3,
              "areaParentId": 450600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450603,
              "id": 2235,
              "areaName": "防城区",
              "areaCode": "0770",
              "areaShort": "Fangcheng",
              "areaInitials": null,
              "cityCode": "0770",
              "center": "108.353499,21.769211",
              "areaLevel": 3,
              "areaParentId": 450600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450621,
              "id": 2236,
              "areaName": "上思县",
              "areaCode": "0770",
              "areaShort": "Shangsi",
              "areaInitials": null,
              "cityCode": "0770",
              "center": "107.983627,22.153671",
              "areaLevel": 3,
              "areaParentId": 450600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450681,
              "id": 2237,
              "areaName": "东兴市",
              "areaCode": "0770",
              "areaShort": "Dongxing",
              "areaInitials": null,
              "cityCode": "0770",
              "center": "107.971828,21.547821",
              "areaLevel": 3,
              "areaParentId": 450600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450700,
          "id": 2238,
          "areaName": "钦州市",
          "areaCode": "0777",
          "areaShort": "Qinzhou",
          "areaInitials": null,
          "cityCode": "145",
          "center": "108.654146,21.979933",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450702,
              "id": 2239,
              "areaName": "钦南区",
              "areaCode": "0777",
              "areaShort": "Qinnan",
              "areaInitials": null,
              "cityCode": "0777",
              "center": "108.657209,21.938859",
              "areaLevel": 3,
              "areaParentId": 450700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450703,
              "id": 2240,
              "areaName": "钦北区",
              "areaCode": "0777",
              "areaShort": "Qinbei",
              "areaInitials": null,
              "cityCode": "0777",
              "center": "108.44911,22.132761",
              "areaLevel": 3,
              "areaParentId": 450700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450721,
              "id": 2241,
              "areaName": "灵山县",
              "areaCode": "0777",
              "areaShort": "Lingshan",
              "areaInitials": null,
              "cityCode": "0777",
              "center": "109.291006,22.416536",
              "areaLevel": 3,
              "areaParentId": 450700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450722,
              "id": 2242,
              "areaName": "浦北县",
              "areaCode": "0777",
              "areaShort": "Pubei",
              "areaInitials": null,
              "cityCode": "0777",
              "center": "109.556953,22.271651",
              "areaLevel": 3,
              "areaParentId": 450700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450800,
          "id": 2243,
          "areaName": "贵港市",
          "areaCode": "1755",
          "areaShort": "Guigang",
          "areaInitials": null,
          "cityCode": "341",
          "center": "109.598926,23.11153",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450802,
              "id": 2244,
              "areaName": "港北区",
              "areaCode": "1755",
              "areaShort": "Gangbei",
              "areaInitials": null,
              "cityCode": "1755",
              "center": "109.57224,23.11153",
              "areaLevel": 3,
              "areaParentId": 450800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450803,
              "id": 2245,
              "areaName": "港南区",
              "areaCode": "1755",
              "areaShort": "Gangnan",
              "areaInitials": null,
              "cityCode": "1755",
              "center": "109.599556,23.075573",
              "areaLevel": 3,
              "areaParentId": 450800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450804,
              "id": 2246,
              "areaName": "覃塘区",
              "areaCode": "1755",
              "areaShort": "Qintang",
              "areaInitials": null,
              "cityCode": "1755",
              "center": "109.452662,23.127149",
              "areaLevel": 3,
              "areaParentId": 450800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450821,
              "id": 2247,
              "areaName": "平南县",
              "areaCode": "1755",
              "areaShort": "Pingnan",
              "areaInitials": null,
              "cityCode": "1755",
              "center": "110.392311,23.539264",
              "areaLevel": 3,
              "areaParentId": 450800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450881,
              "id": 2248,
              "areaName": "桂平市",
              "areaCode": "1755",
              "areaShort": "Guiping",
              "areaInitials": null,
              "cityCode": "1755",
              "center": "110.079379,23.394325",
              "areaLevel": 3,
              "areaParentId": 450800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 450900,
          "id": 2249,
          "areaName": "玉林市",
          "areaCode": "0775",
          "areaShort": "Yulin",
          "areaInitials": null,
          "cityCode": "361",
          "center": "110.18122,22.654032",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 450902,
              "id": 2250,
              "areaName": "玉州区",
              "areaCode": "0775",
              "areaShort": "Yuzhou",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "110.151153,22.628087",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450903,
              "id": 2251,
              "areaName": "福绵区",
              "areaCode": "0775",
              "areaShort": "Fumian",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "110.059439,22.585556",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450904,
              "id": 2252,
              "areaName": "玉东新区",
              "areaCode": null,
              "areaShort": "Yudong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450921,
              "id": 2253,
              "areaName": "容县",
              "areaCode": "0775",
              "areaShort": "Rongxian",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "110.558074,22.857839",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450922,
              "id": 2254,
              "areaName": "陆川县",
              "areaCode": "0775",
              "areaShort": "Luchuan",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "110.264052,22.321048",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450923,
              "id": 2255,
              "areaName": "博白县",
              "areaCode": "0775",
              "areaShort": "Bobai",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "109.975985,22.273048",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450924,
              "id": 2256,
              "areaName": "兴业县",
              "areaCode": "0775",
              "areaShort": "Xingye",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "109.875304,22.736421",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 450981,
              "id": 2257,
              "areaName": "北流市",
              "areaCode": "0775",
              "areaShort": "Beiliu",
              "areaInitials": null,
              "cityCode": "0775",
              "center": "110.354214,22.70831",
              "areaLevel": 3,
              "areaParentId": 450900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 451000,
          "id": 2258,
          "areaName": "百色市",
          "areaCode": "0776",
          "areaShort": "Baise",
          "areaInitials": null,
          "cityCode": "203",
          "center": "106.618202,23.90233",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 451002,
              "id": 2259,
              "areaName": "右江区",
              "areaCode": "0776",
              "areaShort": "Youjiang",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "106.618225,23.90097",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451021,
              "id": 2260,
              "areaName": "田阳县",
              "areaCode": "0776",
              "areaShort": "Tianyang",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "106.915496,23.735692",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451022,
              "id": 2261,
              "areaName": "田东县",
              "areaCode": "0776",
              "areaShort": "Tiandong",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "107.12608,23.597194",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451023,
              "id": 2262,
              "areaName": "平果县",
              "areaCode": "0776",
              "areaShort": "Pingguo",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "107.589809,23.329376",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451024,
              "id": 2263,
              "areaName": "德保县",
              "areaCode": "0776",
              "areaShort": "Debao",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "106.615373,23.32345",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451025,
              "id": 2264,
              "areaName": "靖西县",
              "areaCode": null,
              "areaShort": "Jingxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451026,
              "id": 2265,
              "areaName": "那坡县",
              "areaCode": "0776",
              "areaShort": "Napo",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "105.83253,23.387441",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451027,
              "id": 2266,
              "areaName": "凌云县",
              "areaCode": "0776",
              "areaShort": "Lingyun",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "106.56131,24.347557",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451028,
              "id": 2267,
              "areaName": "乐业县",
              "areaCode": "0776",
              "areaShort": "Leye",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "106.556519,24.776827",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451029,
              "id": 2268,
              "areaName": "田林县",
              "areaCode": "0776",
              "areaShort": "Tianlin",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "106.228538,24.294487",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451030,
              "id": 2269,
              "areaName": "西林县",
              "areaCode": "0776",
              "areaShort": "Xilin",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "105.093825,24.489823",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451031,
              "id": 2270,
              "areaName": "隆林各族自治县",
              "areaCode": "0776",
              "areaShort": "Longlin",
              "areaInitials": null,
              "cityCode": "0776",
              "center": "105.34404,24.770896",
              "areaLevel": 3,
              "areaParentId": 451000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 451100,
          "id": 2271,
          "areaName": "贺州市",
          "areaCode": "1774",
          "areaShort": "Hezhou",
          "areaInitials": null,
          "cityCode": "260",
          "center": "111.566871,24.403528",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 451102,
              "id": 2272,
              "areaName": "八步区",
              "areaCode": "1774",
              "areaShort": "Babu",
              "areaInitials": null,
              "cityCode": "1774",
              "center": "111.552095,24.411805",
              "areaLevel": 3,
              "areaParentId": 451100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451121,
              "id": 2273,
              "areaName": "昭平县",
              "areaCode": "1774",
              "areaShort": "Zhaoping",
              "areaInitials": null,
              "cityCode": "1774",
              "center": "110.811325,24.169385",
              "areaLevel": 3,
              "areaParentId": 451100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451122,
              "id": 2274,
              "areaName": "钟山县",
              "areaCode": "1774",
              "areaShort": "Zhongshan",
              "areaInitials": null,
              "cityCode": "1774",
              "center": "111.303009,24.525957",
              "areaLevel": 3,
              "areaParentId": 451100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451123,
              "id": 2275,
              "areaName": "富川瑶族自治县",
              "areaCode": "1774",
              "areaShort": "Fuchuan",
              "areaInitials": null,
              "cityCode": "1774",
              "center": "111.27745,24.814443",
              "areaLevel": 3,
              "areaParentId": 451100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451124,
              "id": 2276,
              "areaName": "平桂管理区",
              "areaCode": null,
              "areaShort": "Pingui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 451100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 451200,
          "id": 2277,
          "areaName": "河池市",
          "areaCode": "0778",
          "areaShort": "Hechi",
          "areaInitials": null,
          "cityCode": "143",
          "center": "108.085261,24.692931",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 451202,
              "id": 2278,
              "areaName": "金城江区",
              "areaCode": "0778",
              "areaShort": "Jinchengjiang",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "108.037276,24.689703",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451221,
              "id": 2279,
              "areaName": "南丹县",
              "areaCode": "0778",
              "areaShort": "Nandan",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "107.541244,24.975631",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451222,
              "id": 2280,
              "areaName": "天峨县",
              "areaCode": "0778",
              "areaShort": "Tiane",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "107.173802,24.999108",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451223,
              "id": 2281,
              "areaName": "凤山县",
              "areaCode": "0778",
              "areaShort": "Fengshan",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "107.04219,24.546876",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451224,
              "id": 2282,
              "areaName": "东兰县",
              "areaCode": "0778",
              "areaShort": "Donglan",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "107.374293,24.510842",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451225,
              "id": 2283,
              "areaName": "罗城仫佬族自治县",
              "areaCode": "0778",
              "areaShort": "Luocheng",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "108.904706,24.777411",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451226,
              "id": 2284,
              "areaName": "环江毛南族自治县",
              "areaCode": "0778",
              "areaShort": "Huanjiang",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "108.258028,24.825664",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451227,
              "id": 2285,
              "areaName": "巴马瑶族自治县",
              "areaCode": "0778",
              "areaShort": "Bama",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "107.258588,24.142298",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451228,
              "id": 2286,
              "areaName": "都安瑶族自治县",
              "areaCode": "0778",
              "areaShort": "Du'an",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "108.105311,23.932675",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451229,
              "id": 2287,
              "areaName": "大化瑶族自治县",
              "areaCode": "0778",
              "areaShort": "Dahua",
              "areaInitials": null,
              "cityCode": "0778",
              "center": "107.998149,23.736457",
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451281,
              "id": 2288,
              "areaName": "宜州市",
              "areaCode": null,
              "areaShort": "Yizhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 451200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 451300,
          "id": 2289,
          "areaName": "来宾市",
          "areaCode": "1772",
          "areaShort": "Laibin",
          "areaInitials": null,
          "cityCode": "202",
          "center": "109.221465,23.750306",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 451302,
              "id": 2290,
              "areaName": "兴宾区",
              "areaCode": "1772",
              "areaShort": "Xingbin",
              "areaInitials": null,
              "cityCode": "1772",
              "center": "109.183333,23.72892",
              "areaLevel": 3,
              "areaParentId": 451300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451321,
              "id": 2291,
              "areaName": "忻城县",
              "areaCode": "1772",
              "areaShort": "Xincheng",
              "areaInitials": null,
              "cityCode": "1772",
              "center": "108.665666,24.066234",
              "areaLevel": 3,
              "areaParentId": 451300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451322,
              "id": 2292,
              "areaName": "象州县",
              "areaCode": "1772",
              "areaShort": "Xiangzhou",
              "areaInitials": null,
              "cityCode": "1772",
              "center": "109.705065,23.973793",
              "areaLevel": 3,
              "areaParentId": 451300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451323,
              "id": 2293,
              "areaName": "武宣县",
              "areaCode": "1772",
              "areaShort": "Wuxuan",
              "areaInitials": null,
              "cityCode": "1772",
              "center": "109.663206,23.59411",
              "areaLevel": 3,
              "areaParentId": 451300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451324,
              "id": 2294,
              "areaName": "金秀瑶族自治县",
              "areaCode": "1772",
              "areaShort": "Jinxiu",
              "areaInitials": null,
              "cityCode": "1772",
              "center": "110.189462,24.130374",
              "areaLevel": 3,
              "areaParentId": 451300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451381,
              "id": 2295,
              "areaName": "合山市",
              "areaCode": "1772",
              "areaShort": "Heshan",
              "areaInitials": null,
              "cityCode": "1772",
              "center": "108.886082,23.806535",
              "areaLevel": 3,
              "areaParentId": 451300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 451400,
          "id": 2296,
          "areaName": "崇左市",
          "areaCode": "1771",
          "areaShort": "Chongzuo",
          "areaInitials": null,
          "cityCode": "144",
          "center": "107.365094,22.377253",
          "areaLevel": 2,
          "areaParentId": 450000,
          "areaDictionaryVOList": [
            {
              "areaId": 451402,
              "id": 2297,
              "areaName": "江州区",
              "areaCode": "1771",
              "areaShort": "Jiangzhou",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "107.353437,22.405325",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451421,
              "id": 2298,
              "areaName": "扶绥县",
              "areaCode": "1771",
              "areaShort": "Fusui",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "107.904186,22.635012",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451422,
              "id": 2299,
              "areaName": "宁明县",
              "areaCode": "1771",
              "areaShort": "Ningming",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "107.076456,22.140192",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451423,
              "id": 2300,
              "areaName": "龙州县",
              "areaCode": "1771",
              "areaShort": "Longzhou",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "106.854482,22.342778",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451424,
              "id": 2301,
              "areaName": "大新县",
              "areaCode": "1771",
              "areaShort": "Daxin",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "107.200654,22.829287",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451425,
              "id": 2302,
              "areaName": "天等县",
              "areaCode": "1771",
              "areaShort": "Tiandeng",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "107.143432,23.081394",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 451481,
              "id": 2303,
              "areaName": "凭祥市",
              "areaCode": "1771",
              "areaShort": "Pingxiang",
              "areaInitials": null,
              "cityCode": "1771",
              "center": "106.766293,22.094484",
              "areaLevel": 3,
              "areaParentId": 451400,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 460000,
      "id": 2304,
      "areaName": "海南省",
      "areaCode": "[]",
      "areaShort": "Hainan",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "110.349228,20.017377",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 460100,
          "id": 2305,
          "areaName": "海口市",
          "areaCode": "0898",
          "areaShort": "Haikou",
          "areaInitials": null,
          "cityCode": "125",
          "center": "110.198286,20.044412",
          "areaLevel": 2,
          "areaParentId": 460000,
          "areaDictionaryVOList": [
            {
              "areaId": 460105,
              "id": 2306,
              "areaName": "秀英区",
              "areaCode": "0898",
              "areaShort": "Xiuying",
              "areaInitials": null,
              "cityCode": "0898",
              "center": "110.293603,20.007494",
              "areaLevel": 3,
              "areaParentId": 460100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460106,
              "id": 2307,
              "areaName": "龙华区",
              "areaCode": "0898",
              "areaShort": "Longhua",
              "areaInitials": null,
              "cityCode": "0898",
              "center": "110.328492,20.031006",
              "areaLevel": 3,
              "areaParentId": 460100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460107,
              "id": 2308,
              "areaName": "琼山区",
              "areaCode": "0898",
              "areaShort": "Qiongshan",
              "areaInitials": null,
              "cityCode": "0898",
              "center": "110.353972,20.003169",
              "areaLevel": 3,
              "areaParentId": 460100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460108,
              "id": 2309,
              "areaName": "美兰区",
              "areaCode": "0898",
              "areaShort": "Meilan",
              "areaInitials": null,
              "cityCode": "0898",
              "center": "110.366358,20.029083",
              "areaLevel": 3,
              "areaParentId": 460100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 460200,
          "id": 2310,
          "areaName": "三亚市",
          "areaCode": "0899",
          "areaShort": "Sanya",
          "areaInitials": null,
          "cityCode": "121",
          "center": "109.511772,18.253135",
          "areaLevel": 2,
          "areaParentId": 460000,
          "areaDictionaryVOList": [
            {
              "areaId": 460202,
              "id": 2311,
              "areaName": "海棠区",
              "areaCode": "0899",
              "areaShort": "Haitang",
              "areaInitials": null,
              "cityCode": "0899",
              "center": "109.752569,18.400106",
              "areaLevel": 3,
              "areaParentId": 460200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460203,
              "id": 2312,
              "areaName": "吉阳区",
              "areaCode": "0899",
              "areaShort": "Jiyang",
              "areaInitials": null,
              "cityCode": "0899",
              "center": "109.578336,18.281406",
              "areaLevel": 3,
              "areaParentId": 460200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460204,
              "id": 2313,
              "areaName": "天涯区",
              "areaCode": "0899",
              "areaShort": "Tianya",
              "areaInitials": null,
              "cityCode": "0899",
              "center": "109.452378,18.298156",
              "areaLevel": 3,
              "areaParentId": 460200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460205,
              "id": 2314,
              "areaName": "崖州区",
              "areaCode": "0899",
              "areaShort": "Yazhou",
              "areaInitials": null,
              "cityCode": "0899",
              "center": "109.171841,18.357291",
              "areaLevel": 3,
              "areaParentId": 460200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 460300,
          "id": 2315,
          "areaName": "三沙市",
          "areaCode": "2898",
          "areaShort": "Sansha",
          "areaInitials": null,
          "cityCode": "2898",
          "center": "112.338695,16.831839",
          "areaLevel": 2,
          "areaParentId": 460000,
          "areaDictionaryVOList": [
            {
              "areaId": 460321,
              "id": 2316,
              "areaName": "西沙群岛",
              "areaCode": "1895",
              "areaShort": "Xisha Islands",
              "areaInitials": null,
              "cityCode": "1895",
              "center": "111.792944,16.204546",
              "areaLevel": 3,
              "areaParentId": 460300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460322,
              "id": 2317,
              "areaName": "南沙群岛",
              "areaCode": "1891",
              "areaShort": "Nansha Islands",
              "areaInitials": null,
              "cityCode": "1891",
              "center": "116.749997,11.471888",
              "areaLevel": 3,
              "areaParentId": 460300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 460323,
              "id": 2318,
              "areaName": "中沙群岛",
              "areaCode": "2801",
              "areaShort": "Zhongsha Islands",
              "areaInitials": null,
              "cityCode": "2801",
              "center": "117.740071,15.112855",
              "areaLevel": 3,
              "areaParentId": 460300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 500000,
      "id": 2336,
      "areaName": "重庆",
      "areaCode": "023",
      "areaShort": "Chongqing",
      "areaInitials": null,
      "cityCode": "023",
      "center": "106.551643,29.562849",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 500100,
          "id": 2337,
          "areaName": "重庆市",
          "areaCode": "023",
          "areaShort": "Chongqing",
          "areaInitials": null,
          "cityCode": "132",
          "center": "106.551643,29.562849",
          "areaLevel": 2,
          "areaParentId": 500000,
          "areaDictionaryVOList": [
            {
              "areaId": 500101,
              "id": 2338,
              "areaName": "万州区",
              "areaCode": "023",
              "areaShort": "Wanzhou",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.408661,30.807667",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500102,
              "id": 2339,
              "areaName": "涪陵区",
              "areaCode": "023",
              "areaShort": "Fuling",
              "areaInitials": null,
              "cityCode": "023",
              "center": "107.38977,29.703022",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500103,
              "id": 2340,
              "areaName": "渝中区",
              "areaCode": "023",
              "areaShort": "Yuzhong",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.568896,29.552736",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500104,
              "id": 2341,
              "areaName": "大渡口区",
              "areaCode": "023",
              "areaShort": "Dadukou",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.482346,29.484527",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500105,
              "id": 2342,
              "areaName": "江北区",
              "areaCode": "023",
              "areaShort": "Jiangbei",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.574271,29.606703",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500106,
              "id": 2343,
              "areaName": "沙坪坝区",
              "areaCode": "023",
              "areaShort": "Shapingba",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.456878,29.541144",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500107,
              "id": 2344,
              "areaName": "九龙坡区",
              "areaCode": "023",
              "areaShort": "Jiulongpo",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.510676,29.502272",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500108,
              "id": 2345,
              "areaName": "南岸区",
              "areaCode": "023",
              "areaShort": "Nan'an",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.644447,29.50126",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500109,
              "id": 2346,
              "areaName": "北碚区",
              "areaCode": "023",
              "areaShort": "Beibei",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.395612,29.805107",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500110,
              "id": 2347,
              "areaName": "綦江区",
              "areaCode": "023",
              "areaShort": "Qijiang",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.651361,29.028066",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500111,
              "id": 2348,
              "areaName": "大足区",
              "areaCode": "023",
              "areaShort": "Dazu",
              "areaInitials": null,
              "cityCode": "023",
              "center": "105.721733,29.707032",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500112,
              "id": 2349,
              "areaName": "渝北区",
              "areaCode": "023",
              "areaShort": "Yubei",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.631187,29.718142",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500113,
              "id": 2350,
              "areaName": "巴南区",
              "areaCode": "023",
              "areaShort": "Banan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.540256,29.402408",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500114,
              "id": 2351,
              "areaName": "黔江区",
              "areaCode": "023",
              "areaShort": "Qianjiang",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.770677,29.533609",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500115,
              "id": 2352,
              "areaName": "长寿区",
              "areaCode": "023",
              "areaShort": "Changshou",
              "areaInitials": null,
              "cityCode": "023",
              "center": "107.080734,29.857912",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500116,
              "id": 2353,
              "areaName": "江津区",
              "areaCode": "023",
              "areaShort": "Jiangjin",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.259281,29.290069",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500117,
              "id": 2354,
              "areaName": "合川区",
              "areaCode": "023",
              "areaShort": "Hechuan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.27613,29.972084",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500118,
              "id": 2355,
              "areaName": "永川区",
              "areaCode": "023",
              "areaShort": "Yongchuan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "105.927001,29.356311",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500119,
              "id": 2356,
              "areaName": "南川区",
              "areaCode": "023",
              "areaShort": "Nanchuan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "107.099266,29.15789",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500120,
              "id": 2357,
              "areaName": "璧山区",
              "areaCode": "023",
              "areaShort": "Bishan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.227305,29.592024",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500151,
              "id": 2358,
              "areaName": "铜梁区",
              "areaCode": "023",
              "areaShort": "Tongliang",
              "areaInitials": null,
              "cityCode": "023",
              "center": "106.056404,29.844811",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500223,
              "id": 2359,
              "areaName": "潼南县",
              "areaCode": null,
              "areaShort": "Tongnan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500226,
              "id": 2360,
              "areaName": "荣昌县",
              "areaCode": null,
              "areaShort": "Rongchang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500228,
              "id": 2361,
              "areaName": "梁平县",
              "areaCode": null,
              "areaShort": "Liangping",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500229,
              "id": 2362,
              "areaName": "城口县",
              "areaCode": "023",
              "areaShort": "Chengkou",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.664214,31.947633",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500230,
              "id": 2363,
              "areaName": "丰都县",
              "areaCode": "023",
              "areaShort": "Fengdu",
              "areaInitials": null,
              "cityCode": "023",
              "center": "107.730894,29.8635",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500231,
              "id": 2364,
              "areaName": "垫江县",
              "areaCode": "023",
              "areaShort": "Dianjiang",
              "areaInitials": null,
              "cityCode": "023",
              "center": "107.33339,30.327716",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500232,
              "id": 2365,
              "areaName": "武隆县",
              "areaCode": null,
              "areaShort": "Wulong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500233,
              "id": 2366,
              "areaName": "忠县",
              "areaCode": "023",
              "areaShort": "Zhongxian",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.039002,30.299559",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500234,
              "id": 2367,
              "areaName": "开县",
              "areaCode": null,
              "areaShort": "Kaixian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500235,
              "id": 2368,
              "areaName": "云阳县",
              "areaCode": "023",
              "areaShort": "Yunyang",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.697324,30.930612",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500236,
              "id": 2369,
              "areaName": "奉节县",
              "areaCode": "023",
              "areaShort": "Fengjie",
              "areaInitials": null,
              "cityCode": "023",
              "center": "109.400403,31.018363",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500237,
              "id": 2370,
              "areaName": "巫山县",
              "areaCode": "023",
              "areaShort": "Wushan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "109.879153,31.074834",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500238,
              "id": 2371,
              "areaName": "巫溪县",
              "areaCode": "023",
              "areaShort": "Wuxi",
              "areaInitials": null,
              "cityCode": "023",
              "center": "109.570062,31.398604",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500240,
              "id": 2372,
              "areaName": "石柱土家族自治县",
              "areaCode": "023",
              "areaShort": "Shizhu",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.114069,29.999285",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500241,
              "id": 2373,
              "areaName": "秀山土家族苗族自治县",
              "areaCode": "023",
              "areaShort": "Xiushan",
              "areaInitials": null,
              "cityCode": "023",
              "center": "109.007094,28.447997",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500242,
              "id": 2374,
              "areaName": "酉阳土家族苗族自治县",
              "areaCode": "023",
              "areaShort": "Youyang",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.767747,28.841244",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500243,
              "id": 2375,
              "areaName": "彭水苗族土家族自治县",
              "areaCode": "023",
              "areaShort": "Pengshui",
              "areaInitials": null,
              "cityCode": "023",
              "center": "108.165537,29.293902",
              "areaLevel": 3,
              "areaParentId": 500100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 500300,
          "id": 2376,
          "areaName": "两江新区",
          "areaCode": null,
          "areaShort": "Liangjiangxinqu",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 500000,
          "areaDictionaryVOList": [
            {
              "areaId": 500301,
              "id": 2377,
              "areaName": "北部新区",
              "areaCode": null,
              "areaShort": "Beibuxinqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500302,
              "id": 2378,
              "areaName": "保税港区",
              "areaCode": null,
              "areaShort": "Baoshuigangqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 500303,
              "id": 2379,
              "areaName": "工业园区",
              "areaCode": null,
              "areaShort": "Gongyeyuanqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 500300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 510000,
      "id": 2380,
      "areaName": "四川省",
      "areaCode": "[]",
      "areaShort": "Sichuan",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "104.075809,30.651239",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 510100,
          "id": 2381,
          "areaName": "成都市",
          "areaCode": "028",
          "areaShort": "Chengdu",
          "areaInitials": null,
          "cityCode": "75",
          "center": "104.066794,30.572893",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510104,
              "id": 2382,
              "areaName": "锦江区",
              "areaCode": "028",
              "areaShort": "Jinjiang",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.117022,30.598158",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510105,
              "id": 2383,
              "areaName": "青羊区",
              "areaCode": "028",
              "areaShort": "Qingyang",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.061442,30.673914",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510106,
              "id": 2384,
              "areaName": "金牛区",
              "areaCode": "028",
              "areaShort": "Jinniu",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.052236,30.691359",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510107,
              "id": 2385,
              "areaName": "武侯区",
              "areaCode": "028",
              "areaShort": "Wuhou",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.043235,30.641907",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510108,
              "id": 2386,
              "areaName": "成华区",
              "areaCode": "028",
              "areaShort": "Chenghua",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.101515,30.659966",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510112,
              "id": 2387,
              "areaName": "龙泉驿区",
              "areaCode": "028",
              "areaShort": "Longquanyi",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.274632,30.556506",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510113,
              "id": 2388,
              "areaName": "青白江区",
              "areaCode": "028",
              "areaShort": "Qingbaijiang",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.250945,30.878629",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510114,
              "id": 2389,
              "areaName": "新都区",
              "areaCode": "028",
              "areaShort": "Xindu",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.158705,30.823498",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510115,
              "id": 2390,
              "areaName": "温江区",
              "areaCode": "028",
              "areaShort": "Wenjiang",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.856646,30.682203",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510121,
              "id": 2391,
              "areaName": "金堂县",
              "areaCode": "028",
              "areaShort": "Jintang",
              "areaInitials": null,
              "cityCode": "028",
              "center": "104.411976,30.861979",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510122,
              "id": 2392,
              "areaName": "双流县",
              "areaCode": null,
              "areaShort": "Shuangliu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510124,
              "id": 2393,
              "areaName": "郫县",
              "areaCode": null,
              "areaShort": "Pixian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510129,
              "id": 2394,
              "areaName": "大邑县",
              "areaCode": "028",
              "areaShort": "Dayi",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.511865,30.572268",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510131,
              "id": 2395,
              "areaName": "蒲江县",
              "areaCode": "028",
              "areaShort": "Pujiang",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.506498,30.196788",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510132,
              "id": 2396,
              "areaName": "新津县",
              "areaCode": "028",
              "areaShort": "Xinjin",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.811286,30.410346",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510181,
              "id": 2397,
              "areaName": "都江堰市",
              "areaCode": "028",
              "areaShort": "Dujiangyan",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.647153,30.988767",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510182,
              "id": 2398,
              "areaName": "彭州市",
              "areaCode": "028",
              "areaShort": "Pengzhou",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.957983,30.990212",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510183,
              "id": 2399,
              "areaName": "邛崃市",
              "areaCode": "028",
              "areaShort": "Qionglai",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.464207,30.410324",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510184,
              "id": 2400,
              "areaName": "崇州市",
              "areaCode": "028",
              "areaShort": "Chongzhou",
              "areaInitials": null,
              "cityCode": "028",
              "center": "103.673001,30.630122",
              "areaLevel": 3,
              "areaParentId": 510100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510300,
          "id": 2401,
          "areaName": "自贡市",
          "areaCode": "0813",
          "areaShort": "Zigong",
          "areaInitials": null,
          "cityCode": "78",
          "center": "104.778442,29.33903",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510302,
              "id": 2402,
              "areaName": "自流井区",
              "areaCode": "0813",
              "areaShort": "Ziliujing",
              "areaInitials": null,
              "cityCode": "0813",
              "center": "104.777191,29.337429",
              "areaLevel": 3,
              "areaParentId": 510300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510303,
              "id": 2403,
              "areaName": "贡井区",
              "areaCode": "0813",
              "areaShort": "Gongjing",
              "areaInitials": null,
              "cityCode": "0813",
              "center": "104.715288,29.345313",
              "areaLevel": 3,
              "areaParentId": 510300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510304,
              "id": 2404,
              "areaName": "大安区",
              "areaCode": "0813",
              "areaShort": "Da'an",
              "areaInitials": null,
              "cityCode": "0813",
              "center": "104.773994,29.363702",
              "areaLevel": 3,
              "areaParentId": 510300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510311,
              "id": 2405,
              "areaName": "沿滩区",
              "areaCode": "0813",
              "areaShort": "Yantan",
              "areaInitials": null,
              "cityCode": "0813",
              "center": "104.874079,29.272586",
              "areaLevel": 3,
              "areaParentId": 510300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510321,
              "id": 2406,
              "areaName": "荣县",
              "areaCode": "0813",
              "areaShort": "Rongxian",
              "areaInitials": null,
              "cityCode": "0813",
              "center": "104.417493,29.445479",
              "areaLevel": 3,
              "areaParentId": 510300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510322,
              "id": 2407,
              "areaName": "富顺县",
              "areaCode": "0813",
              "areaShort": "Fushun",
              "areaInitials": null,
              "cityCode": "0813",
              "center": "104.975048,29.181429",
              "areaLevel": 3,
              "areaParentId": 510300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510400,
          "id": 2408,
          "areaName": "攀枝花市",
          "areaCode": "0812",
          "areaShort": "Panzhihua",
          "areaInitials": null,
          "cityCode": "81",
          "center": "101.718637,26.582347",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510402,
              "id": 2409,
              "areaName": "东区",
              "areaCode": "0812",
              "areaShort": "Dongqu",
              "areaInitials": null,
              "cityCode": "0812",
              "center": "101.704109,26.546491",
              "areaLevel": 3,
              "areaParentId": 510400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510403,
              "id": 2410,
              "areaName": "西区",
              "areaCode": "0812",
              "areaShort": "Xiqu",
              "areaInitials": null,
              "cityCode": "0812",
              "center": "101.630619,26.597781",
              "areaLevel": 3,
              "areaParentId": 510400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510411,
              "id": 2411,
              "areaName": "仁和区",
              "areaCode": "0812",
              "areaShort": "Renhe",
              "areaInitials": null,
              "cityCode": "0812",
              "center": "101.738528,26.497765",
              "areaLevel": 3,
              "areaParentId": 510400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510421,
              "id": 2412,
              "areaName": "米易县",
              "areaCode": "0812",
              "areaShort": "Miyi",
              "areaInitials": null,
              "cityCode": "0812",
              "center": "102.112895,26.897694",
              "areaLevel": 3,
              "areaParentId": 510400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510422,
              "id": 2413,
              "areaName": "盐边县",
              "areaCode": "0812",
              "areaShort": "Yanbian",
              "areaInitials": null,
              "cityCode": "0812",
              "center": "101.855071,26.683213",
              "areaLevel": 3,
              "areaParentId": 510400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510500,
          "id": 2414,
          "areaName": "泸州市",
          "areaCode": "0830",
          "areaShort": "Luzhou",
          "areaInitials": null,
          "cityCode": "331",
          "center": "105.442285,28.871805",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510502,
              "id": 2415,
              "areaName": "江阳区",
              "areaCode": "0830",
              "areaShort": "Jiangyang",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.434982,28.87881",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510503,
              "id": 2416,
              "areaName": "纳溪区",
              "areaCode": "0830",
              "areaShort": "Naxi",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.371505,28.773134",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510504,
              "id": 2417,
              "areaName": "龙马潭区",
              "areaCode": "0830",
              "areaShort": "Longmatan",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.437751,28.913257",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510521,
              "id": 2418,
              "areaName": "泸县",
              "areaCode": "0830",
              "areaShort": "Luxian",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.381893,29.151534",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510522,
              "id": 2419,
              "areaName": "合江县",
              "areaCode": "0830",
              "areaShort": "Hejiang",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.830986,28.811164",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510524,
              "id": 2420,
              "areaName": "叙永县",
              "areaCode": "0830",
              "areaShort": "Xuyong",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.444765,28.155801",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510525,
              "id": 2421,
              "areaName": "古蔺县",
              "areaCode": "0830",
              "areaShort": "Gulin",
              "areaInitials": null,
              "cityCode": "0830",
              "center": "105.812601,28.038801",
              "areaLevel": 3,
              "areaParentId": 510500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510600,
          "id": 2422,
          "areaName": "德阳市",
          "areaCode": "0838",
          "areaShort": "Deyang",
          "areaInitials": null,
          "cityCode": "74",
          "center": "104.397894,31.126855",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510603,
              "id": 2423,
              "areaName": "旌阳区",
              "areaCode": "0838",
              "areaShort": "Jingyang",
              "areaInitials": null,
              "cityCode": "0838",
              "center": "104.416966,31.142633",
              "areaLevel": 3,
              "areaParentId": 510600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510623,
              "id": 2424,
              "areaName": "中江县",
              "areaCode": "0838",
              "areaShort": "Zhongjiang",
              "areaInitials": null,
              "cityCode": "0838",
              "center": "104.678751,31.03307",
              "areaLevel": 3,
              "areaParentId": 510600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510626,
              "id": 2425,
              "areaName": "罗江县",
              "areaCode": "0838",
              "areaShort": "Luojiang",
              "areaInitials": null,
              "cityCode": "0838",
              "center": "104.510249,31.317045",
              "areaLevel": 3,
              "areaParentId": 510600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510681,
              "id": 2426,
              "areaName": "广汉市",
              "areaCode": "0838",
              "areaShort": "Guanghan",
              "areaInitials": null,
              "cityCode": "0838",
              "center": "104.282429,30.977119",
              "areaLevel": 3,
              "areaParentId": 510600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510682,
              "id": 2427,
              "areaName": "什邡市",
              "areaCode": "0838",
              "areaShort": "Shifang",
              "areaInitials": null,
              "cityCode": "0838",
              "center": "104.167501,31.12678",
              "areaLevel": 3,
              "areaParentId": 510600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510683,
              "id": 2428,
              "areaName": "绵竹市",
              "areaCode": "0838",
              "areaShort": "Mianzhu",
              "areaInitials": null,
              "cityCode": "0838",
              "center": "104.22075,31.338077",
              "areaLevel": 3,
              "areaParentId": 510600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510700,
          "id": 2429,
          "areaName": "绵阳市",
          "areaCode": "0816",
          "areaShort": "Mianyang",
          "areaInitials": null,
          "cityCode": "240",
          "center": "104.679004,31.467459",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510703,
              "id": 2430,
              "areaName": "涪城区",
              "areaCode": "0816",
              "areaShort": "Fucheng",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "104.756944,31.455101",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510704,
              "id": 2431,
              "areaName": "游仙区",
              "areaCode": "0816",
              "areaShort": "Youxian",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "104.766392,31.473779",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510722,
              "id": 2432,
              "areaName": "三台县",
              "areaCode": "0816",
              "areaShort": "Santai",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "105.094586,31.095979",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510723,
              "id": 2433,
              "areaName": "盐亭县",
              "areaCode": "0816",
              "areaShort": "Yanting",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "105.389453,31.208362",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510724,
              "id": 2434,
              "areaName": "安县",
              "areaCode": null,
              "areaShort": "Anxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510725,
              "id": 2435,
              "areaName": "梓潼县",
              "areaCode": "0816",
              "areaShort": "Zitong",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "105.170845,31.642718",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510726,
              "id": 2436,
              "areaName": "北川羌族自治县",
              "areaCode": "0816",
              "areaShort": "Beichuan",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "104.46797,31.617203",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510727,
              "id": 2437,
              "areaName": "平武县",
              "areaCode": "0816",
              "areaShort": "Pingwu",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "104.555583,32.409675",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510781,
              "id": 2438,
              "areaName": "江油市",
              "areaCode": "0816",
              "areaShort": "Jiangyou",
              "areaInitials": null,
              "cityCode": "0816",
              "center": "104.745915,31.778026",
              "areaLevel": 3,
              "areaParentId": 510700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510800,
          "id": 2439,
          "areaName": "广元市",
          "areaCode": "0839",
          "areaShort": "Guangyuan",
          "areaInitials": null,
          "cityCode": "329",
          "center": "105.843357,32.435435",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510802,
              "id": 2440,
              "areaName": "利州区",
              "areaCode": "0839",
              "areaShort": "Lizhou",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "105.845307,32.433756",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510811,
              "id": 2441,
              "areaName": "昭化区",
              "areaCode": "0839",
              "areaShort": "Zhaohua",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "105.962819,32.323256",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510812,
              "id": 2442,
              "areaName": "朝天区",
              "areaCode": "0839",
              "areaShort": "Chaotian",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "105.882642,32.651336",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510821,
              "id": 2443,
              "areaName": "旺苍县",
              "areaCode": "0839",
              "areaShort": "Wangcang",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "106.289983,32.229058",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510822,
              "id": 2444,
              "areaName": "青川县",
              "areaCode": "0839",
              "areaShort": "Qingchuan",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "105.238842,32.575484",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510823,
              "id": 2445,
              "areaName": "剑阁县",
              "areaCode": "0839",
              "areaShort": "Jiange",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "105.524766,32.287722",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510824,
              "id": 2446,
              "areaName": "苍溪县",
              "areaCode": "0839",
              "areaShort": "Cangxi",
              "areaInitials": null,
              "cityCode": "0839",
              "center": "105.934756,31.731709",
              "areaLevel": 3,
              "areaParentId": 510800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 510900,
          "id": 2447,
          "areaName": "遂宁市",
          "areaCode": "0825",
          "areaShort": "Suining",
          "areaInitials": null,
          "cityCode": "330",
          "center": "105.592803,30.53292",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 510903,
              "id": 2448,
              "areaName": "船山区",
              "areaCode": "0825",
              "areaShort": "Chuanshan",
              "areaInitials": null,
              "cityCode": "0825",
              "center": "105.568297,30.525475",
              "areaLevel": 3,
              "areaParentId": 510900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510904,
              "id": 2449,
              "areaName": "安居区",
              "areaCode": "0825",
              "areaShort": "Anju",
              "areaInitials": null,
              "cityCode": "0825",
              "center": "105.456342,30.355379",
              "areaLevel": 3,
              "areaParentId": 510900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510921,
              "id": 2450,
              "areaName": "蓬溪县",
              "areaCode": "0825",
              "areaShort": "Pengxi",
              "areaInitials": null,
              "cityCode": "0825",
              "center": "105.70757,30.757575",
              "areaLevel": 3,
              "areaParentId": 510900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510922,
              "id": 2451,
              "areaName": "射洪县",
              "areaCode": "0825",
              "areaShort": "Shehong",
              "areaInitials": null,
              "cityCode": "0825",
              "center": "105.388412,30.871131",
              "areaLevel": 3,
              "areaParentId": 510900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 510923,
              "id": 2452,
              "areaName": "大英县",
              "areaCode": "0825",
              "areaShort": "Daying",
              "areaInitials": null,
              "cityCode": "0825",
              "center": "105.236923,30.594409",
              "areaLevel": 3,
              "areaParentId": 510900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511000,
          "id": 2453,
          "areaName": "内江市",
          "areaCode": "1832",
          "areaShort": "Neijiang",
          "areaInitials": null,
          "cityCode": "248",
          "center": "105.058432,29.580228",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511002,
              "id": 2454,
              "areaName": "市中区",
              "areaCode": "1832",
              "areaShort": "Shizhongqu",
              "areaInitials": null,
              "cityCode": "1832",
              "center": "105.067597,29.587053",
              "areaLevel": 3,
              "areaParentId": 511000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511011,
              "id": 2455,
              "areaName": "东兴区",
              "areaCode": "1832",
              "areaShort": "Dongxing",
              "areaInitials": null,
              "cityCode": "1832",
              "center": "105.075489,29.592756",
              "areaLevel": 3,
              "areaParentId": 511000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511024,
              "id": 2456,
              "areaName": "威远县",
              "areaCode": "1832",
              "areaShort": "Weiyuan",
              "areaInitials": null,
              "cityCode": "1832",
              "center": "104.668879,29.52744",
              "areaLevel": 3,
              "areaParentId": 511000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511025,
              "id": 2457,
              "areaName": "资中县",
              "areaCode": "1832",
              "areaShort": "Zizhong",
              "areaInitials": null,
              "cityCode": "1832",
              "center": "104.851944,29.764059",
              "areaLevel": 3,
              "areaParentId": 511000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511028,
              "id": 2458,
              "areaName": "隆昌县",
              "areaCode": "1832",
              "areaShort": "Longchang",
              "areaInitials": null,
              "cityCode": "1832",
              "center": "105.287612,29.339476",
              "areaLevel": 3,
              "areaParentId": 511000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511100,
          "id": 2459,
          "areaName": "乐山市",
          "areaCode": "0833",
          "areaShort": "Leshan",
          "areaInitials": null,
          "cityCode": "79",
          "center": "103.765678,29.552115",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511102,
              "id": 2460,
              "areaName": "市中区",
              "areaCode": "0833",
              "areaShort": "Shizhongqu",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.761329,29.555374",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511111,
              "id": 2461,
              "areaName": "沙湾区",
              "areaCode": "0833",
              "areaShort": "Shawan",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.549991,29.413091",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511112,
              "id": 2462,
              "areaName": "五通桥区",
              "areaCode": "0833",
              "areaShort": "Wutongqiao",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.818014,29.406945",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511113,
              "id": 2463,
              "areaName": "金口河区",
              "areaCode": "0833",
              "areaShort": "Jinkouhe",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.07862,29.244345",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511123,
              "id": 2464,
              "areaName": "犍为县",
              "areaCode": "0833",
              "areaShort": "Qianwei",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.949326,29.20817",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511124,
              "id": 2465,
              "areaName": "井研县",
              "areaCode": "0833",
              "areaShort": "Jingyan",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "104.069726,29.651287",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511126,
              "id": 2466,
              "areaName": "夹江县",
              "areaCode": "0833",
              "areaShort": "Jiajiang",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.571656,29.73763",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511129,
              "id": 2467,
              "areaName": "沐川县",
              "areaCode": "0833",
              "areaShort": "Muchuan",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.902334,28.956647",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511132,
              "id": 2468,
              "areaName": "峨边彝族自治县",
              "areaCode": "0833",
              "areaShort": "Ebian",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.262048,29.230425",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511133,
              "id": 2469,
              "areaName": "马边彝族自治县",
              "areaCode": "0833",
              "areaShort": "Mabian",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.546347,28.83552",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511181,
              "id": 2470,
              "areaName": "峨眉山市",
              "areaCode": "0833",
              "areaShort": "Emeishan",
              "areaInitials": null,
              "cityCode": "0833",
              "center": "103.484503,29.601198",
              "areaLevel": 3,
              "areaParentId": 511100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511300,
          "id": 2471,
          "areaName": "南充市",
          "areaCode": "0817",
          "areaShort": "Nanchong",
          "areaInitials": null,
          "cityCode": "291",
          "center": "106.110698,30.837793",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511302,
              "id": 2472,
              "areaName": "顺庆区",
              "areaCode": "0817",
              "areaShort": "Shunqing",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.09245,30.796803",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511303,
              "id": 2473,
              "areaName": "高坪区",
              "areaCode": "0817",
              "areaShort": "Gaoping",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.118808,30.781623",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511304,
              "id": 2474,
              "areaName": "嘉陵区",
              "areaCode": "0817",
              "areaShort": "Jialing",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.071876,30.758823",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511321,
              "id": 2475,
              "areaName": "南部县",
              "areaCode": "0817",
              "areaShort": "Nanbu",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.036584,31.347467",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511322,
              "id": 2476,
              "areaName": "营山县",
              "areaCode": "0817",
              "areaShort": "Yingshan",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.565519,31.076579",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511323,
              "id": 2477,
              "areaName": "蓬安县",
              "areaCode": "0817",
              "areaShort": "Peng'an",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.412136,31.029091",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511324,
              "id": 2478,
              "areaName": "仪陇县",
              "areaCode": "0817",
              "areaShort": "Yilong",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.303042,31.271561",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511325,
              "id": 2479,
              "areaName": "西充县",
              "areaCode": "0817",
              "areaShort": "Xichong",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "105.90087,30.995683",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511381,
              "id": 2480,
              "areaName": "阆中市",
              "areaCode": "0817",
              "areaShort": "Langzhong",
              "areaInitials": null,
              "cityCode": "0817",
              "center": "106.005046,31.558356",
              "areaLevel": 3,
              "areaParentId": 511300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511400,
          "id": 2481,
          "areaName": "眉山市",
          "areaCode": "1833",
          "areaShort": "Meishan",
          "areaInitials": null,
          "cityCode": "77",
          "center": "103.848403,30.076994",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511402,
              "id": 2482,
              "areaName": "东坡区",
              "areaCode": "1833",
              "areaShort": "Dongpo",
              "areaInitials": null,
              "cityCode": "1833",
              "center": "103.831863,30.042308",
              "areaLevel": 3,
              "areaParentId": 511400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511403,
              "id": 2483,
              "areaName": "彭山区",
              "areaCode": "1833",
              "areaShort": "Pengshan",
              "areaInitials": null,
              "cityCode": "1833",
              "center": "103.872949,30.193056",
              "areaLevel": 3,
              "areaParentId": 511400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511421,
              "id": 2484,
              "areaName": "仁寿县",
              "areaCode": "1833",
              "areaShort": "Renshou",
              "areaInitials": null,
              "cityCode": "1833",
              "center": "104.133995,29.995635",
              "areaLevel": 3,
              "areaParentId": 511400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511423,
              "id": 2485,
              "areaName": "洪雅县",
              "areaCode": "1833",
              "areaShort": "Hongya",
              "areaInitials": null,
              "cityCode": "1833",
              "center": "103.372863,29.90489",
              "areaLevel": 3,
              "areaParentId": 511400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511424,
              "id": 2486,
              "areaName": "丹棱县",
              "areaCode": "1833",
              "areaShort": "Danling",
              "areaInitials": null,
              "cityCode": "1833",
              "center": "103.512783,30.01521",
              "areaLevel": 3,
              "areaParentId": 511400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511425,
              "id": 2487,
              "areaName": "青神县",
              "areaCode": "1833",
              "areaShort": "Qingshen",
              "areaInitials": null,
              "cityCode": "1833",
              "center": "103.846688,29.831357",
              "areaLevel": 3,
              "areaParentId": 511400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511500,
          "id": 2488,
          "areaName": "宜宾市",
          "areaCode": "0831",
          "areaShort": "Yibin",
          "areaInitials": null,
          "cityCode": "186",
          "center": "104.642845,28.752134",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511502,
              "id": 2489,
              "areaName": "翠屏区",
              "areaCode": "0831",
              "areaShort": "Cuiping",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.620009,28.765689",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511503,
              "id": 2490,
              "areaName": "南溪区",
              "areaCode": "0831",
              "areaShort": "Nanxi",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.969152,28.846382",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511521,
              "id": 2491,
              "areaName": "宜宾县",
              "areaCode": "0831",
              "areaShort": "Yibin",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.533212,28.690045",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511523,
              "id": 2492,
              "areaName": "江安县",
              "areaCode": "0831",
              "areaShort": "Jiang'an",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "105.066879,28.723855",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511524,
              "id": 2493,
              "areaName": "长宁县",
              "areaCode": "0831",
              "areaShort": "Changning",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.921174,28.582169",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511525,
              "id": 2494,
              "areaName": "高县",
              "areaCode": "0831",
              "areaShort": "Gaoxian",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.517748,28.436166",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511526,
              "id": 2495,
              "areaName": "珙县",
              "areaCode": "0831",
              "areaShort": "Gongxian",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.709202,28.43863",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511527,
              "id": 2496,
              "areaName": "筠连县",
              "areaCode": "0831",
              "areaShort": "Junlian",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.512025,28.167831",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511528,
              "id": 2497,
              "areaName": "兴文县",
              "areaCode": "0831",
              "areaShort": "Xingwen",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "105.236325,28.303614",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511529,
              "id": 2498,
              "areaName": "屏山县",
              "areaCode": "0831",
              "areaShort": "Pingshan",
              "areaInitials": null,
              "cityCode": "0831",
              "center": "104.345974,28.828482",
              "areaLevel": 3,
              "areaParentId": 511500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511600,
          "id": 2499,
          "areaName": "广安市",
          "areaCode": "0826",
          "areaShort": "Guang'an",
          "areaInitials": null,
          "cityCode": "241",
          "center": "106.633088,30.456224",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511602,
              "id": 2500,
              "areaName": "广安区",
              "areaCode": "0826",
              "areaShort": "Guang'an",
              "areaInitials": null,
              "cityCode": "0826",
              "center": "106.641662,30.473913",
              "areaLevel": 3,
              "areaParentId": 511600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511603,
              "id": 2501,
              "areaName": "前锋区",
              "areaCode": "0826",
              "areaShort": "Qianfeng",
              "areaInitials": null,
              "cityCode": "0826",
              "center": "106.886143,30.495804",
              "areaLevel": 3,
              "areaParentId": 511600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511621,
              "id": 2502,
              "areaName": "岳池县",
              "areaCode": "0826",
              "areaShort": "Yuechi",
              "areaInitials": null,
              "cityCode": "0826",
              "center": "106.440114,30.537863",
              "areaLevel": 3,
              "areaParentId": 511600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511622,
              "id": 2503,
              "areaName": "武胜县",
              "areaCode": "0826",
              "areaShort": "Wusheng",
              "areaInitials": null,
              "cityCode": "0826",
              "center": "106.295764,30.348772",
              "areaLevel": 3,
              "areaParentId": 511600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511623,
              "id": 2504,
              "areaName": "邻水县",
              "areaCode": "0826",
              "areaShort": "Linshui",
              "areaInitials": null,
              "cityCode": "0826",
              "center": "106.93038,30.334768",
              "areaLevel": 3,
              "areaParentId": 511600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511681,
              "id": 2505,
              "areaName": "华蓥市",
              "areaCode": "0826",
              "areaShort": "Huaying",
              "areaInitials": null,
              "cityCode": "0826",
              "center": "106.7831,30.390188",
              "areaLevel": 3,
              "areaParentId": 511600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511700,
          "id": 2506,
          "areaName": "达州市",
          "areaCode": "0818",
          "areaShort": "Dazhou",
          "areaInitials": null,
          "cityCode": "369",
          "center": "107.467758,31.209121",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511702,
              "id": 2507,
              "areaName": "通川区",
              "areaCode": "0818",
              "areaShort": "Tongchuan",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "107.504928,31.214715",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511703,
              "id": 2508,
              "areaName": "达川区",
              "areaCode": "0818",
              "areaShort": "Dachuan",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "107.511749,31.196157",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511722,
              "id": 2509,
              "areaName": "宣汉县",
              "areaCode": "0818",
              "areaShort": "Xuanhan",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "107.72719,31.353835",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511723,
              "id": 2510,
              "areaName": "开江县",
              "areaCode": "0818",
              "areaShort": "Kaijiang",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "107.868736,31.082986",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511724,
              "id": 2511,
              "areaName": "大竹县",
              "areaCode": "0818",
              "areaShort": "Dazhu",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "107.204795,30.73641",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511725,
              "id": 2512,
              "areaName": "渠县",
              "areaCode": "0818",
              "areaShort": "Quxian",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "106.97303,30.836618",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511781,
              "id": 2513,
              "areaName": "万源市",
              "areaCode": "0818",
              "areaShort": "Wanyuan",
              "areaInitials": null,
              "cityCode": "0818",
              "center": "108.034657,32.081631",
              "areaLevel": 3,
              "areaParentId": 511700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511800,
          "id": 2514,
          "areaName": "雅安市",
          "areaCode": "0835",
          "areaShort": "Ya'an",
          "areaInitials": null,
          "cityCode": "76",
          "center": "103.042375,30.010602",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511802,
              "id": 2515,
              "areaName": "雨城区",
              "areaCode": "0835",
              "areaShort": "Yucheng",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "103.033026,30.005461",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511803,
              "id": 2516,
              "areaName": "名山区",
              "areaCode": "0835",
              "areaShort": "Mingshan",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "103.109184,30.069954",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511822,
              "id": 2517,
              "areaName": "荥经县",
              "areaCode": "0835",
              "areaShort": "Yingjing",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "102.846737,29.792931",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511823,
              "id": 2518,
              "areaName": "汉源县",
              "areaCode": "0835",
              "areaShort": "Hanyuan",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "102.645467,29.347192",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511824,
              "id": 2519,
              "areaName": "石棉县",
              "areaCode": "0835",
              "areaShort": "Shimian",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "102.359462,29.227874",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511825,
              "id": 2520,
              "areaName": "天全县",
              "areaCode": "0835",
              "areaShort": "Tianquan",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "102.758317,30.066712",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511826,
              "id": 2521,
              "areaName": "芦山县",
              "areaCode": "0835",
              "areaShort": "Lushan",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "102.932385,30.142307",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511827,
              "id": 2522,
              "areaName": "宝兴县",
              "areaCode": "0835",
              "areaShort": "Baoxing",
              "areaInitials": null,
              "cityCode": "0835",
              "center": "102.815403,30.37641",
              "areaLevel": 3,
              "areaParentId": 511800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 511900,
          "id": 2523,
          "areaName": "巴中市",
          "areaCode": "0827",
          "areaShort": "Bazhong",
          "areaInitials": null,
          "cityCode": "239",
          "center": "106.747477,31.867903",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 511902,
              "id": 2524,
              "areaName": "巴州区",
              "areaCode": "0827",
              "areaShort": "Bazhou",
              "areaInitials": null,
              "cityCode": "0827",
              "center": "106.768878,31.851478",
              "areaLevel": 3,
              "areaParentId": 511900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511903,
              "id": 2525,
              "areaName": "恩阳区",
              "areaCode": "0827",
              "areaShort": "Enyang",
              "areaInitials": null,
              "cityCode": "0827",
              "center": "106.654386,31.787186",
              "areaLevel": 3,
              "areaParentId": 511900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511921,
              "id": 2526,
              "areaName": "通江县",
              "areaCode": "0827",
              "areaShort": "Tongjiang",
              "areaInitials": null,
              "cityCode": "0827",
              "center": "107.245033,31.911705",
              "areaLevel": 3,
              "areaParentId": 511900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511922,
              "id": 2527,
              "areaName": "南江县",
              "areaCode": "0827",
              "areaShort": "Nanjiang",
              "areaInitials": null,
              "cityCode": "0827",
              "center": "106.828697,32.346589",
              "areaLevel": 3,
              "areaParentId": 511900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 511923,
              "id": 2528,
              "areaName": "平昌县",
              "areaCode": "0827",
              "areaShort": "Pingchang",
              "areaInitials": null,
              "cityCode": "0827",
              "center": "107.104008,31.560874",
              "areaLevel": 3,
              "areaParentId": 511900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 512000,
          "id": 2529,
          "areaName": "资阳市",
          "areaCode": "0832",
          "areaShort": "Ziyang",
          "areaInitials": null,
          "cityCode": "242",
          "center": "104.627636,30.128901",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 512002,
              "id": 2530,
              "areaName": "雁江区",
              "areaCode": "0832",
              "areaShort": "Yanjiang",
              "areaInitials": null,
              "cityCode": "0832",
              "center": "104.677091,30.108216",
              "areaLevel": 3,
              "areaParentId": 512000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 512021,
              "id": 2531,
              "areaName": "安岳县",
              "areaCode": "0832",
              "areaShort": "Anyue",
              "areaInitials": null,
              "cityCode": "0832",
              "center": "105.35534,30.103107",
              "areaLevel": 3,
              "areaParentId": 512000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 512022,
              "id": 2532,
              "areaName": "乐至县",
              "areaCode": "0832",
              "areaShort": "Lezhi",
              "areaInitials": null,
              "cityCode": "0832",
              "center": "105.02019,30.276121",
              "areaLevel": 3,
              "areaParentId": 512000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 512081,
              "id": 2533,
              "areaName": "简阳市",
              "areaCode": null,
              "areaShort": "Jianyang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 512000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 513200,
          "id": 2534,
          "areaName": "阿坝藏族羌族自治州",
          "areaCode": "0837",
          "areaShort": "Aba",
          "areaInitials": null,
          "cityCode": "185",
          "center": "102.224653,31.899413",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 513221,
              "id": 2535,
              "areaName": "汶川县",
              "areaCode": "0837",
              "areaShort": "Wenchuan",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "103.590179,31.476854",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513222,
              "id": 2536,
              "areaName": "理县",
              "areaCode": "0837",
              "areaShort": "Lixian",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "103.164661,31.435174",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513223,
              "id": 2537,
              "areaName": "茂县",
              "areaCode": "0837",
              "areaShort": "Maoxian",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "103.853363,31.681547",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513224,
              "id": 2538,
              "areaName": "松潘县",
              "areaCode": "0837",
              "areaShort": "Songpan",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "103.604698,32.655325",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513225,
              "id": 2539,
              "areaName": "九寨沟县",
              "areaCode": "0837",
              "areaShort": "Jiuzhaigou",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "104.243841,33.252056",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513226,
              "id": 2540,
              "areaName": "金川县",
              "areaCode": "0837",
              "areaShort": "Jinchuan",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "102.063829,31.476277",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513227,
              "id": 2541,
              "areaName": "小金县",
              "areaCode": "0837",
              "areaShort": "Xiaojin",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "102.362984,30.995823",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513228,
              "id": 2542,
              "areaName": "黑水县",
              "areaCode": "0837",
              "areaShort": "Heishui",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "102.990108,32.061895",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513229,
              "id": 2543,
              "areaName": "马尔康县",
              "areaCode": null,
              "areaShort": "Maerkang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513230,
              "id": 2544,
              "areaName": "壤塘县",
              "areaCode": "0837",
              "areaShort": "Rangtang",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "100.978526,32.265796",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513231,
              "id": 2545,
              "areaName": "阿坝县",
              "areaCode": "0837",
              "areaShort": "Aba",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "101.706655,32.902459",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513232,
              "id": 2546,
              "areaName": "若尔盖县",
              "areaCode": "0837",
              "areaShort": "Ruoergai",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "102.967826,33.578159",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513233,
              "id": 2547,
              "areaName": "红原县",
              "areaCode": "0837",
              "areaShort": "Hongyuan",
              "areaInitials": null,
              "cityCode": "0837",
              "center": "102.544405,32.790891",
              "areaLevel": 3,
              "areaParentId": 513200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 513300,
          "id": 2548,
          "areaName": "甘孜藏族自治州",
          "areaCode": "0836",
          "areaShort": "Garze",
          "areaInitials": null,
          "cityCode": "73",
          "center": "101.96231,30.04952",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 513321,
              "id": 2549,
              "areaName": "康定县",
              "areaCode": null,
              "areaShort": "Kangding",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513322,
              "id": 2550,
              "areaName": "泸定县",
              "areaCode": "0836",
              "areaShort": "Luding",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "102.234617,29.91416",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513323,
              "id": 2551,
              "areaName": "丹巴县",
              "areaCode": "0836",
              "areaShort": "Danba",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "101.890358,30.878577",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513324,
              "id": 2552,
              "areaName": "九龙县",
              "areaCode": "0836",
              "areaShort": "Jiulong",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "101.507294,29.000347",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513325,
              "id": 2553,
              "areaName": "雅江县",
              "areaCode": "0836",
              "areaShort": "Yajiang",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "101.014425,30.031533",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513326,
              "id": 2554,
              "areaName": "道孚县",
              "areaCode": "0836",
              "areaShort": "Daofu",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "101.125237,30.979545",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513327,
              "id": 2555,
              "areaName": "炉霍县",
              "areaCode": "0836",
              "areaShort": "Luhuo",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "100.676372,31.39179",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513328,
              "id": 2556,
              "areaName": "甘孜县",
              "areaCode": "0836",
              "areaShort": "Ganzi",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "99.99267,31.622933",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513329,
              "id": 2557,
              "areaName": "新龙县",
              "areaCode": "0836",
              "areaShort": "Xinlong",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "100.311368,30.939169",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513330,
              "id": 2558,
              "areaName": "德格县",
              "areaCode": "0836",
              "areaShort": "Dege",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "98.580914,31.806118",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513331,
              "id": 2559,
              "areaName": "白玉县",
              "areaCode": "0836",
              "areaShort": "Baiyu",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "98.824182,31.209913",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513332,
              "id": 2560,
              "areaName": "石渠县",
              "areaCode": "0836",
              "areaShort": "Shiqu",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "98.102914,32.97896",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513333,
              "id": 2561,
              "areaName": "色达县",
              "areaCode": "0836",
              "areaShort": "Seda",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "100.332743,32.268129",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513334,
              "id": 2562,
              "areaName": "理塘县",
              "areaCode": "0836",
              "areaShort": "Litang",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "100.269817,29.996049",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513335,
              "id": 2563,
              "areaName": "巴塘县",
              "areaCode": "0836",
              "areaShort": "Batang",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "99.110712,30.004677",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513336,
              "id": 2564,
              "areaName": "乡城县",
              "areaCode": "0836",
              "areaShort": "Xiangcheng",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "99.798435,28.931172",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513337,
              "id": 2565,
              "areaName": "稻城县",
              "areaCode": "0836",
              "areaShort": "Daocheng",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "100.298403,29.037007",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513338,
              "id": 2566,
              "areaName": "得荣县",
              "areaCode": "0836",
              "areaShort": "Derong",
              "areaInitials": null,
              "cityCode": "0836",
              "center": "99.286335,28.713036",
              "areaLevel": 3,
              "areaParentId": 513300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 513400,
          "id": 2567,
          "areaName": "凉山彝族自治州",
          "areaCode": "0834",
          "areaShort": "Liangshan",
          "areaInitials": null,
          "cityCode": "80",
          "center": "102.267712,27.88157",
          "areaLevel": 2,
          "areaParentId": 510000,
          "areaDictionaryVOList": [
            {
              "areaId": 513401,
              "id": 2568,
              "areaName": "西昌市",
              "areaCode": "0834",
              "areaShort": "Xichang",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.264449,27.894504",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513422,
              "id": 2569,
              "areaName": "木里藏族自治县",
              "areaCode": "0834",
              "areaShort": "Muli",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "101.280205,27.928835",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513423,
              "id": 2570,
              "areaName": "盐源县",
              "areaCode": "0834",
              "areaShort": "Yanyuan",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "101.509188,27.422645",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513424,
              "id": 2571,
              "areaName": "德昌县",
              "areaCode": "0834",
              "areaShort": "Dechang",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.17567,27.402839",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513425,
              "id": 2572,
              "areaName": "会理县",
              "areaCode": "0834",
              "areaShort": "Huili",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.244683,26.655026",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513426,
              "id": 2573,
              "areaName": "会东县",
              "areaCode": "0834",
              "areaShort": "Huidong",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.57796,26.634669",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513427,
              "id": 2574,
              "areaName": "宁南县",
              "areaCode": "0834",
              "areaShort": "Ningnan",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.751745,27.061189",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513428,
              "id": 2575,
              "areaName": "普格县",
              "areaCode": "0834",
              "areaShort": "Puge",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.540901,27.376413",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513429,
              "id": 2576,
              "areaName": "布拖县",
              "areaCode": "0834",
              "areaShort": "Butuo",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.812061,27.706061",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513430,
              "id": 2577,
              "areaName": "金阳县",
              "areaCode": "0834",
              "areaShort": "Jinyang",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "103.248772,27.69686",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513431,
              "id": 2578,
              "areaName": "昭觉县",
              "areaCode": "0834",
              "areaShort": "Zhaojue",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.840264,28.015333",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513432,
              "id": 2579,
              "areaName": "喜德县",
              "areaCode": "0834",
              "areaShort": "Xide",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.412518,28.306726",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513433,
              "id": 2580,
              "areaName": "冕宁县",
              "areaCode": "0834",
              "areaShort": "Mianning",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.17701,28.549656",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513434,
              "id": 2581,
              "areaName": "越西县",
              "areaCode": "0834",
              "areaShort": "Yuexi",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.50768,28.639801",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513435,
              "id": 2582,
              "areaName": "甘洛县",
              "areaCode": "0834",
              "areaShort": "Ganluo",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "102.771504,28.959157",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513436,
              "id": 2583,
              "areaName": "美姑县",
              "areaCode": "0834",
              "areaShort": "Meigu",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "103.132179,28.32864",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 513437,
              "id": 2584,
              "areaName": "雷波县",
              "areaCode": "0834",
              "areaShort": "Leibo",
              "areaInitials": null,
              "cityCode": "0834",
              "center": "103.571696,28.262682",
              "areaLevel": 3,
              "areaParentId": 513400,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 520000,
      "id": 2585,
      "areaName": "贵州省",
      "areaCode": "[]",
      "areaShort": "Guizhou",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "106.70546,26.600055",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 520100,
          "id": 2586,
          "areaName": "贵阳市",
          "areaCode": "0851",
          "areaShort": "Guiyang",
          "areaInitials": null,
          "cityCode": "146",
          "center": "106.630153,26.647661",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 520102,
              "id": 2587,
              "areaName": "南明区",
              "areaCode": "0851",
              "areaShort": "Nanming",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.714374,26.567944",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520103,
              "id": 2588,
              "areaName": "云岩区",
              "areaCode": "0851",
              "areaShort": "Yunyan",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.724494,26.604688",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520111,
              "id": 2589,
              "areaName": "花溪区",
              "areaCode": "0851",
              "areaShort": "Huaxi",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.67026,26.409817",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520112,
              "id": 2590,
              "areaName": "乌当区",
              "areaCode": "0851",
              "areaShort": "Wudang",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.750625,26.630845",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520113,
              "id": 2591,
              "areaName": "白云区",
              "areaCode": "0851",
              "areaShort": "Baiyun",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.623007,26.678561",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520115,
              "id": 2592,
              "areaName": "观山湖区",
              "areaCode": "0851",
              "areaShort": "Guanshanhu",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.622453,26.60145",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520121,
              "id": 2593,
              "areaName": "开阳县",
              "areaCode": "0851",
              "areaShort": "Kaiyang",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.965089,27.057764",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520122,
              "id": 2594,
              "areaName": "息烽县",
              "areaCode": "0851",
              "areaShort": "Xifeng",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.740407,27.090479",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520123,
              "id": 2595,
              "areaName": "修文县",
              "areaCode": "0851",
              "areaShort": "Xiuwen",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.592108,26.838926",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520181,
              "id": 2596,
              "areaName": "清镇市",
              "areaCode": "0851",
              "areaShort": "Qingzhen",
              "areaInitials": null,
              "cityCode": "0851",
              "center": "106.470714,26.556079",
              "areaLevel": 3,
              "areaParentId": 520100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 520200,
          "id": 2597,
          "areaName": "六盘水市",
          "areaCode": "0858",
          "areaShort": "Liupanshui",
          "areaInitials": null,
          "cityCode": "147",
          "center": "104.830458,26.592707",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 520201,
              "id": 2598,
              "areaName": "钟山区",
              "areaCode": "0858",
              "areaShort": "Zhongshan",
              "areaInitials": null,
              "cityCode": "0858",
              "center": "104.843555,26.574979",
              "areaLevel": 3,
              "areaParentId": 520200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520203,
              "id": 2599,
              "areaName": "六枝特区",
              "areaCode": "0858",
              "areaShort": "Liuzhi",
              "areaInitials": null,
              "cityCode": "0858",
              "center": "105.476608,26.213108",
              "areaLevel": 3,
              "areaParentId": 520200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520221,
              "id": 2600,
              "areaName": "水城县",
              "areaCode": "0858",
              "areaShort": "Shuicheng",
              "areaInitials": null,
              "cityCode": "0858",
              "center": "104.95783,26.547904",
              "areaLevel": 3,
              "areaParentId": 520200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520222,
              "id": 2601,
              "areaName": "盘县",
              "areaCode": "0858",
              "areaShort": "Panxian",
              "areaInitials": null,
              "cityCode": "0858",
              "center": "104.471375,25.709852",
              "areaLevel": 3,
              "areaParentId": 520200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 520300,
          "id": 2602,
          "areaName": "遵义市",
          "areaCode": "0852",
          "areaShort": "Zunyi",
          "areaInitials": null,
          "cityCode": "262",
          "center": "106.927389,27.725654",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 520302,
              "id": 2603,
              "areaName": "红花岗区",
              "areaCode": "0852",
              "areaShort": "Honghuagang",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "106.8937,27.644754",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520303,
              "id": 2604,
              "areaName": "汇川区",
              "areaCode": "0852",
              "areaShort": "Huichuan",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "106.93427,27.750125",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520321,
              "id": 2605,
              "areaName": "遵义县",
              "areaCode": null,
              "areaShort": "Zunyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520322,
              "id": 2606,
              "areaName": "桐梓县",
              "areaCode": "0852",
              "areaShort": "Tongzi",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "106.825198,28.133311",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520323,
              "id": 2607,
              "areaName": "绥阳县",
              "areaCode": "0852",
              "areaShort": "Suiyang",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.191222,27.946222",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520324,
              "id": 2608,
              "areaName": "正安县",
              "areaCode": "0852",
              "areaShort": "Zheng'an",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.453945,28.553285",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520325,
              "id": 2609,
              "areaName": "道真仡佬族苗族自治县",
              "areaCode": "0852",
              "areaShort": "Daozhen",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.613133,28.862425",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520326,
              "id": 2610,
              "areaName": "务川仡佬族苗族自治县",
              "areaCode": "0852",
              "areaShort": "Wuchuan",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.898956,28.563086",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520327,
              "id": 2611,
              "areaName": "凤冈县",
              "areaCode": "0852",
              "areaShort": "Fenggang",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.716355,27.954695",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520328,
              "id": 2612,
              "areaName": "湄潭县",
              "areaCode": "0852",
              "areaShort": "Meitan",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.465407,27.749055",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520329,
              "id": 2613,
              "areaName": "余庆县",
              "areaCode": "0852",
              "areaShort": "Yuqing",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "107.905197,27.215491",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520330,
              "id": 2614,
              "areaName": "习水县",
              "areaCode": "0852",
              "areaShort": "Xishui",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "106.197137,28.33127",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520381,
              "id": 2615,
              "areaName": "赤水市",
              "areaCode": "0852",
              "areaShort": "Chishui",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "105.697472,28.590337",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520382,
              "id": 2616,
              "areaName": "仁怀市",
              "areaCode": "0852",
              "areaShort": "Renhuai",
              "areaInitials": null,
              "cityCode": "0852",
              "center": "106.40109,27.792514",
              "areaLevel": 3,
              "areaParentId": 520300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 520400,
          "id": 2617,
          "areaName": "安顺市",
          "areaCode": "0853",
          "areaShort": "Anshun",
          "areaInitials": null,
          "cityCode": "263",
          "center": "105.947594,26.253088",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 520402,
              "id": 2618,
              "areaName": "西秀区",
              "areaCode": "0853",
              "areaShort": "Xixiu",
              "areaInitials": null,
              "cityCode": "0853",
              "center": "105.965116,26.245315",
              "areaLevel": 3,
              "areaParentId": 520400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520421,
              "id": 2619,
              "areaName": "平坝区",
              "areaCode": null,
              "areaShort": "Pingba",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 520400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520422,
              "id": 2620,
              "areaName": "普定县",
              "areaCode": "0853",
              "areaShort": "Puding",
              "areaInitials": null,
              "cityCode": "0853",
              "center": "105.743277,26.301565",
              "areaLevel": 3,
              "areaParentId": 520400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520423,
              "id": 2621,
              "areaName": "镇宁布依族苗族自治县",
              "areaCode": "0853",
              "areaShort": "Zhenning",
              "areaInitials": null,
              "cityCode": "0853",
              "center": "105.770283,26.058086",
              "areaLevel": 3,
              "areaParentId": 520400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520424,
              "id": 2622,
              "areaName": "关岭布依族苗族自治县",
              "areaCode": "0853",
              "areaShort": "Guanling",
              "areaInitials": null,
              "cityCode": "0853",
              "center": "105.61933,25.94361",
              "areaLevel": 3,
              "areaParentId": 520400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520425,
              "id": 2623,
              "areaName": "紫云苗族布依族自治县",
              "areaCode": "0853",
              "areaShort": "Ziyun",
              "areaInitials": null,
              "cityCode": "0853",
              "center": "106.084441,25.751047",
              "areaLevel": 3,
              "areaParentId": 520400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 520500,
          "id": 2624,
          "areaName": "毕节市",
          "areaCode": "0857",
          "areaShort": "Bijie",
          "areaInitials": null,
          "cityCode": "206",
          "center": "105.291702,27.283908",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 520502,
              "id": 2625,
              "areaName": "七星关区",
              "areaCode": "0857",
              "areaShort": "Qixingguan",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "105.30474,27.298458",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520521,
              "id": 2626,
              "areaName": "大方县",
              "areaCode": "0857",
              "areaShort": "Dafang",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "105.613037,27.141735",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520522,
              "id": 2627,
              "areaName": "黔西县",
              "areaCode": "0857",
              "areaShort": "Qianxi",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "106.033544,27.007713",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520523,
              "id": 2628,
              "areaName": "金沙县",
              "areaCode": "0857",
              "areaShort": "Jinsha",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "106.220227,27.459214",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520524,
              "id": 2629,
              "areaName": "织金县",
              "areaCode": "0857",
              "areaShort": "Zhijin",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "105.770542,26.663449",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520525,
              "id": 2630,
              "areaName": "纳雍县",
              "areaCode": "0857",
              "areaShort": "Nayong",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "105.382714,26.777645",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520526,
              "id": 2631,
              "areaName": "威宁彝族回族苗族自治县",
              "areaCode": "0857",
              "areaShort": "Weining",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "104.253071,26.873806",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520527,
              "id": 2632,
              "areaName": "赫章县",
              "areaCode": "0857",
              "areaShort": "Hezhang",
              "areaInitials": null,
              "cityCode": "0857",
              "center": "104.727418,27.123078",
              "areaLevel": 3,
              "areaParentId": 520500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 520600,
          "id": 2633,
          "areaName": "铜仁市",
          "areaCode": "0856",
          "areaShort": "Tongren",
          "areaInitials": null,
          "cityCode": "205",
          "center": "109.189598,27.731514",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 520602,
              "id": 2634,
              "areaName": "碧江区",
              "areaCode": "0856",
              "areaShort": "Bijiang",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "109.263998,27.815927",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520603,
              "id": 2635,
              "areaName": "万山区",
              "areaCode": "0856",
              "areaShort": "Wanshan",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "109.213644,27.517896",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520621,
              "id": 2636,
              "areaName": "江口县",
              "areaCode": "0856",
              "areaShort": "Jiangkou",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.839557,27.69965",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520622,
              "id": 2637,
              "areaName": "玉屏侗族自治县",
              "areaCode": "0856",
              "areaShort": "Yuping",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.906411,27.235813",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520623,
              "id": 2638,
              "areaName": "石阡县",
              "areaCode": "0856",
              "areaShort": "Shiqian",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.223612,27.513829",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520624,
              "id": 2639,
              "areaName": "思南县",
              "areaCode": "0856",
              "areaShort": "Sinan",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.253882,27.93755",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520625,
              "id": 2640,
              "areaName": "印江土家族苗族自治县",
              "areaCode": "0856",
              "areaShort": "Yinjiang",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.409751,27.994246",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520626,
              "id": 2641,
              "areaName": "德江县",
              "areaCode": "0856",
              "areaShort": "Dejiang",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.119807,28.263963",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520627,
              "id": 2642,
              "areaName": "沿河土家族自治县",
              "areaCode": "0856",
              "areaShort": "Yuanhe",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "108.50387,28.563927",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 520628,
              "id": 2643,
              "areaName": "松桃苗族自治县",
              "areaCode": "0856",
              "areaShort": "Songtao",
              "areaInitials": null,
              "cityCode": "0856",
              "center": "109.202886,28.154071",
              "areaLevel": 3,
              "areaParentId": 520600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 522300,
          "id": 2644,
          "areaName": "黔西南布依族苗族自治州",
          "areaCode": "0859",
          "areaShort": "Qianxinan",
          "areaInitials": null,
          "cityCode": "343",
          "center": "104.906397,25.087856",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 522301,
              "id": 2645,
              "areaName": "兴义市 ",
              "areaCode": "0859",
              "areaShort": "Xingyi",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "104.895467,25.09204",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522322,
              "id": 2646,
              "areaName": "兴仁县",
              "areaCode": "0859",
              "areaShort": "Xingren",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "105.186237,25.435183",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522323,
              "id": 2647,
              "areaName": "普安县",
              "areaCode": "0859",
              "areaShort": "Pu'an",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "104.953062,25.784135",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522324,
              "id": 2648,
              "areaName": "晴隆县",
              "areaCode": "0859",
              "areaShort": "Qinglong",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "105.218991,25.834783",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522325,
              "id": 2649,
              "areaName": "贞丰县",
              "areaCode": "0859",
              "areaShort": "Zhenfeng",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "105.649864,25.38576",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522326,
              "id": 2650,
              "areaName": "望谟县",
              "areaCode": "0859",
              "areaShort": "Wangmo",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "106.099617,25.178421",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522327,
              "id": 2651,
              "areaName": "册亨县",
              "areaCode": "0859",
              "areaShort": "Ceheng",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "105.811592,24.983663",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522328,
              "id": 2652,
              "areaName": "安龙县",
              "areaCode": "0859",
              "areaShort": "Anlong",
              "areaInitials": null,
              "cityCode": "0859",
              "center": "105.442701,25.099014",
              "areaLevel": 3,
              "areaParentId": 522300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 522600,
          "id": 2653,
          "areaName": "黔东南苗族侗族自治州",
          "areaCode": "0855",
          "areaShort": "Qiandongnan",
          "areaInitials": null,
          "cityCode": "342",
          "center": "107.982874,26.583457",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 522601,
              "id": 2654,
              "areaName": "凯里市",
              "areaCode": "0855",
              "areaShort": "Kaili",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "107.97754,26.582963",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522622,
              "id": 2655,
              "areaName": "黄平县",
              "areaCode": "0855",
              "areaShort": "Huangping",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "107.916411,26.905396",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522623,
              "id": 2656,
              "areaName": "施秉县",
              "areaCode": "0855",
              "areaShort": "Shibing",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.124379,27.03292",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522624,
              "id": 2657,
              "areaName": "三穗县",
              "areaCode": "0855",
              "areaShort": "Sansui",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.675267,26.952967",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522625,
              "id": 2658,
              "areaName": "镇远县",
              "areaCode": "0855",
              "areaShort": "Zhenyuan",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.429534,27.049497",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522626,
              "id": 2659,
              "areaName": "岑巩县",
              "areaCode": "0855",
              "areaShort": "Cengong",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.81606,27.173887",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522627,
              "id": 2660,
              "areaName": "天柱县",
              "areaCode": "0855",
              "areaShort": "Tianzhu",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "109.207751,26.909639",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522628,
              "id": 2661,
              "areaName": "锦屏县",
              "areaCode": "0855",
              "areaShort": "Jinping",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "109.200534,26.676233",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522629,
              "id": 2662,
              "areaName": "剑河县",
              "areaCode": "0855",
              "areaShort": "Jianhe",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.441501,26.728274",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522630,
              "id": 2663,
              "areaName": "台江县",
              "areaCode": "0855",
              "areaShort": "Taijiang",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.321245,26.667525",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522631,
              "id": 2664,
              "areaName": "黎平县",
              "areaCode": "0855",
              "areaShort": "Liping",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "109.136932,26.230706",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522632,
              "id": 2665,
              "areaName": "榕江县",
              "areaCode": "0855",
              "areaShort": "Rongjiang",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.52188,25.931893",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522633,
              "id": 2666,
              "areaName": "从江县",
              "areaCode": "0855",
              "areaShort": "Congjiang",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.905329,25.753009",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522634,
              "id": 2667,
              "areaName": "雷山县",
              "areaCode": "0855",
              "areaShort": "Leishan",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "108.07754,26.378442",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522635,
              "id": 2668,
              "areaName": "麻江县",
              "areaCode": "0855",
              "areaShort": "Majiang",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "107.589359,26.491105",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522636,
              "id": 2669,
              "areaName": "丹寨县",
              "areaCode": "0855",
              "areaShort": "Danzhai",
              "areaInitials": null,
              "cityCode": "0855",
              "center": "107.788727,26.19832",
              "areaLevel": 3,
              "areaParentId": 522600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 522700,
          "id": 2670,
          "areaName": "黔南布依族苗族自治州",
          "areaCode": "0854",
          "areaShort": "Qiannan",
          "areaInitials": null,
          "cityCode": "306",
          "center": "107.522171,26.253275",
          "areaLevel": 2,
          "areaParentId": 520000,
          "areaDictionaryVOList": [
            {
              "areaId": 522701,
              "id": 2671,
              "areaName": "都匀市",
              "areaCode": "0854",
              "areaShort": "Duyun",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.518847,26.259427",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522702,
              "id": 2672,
              "areaName": "福泉市",
              "areaCode": "0854",
              "areaShort": "Fuquan",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.520386,26.686335",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522722,
              "id": 2673,
              "areaName": "荔波县",
              "areaCode": "0854",
              "areaShort": "Libo",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.898882,25.423895",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522723,
              "id": 2674,
              "areaName": "贵定县",
              "areaCode": "0854",
              "areaShort": "Guiding",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.232793,26.557089",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522725,
              "id": 2675,
              "areaName": "瓮安县",
              "areaCode": "0854",
              "areaShort": "Weng'an",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.470942,27.078441",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522726,
              "id": 2676,
              "areaName": "独山县",
              "areaCode": "0854",
              "areaShort": "Dushan",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.545048,25.822132",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522727,
              "id": 2677,
              "areaName": "平塘县",
              "areaCode": "0854",
              "areaShort": "Pingtang",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.322323,25.822349",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522728,
              "id": 2678,
              "areaName": "罗甸县",
              "areaCode": "0854",
              "areaShort": "Luodian",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "106.751589,25.426173",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522729,
              "id": 2679,
              "areaName": "长顺县",
              "areaCode": "0854",
              "areaShort": "Changshun",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "106.441805,26.025626",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522730,
              "id": 2680,
              "areaName": "龙里县",
              "areaCode": "0854",
              "areaShort": "Longli",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "106.979524,26.453154",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522731,
              "id": 2681,
              "areaName": "惠水县",
              "areaCode": "0854",
              "areaShort": "Huishui",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "106.656442,26.13278",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 522732,
              "id": 2682,
              "areaName": "三都水族自治县",
              "areaCode": "0854",
              "areaShort": "Sandu",
              "areaInitials": null,
              "cityCode": "0854",
              "center": "107.869749,25.983202",
              "areaLevel": 3,
              "areaParentId": 522700,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 530000,
      "id": 2683,
      "areaName": "云南省",
      "areaCode": "[]",
      "areaShort": "Yunnan",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "102.710002,25.045806",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 530100,
          "id": 2684,
          "areaName": "昆明市",
          "areaCode": "0871",
          "areaShort": "Kunming",
          "areaInitials": null,
          "cityCode": "104",
          "center": "102.832891,24.880095",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530102,
              "id": 2685,
              "areaName": "五华区",
              "areaCode": "0871",
              "areaShort": "Wuhua",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.707262,25.043635",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530103,
              "id": 2686,
              "areaName": "盘龙区",
              "areaCode": "0871",
              "areaShort": "Panlong",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.751941,25.116465",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530111,
              "id": 2687,
              "areaName": "官渡区",
              "areaCode": "0871",
              "areaShort": "Guandu",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.749026,24.950231",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530112,
              "id": 2688,
              "areaName": "西山区",
              "areaCode": "0871",
              "areaShort": "Xishan",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.664382,25.038604",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530113,
              "id": 2689,
              "areaName": "东川区",
              "areaCode": "0871",
              "areaShort": "Dongchuan",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "103.187824,26.082873",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530114,
              "id": 2690,
              "areaName": "呈贡区",
              "areaCode": "0871",
              "areaShort": "Chenggong",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.821675,24.885587",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530122,
              "id": 2691,
              "areaName": "晋宁县",
              "areaCode": null,
              "areaShort": "Jinning",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530124,
              "id": 2692,
              "areaName": "富民县",
              "areaCode": "0871",
              "areaShort": "Fumin",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.4976,25.221935",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530125,
              "id": 2693,
              "areaName": "宜良县",
              "areaCode": "0871",
              "areaShort": "Yiliang",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "103.141603,24.919839",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530126,
              "id": 2694,
              "areaName": "石林彝族自治县",
              "areaCode": "0871",
              "areaShort": "Shilin",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "103.290536,24.771761",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530127,
              "id": 2695,
              "areaName": "嵩明县",
              "areaCode": "0871",
              "areaShort": "Songming",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "103.036908,25.338643",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530128,
              "id": 2696,
              "areaName": "禄劝彝族苗族自治县",
              "areaCode": "0871",
              "areaShort": "Luquan",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.471518,25.551332",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530129,
              "id": 2697,
              "areaName": "寻甸回族彝族自治县 ",
              "areaCode": "0871",
              "areaShort": "Xundian",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "103.256615,25.558201",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530181,
              "id": 2698,
              "areaName": "安宁市",
              "areaCode": "0871",
              "areaShort": "Anning",
              "areaInitials": null,
              "cityCode": "0871",
              "center": "102.478494,24.919493",
              "areaLevel": 3,
              "areaParentId": 530100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530300,
          "id": 2699,
          "areaName": "曲靖市",
          "areaCode": "0874",
          "areaShort": "Qujing",
          "areaInitials": null,
          "cityCode": "249",
          "center": "103.796167,25.489999",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530302,
              "id": 2700,
              "areaName": "麒麟区",
              "areaCode": "0874",
              "areaShort": "Qilin",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "103.80474,25.495326",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530321,
              "id": 2701,
              "areaName": "马龙县",
              "areaCode": "0874",
              "areaShort": "Malong",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "103.578478,25.42805",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530322,
              "id": 2702,
              "areaName": "陆良县",
              "areaCode": "0874",
              "areaShort": "Luliang",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "103.666663,25.030051",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530323,
              "id": 2703,
              "areaName": "师宗县",
              "areaCode": "0874",
              "areaShort": "Shizong",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "103.985321,24.822233",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530324,
              "id": 2704,
              "areaName": "罗平县",
              "areaCode": "0874",
              "areaShort": "Luoping",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "104.308675,24.884626",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530325,
              "id": 2705,
              "areaName": "富源县",
              "areaCode": "0874",
              "areaShort": "Fuyuan",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "104.255014,25.674238",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530326,
              "id": 2706,
              "areaName": "会泽县",
              "areaCode": "0874",
              "areaShort": "Huize",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "103.297386,26.417345",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530328,
              "id": 2707,
              "areaName": "沾益县",
              "areaCode": null,
              "areaShort": "Zhanyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530381,
              "id": 2708,
              "areaName": "宣威市",
              "areaCode": "0874",
              "areaShort": "Xuanwei",
              "areaInitials": null,
              "cityCode": "0874",
              "center": "104.10455,26.219735",
              "areaLevel": 3,
              "areaParentId": 530300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530400,
          "id": 2709,
          "areaName": "玉溪市",
          "areaCode": "0877",
          "areaShort": "Yuxi",
          "areaInitials": null,
          "cityCode": "106",
          "center": "102.527197,24.347324",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530402,
              "id": 2710,
              "areaName": "红塔区",
              "areaCode": "0877",
              "areaShort": "Hongta",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "102.540122,24.341215",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530421,
              "id": 2711,
              "areaName": "江川县",
              "areaCode": null,
              "areaShort": "Jiangchuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530422,
              "id": 2712,
              "areaName": "澄江县",
              "areaCode": "0877",
              "areaShort": "Chengjiang",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "102.904629,24.675689",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530423,
              "id": 2713,
              "areaName": "通海县",
              "areaCode": "0877",
              "areaShort": "Tonghai",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "102.725452,24.111048",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530424,
              "id": 2714,
              "areaName": "华宁县",
              "areaCode": "0877",
              "areaShort": "Huaning",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "102.928835,24.19276",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530425,
              "id": 2715,
              "areaName": "易门县",
              "areaCode": "0877",
              "areaShort": "Yimen",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "102.162531,24.671651",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530426,
              "id": 2716,
              "areaName": "峨山彝族自治县",
              "areaCode": "0877",
              "areaShort": "Eshan",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "102.405819,24.168957",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530427,
              "id": 2717,
              "areaName": "新平彝族傣族自治县",
              "areaCode": "0877",
              "areaShort": "Xinping",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "101.990157,24.07005",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530428,
              "id": 2718,
              "areaName": "元江哈尼族彝族傣族自治县",
              "areaCode": "0877",
              "areaShort": "Yuanjiang",
              "areaInitials": null,
              "cityCode": "0877",
              "center": "101.998103,23.596503",
              "areaLevel": 3,
              "areaParentId": 530400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530500,
          "id": 2719,
          "areaName": "保山市",
          "areaCode": "0875",
          "areaShort": "Baoshan",
          "areaInitials": null,
          "cityCode": "112",
          "center": "99.161761,25.112046",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530502,
              "id": 2720,
              "areaName": "隆阳区",
              "areaCode": "0875",
              "areaShort": "Longyang",
              "areaInitials": null,
              "cityCode": "0875",
              "center": "99.165607,25.121154",
              "areaLevel": 3,
              "areaParentId": 530500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530521,
              "id": 2721,
              "areaName": "施甸县",
              "areaCode": "0875",
              "areaShort": "Shidian",
              "areaInitials": null,
              "cityCode": "0875",
              "center": "99.189221,24.723064",
              "areaLevel": 3,
              "areaParentId": 530500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530522,
              "id": 2722,
              "areaName": "腾冲县",
              "areaCode": null,
              "areaShort": "Tengchong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 530500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530523,
              "id": 2723,
              "areaName": "龙陵县",
              "areaCode": "0875",
              "areaShort": "Longling",
              "areaInitials": null,
              "cityCode": "0875",
              "center": "98.689261,24.586794",
              "areaLevel": 3,
              "areaParentId": 530500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530524,
              "id": 2724,
              "areaName": "昌宁县",
              "areaCode": "0875",
              "areaShort": "Changning",
              "areaInitials": null,
              "cityCode": "0875",
              "center": "99.605142,24.827839",
              "areaLevel": 3,
              "areaParentId": 530500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530600,
          "id": 2725,
          "areaName": "昭通市",
          "areaCode": "0870",
          "areaShort": "Zhaotong",
          "areaInitials": null,
          "cityCode": "336",
          "center": "103.717465,27.338257",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530602,
              "id": 2726,
              "areaName": "昭阳区",
              "areaCode": "0870",
              "areaShort": "Zhaoyang",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "103.706539,27.320075",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530621,
              "id": 2727,
              "areaName": "鲁甸县",
              "areaCode": "0870",
              "areaShort": "Ludian",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "103.558042,27.186659",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530622,
              "id": 2728,
              "areaName": "巧家县",
              "areaCode": "0870",
              "areaShort": "Qiaojia",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "102.930164,26.90846",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530623,
              "id": 2729,
              "areaName": "盐津县",
              "areaCode": "0870",
              "areaShort": "Yanjin",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "104.234441,28.10871",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530624,
              "id": 2730,
              "areaName": "大关县",
              "areaCode": "0870",
              "areaShort": "Daguan",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "103.891146,27.747978",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530625,
              "id": 2731,
              "areaName": "永善县",
              "areaCode": "0870",
              "areaShort": "Yongshan",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "103.638067,28.229112",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530626,
              "id": 2732,
              "areaName": "绥江县",
              "areaCode": "0870",
              "areaShort": "Suijiang",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "103.968978,28.592099",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530627,
              "id": 2733,
              "areaName": "镇雄县",
              "areaCode": "0870",
              "areaShort": "Zhenxiong",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "104.87376,27.441622",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530628,
              "id": 2734,
              "areaName": "彝良县",
              "areaCode": "0870",
              "areaShort": "Yiliang",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "104.048289,27.625418",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530629,
              "id": 2735,
              "areaName": "威信县",
              "areaCode": "0870",
              "areaShort": "Weixin",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "105.049027,27.8469",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530630,
              "id": 2736,
              "areaName": "水富县",
              "areaCode": "0870",
              "areaShort": "Shuifu",
              "areaInitials": null,
              "cityCode": "0870",
              "center": "104.41603,28.62988",
              "areaLevel": 3,
              "areaParentId": 530600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530700,
          "id": 2737,
          "areaName": "丽江市",
          "areaCode": "0888",
          "areaShort": "Lijiang",
          "areaInitials": null,
          "cityCode": "114",
          "center": "100.22775,26.855047",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530702,
              "id": 2738,
              "areaName": "古城区",
              "areaCode": "0888",
              "areaShort": "Gucheng",
              "areaInitials": null,
              "cityCode": "0888",
              "center": "100.225784,26.876927",
              "areaLevel": 3,
              "areaParentId": 530700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530721,
              "id": 2739,
              "areaName": "玉龙纳西族自治县",
              "areaCode": "0888",
              "areaShort": "Yulong",
              "areaInitials": null,
              "cityCode": "0888",
              "center": "100.236954,26.821459",
              "areaLevel": 3,
              "areaParentId": 530700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530722,
              "id": 2740,
              "areaName": "永胜县",
              "areaCode": "0888",
              "areaShort": "Yongsheng",
              "areaInitials": null,
              "cityCode": "0888",
              "center": "100.750826,26.684225",
              "areaLevel": 3,
              "areaParentId": 530700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530723,
              "id": 2741,
              "areaName": "华坪县",
              "areaCode": "0888",
              "areaShort": "Huaping",
              "areaInitials": null,
              "cityCode": "0888",
              "center": "101.266195,26.629211",
              "areaLevel": 3,
              "areaParentId": 530700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530724,
              "id": 2742,
              "areaName": "宁蒗彝族自治县",
              "areaCode": "0888",
              "areaShort": "Ninglang",
              "areaInitials": null,
              "cityCode": "0888",
              "center": "100.852001,27.28207",
              "areaLevel": 3,
              "areaParentId": 530700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530800,
          "id": 2743,
          "areaName": "普洱市",
          "areaCode": "0879",
          "areaShort": "Pu'er",
          "areaInitials": null,
          "cityCode": "108",
          "center": "100.966156,22.825155",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530802,
              "id": 2744,
              "areaName": "思茅区",
              "areaCode": "0879",
              "areaShort": "Simao",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "100.977256,22.787115",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530821,
              "id": 2745,
              "areaName": "宁洱哈尼族彝族自治县",
              "areaCode": "0879",
              "areaShort": "Ninger",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "101.045837,23.048401",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530822,
              "id": 2746,
              "areaName": "墨江哈尼族自治县",
              "areaCode": "0879",
              "areaShort": "Mojiang",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "101.692461,23.431894",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530823,
              "id": 2747,
              "areaName": "景东彝族自治县",
              "areaCode": "0879",
              "areaShort": "Jingdong",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "100.833877,24.446731",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530824,
              "id": 2748,
              "areaName": "景谷傣族彝族自治县",
              "areaCode": "0879",
              "areaShort": "Jinggu",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "100.702871,23.497028",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530825,
              "id": 2749,
              "areaName": "镇沅彝族哈尼族拉祜族自治县",
              "areaCode": "0879",
              "areaShort": "Zhenyuan",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "101.108595,24.004441",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530826,
              "id": 2750,
              "areaName": "江城哈尼族彝族自治县",
              "areaCode": "0879",
              "areaShort": "Jiangcheng",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "101.86212,22.585867",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530827,
              "id": 2751,
              "areaName": "孟连傣族拉祜族佤族自治县",
              "areaCode": "0879",
              "areaShort": "Menglian",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "99.584157,22.329099",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530828,
              "id": 2752,
              "areaName": "澜沧拉祜族自治县",
              "areaCode": "0879",
              "areaShort": "Lancang",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "99.931975,22.555904",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530829,
              "id": 2753,
              "areaName": "西盟佤族自治县",
              "areaCode": "0879",
              "areaShort": "Ximeng",
              "areaInitials": null,
              "cityCode": "0879",
              "center": "99.590123,22.644508",
              "areaLevel": 3,
              "areaParentId": 530800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 530900,
          "id": 2754,
          "areaName": "临沧市",
          "areaCode": "0883",
          "areaShort": "Lincang",
          "areaInitials": null,
          "cityCode": "110",
          "center": "100.08879,23.883955",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 530902,
              "id": 2755,
              "areaName": "临翔区",
              "areaCode": "0883",
              "areaShort": "Linxiang",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "100.082523,23.895137",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530921,
              "id": 2756,
              "areaName": "凤庆县",
              "areaCode": "0883",
              "areaShort": "Fengqing",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "99.928459,24.580424",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530922,
              "id": 2757,
              "areaName": "云县",
              "areaCode": "0883",
              "areaShort": "Yunxian",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "100.129354,24.44422",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530923,
              "id": 2758,
              "areaName": "永德县",
              "areaCode": "0883",
              "areaShort": "Yongde",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "99.259339,24.018357",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530924,
              "id": 2759,
              "areaName": "镇康县",
              "areaCode": "0883",
              "areaShort": "Zhenkang",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "98.825284,23.762584",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530925,
              "id": 2760,
              "areaName": "双江拉祜族佤族布朗族傣族自治县",
              "areaCode": "0883",
              "areaShort": "Shuangjiang",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "99.827697,23.473499",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530926,
              "id": 2761,
              "areaName": "耿马傣族佤族自治县",
              "areaCode": "0883",
              "areaShort": "Gengma",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "99.397126,23.538092",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 530927,
              "id": 2762,
              "areaName": "沧源佤族自治县",
              "areaCode": "0883",
              "areaShort": "Cangyuan",
              "areaInitials": null,
              "cityCode": "0883",
              "center": "99.246196,23.146712",
              "areaLevel": 3,
              "areaParentId": 530900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 532300,
          "id": 2763,
          "areaName": "楚雄彝族自治州",
          "areaCode": "0878",
          "areaShort": "Chuxiong",
          "areaInitials": null,
          "cityCode": "105",
          "center": "101.527992,25.045513",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 532301,
              "id": 2764,
              "areaName": "楚雄市",
              "areaCode": "0878",
              "areaShort": "Chuxiong",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.545906,25.032889",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532322,
              "id": 2765,
              "areaName": "双柏县",
              "areaCode": "0878",
              "areaShort": "Shuangbai",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.641937,24.688875",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532323,
              "id": 2766,
              "areaName": "牟定县",
              "areaCode": "0878",
              "areaShort": "Mouding",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.546566,25.313121",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532324,
              "id": 2767,
              "areaName": "南华县",
              "areaCode": "0878",
              "areaShort": "Nanhua",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.273577,25.192293",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532325,
              "id": 2768,
              "areaName": "姚安县",
              "areaCode": "0878",
              "areaShort": "Yao'an",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.241728,25.504173",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532326,
              "id": 2769,
              "areaName": "大姚县",
              "areaCode": "0878",
              "areaShort": "Dayao",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.336617,25.729513",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532327,
              "id": 2770,
              "areaName": "永仁县",
              "areaCode": "0878",
              "areaShort": "Yongren",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.666132,26.049464",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532328,
              "id": 2771,
              "areaName": "元谋县",
              "areaCode": "0878",
              "areaShort": "Yuanmou",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "101.87452,25.704338",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532329,
              "id": 2772,
              "areaName": "武定县",
              "areaCode": "0878",
              "areaShort": "Wuding",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "102.404337,25.530389",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532331,
              "id": 2773,
              "areaName": "禄丰县",
              "areaCode": "0878",
              "areaShort": "Lufeng",
              "areaInitials": null,
              "cityCode": "0878",
              "center": "102.079027,25.150111",
              "areaLevel": 3,
              "areaParentId": 532300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 532500,
          "id": 2774,
          "areaName": "红河哈尼族彝族自治州",
          "areaCode": "0873",
          "areaShort": "Honghe",
          "areaInitials": null,
          "cityCode": "107",
          "center": "103.374893,23.363245",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 532501,
              "id": 2775,
              "areaName": "个旧市",
              "areaCode": "0873",
              "areaShort": "Gejiu",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.160034,23.359121",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532502,
              "id": 2776,
              "areaName": "开远市",
              "areaCode": "0873",
              "areaShort": "Kaiyuan",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.266624,23.714523",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532503,
              "id": 2777,
              "areaName": "蒙自市",
              "areaCode": "0873",
              "areaShort": "Mengzi",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.364905,23.396201",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532504,
              "id": 2778,
              "areaName": "弥勒市",
              "areaCode": "0873",
              "areaShort": "Mile ",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.414874,24.411912",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532523,
              "id": 2779,
              "areaName": "屏边苗族自治县",
              "areaCode": "0873",
              "areaShort": "Pingbian",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.687612,22.983559",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532524,
              "id": 2780,
              "areaName": "建水县",
              "areaCode": "0873",
              "areaShort": "Jianshui",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "102.826557,23.6347",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532525,
              "id": 2781,
              "areaName": "石屏县",
              "areaCode": "0873",
              "areaShort": "Shiping",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "102.494983,23.705936",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532527,
              "id": 2782,
              "areaName": "泸西县",
              "areaCode": "0873",
              "areaShort": "Luxi",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.766196,24.532025",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532528,
              "id": 2783,
              "areaName": "元阳县",
              "areaCode": "0873",
              "areaShort": "Yuanyang",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "102.835223,23.219932",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532529,
              "id": 2784,
              "areaName": "红河县",
              "areaCode": "0873",
              "areaShort": "Honghexian",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "102.4206,23.369161",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532530,
              "id": 2785,
              "areaName": "金平苗族瑶族傣族自治县",
              "areaCode": "0873",
              "areaShort": "Jinping",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.226448,22.779543",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532531,
              "id": 2786,
              "areaName": "绿春县",
              "areaCode": "0873",
              "areaShort": "Lvchun",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "102.392463,22.993717",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532532,
              "id": 2787,
              "areaName": "河口瑶族自治县",
              "areaCode": "0873",
              "areaShort": "Hekou",
              "areaInitials": null,
              "cityCode": "0873",
              "center": "103.93952,22.529645",
              "areaLevel": 3,
              "areaParentId": 532500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 532600,
          "id": 2788,
          "areaName": "文山壮族苗族自治州",
          "areaCode": "0876",
          "areaShort": "Wenshan",
          "areaInitials": null,
          "cityCode": "177",
          "center": "104.216248,23.400733",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 532601,
              "id": 2789,
              "areaName": "文山市",
              "areaCode": "0876",
              "areaShort": "Wenshan",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "104.232665,23.386527",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532622,
              "id": 2790,
              "areaName": "砚山县",
              "areaCode": "0876",
              "areaShort": "Yanshan",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "104.337211,23.605768",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532623,
              "id": 2791,
              "areaName": "西畴县",
              "areaCode": "0876",
              "areaShort": "Xichou",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "104.672597,23.437782",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532624,
              "id": 2792,
              "areaName": "麻栗坡县",
              "areaCode": "0876",
              "areaShort": "Malipo",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "104.702799,23.125714",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532625,
              "id": 2793,
              "areaName": "马关县",
              "areaCode": "0876",
              "areaShort": "Maguan",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "104.394157,23.012915",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532626,
              "id": 2794,
              "areaName": "丘北县",
              "areaCode": "0876",
              "areaShort": "Qiubei",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "104.166587,24.051746",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532627,
              "id": 2795,
              "areaName": "广南县",
              "areaCode": "0876",
              "areaShort": "Guangnan",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "105.055107,24.046386",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532628,
              "id": 2796,
              "areaName": "富宁县",
              "areaCode": "0876",
              "areaShort": "Funing",
              "areaInitials": null,
              "cityCode": "0876",
              "center": "105.630999,23.625283",
              "areaLevel": 3,
              "areaParentId": 532600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 532800,
          "id": 2797,
          "areaName": "西双版纳傣族自治州",
          "areaCode": "0691",
          "areaShort": "Xishuangbanna",
          "areaInitials": null,
          "cityCode": "109",
          "center": "100.796984,22.009113",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 532801,
              "id": 2798,
              "areaName": "景洪市",
              "areaCode": "0691",
              "areaShort": "Jinghong",
              "areaInitials": null,
              "cityCode": "0691",
              "center": "100.799545,22.011928",
              "areaLevel": 3,
              "areaParentId": 532800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532822,
              "id": 2799,
              "areaName": "勐海县",
              "areaCode": "0691",
              "areaShort": "Menghai",
              "areaInitials": null,
              "cityCode": "0691",
              "center": "100.452547,21.957353",
              "areaLevel": 3,
              "areaParentId": 532800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532823,
              "id": 2800,
              "areaName": "勐腊县",
              "areaCode": "0691",
              "areaShort": "Mengla",
              "areaInitials": null,
              "cityCode": "0691",
              "center": "101.564635,21.459233",
              "areaLevel": 3,
              "areaParentId": 532800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 532900,
          "id": 2801,
          "areaName": "大理白族自治州",
          "areaCode": "0872",
          "areaShort": "Dali",
          "areaInitials": null,
          "cityCode": "111",
          "center": "100.267638,25.606486",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 532901,
              "id": 2802,
              "areaName": "大理市",
              "areaCode": "0872",
              "areaShort": "Dali",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.30127,25.678068",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532922,
              "id": 2803,
              "areaName": "漾濞彝族自治县",
              "areaCode": "0872",
              "areaShort": "Yangbi",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "99.958015,25.670148",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532923,
              "id": 2804,
              "areaName": "祥云县",
              "areaCode": "0872",
              "areaShort": "Xiangyun",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.550945,25.48385",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532924,
              "id": 2805,
              "areaName": "宾川县",
              "areaCode": "0872",
              "areaShort": "Binchuan",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.590473,25.829828",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532925,
              "id": 2806,
              "areaName": "弥渡县",
              "areaCode": "0872",
              "areaShort": "Midu",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.49099,25.343804",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532926,
              "id": 2807,
              "areaName": "南涧彝族自治县",
              "areaCode": "0872",
              "areaShort": "Nanjian",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.509035,25.04351",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532927,
              "id": 2808,
              "areaName": "巍山彝族回族自治县",
              "areaCode": "0872",
              "areaShort": "Weishan",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.307174,25.227212",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532928,
              "id": 2809,
              "areaName": "永平县",
              "areaCode": "0872",
              "areaShort": "Yongping",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "99.541236,25.464681",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532929,
              "id": 2810,
              "areaName": "云龙县",
              "areaCode": "0872",
              "areaShort": "Yunlong",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "99.37112,25.885595",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532930,
              "id": 2811,
              "areaName": "洱源县",
              "areaCode": "0872",
              "areaShort": "Eryuan",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "99.951053,26.11116",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532931,
              "id": 2812,
              "areaName": "剑川县",
              "areaCode": "0872",
              "areaShort": "Jianchuan",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "99.905559,26.537033",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 532932,
              "id": 2813,
              "areaName": "鹤庆县",
              "areaCode": "0872",
              "areaShort": "Heqing",
              "areaInitials": null,
              "cityCode": "0872",
              "center": "100.176498,26.560231",
              "areaLevel": 3,
              "areaParentId": 532900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 533100,
          "id": 2814,
          "areaName": "德宏傣族景颇族自治州",
          "areaCode": "0692",
          "areaShort": "Dehong",
          "areaInitials": null,
          "cityCode": "116",
          "center": "98.584895,24.433353",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 533102,
              "id": 2815,
              "areaName": "瑞丽市",
              "areaCode": "0692",
              "areaShort": "Ruili",
              "areaInitials": null,
              "cityCode": "0692",
              "center": "97.85559,24.017958",
              "areaLevel": 3,
              "areaParentId": 533100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533103,
              "id": 2816,
              "areaName": "芒市",
              "areaCode": "0692",
              "areaShort": "Mangshi",
              "areaInitials": null,
              "cityCode": "0692",
              "center": "98.588086,24.43369",
              "areaLevel": 3,
              "areaParentId": 533100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533122,
              "id": 2817,
              "areaName": "梁河县",
              "areaCode": "0692",
              "areaShort": "Lianghe",
              "areaInitials": null,
              "cityCode": "0692",
              "center": "98.296657,24.804232",
              "areaLevel": 3,
              "areaParentId": 533100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533123,
              "id": 2818,
              "areaName": "盈江县",
              "areaCode": "0692",
              "areaShort": "Yingjiang",
              "areaInitials": null,
              "cityCode": "0692",
              "center": "97.931936,24.705164",
              "areaLevel": 3,
              "areaParentId": 533100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533124,
              "id": 2819,
              "areaName": "陇川县",
              "areaCode": "0692",
              "areaShort": "Longchuan",
              "areaInitials": null,
              "cityCode": "0692",
              "center": "97.792104,24.182965",
              "areaLevel": 3,
              "areaParentId": 533100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 533300,
          "id": 2820,
          "areaName": "怒江傈僳族自治州",
          "areaCode": "0886",
          "areaShort": "Nujiang",
          "areaInitials": null,
          "cityCode": "113",
          "center": "98.8566,25.817555",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 533321,
              "id": 2821,
              "areaName": "泸水县",
              "areaCode": null,
              "areaShort": "Lushui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 533300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533323,
              "id": 2822,
              "areaName": "福贡县",
              "areaCode": "0886",
              "areaShort": "Fugong",
              "areaInitials": null,
              "cityCode": "0886",
              "center": "98.869132,26.901831",
              "areaLevel": 3,
              "areaParentId": 533300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533324,
              "id": 2823,
              "areaName": "贡山独龙族怒族自治县",
              "areaCode": "0886",
              "areaShort": "Gongshan",
              "areaInitials": null,
              "cityCode": "0886",
              "center": "98.665964,27.740999",
              "areaLevel": 3,
              "areaParentId": 533300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533325,
              "id": 2824,
              "areaName": "兰坪白族普米族自治县",
              "areaCode": "0886",
              "areaShort": "Lanping",
              "areaInitials": null,
              "cityCode": "0886",
              "center": "99.416677,26.453571",
              "areaLevel": 3,
              "areaParentId": 533300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 533400,
          "id": 2825,
          "areaName": "迪庆藏族自治州",
          "areaCode": "0887",
          "areaShort": "Deqen",
          "areaInitials": null,
          "cityCode": "115",
          "center": "99.702583,27.818807",
          "areaLevel": 2,
          "areaParentId": 530000,
          "areaDictionaryVOList": [
            {
              "areaId": 533421,
              "id": 2826,
              "areaName": "香格里拉市",
              "areaCode": null,
              "areaShort": "Xianggelila",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 533400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533422,
              "id": 2827,
              "areaName": "德钦县",
              "areaCode": "0887",
              "areaShort": "Deqin",
              "areaInitials": null,
              "cityCode": "0887",
              "center": "98.911559,28.486163",
              "areaLevel": 3,
              "areaParentId": 533400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 533423,
              "id": 2828,
              "areaName": "维西傈僳族自治县",
              "areaCode": "0887",
              "areaShort": "Weixi",
              "areaInitials": null,
              "cityCode": "0887",
              "center": "99.287173,27.177161",
              "areaLevel": 3,
              "areaParentId": 533400,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 540000,
      "id": 2829,
      "areaName": "西藏自治区",
      "areaCode": "[]",
      "areaShort": "Tibet",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "91.117525,29.647535",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 540100,
          "id": 2830,
          "areaName": "拉萨市",
          "areaCode": "0891",
          "areaShort": "Lhasa",
          "areaInitials": null,
          "cityCode": "100",
          "center": "91.172148,29.652341",
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 540102,
              "id": 2831,
              "areaName": "城关区",
              "areaCode": "0891",
              "areaShort": "Chengguan",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "91.140552,29.654838",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540121,
              "id": 2832,
              "areaName": "林周县",
              "areaCode": "0891",
              "areaShort": "Linzhou",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "91.265287,29.893545",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540122,
              "id": 2833,
              "areaName": "当雄县",
              "areaCode": "0891",
              "areaShort": "Dangxiong",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "91.101162,30.473118",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540123,
              "id": 2834,
              "areaName": "尼木县",
              "areaCode": "0891",
              "areaShort": "Nimu",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "90.164524,29.431831",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540124,
              "id": 2835,
              "areaName": "曲水县",
              "areaCode": "0891",
              "areaShort": "Qushui",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "90.743853,29.353058",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540125,
              "id": 2836,
              "areaName": "堆龙德庆县",
              "areaCode": null,
              "areaShort": "Duilongdeqing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540126,
              "id": 2837,
              "areaName": "达孜县",
              "areaCode": "0891",
              "areaShort": "Dazi",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "91.349867,29.66941",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540127,
              "id": 2838,
              "areaName": "墨竹工卡县",
              "areaCode": "0891",
              "areaShort": "Mozhugongka",
              "areaInitials": null,
              "cityCode": "0891",
              "center": "91.730732,29.834111",
              "areaLevel": 3,
              "areaParentId": 540100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 540200,
          "id": 2839,
          "areaName": "日喀则市",
          "areaCode": "0892",
          "areaShort": "Rikaze",
          "areaInitials": null,
          "cityCode": "102",
          "center": "88.880583,29.266869",
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 540202,
              "id": 2840,
              "areaName": "桑珠孜区",
              "areaCode": "0892",
              "areaShort": "Sangzhuzi",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "88.898483,29.24779",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540221,
              "id": 2841,
              "areaName": "南木林县",
              "areaCode": "0892",
              "areaShort": "Nanmulin",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "89.099242,29.68233",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540222,
              "id": 2842,
              "areaName": "江孜县",
              "areaCode": "0892",
              "areaShort": "Jiangzi",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "89.605627,28.911626",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540223,
              "id": 2843,
              "areaName": "定日县",
              "areaCode": "0892",
              "areaShort": "Dingri",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "87.12612,28.658743",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540224,
              "id": 2844,
              "areaName": "萨迦县",
              "areaCode": "0892",
              "areaShort": "Sajia",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "88.021674,28.899664",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540225,
              "id": 2845,
              "areaName": "拉孜县",
              "areaCode": "0892",
              "areaShort": "Lazi",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "87.63704,29.081659",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540226,
              "id": 2846,
              "areaName": "昂仁县",
              "areaCode": "0892",
              "areaShort": "Angren",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "87.236051,29.294802",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540227,
              "id": 2847,
              "areaName": "谢通门县",
              "areaCode": "0892",
              "areaShort": "Xietongmen",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "88.261664,29.432476",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540228,
              "id": 2848,
              "areaName": "白朗县",
              "areaCode": "0892",
              "areaShort": "Bailang",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "89.261977,29.107688",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540229,
              "id": 2849,
              "areaName": "仁布县",
              "areaCode": "0892",
              "areaShort": "Renbu",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "89.841983,29.230933",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540230,
              "id": 2850,
              "areaName": "康马县",
              "areaCode": "0892",
              "areaShort": "Kangma",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "89.681663,28.555627",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540231,
              "id": 2851,
              "areaName": "定结县",
              "areaCode": "0892",
              "areaShort": "Dingjie",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "87.765872,28.364159",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540232,
              "id": 2852,
              "areaName": "仲巴县",
              "areaCode": "0892",
              "areaShort": "Zhongba",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "84.03153,29.770279",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540233,
              "id": 2853,
              "areaName": "亚东县",
              "areaCode": "0892",
              "areaShort": "Yadong",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "88.907093,27.484806",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540234,
              "id": 2854,
              "areaName": "吉隆县",
              "areaCode": "0892",
              "areaShort": "Jilong",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "85.297534,28.852393",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540235,
              "id": 2855,
              "areaName": "聂拉木县",
              "areaCode": "0892",
              "areaShort": "Nielamu",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "85.982237,28.155186",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540236,
              "id": 2856,
              "areaName": "萨嘎县",
              "areaCode": "0892",
              "areaShort": "Saga",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "85.232941,29.328818",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540237,
              "id": 2857,
              "areaName": "岗巴县",
              "areaCode": "0892",
              "areaShort": "Gangba",
              "areaInitials": null,
              "cityCode": "0892",
              "center": "88.520031,28.274601",
              "areaLevel": 3,
              "areaParentId": 540200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 540300,
          "id": 2858,
          "areaName": "昌都市",
          "areaCode": "0895",
          "areaShort": "Qamdo",
          "areaInitials": null,
          "cityCode": "99",
          "center": "97.17202,31.140969",
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 540302,
              "id": 2859,
              "areaName": "卡若区",
              "areaCode": "0895",
              "areaShort": "Karuo",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "97.196021,31.112087",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540321,
              "id": 2860,
              "areaName": "江达县",
              "areaCode": "0895",
              "areaShort": "Jiangda",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "98.21843,31.499202",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540322,
              "id": 2861,
              "areaName": "贡觉县",
              "areaCode": "0895",
              "areaShort": "Gongjue",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "98.27097,30.860099",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540323,
              "id": 2862,
              "areaName": "类乌齐县",
              "areaCode": "0895",
              "areaShort": "Leiwuqi",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "96.600246,31.211601",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540324,
              "id": 2863,
              "areaName": "丁青县",
              "areaCode": "0895",
              "areaShort": "Dingqing",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "95.619868,31.409024",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540325,
              "id": 2864,
              "areaName": "察雅县",
              "areaCode": "0895",
              "areaShort": "Chaya",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "97.568752,30.653943",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540326,
              "id": 2865,
              "areaName": "八宿县",
              "areaCode": "0895",
              "areaShort": "Basu",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "96.917836,30.053209",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540327,
              "id": 2866,
              "areaName": "左贡县",
              "areaCode": "0895",
              "areaShort": "Zuogong",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "97.841022,29.671069",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540328,
              "id": 2867,
              "areaName": "芒康县",
              "areaCode": "0895",
              "areaShort": "Mangkang",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "98.593113,29.679907",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540329,
              "id": 2868,
              "areaName": "洛隆县",
              "areaCode": "0895",
              "areaShort": "Luolong",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "95.825197,30.741845",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 540330,
              "id": 2869,
              "areaName": "边坝县",
              "areaCode": "0895",
              "areaShort": "Bianba",
              "areaInitials": null,
              "cityCode": "0895",
              "center": "94.7078,30.933652",
              "areaLevel": 3,
              "areaParentId": 540300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 542200,
          "id": 2870,
          "areaName": "山南地区",
          "areaCode": null,
          "areaShort": "Shannan",
          "areaInitials": null,
          "cityCode": "97",
          "center": null,
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 542221,
              "id": 2871,
              "areaName": "乃东县",
              "areaCode": null,
              "areaShort": "Naidong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542222,
              "id": 2872,
              "areaName": "扎囊县",
              "areaCode": null,
              "areaShort": "Zhanang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542223,
              "id": 2873,
              "areaName": "贡嘎县",
              "areaCode": null,
              "areaShort": "Gongga",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542224,
              "id": 2874,
              "areaName": "桑日县",
              "areaCode": null,
              "areaShort": "Sangri",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542225,
              "id": 2875,
              "areaName": "琼结县",
              "areaCode": null,
              "areaShort": "Qiongjie",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542226,
              "id": 2876,
              "areaName": "曲松县",
              "areaCode": null,
              "areaShort": "Qusong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542227,
              "id": 2877,
              "areaName": "措美县",
              "areaCode": null,
              "areaShort": "Cuomei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542228,
              "id": 2878,
              "areaName": "洛扎县",
              "areaCode": null,
              "areaShort": "Luozha",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542229,
              "id": 2879,
              "areaName": "加查县",
              "areaCode": null,
              "areaShort": "Jiacha",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542231,
              "id": 2880,
              "areaName": "隆子县",
              "areaCode": null,
              "areaShort": "Longzi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542232,
              "id": 2881,
              "areaName": "错那县",
              "areaCode": null,
              "areaShort": "Cuona",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542233,
              "id": 2882,
              "areaName": "浪卡子县",
              "areaCode": null,
              "areaShort": "Langkazi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 542400,
          "id": 2883,
          "areaName": "那曲地区",
          "areaCode": "0896",
          "areaShort": "Nagqu",
          "areaInitials": null,
          "cityCode": "101",
          "center": "92.052064,31.476479",
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 542421,
              "id": 2884,
              "areaName": "那曲县",
              "areaCode": "0896",
              "areaShort": "Naqu",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "92.0535,31.469643",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542422,
              "id": 2885,
              "areaName": "嘉黎县",
              "areaCode": "0896",
              "areaShort": "Jiali",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "93.232528,30.640814",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542423,
              "id": 2886,
              "areaName": "比如县",
              "areaCode": "0896",
              "areaShort": "Biru",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "93.679639,31.480249",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542424,
              "id": 2887,
              "areaName": "聂荣县",
              "areaCode": "0896",
              "areaShort": "Nierong",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "92.303377,32.10775",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542425,
              "id": 2888,
              "areaName": "安多县",
              "areaCode": "0896",
              "areaShort": "Anduo",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "91.68233,32.265176",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542426,
              "id": 2889,
              "areaName": "申扎县",
              "areaCode": "0896",
              "areaShort": "Shenzha",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "88.709852,30.930505",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542427,
              "id": 2890,
              "areaName": "索县",
              "areaCode": "0896",
              "areaShort": "Suoxian",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "93.785516,31.886671",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542428,
              "id": 2891,
              "areaName": "班戈县",
              "areaCode": "0896",
              "areaShort": "Bange",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "90.009957,31.392411",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542429,
              "id": 2892,
              "areaName": "巴青县",
              "areaCode": "0896",
              "areaShort": "Baqing",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "94.053438,31.91847",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542430,
              "id": 2893,
              "areaName": "尼玛县",
              "areaCode": "0896",
              "areaShort": "Nima",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "87.236772,31.784701",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542431,
              "id": 2894,
              "areaName": "双湖县",
              "areaCode": "0896",
              "areaShort": "Shuanghu",
              "areaInitials": null,
              "cityCode": "0896",
              "center": "88.837641,33.188514",
              "areaLevel": 3,
              "areaParentId": 542400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 542500,
          "id": 2895,
          "areaName": "阿里地区",
          "areaCode": "0897",
          "areaShort": "Ngari",
          "areaInitials": null,
          "cityCode": "103",
          "center": "80.105804,32.501111",
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 542521,
              "id": 2896,
              "areaName": "普兰县",
              "areaCode": "0897",
              "areaShort": "Pulan",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "81.176237,30.294402",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542522,
              "id": 2897,
              "areaName": "札达县",
              "areaCode": "0897",
              "areaShort": "Zhada",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "79.802706,31.479216",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542523,
              "id": 2898,
              "areaName": "噶尔县",
              "areaCode": "0897",
              "areaShort": "Gaer",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "80.096419,32.491488",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542524,
              "id": 2899,
              "areaName": "日土县",
              "areaCode": "0897",
              "areaShort": "Ritu",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "79.732427,33.381359",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542525,
              "id": 2900,
              "areaName": "革吉县",
              "areaCode": "0897",
              "areaShort": "Geji",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "81.145433,32.387233",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542526,
              "id": 2901,
              "areaName": "改则县",
              "areaCode": "0897",
              "areaShort": "Gaize",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "84.06259,32.302713",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542527,
              "id": 2902,
              "areaName": "措勤县",
              "areaCode": "0897",
              "areaShort": "Cuoqin",
              "areaInitials": null,
              "cityCode": "0897",
              "center": "85.151455,31.017312",
              "areaLevel": 3,
              "areaParentId": 542500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 542600,
          "id": 2903,
          "areaName": "林芝地区",
          "areaCode": null,
          "areaShort": "Nyingchi",
          "areaInitials": null,
          "cityCode": "98",
          "center": null,
          "areaLevel": 2,
          "areaParentId": 540000,
          "areaDictionaryVOList": [
            {
              "areaId": 542621,
              "id": 2904,
              "areaName": "林芝县",
              "areaCode": null,
              "areaShort": "Linzhi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542622,
              "id": 2905,
              "areaName": "工布江达县",
              "areaCode": null,
              "areaShort": "Gongbujiangda",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542623,
              "id": 2906,
              "areaName": "米林县",
              "areaCode": null,
              "areaShort": "Milin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542624,
              "id": 2907,
              "areaName": "墨脱县",
              "areaCode": null,
              "areaShort": "Motuo",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542625,
              "id": 2908,
              "areaName": "波密县",
              "areaCode": null,
              "areaShort": "Bomi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542626,
              "id": 2909,
              "areaName": "察隅县",
              "areaCode": null,
              "areaShort": "Chayu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 542627,
              "id": 2910,
              "areaName": "朗县",
              "areaCode": null,
              "areaShort": "Langxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 542600,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 610000,
      "id": 2911,
      "areaName": "陕西省",
      "areaCode": "[]",
      "areaShort": "Shaanxi",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "108.954347,34.265502",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 610100,
          "id": 2912,
          "areaName": "西安市",
          "areaCode": "029",
          "areaShort": "Xi'an",
          "areaInitials": null,
          "cityCode": "233",
          "center": "108.93977,34.341574",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610102,
              "id": 2913,
              "areaName": "新城区",
              "areaCode": "029",
              "areaShort": "Xincheng",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.960716,34.266447",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610103,
              "id": 2914,
              "areaName": "碑林区",
              "areaCode": "029",
              "areaShort": "Beilin",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.94059,34.256783",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610104,
              "id": 2915,
              "areaName": "莲湖区",
              "areaCode": "029",
              "areaShort": "Lianhu",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.943895,34.265239",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610111,
              "id": 2916,
              "areaName": "灞桥区",
              "areaCode": "029",
              "areaShort": "Baqiao",
              "areaInitials": null,
              "cityCode": "029",
              "center": "109.064646,34.272793",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610112,
              "id": 2917,
              "areaName": "未央区",
              "areaCode": "029",
              "areaShort": "Weiyang",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.946825,34.29292",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610113,
              "id": 2918,
              "areaName": "雁塔区",
              "areaCode": "029",
              "areaShort": "Yanta",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.944644,34.214113",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610114,
              "id": 2919,
              "areaName": "阎良区",
              "areaCode": "029",
              "areaShort": "Yanliang",
              "areaInitials": null,
              "cityCode": "029",
              "center": "109.226124,34.662232",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610115,
              "id": 2920,
              "areaName": "临潼区",
              "areaCode": "029",
              "areaShort": "Lintong",
              "areaInitials": null,
              "cityCode": "029",
              "center": "109.214237,34.367069",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610116,
              "id": 2921,
              "areaName": "长安区",
              "areaCode": "029",
              "areaShort": "Chang'an",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.907173,34.158926",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610122,
              "id": 2922,
              "areaName": "蓝田县",
              "areaCode": "029",
              "areaShort": "Lantian",
              "areaInitials": null,
              "cityCode": "029",
              "center": "109.32345,34.151298",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610124,
              "id": 2923,
              "areaName": "周至县",
              "areaCode": "029",
              "areaShort": "Zhouzhi",
              "areaInitials": null,
              "cityCode": "029",
              "center": "108.222162,34.163669",
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610125,
              "id": 2924,
              "areaName": "户县",
              "areaCode": null,
              "areaShort": "Huxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610126,
              "id": 2925,
              "areaName": "高陵区",
              "areaCode": null,
              "areaShort": "Gaoling",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 610100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610200,
          "id": 2926,
          "areaName": "铜川市",
          "areaCode": "0919",
          "areaShort": "Tongchuan",
          "areaInitials": null,
          "cityCode": "232",
          "center": "108.945019,34.897887",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610202,
              "id": 2927,
              "areaName": "王益区",
              "areaCode": "0919",
              "areaShort": "Wangyi",
              "areaInitials": null,
              "cityCode": "0919",
              "center": "109.075578,35.068964",
              "areaLevel": 3,
              "areaParentId": 610200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610203,
              "id": 2928,
              "areaName": "印台区",
              "areaCode": "0919",
              "areaShort": "Yintai",
              "areaInitials": null,
              "cityCode": "0919",
              "center": "109.099974,35.114492",
              "areaLevel": 3,
              "areaParentId": 610200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610204,
              "id": 2929,
              "areaName": "耀州区",
              "areaCode": "0919",
              "areaShort": "Yaozhou",
              "areaInitials": null,
              "cityCode": "0919",
              "center": "108.980102,34.909793",
              "areaLevel": 3,
              "areaParentId": 610200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610222,
              "id": 2930,
              "areaName": "宜君县",
              "areaCode": "0919",
              "areaShort": "Yijun",
              "areaInitials": null,
              "cityCode": "0919",
              "center": "109.116932,35.398577",
              "areaLevel": 3,
              "areaParentId": 610200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610300,
          "id": 2931,
          "areaName": "宝鸡市",
          "areaCode": "0917",
          "areaShort": "Baoji",
          "areaInitials": null,
          "cityCode": "171",
          "center": "107.237743,34.363184",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610302,
              "id": 2932,
              "areaName": "渭滨区",
              "areaCode": "0917",
              "areaShort": "Weibin",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.155344,34.355068",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610303,
              "id": 2933,
              "areaName": "金台区",
              "areaCode": "0917",
              "areaShort": "Jintai",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.146806,34.376069",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610304,
              "id": 2934,
              "areaName": "陈仓区",
              "areaCode": "0917",
              "areaShort": "Chencang",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.369987,34.35147",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610322,
              "id": 2935,
              "areaName": "凤翔县",
              "areaCode": "0917",
              "areaShort": "Fengxiang",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.400737,34.521217",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610323,
              "id": 2936,
              "areaName": "岐山县",
              "areaCode": "0917",
              "areaShort": "Qishan",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.621053,34.443459",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610324,
              "id": 2937,
              "areaName": "扶风县",
              "areaCode": "0917",
              "areaShort": "Fufeng",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.900219,34.37541",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610326,
              "id": 2938,
              "areaName": "眉县",
              "areaCode": "0917",
              "areaShort": "Meixian",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.749766,34.274246",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610327,
              "id": 2939,
              "areaName": "陇县",
              "areaCode": "0917",
              "areaShort": "Longxian",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "106.864397,34.89305",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610328,
              "id": 2940,
              "areaName": "千阳县",
              "areaCode": "0917",
              "areaShort": "Qianyang",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.132441,34.642381",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610329,
              "id": 2941,
              "areaName": "麟游县",
              "areaCode": "0917",
              "areaShort": "Linyou",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.793524,34.677902",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610330,
              "id": 2942,
              "areaName": "凤县",
              "areaCode": "0917",
              "areaShort": "Fengxian",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "106.515803,33.91091",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610331,
              "id": 2943,
              "areaName": "太白县",
              "areaCode": "0917",
              "areaShort": "Taibai",
              "areaInitials": null,
              "cityCode": "0917",
              "center": "107.319116,34.058401",
              "areaLevel": 3,
              "areaParentId": 610300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610400,
          "id": 2944,
          "areaName": "咸阳市",
          "areaCode": "0910",
          "areaShort": "Xianyang",
          "areaInitials": null,
          "cityCode": "323",
          "center": "108.709136,34.32987",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610402,
              "id": 2945,
              "areaName": "秦都区",
              "areaCode": "0910",
              "areaShort": "Qindu",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.706272,34.329567",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610403,
              "id": 2946,
              "areaName": "杨陵区",
              "areaCode": "0910",
              "areaShort": "Yangling",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.084731,34.272117",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610404,
              "id": 2947,
              "areaName": "渭城区",
              "areaCode": "0910",
              "areaShort": "Weicheng",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.737204,34.36195",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610422,
              "id": 2948,
              "areaName": "三原县",
              "areaCode": "0910",
              "areaShort": "Sanyuan",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.940509,34.617381",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610423,
              "id": 2949,
              "areaName": "泾阳县",
              "areaCode": "0910",
              "areaShort": "Jingyang",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.842622,34.527114",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610424,
              "id": 2950,
              "areaName": "乾县",
              "areaCode": "0910",
              "areaShort": "Qianxian",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.239473,34.527551",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610425,
              "id": 2951,
              "areaName": "礼泉县",
              "areaCode": "0910",
              "areaShort": "Liquan",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.425018,34.481764",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610426,
              "id": 2952,
              "areaName": "永寿县",
              "areaCode": "0910",
              "areaShort": "Yongshou",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.142311,34.691979",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610427,
              "id": 2953,
              "areaName": "彬县",
              "areaCode": "0910",
              "areaShort": "Binxian",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.077658,35.043911",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610428,
              "id": 2954,
              "areaName": "长武县",
              "areaCode": "0910",
              "areaShort": "Changwu",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "107.798757,35.205886",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610429,
              "id": 2955,
              "areaName": "旬邑县",
              "areaCode": "0910",
              "areaShort": "Xunyi",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.333986,35.111978",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610430,
              "id": 2956,
              "areaName": "淳化县",
              "areaCode": "0910",
              "areaShort": "Chunhua",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.580681,34.79925",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610431,
              "id": 2957,
              "areaName": "武功县",
              "areaCode": "0910",
              "areaShort": "Wugong",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.200398,34.260203",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610481,
              "id": 2958,
              "areaName": "兴平市",
              "areaCode": "0910",
              "areaShort": "Xingping",
              "areaInitials": null,
              "cityCode": "0910",
              "center": "108.490475,34.29922",
              "areaLevel": 3,
              "areaParentId": 610400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610500,
          "id": 2959,
          "areaName": "渭南市",
          "areaCode": "0913",
          "areaShort": "Weinan",
          "areaInitials": null,
          "cityCode": "170",
          "center": "109.471094,34.52044",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610502,
              "id": 2960,
              "areaName": "临渭区",
              "areaCode": "0913",
              "areaShort": "Linwei",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "109.510175,34.499314",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610521,
              "id": 2961,
              "areaName": "华县",
              "areaCode": null,
              "areaShort": "Huaxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610522,
              "id": 2962,
              "areaName": "潼关县",
              "areaCode": "0913",
              "areaShort": "Tongguan",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "110.246349,34.544296",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610523,
              "id": 2963,
              "areaName": "大荔县",
              "areaCode": "0913",
              "areaShort": "Dali",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "109.941734,34.797259",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610524,
              "id": 2964,
              "areaName": "合阳县",
              "areaCode": "0913",
              "areaShort": "Heyang",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "110.149453,35.237988",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610525,
              "id": 2965,
              "areaName": "澄城县",
              "areaCode": "0913",
              "areaShort": "Chengcheng",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "109.93235,35.190245",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610526,
              "id": 2966,
              "areaName": "蒲城县",
              "areaCode": "0913",
              "areaShort": "Pucheng",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "109.586403,34.955562",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610527,
              "id": 2967,
              "areaName": "白水县",
              "areaCode": "0913",
              "areaShort": "Baishui",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "109.590671,35.177451",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610528,
              "id": 2968,
              "areaName": "富平县",
              "areaCode": "0913",
              "areaShort": "Fuping",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "109.18032,34.751077",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610581,
              "id": 2969,
              "areaName": "韩城市",
              "areaCode": "0913",
              "areaShort": "Hancheng",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "110.442846,35.476788",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610582,
              "id": 2970,
              "areaName": "华阴市",
              "areaCode": "0913",
              "areaShort": "Huayin",
              "areaInitials": null,
              "cityCode": "0913",
              "center": "110.092078,34.566079",
              "areaLevel": 3,
              "areaParentId": 610500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610600,
          "id": 2971,
          "areaName": "延安市",
          "areaCode": "0911",
          "areaShort": "Yan'an",
          "areaInitials": null,
          "cityCode": "284",
          "center": "109.494112,36.651381",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610602,
              "id": 2972,
              "areaName": "宝塔区",
              "areaCode": "0911",
              "areaShort": "Baota",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.48976,36.585472",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610621,
              "id": 2973,
              "areaName": "延长县",
              "areaCode": "0911",
              "areaShort": "Yanchang",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "110.012334,36.579313",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610622,
              "id": 2974,
              "areaName": "延川县",
              "areaCode": "0911",
              "areaShort": "Yanchuan",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "110.193514,36.878117",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610623,
              "id": 2975,
              "areaName": "子长县",
              "areaCode": "0911",
              "areaShort": "Zichang",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.675264,37.142535",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610624,
              "id": 2976,
              "areaName": "安塞县",
              "areaCode": null,
              "areaShort": "Ansai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610625,
              "id": 2977,
              "areaName": "志丹县",
              "areaCode": "0911",
              "areaShort": "Zhidan",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "108.768432,36.822194",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610626,
              "id": 2978,
              "areaName": "吴起县",
              "areaCode": "0911",
              "areaShort": "Wuqi",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "108.175933,36.927215",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610627,
              "id": 2979,
              "areaName": "甘泉县",
              "areaCode": "0911",
              "areaShort": "Ganquan",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.351019,36.276526",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610628,
              "id": 2980,
              "areaName": "富县",
              "areaCode": "0911",
              "areaShort": "Fuxian",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.379776,35.987953",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610629,
              "id": 2981,
              "areaName": "洛川县",
              "areaCode": "0911",
              "areaShort": "Luochuan",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.432369,35.761974",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610630,
              "id": 2982,
              "areaName": "宜川县",
              "areaCode": "0911",
              "areaShort": "Yichuan",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "110.168963,36.050178",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610631,
              "id": 2983,
              "areaName": "黄龙县",
              "areaCode": "0911",
              "areaShort": "Huanglong",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.840314,35.584743",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610632,
              "id": 2984,
              "areaName": "黄陵县",
              "areaCode": "0911",
              "areaShort": "Huangling",
              "areaInitials": null,
              "cityCode": "0911",
              "center": "109.262961,35.579427",
              "areaLevel": 3,
              "areaParentId": 610600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610700,
          "id": 2985,
          "areaName": "汉中市",
          "areaCode": "0916",
          "areaShort": "Hanzhong",
          "areaInitials": null,
          "cityCode": "352",
          "center": "107.02305,33.067225",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610702,
              "id": 2986,
              "areaName": "汉台区",
              "areaCode": "0916",
              "areaShort": "Hantai",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "107.031856,33.067771",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610721,
              "id": 2987,
              "areaName": "南郑县",
              "areaCode": "0916",
              "areaShort": "Nanzheng",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "106.93623,32.999333",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610722,
              "id": 2988,
              "areaName": "城固县",
              "areaCode": "0916",
              "areaShort": "Chenggu",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "107.33393,33.157131",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610723,
              "id": 2989,
              "areaName": "洋县",
              "areaCode": "0916",
              "areaShort": "Yangxian",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "107.545836,33.222738",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610724,
              "id": 2990,
              "areaName": "西乡县",
              "areaCode": "0916",
              "areaShort": "Xixiang",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "107.766613,32.983101",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610725,
              "id": 2991,
              "areaName": "勉县",
              "areaCode": "0916",
              "areaShort": "Mianxian",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "106.673221,33.153553",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610726,
              "id": 2992,
              "areaName": "宁强县",
              "areaCode": "0916",
              "areaShort": "Ningqiang",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "106.257171,32.829694",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610727,
              "id": 2993,
              "areaName": "略阳县",
              "areaCode": "0916",
              "areaShort": "Lueyang",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "106.156718,33.327281",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610728,
              "id": 2994,
              "areaName": "镇巴县",
              "areaCode": "0916",
              "areaShort": "Zhenba",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "107.895035,32.536704",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610729,
              "id": 2995,
              "areaName": "留坝县",
              "areaCode": "0916",
              "areaShort": "Liuba",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "106.920808,33.617571",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610730,
              "id": 2996,
              "areaName": "佛坪县",
              "areaCode": "0916",
              "areaShort": "Foping",
              "areaInitials": null,
              "cityCode": "0916",
              "center": "107.990538,33.524359",
              "areaLevel": 3,
              "areaParentId": 610700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610800,
          "id": 2997,
          "areaName": "榆林市",
          "areaCode": "0912",
          "areaShort": "Yulin",
          "areaInitials": null,
          "cityCode": "231",
          "center": "109.734474,38.285369",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610802,
              "id": 2998,
              "areaName": "榆阳区",
              "areaCode": "0912",
              "areaShort": "Yuyang",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "109.721069,38.277046",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610821,
              "id": 2999,
              "areaName": "神木县",
              "areaCode": null,
              "areaShort": "Shenmu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610822,
              "id": 3000,
              "areaName": "府谷县",
              "areaCode": "0912",
              "areaShort": "Fugu",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "111.067276,39.028116",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610823,
              "id": 3001,
              "areaName": "横山县",
              "areaCode": null,
              "areaShort": "Hengshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610824,
              "id": 3002,
              "areaName": "靖边县",
              "areaCode": "0912",
              "areaShort": "Jingbian",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "108.793988,37.599438",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610825,
              "id": 3003,
              "areaName": "定边县",
              "areaCode": "0912",
              "areaShort": "Dingbian",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "107.601267,37.594612",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610826,
              "id": 3004,
              "areaName": "绥德县",
              "areaCode": "0912",
              "areaShort": "Suide",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "110.263362,37.50294",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610827,
              "id": 3005,
              "areaName": "米脂县",
              "areaCode": "0912",
              "areaShort": "Mizhi",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "110.183754,37.755416",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610828,
              "id": 3006,
              "areaName": "佳县",
              "areaCode": "0912",
              "areaShort": "Jiaxian",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "110.491345,38.01951",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610829,
              "id": 3007,
              "areaName": "吴堡县",
              "areaCode": "0912",
              "areaShort": "Wubu",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "110.739673,37.452067",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610830,
              "id": 3008,
              "areaName": "清涧县",
              "areaCode": "0912",
              "areaShort": "Qingjian",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "110.121209,37.088878",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610831,
              "id": 3009,
              "areaName": "子洲县",
              "areaCode": "0912",
              "areaShort": "Zizhou",
              "areaInitials": null,
              "cityCode": "0912",
              "center": "110.03525,37.610683",
              "areaLevel": 3,
              "areaParentId": 610800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 610900,
          "id": 3010,
          "areaName": "安康市",
          "areaCode": "0915",
          "areaShort": "Ankang",
          "areaInitials": null,
          "cityCode": "324",
          "center": "109.029113,32.68481",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 610902,
              "id": 3011,
              "areaName": "汉滨区",
              "areaCode": "0915",
              "areaShort": "Hanbin",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "109.026836,32.695172",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610921,
              "id": 3012,
              "areaName": "汉阴县",
              "areaCode": "0915",
              "areaShort": "Hanyin",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "108.508745,32.893026",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610922,
              "id": 3013,
              "areaName": "石泉县",
              "areaCode": "0915",
              "areaShort": "Shiquan",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "108.247886,33.038408",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610923,
              "id": 3014,
              "areaName": "宁陕县",
              "areaCode": "0915",
              "areaShort": "Ningshan",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "108.314283,33.310527",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610924,
              "id": 3015,
              "areaName": "紫阳县",
              "areaCode": "0915",
              "areaShort": "Ziyang",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "108.534228,32.520246",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610925,
              "id": 3016,
              "areaName": "岚皋县",
              "areaCode": "0915",
              "areaShort": "Langao",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "108.902049,32.307001",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610926,
              "id": 3017,
              "areaName": "平利县",
              "areaCode": "0915",
              "areaShort": "Pingli",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "109.361864,32.388854",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610927,
              "id": 3018,
              "areaName": "镇坪县",
              "areaCode": "0915",
              "areaShort": "Zhenping",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "109.526873,31.883672",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610928,
              "id": 3019,
              "areaName": "旬阳县",
              "areaCode": "0915",
              "areaShort": "Xunyang",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "109.361024,32.832012",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 610929,
              "id": 3020,
              "areaName": "白河县",
              "areaCode": "0915",
              "areaShort": "Baihe",
              "areaInitials": null,
              "cityCode": "0915",
              "center": "110.112629,32.809026",
              "areaLevel": 3,
              "areaParentId": 610900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 611000,
          "id": 3021,
          "areaName": "商洛市",
          "areaCode": "0914",
          "areaShort": "Shangluo",
          "areaInitials": null,
          "cityCode": "285",
          "center": "109.91857,33.872726",
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 611002,
              "id": 3022,
              "areaName": "商州区",
              "areaCode": "0914",
              "areaShort": "Shangzhou",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "109.941839,33.862599",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611021,
              "id": 3023,
              "areaName": "洛南县",
              "areaCode": "0914",
              "areaShort": "Luonan",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "110.148508,34.090837",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611022,
              "id": 3024,
              "areaName": "丹凤县",
              "areaCode": "0914",
              "areaShort": "Danfeng",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "110.32733,33.695783",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611023,
              "id": 3025,
              "areaName": "商南县",
              "areaCode": "0914",
              "areaShort": "Shangnan",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "110.881807,33.530995",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611024,
              "id": 3026,
              "areaName": "山阳县",
              "areaCode": "0914",
              "areaShort": "Shanyang",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "109.882289,33.532172",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611025,
              "id": 3027,
              "areaName": "镇安县",
              "areaCode": "0914",
              "areaShort": "Zhen'an",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "109.152892,33.423357",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611026,
              "id": 3028,
              "areaName": "柞水县",
              "areaCode": "0914",
              "areaShort": "Zhashui",
              "areaInitials": null,
              "cityCode": "0914",
              "center": "109.114206,33.68611",
              "areaLevel": 3,
              "areaParentId": 611000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 611100,
          "id": 3029,
          "areaName": "西咸新区",
          "areaCode": null,
          "areaShort": "Xixian",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 610000,
          "areaDictionaryVOList": [
            {
              "areaId": 611101,
              "id": 3030,
              "areaName": "空港新城",
              "areaCode": null,
              "areaShort": "Konggang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 611100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611102,
              "id": 3031,
              "areaName": "沣东新城",
              "areaCode": null,
              "areaShort": "Fengdong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 611100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611103,
              "id": 3032,
              "areaName": "秦汉新城",
              "areaCode": null,
              "areaShort": "Qinhan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 611100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611104,
              "id": 3033,
              "areaName": "沣西新城",
              "areaCode": null,
              "areaShort": "Fengxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 611100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 611105,
              "id": 3034,
              "areaName": "泾河新城",
              "areaCode": null,
              "areaShort": "Jinghe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 611100,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 620000,
      "id": 3035,
      "areaName": "甘肃省",
      "areaCode": "[]",
      "areaShort": "Gansu",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "103.826447,36.05956",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 620100,
          "id": 3036,
          "areaName": "兰州市",
          "areaCode": "0931",
          "areaShort": "Lanzhou",
          "areaInitials": null,
          "cityCode": "36",
          "center": "103.834303,36.061089",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620102,
              "id": 3037,
              "areaName": "城关区",
              "areaCode": "0931",
              "areaShort": "Chengguan",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "103.825307,36.057464",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620103,
              "id": 3038,
              "areaName": "七里河区",
              "areaCode": "0931",
              "areaShort": "Qilihe",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "103.785949,36.066146",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620104,
              "id": 3039,
              "areaName": "西固区",
              "areaCode": "0931",
              "areaShort": "Xigu",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "103.627951,36.088552",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620105,
              "id": 3040,
              "areaName": "安宁区",
              "areaCode": "0931",
              "areaShort": "Anning",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "103.719054,36.104579",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620111,
              "id": 3041,
              "areaName": "红古区",
              "areaCode": "0931",
              "areaShort": "Honggu",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "102.859323,36.345669",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620121,
              "id": 3042,
              "areaName": "永登县",
              "areaCode": "0931",
              "areaShort": "Yongdeng",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "103.26038,36.736513",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620122,
              "id": 3043,
              "areaName": "皋兰县",
              "areaCode": "0931",
              "areaShort": "Gaolan",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "103.947377,36.332663",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620123,
              "id": 3044,
              "areaName": "榆中县",
              "areaCode": "0931",
              "areaShort": "Yuzhong",
              "areaInitials": null,
              "cityCode": "0931",
              "center": "104.112527,35.843056",
              "areaLevel": 3,
              "areaParentId": 620100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620200,
          "id": 3045,
          "areaName": "嘉峪关市",
          "areaCode": "1937",
          "areaShort": "Jiayuguan",
          "areaInitials": null,
          "cityCode": "33",
          "center": "98.289419,39.772554",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620201,
              "id": 3046,
              "areaName": "雄关区",
              "areaCode": null,
              "areaShort": "Xiongguan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 620200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620202,
              "id": 3047,
              "areaName": "长城区",
              "areaCode": null,
              "areaShort": "Changcheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 620200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620203,
              "id": 3048,
              "areaName": "镜铁区",
              "areaCode": null,
              "areaShort": "Jingtie",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 620200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620300,
          "id": 3049,
          "areaName": "金昌市",
          "areaCode": "0935",
          "areaShort": "Jinchang",
          "areaInitials": null,
          "cityCode": "34",
          "center": "102.188117,38.520717",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620302,
              "id": 3050,
              "areaName": "金川区",
              "areaCode": "0935",
              "areaShort": "Jinchuan",
              "areaInitials": null,
              "cityCode": "0935",
              "center": "102.194015,38.521087",
              "areaLevel": 3,
              "areaParentId": 620300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620321,
              "id": 3051,
              "areaName": "永昌县",
              "areaCode": "0935",
              "areaShort": "Yongchang",
              "areaInitials": null,
              "cityCode": "0935",
              "center": "101.984458,38.243434",
              "areaLevel": 3,
              "areaParentId": 620300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620400,
          "id": 3052,
          "areaName": "白银市",
          "areaCode": "0943",
          "areaShort": "Baiyin",
          "areaInitials": null,
          "cityCode": "35",
          "center": "104.138771,36.545261",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620402,
              "id": 3053,
              "areaName": "白银区",
              "areaCode": "0943",
              "areaShort": "Baiyin",
              "areaInitials": null,
              "cityCode": "0943",
              "center": "104.148556,36.535398",
              "areaLevel": 3,
              "areaParentId": 620400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620403,
              "id": 3054,
              "areaName": "平川区",
              "areaCode": "0943",
              "areaShort": "Pingchuan",
              "areaInitials": null,
              "cityCode": "0943",
              "center": "104.825208,36.728304",
              "areaLevel": 3,
              "areaParentId": 620400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620421,
              "id": 3055,
              "areaName": "靖远县",
              "areaCode": "0943",
              "areaShort": "Jingyuan",
              "areaInitials": null,
              "cityCode": "0943",
              "center": "104.676774,36.571365",
              "areaLevel": 3,
              "areaParentId": 620400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620422,
              "id": 3056,
              "areaName": "会宁县",
              "areaCode": "0943",
              "areaShort": "Huining",
              "areaInitials": null,
              "cityCode": "0943",
              "center": "105.053358,35.692823",
              "areaLevel": 3,
              "areaParentId": 620400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620423,
              "id": 3057,
              "areaName": "景泰县",
              "areaCode": "0943",
              "areaShort": "Jingtai",
              "areaInitials": null,
              "cityCode": "0943",
              "center": "104.063091,37.183804",
              "areaLevel": 3,
              "areaParentId": 620400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620500,
          "id": 3058,
          "areaName": "天水市",
          "areaCode": "0938",
          "areaShort": "Tianshui",
          "areaInitials": null,
          "cityCode": "196",
          "center": "105.724979,34.580885",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620502,
              "id": 3059,
              "areaName": "秦州区",
              "areaCode": "0938",
              "areaShort": "Qinzhou",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "105.724215,34.580888",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620503,
              "id": 3060,
              "areaName": "麦积区",
              "areaCode": "0938",
              "areaShort": "Maiji",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "105.889556,34.570384",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620521,
              "id": 3061,
              "areaName": "清水县",
              "areaCode": "0938",
              "areaShort": "Qingshui",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "106.137293,34.749864",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620522,
              "id": 3062,
              "areaName": "秦安县",
              "areaCode": "0938",
              "areaShort": "Qin'an",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "105.674982,34.858916",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620523,
              "id": 3063,
              "areaName": "甘谷县",
              "areaCode": "0938",
              "areaShort": "Gangu",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "105.340747,34.745486",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620524,
              "id": 3064,
              "areaName": "武山县",
              "areaCode": "0938",
              "areaShort": "Wushan",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "104.890587,34.72139",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620525,
              "id": 3065,
              "areaName": "张家川回族自治县",
              "areaCode": "0938",
              "areaShort": "Zhangjiachuan",
              "areaInitials": null,
              "cityCode": "0938",
              "center": "106.204517,34.988037",
              "areaLevel": 3,
              "areaParentId": 620500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620600,
          "id": 3066,
          "areaName": "武威市",
          "areaCode": "1935",
          "areaShort": "Wuwei",
          "areaInitials": null,
          "cityCode": "118",
          "center": "102.638201,37.928267",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620602,
              "id": 3067,
              "areaName": "凉州区",
              "areaCode": "1935",
              "areaShort": "Liangzhou",
              "areaInitials": null,
              "cityCode": "1935",
              "center": "102.642184,37.928224",
              "areaLevel": 3,
              "areaParentId": 620600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620621,
              "id": 3068,
              "areaName": "民勤县",
              "areaCode": "1935",
              "areaShort": "Minqin",
              "areaInitials": null,
              "cityCode": "1935",
              "center": "103.093791,38.62435",
              "areaLevel": 3,
              "areaParentId": 620600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620622,
              "id": 3069,
              "areaName": "古浪县",
              "areaCode": "1935",
              "areaShort": "Gulang",
              "areaInitials": null,
              "cityCode": "1935",
              "center": "102.897533,37.47012",
              "areaLevel": 3,
              "areaParentId": 620600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620623,
              "id": 3070,
              "areaName": "天祝藏族自治县",
              "areaCode": "1935",
              "areaShort": "Tianzhu",
              "areaInitials": null,
              "cityCode": "1935",
              "center": "103.141757,36.97174",
              "areaLevel": 3,
              "areaParentId": 620600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620700,
          "id": 3071,
          "areaName": "张掖市",
          "areaCode": "0936",
          "areaShort": "Zhangye",
          "areaInitials": null,
          "cityCode": "117",
          "center": "100.449913,38.925548",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620702,
              "id": 3072,
              "areaName": "甘州区",
              "areaCode": "0936",
              "areaShort": "Ganzhou",
              "areaInitials": null,
              "cityCode": "0936",
              "center": "100.415096,38.944662",
              "areaLevel": 3,
              "areaParentId": 620700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620721,
              "id": 3073,
              "areaName": "肃南裕固族自治县",
              "areaCode": "0936",
              "areaShort": "Sunan",
              "areaInitials": null,
              "cityCode": "0936",
              "center": "99.615601,38.836931",
              "areaLevel": 3,
              "areaParentId": 620700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620722,
              "id": 3074,
              "areaName": "民乐县",
              "areaCode": "0936",
              "areaShort": "Minle",
              "areaInitials": null,
              "cityCode": "0936",
              "center": "100.812629,38.430347",
              "areaLevel": 3,
              "areaParentId": 620700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620723,
              "id": 3075,
              "areaName": "临泽县",
              "areaCode": "0936",
              "areaShort": "Linze",
              "areaInitials": null,
              "cityCode": "0936",
              "center": "100.164283,39.152462",
              "areaLevel": 3,
              "areaParentId": 620700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620724,
              "id": 3076,
              "areaName": "高台县",
              "areaCode": "0936",
              "areaShort": "Gaotai",
              "areaInitials": null,
              "cityCode": "0936",
              "center": "99.819519,39.378311",
              "areaLevel": 3,
              "areaParentId": 620700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620725,
              "id": 3077,
              "areaName": "山丹县",
              "areaCode": "0936",
              "areaShort": "Shandan",
              "areaInitials": null,
              "cityCode": "0936",
              "center": "101.088529,38.784505",
              "areaLevel": 3,
              "areaParentId": 620700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620800,
          "id": 3078,
          "areaName": "平凉市",
          "areaCode": "0933",
          "areaShort": "Pingliang",
          "areaInitials": null,
          "cityCode": "359",
          "center": "106.665061,35.542606",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620802,
              "id": 3079,
              "areaName": "崆峒区",
              "areaCode": "0933",
              "areaShort": "Kongtong",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "106.674767,35.542491",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620821,
              "id": 3080,
              "areaName": "泾川县",
              "areaCode": "0933",
              "areaShort": "Jingchuan",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "107.36785,35.332666",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620822,
              "id": 3081,
              "areaName": "灵台县",
              "areaCode": "0933",
              "areaShort": "Lingtai",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "107.595874,35.070027",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620823,
              "id": 3082,
              "areaName": "崇信县",
              "areaCode": "0933",
              "areaShort": "Chongxin",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "107.025763,35.305596",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620824,
              "id": 3083,
              "areaName": "华亭县",
              "areaCode": "0933",
              "areaShort": "Huating",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "106.653158,35.218292",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620825,
              "id": 3084,
              "areaName": "庄浪县",
              "areaCode": "0933",
              "areaShort": "Zhuanglang",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "106.036686,35.202385",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620826,
              "id": 3085,
              "areaName": "静宁县",
              "areaCode": "0933",
              "areaShort": "Jingning",
              "areaInitials": null,
              "cityCode": "0933",
              "center": "105.732556,35.521976",
              "areaLevel": 3,
              "areaParentId": 620800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 620900,
          "id": 3086,
          "areaName": "酒泉市",
          "areaCode": "0937",
          "areaShort": "Jiuquan",
          "areaInitials": null,
          "cityCode": "37",
          "center": "98.493927,39.732795",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 620902,
              "id": 3087,
              "areaName": "肃州区",
              "areaCode": "0937",
              "areaShort": "Suzhou",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "98.507843,39.744953",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620921,
              "id": 3088,
              "areaName": "金塔县",
              "areaCode": "0937",
              "areaShort": "Jinta",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "98.901252,39.983955",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620922,
              "id": 3089,
              "areaName": "瓜州县",
              "areaCode": "0937",
              "areaShort": "Guazhou",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "95.782318,40.520538",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620923,
              "id": 3090,
              "areaName": "肃北蒙古族自治县",
              "areaCode": "0937",
              "areaShort": "Subei",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "94.876579,39.51245",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620924,
              "id": 3091,
              "areaName": "阿克塞哈萨克族自治县",
              "areaCode": "0937",
              "areaShort": "Akesai",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "94.340204,39.633943",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620981,
              "id": 3092,
              "areaName": "玉门市",
              "areaCode": "0937",
              "areaShort": "Yumen",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "97.045661,40.292106",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 620982,
              "id": 3093,
              "areaName": "敦煌市",
              "areaCode": "0937",
              "areaShort": "Dunhuang",
              "areaInitials": null,
              "cityCode": "0937",
              "center": "94.661941,40.142089",
              "areaLevel": 3,
              "areaParentId": 620900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 621000,
          "id": 3094,
          "areaName": "庆阳市",
          "areaCode": "0934",
          "areaShort": "Qingyang",
          "areaInitials": null,
          "cityCode": "135",
          "center": "107.643571,35.70898",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 621002,
              "id": 3095,
              "areaName": "西峰区",
              "areaCode": "0934",
              "areaShort": "Xifeng",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "107.651077,35.730652",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621021,
              "id": 3096,
              "areaName": "庆城县",
              "areaCode": "0934",
              "areaShort": "Qingcheng",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "107.881802,36.016299",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621022,
              "id": 3097,
              "areaName": "环县",
              "areaCode": "0934",
              "areaShort": "Huanxian",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "107.308501,36.568434",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621023,
              "id": 3098,
              "areaName": "华池县",
              "areaCode": "0934",
              "areaShort": "Huachi",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "107.990062,36.461306",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621024,
              "id": 3099,
              "areaName": "合水县",
              "areaCode": "0934",
              "areaShort": "Heshui",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "108.019554,35.819194",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621025,
              "id": 3100,
              "areaName": "正宁县",
              "areaCode": "0934",
              "areaShort": "Zhengning",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "108.359865,35.49178",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621026,
              "id": 3101,
              "areaName": "宁县",
              "areaCode": "0934",
              "areaShort": "Ningxian",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "107.928371,35.502176",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621027,
              "id": 3102,
              "areaName": "镇原县",
              "areaCode": "0934",
              "areaShort": "Zhenyuan",
              "areaInitials": null,
              "cityCode": "0934",
              "center": "107.200832,35.677462",
              "areaLevel": 3,
              "areaParentId": 621000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 621100,
          "id": 3103,
          "areaName": "定西市",
          "areaCode": "0932",
          "areaShort": "Dingxi",
          "areaInitials": null,
          "cityCode": "136",
          "center": "104.592225,35.606978",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 621102,
              "id": 3104,
              "areaName": "安定区",
              "areaCode": "0932",
              "areaShort": "Anding",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "104.610668,35.580629",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621121,
              "id": 3105,
              "areaName": "通渭县",
              "areaCode": "0932",
              "areaShort": "Tongwei",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "105.24206,35.210831",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621122,
              "id": 3106,
              "areaName": "陇西县",
              "areaCode": "0932",
              "areaShort": "Longxi",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "104.634983,35.00394",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621123,
              "id": 3107,
              "areaName": "渭源县",
              "areaCode": "0932",
              "areaShort": "Weiyuan",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "104.215467,35.136755",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621124,
              "id": 3108,
              "areaName": "临洮县",
              "areaCode": "0932",
              "areaShort": "Lintao",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "103.859565,35.394988",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621125,
              "id": 3109,
              "areaName": "漳县",
              "areaCode": "0932",
              "areaShort": "Zhangxian",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "104.471572,34.848444",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621126,
              "id": 3110,
              "areaName": "岷县",
              "areaCode": "0932",
              "areaShort": "Minxian",
              "areaInitials": null,
              "cityCode": "0932",
              "center": "104.03688,34.438075",
              "areaLevel": 3,
              "areaParentId": 621100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 621200,
          "id": 3111,
          "areaName": "陇南市",
          "areaCode": "2935",
          "areaShort": "Longnan",
          "areaInitials": null,
          "cityCode": "256",
          "center": "104.960851,33.37068",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 621202,
              "id": 3112,
              "areaName": "武都区",
              "areaCode": "2935",
              "areaShort": "Wudu",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "104.926337,33.392211",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621221,
              "id": 3113,
              "areaName": "成县",
              "areaCode": "2935",
              "areaShort": "Chengxian",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "105.742424,33.75061",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621222,
              "id": 3114,
              "areaName": "文县",
              "areaCode": "2935",
              "areaShort": "Wenxian",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "104.683433,32.943815",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621223,
              "id": 3115,
              "areaName": "宕昌县",
              "areaCode": "2935",
              "areaShort": "Dangchang",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "104.393385,34.047261",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621224,
              "id": 3116,
              "areaName": "康县",
              "areaCode": "2935",
              "areaShort": "Kangxian",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "105.609169,33.329136",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621225,
              "id": 3117,
              "areaName": "西和县",
              "areaCode": "2935",
              "areaShort": "Xihe",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "105.298756,34.014215",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621226,
              "id": 3118,
              "areaName": "礼县",
              "areaCode": "2935",
              "areaShort": "Lixian",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "105.17864,34.189345",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621227,
              "id": 3119,
              "areaName": "徽县",
              "areaCode": "2935",
              "areaShort": "Huixian",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "106.08778,33.768826",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 621228,
              "id": 3120,
              "areaName": "两当县",
              "areaCode": "2935",
              "areaShort": "Liangdang",
              "areaInitials": null,
              "cityCode": "2935",
              "center": "106.304966,33.908917",
              "areaLevel": 3,
              "areaParentId": 621200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 622900,
          "id": 3121,
          "areaName": "临夏回族自治州",
          "areaCode": "0930",
          "areaShort": "Linxia",
          "areaInitials": null,
          "cityCode": "182",
          "center": "103.210655,35.601352",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 622901,
              "id": 3122,
              "areaName": "临夏市",
              "areaCode": "0930",
              "areaShort": "Linxia",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.243021,35.604376",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622921,
              "id": 3123,
              "areaName": "临夏县",
              "areaCode": "0930",
              "areaShort": "Linxia",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.039826,35.478722",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622922,
              "id": 3124,
              "areaName": "康乐县",
              "areaCode": "0930",
              "areaShort": "Kangle",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.708354,35.370505",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622923,
              "id": 3125,
              "areaName": "永靖县",
              "areaCode": "0930",
              "areaShort": "Yongjing",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.285853,35.958306",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622924,
              "id": 3126,
              "areaName": "广河县",
              "areaCode": "0930",
              "areaShort": "Guanghe",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.575834,35.488051",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622925,
              "id": 3127,
              "areaName": "和政县",
              "areaCode": "0930",
              "areaShort": "Hezheng",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.350997,35.424603",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622926,
              "id": 3128,
              "areaName": "东乡族自治县",
              "areaCode": "0930",
              "areaShort": "Dongxiangzu",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "103.389346,35.663752",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 622927,
              "id": 3129,
              "areaName": "积石山保安族东乡族撒拉族自治县",
              "areaCode": "0930",
              "areaShort": "Jishishan",
              "areaInitials": null,
              "cityCode": "0930",
              "center": "102.875843,35.71766",
              "areaLevel": 3,
              "areaParentId": 622900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 623000,
          "id": 3130,
          "areaName": "甘南藏族自治州",
          "areaCode": "0941",
          "areaShort": "Gannan",
          "areaInitials": null,
          "cityCode": "247",
          "center": "102.910995,34.983409",
          "areaLevel": 2,
          "areaParentId": 620000,
          "areaDictionaryVOList": [
            {
              "areaId": 623001,
              "id": 3131,
              "areaName": "合作市",
              "areaCode": "0941",
              "areaShort": "Hezuo",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "102.910484,35.000286",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623021,
              "id": 3132,
              "areaName": "临潭县",
              "areaCode": "0941",
              "areaShort": "Lintan",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "103.353919,34.692747",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623022,
              "id": 3133,
              "areaName": "卓尼县",
              "areaCode": "0941",
              "areaShort": "Zhuoni",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "103.507109,34.589588",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623023,
              "id": 3134,
              "areaName": "舟曲县",
              "areaCode": "0941",
              "areaShort": "Zhouqu",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "104.251482,33.793631",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623024,
              "id": 3135,
              "areaName": "迭部县",
              "areaCode": "0941",
              "areaShort": "Diebu",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "103.221869,34.055938",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623025,
              "id": 3136,
              "areaName": "玛曲县",
              "areaCode": "0941",
              "areaShort": "Maqu",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "102.072698,33.997712",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623026,
              "id": 3137,
              "areaName": "碌曲县",
              "areaCode": "0941",
              "areaShort": "Luqu",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "102.487327,34.590944",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 623027,
              "id": 3138,
              "areaName": "夏河县",
              "areaCode": "0941",
              "areaShort": "Xiahe",
              "areaInitials": null,
              "cityCode": "0941",
              "center": "102.521807,35.202503",
              "areaLevel": 3,
              "areaParentId": 623000,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 630000,
      "id": 3139,
      "areaName": "青海省",
      "areaCode": "[]",
      "areaShort": "Qinghai",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "101.780268,36.620939",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 630100,
          "id": 3140,
          "areaName": "西宁市",
          "areaCode": "0971",
          "areaShort": "Xining",
          "areaInitials": null,
          "cityCode": "66",
          "center": "101.778223,36.617134",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 630102,
              "id": 3141,
              "areaName": "城东区",
              "areaCode": "0971",
              "areaShort": "Chengdong",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.803717,36.599744",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630103,
              "id": 3142,
              "areaName": "城中区",
              "areaCode": "0971",
              "areaShort": "Chengzhong",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.705298,36.545652",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630104,
              "id": 3143,
              "areaName": "城西区",
              "areaCode": "0971",
              "areaShort": "Chengxi",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.765843,36.628304",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630105,
              "id": 3144,
              "areaName": "城北区",
              "areaCode": "0971",
              "areaShort": "Chengbei",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.766228,36.650038",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630121,
              "id": 3145,
              "areaName": "大通回族土族自治县",
              "areaCode": "0971",
              "areaShort": "Datong",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.685643,36.926954",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630122,
              "id": 3146,
              "areaName": "湟中县",
              "areaCode": "0971",
              "areaShort": "Huangzhong",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.571667,36.500879",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630123,
              "id": 3147,
              "areaName": "湟源县",
              "areaCode": "0971",
              "areaShort": "Huangyuan",
              "areaInitials": null,
              "cityCode": "0971",
              "center": "101.256464,36.682426",
              "areaLevel": 3,
              "areaParentId": 630100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 630200,
          "id": 3148,
          "areaName": "海东市",
          "areaCode": "0972",
          "areaShort": "Haidong",
          "areaInitials": null,
          "cityCode": "69",
          "center": "102.104287,36.502039",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 630202,
              "id": 3149,
              "areaName": "乐都区",
              "areaCode": "0972",
              "areaShort": "Ledu",
              "areaInitials": null,
              "cityCode": "0972",
              "center": "102.401724,36.482058",
              "areaLevel": 3,
              "areaParentId": 630200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630221,
              "id": 3150,
              "areaName": "平安县",
              "areaCode": null,
              "areaShort": "Ping'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 630200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630222,
              "id": 3151,
              "areaName": "民和回族土族自治县",
              "areaCode": "0972",
              "areaShort": "Minhe",
              "areaInitials": null,
              "cityCode": "0972",
              "center": "102.830892,36.320321",
              "areaLevel": 3,
              "areaParentId": 630200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630223,
              "id": 3152,
              "areaName": "互助土族自治县",
              "areaCode": "0972",
              "areaShort": "Huzhu",
              "areaInitials": null,
              "cityCode": "0972",
              "center": "101.959271,36.844248",
              "areaLevel": 3,
              "areaParentId": 630200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630224,
              "id": 3153,
              "areaName": "化隆回族自治县",
              "areaCode": "0972",
              "areaShort": "Hualong",
              "areaInitials": null,
              "cityCode": "0972",
              "center": "102.264143,36.094908",
              "areaLevel": 3,
              "areaParentId": 630200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 630225,
              "id": 3154,
              "areaName": "循化撒拉族自治县",
              "areaCode": "0972",
              "areaShort": "Xunhua",
              "areaInitials": null,
              "cityCode": "0972",
              "center": "102.489135,35.851152",
              "areaLevel": 3,
              "areaParentId": 630200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 632200,
          "id": 3155,
          "areaName": "海北藏族自治州",
          "areaCode": "0970",
          "areaShort": "Haibei",
          "areaInitials": null,
          "cityCode": "67",
          "center": "100.900997,36.954413",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 632221,
              "id": 3156,
              "areaName": "门源回族自治县",
              "areaCode": "0970",
              "areaShort": "Menyuan",
              "areaInitials": null,
              "cityCode": "0970",
              "center": "101.611539,37.388746",
              "areaLevel": 3,
              "areaParentId": 632200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632222,
              "id": 3157,
              "areaName": "祁连县",
              "areaCode": "0970",
              "areaShort": "Qilian",
              "areaInitials": null,
              "cityCode": "0970",
              "center": "100.253211,38.177112",
              "areaLevel": 3,
              "areaParentId": 632200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632223,
              "id": 3158,
              "areaName": "海晏县",
              "areaCode": "0970",
              "areaShort": "Haiyan",
              "areaInitials": null,
              "cityCode": "0970",
              "center": "100.99426,36.896359",
              "areaLevel": 3,
              "areaParentId": 632200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632224,
              "id": 3159,
              "areaName": "刚察县",
              "areaCode": "0970",
              "areaShort": "Gangcha",
              "areaInitials": null,
              "cityCode": "0970",
              "center": "100.145833,37.32547",
              "areaLevel": 3,
              "areaParentId": 632200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 632300,
          "id": 3160,
          "areaName": "黄南藏族自治州",
          "areaCode": "0973",
          "areaShort": "Huangnan",
          "areaInitials": null,
          "cityCode": "70",
          "center": "102.015248,35.519548",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 632321,
              "id": 3161,
              "areaName": "同仁县",
              "areaCode": "0973",
              "areaShort": "Tongren",
              "areaInitials": null,
              "cityCode": "0973",
              "center": "102.018323,35.516063",
              "areaLevel": 3,
              "areaParentId": 632300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632322,
              "id": 3162,
              "areaName": "尖扎县",
              "areaCode": "0973",
              "areaShort": "Jianzha",
              "areaInitials": null,
              "cityCode": "0973",
              "center": "102.04014,35.943156",
              "areaLevel": 3,
              "areaParentId": 632300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632323,
              "id": 3163,
              "areaName": "泽库县",
              "areaCode": "0973",
              "areaShort": "Zeku",
              "areaInitials": null,
              "cityCode": "0973",
              "center": "101.466689,35.035313",
              "areaLevel": 3,
              "areaParentId": 632300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632324,
              "id": 3164,
              "areaName": "河南蒙古族自治县",
              "areaCode": "0973",
              "areaShort": "Henan",
              "areaInitials": null,
              "cityCode": "0973",
              "center": "101.617503,34.734568",
              "areaLevel": 3,
              "areaParentId": 632300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 632500,
          "id": 3165,
          "areaName": "海南藏族自治州",
          "areaCode": "0974",
          "areaShort": "Hainan",
          "areaInitials": null,
          "cityCode": "68",
          "center": "100.622692,36.296529",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 632521,
              "id": 3166,
              "areaName": "共和县",
              "areaCode": "0974",
              "areaShort": "Gonghe",
              "areaInitials": null,
              "cityCode": "0974",
              "center": "100.620031,36.284107",
              "areaLevel": 3,
              "areaParentId": 632500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632522,
              "id": 3167,
              "areaName": "同德县",
              "areaCode": "0974",
              "areaShort": "Tongde",
              "areaInitials": null,
              "cityCode": "0974",
              "center": "100.578051,35.25479",
              "areaLevel": 3,
              "areaParentId": 632500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632523,
              "id": 3168,
              "areaName": "贵德县",
              "areaCode": "0974",
              "areaShort": "Guide",
              "areaInitials": null,
              "cityCode": "0974",
              "center": "101.433391,36.040166",
              "areaLevel": 3,
              "areaParentId": 632500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632524,
              "id": 3169,
              "areaName": "兴海县",
              "areaCode": "0974",
              "areaShort": "Xinghai",
              "areaInitials": null,
              "cityCode": "0974",
              "center": "99.987965,35.588612",
              "areaLevel": 3,
              "areaParentId": 632500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632525,
              "id": 3170,
              "areaName": "贵南县",
              "areaCode": "0974",
              "areaShort": "Guinan",
              "areaInitials": null,
              "cityCode": "0974",
              "center": "100.747503,35.586714",
              "areaLevel": 3,
              "areaParentId": 632500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 632600,
          "id": 3171,
          "areaName": "果洛藏族自治州",
          "areaCode": "0975",
          "areaShort": "Golog",
          "areaInitials": null,
          "cityCode": "72",
          "center": "100.244808,34.471431",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 632621,
              "id": 3172,
              "areaName": "玛沁县",
              "areaCode": "0975",
              "areaShort": "Maqin",
              "areaInitials": null,
              "cityCode": "0975",
              "center": "100.238888,34.477433",
              "areaLevel": 3,
              "areaParentId": 632600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632622,
              "id": 3173,
              "areaName": "班玛县",
              "areaCode": "0975",
              "areaShort": "Banma",
              "areaInitials": null,
              "cityCode": "0975",
              "center": "100.737138,32.932723",
              "areaLevel": 3,
              "areaParentId": 632600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632623,
              "id": 3174,
              "areaName": "甘德县",
              "areaCode": "0975",
              "areaShort": "Gande",
              "areaInitials": null,
              "cityCode": "0975",
              "center": "99.900923,33.969216",
              "areaLevel": 3,
              "areaParentId": 632600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632624,
              "id": 3175,
              "areaName": "达日县",
              "areaCode": "0975",
              "areaShort": "Dari",
              "areaInitials": null,
              "cityCode": "0975",
              "center": "99.651392,33.74892",
              "areaLevel": 3,
              "areaParentId": 632600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632625,
              "id": 3176,
              "areaName": "久治县",
              "areaCode": "0975",
              "areaShort": "Jiuzhi",
              "areaInitials": null,
              "cityCode": "0975",
              "center": "101.482831,33.429471",
              "areaLevel": 3,
              "areaParentId": 632600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632626,
              "id": 3177,
              "areaName": "玛多县",
              "areaCode": "0975",
              "areaShort": "Maduo",
              "areaInitials": null,
              "cityCode": "0975",
              "center": "98.209206,34.915946",
              "areaLevel": 3,
              "areaParentId": 632600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 632700,
          "id": 3178,
          "areaName": "玉树藏族自治州",
          "areaCode": "0976",
          "areaShort": "Yushu",
          "areaInitials": null,
          "cityCode": "71",
          "center": "97.091934,33.011674",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 632701,
              "id": 3179,
              "areaName": "玉树市",
              "areaCode": "0976",
              "areaShort": "Yushu",
              "areaInitials": null,
              "cityCode": "0976",
              "center": "97.008784,32.993106",
              "areaLevel": 3,
              "areaParentId": 632700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632722,
              "id": 3180,
              "areaName": "杂多县",
              "areaCode": "0976",
              "areaShort": "Zaduo",
              "areaInitials": null,
              "cityCode": "0976",
              "center": "95.300723,32.893185",
              "areaLevel": 3,
              "areaParentId": 632700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632723,
              "id": 3181,
              "areaName": "称多县",
              "areaCode": "0976",
              "areaShort": "Chenduo",
              "areaInitials": null,
              "cityCode": "0976",
              "center": "97.110831,33.369218",
              "areaLevel": 3,
              "areaParentId": 632700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632724,
              "id": 3182,
              "areaName": "治多县",
              "areaCode": "0976",
              "areaShort": "Zhiduo",
              "areaInitials": null,
              "cityCode": "0976",
              "center": "95.61896,33.844956",
              "areaLevel": 3,
              "areaParentId": 632700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632725,
              "id": 3183,
              "areaName": "囊谦县",
              "areaCode": "0976",
              "areaShort": "Nangqian",
              "areaInitials": null,
              "cityCode": "0976",
              "center": "96.48943,32.203432",
              "areaLevel": 3,
              "areaParentId": 632700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632726,
              "id": 3184,
              "areaName": "曲麻莱县",
              "areaCode": "0976",
              "areaShort": "Qumalai",
              "areaInitials": null,
              "cityCode": "0976",
              "center": "95.797367,34.126428",
              "areaLevel": 3,
              "areaParentId": 632700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 632800,
          "id": 3185,
          "areaName": "海西蒙古族藏族自治州",
          "areaCode": "0977",
          "areaShort": "Haixi",
          "areaInitials": null,
          "cityCode": "65",
          "center": "97.369751,37.377139",
          "areaLevel": 2,
          "areaParentId": 630000,
          "areaDictionaryVOList": [
            {
              "areaId": 632801,
              "id": 3186,
              "areaName": "格尔木市",
              "areaCode": "0977",
              "areaShort": "Geermu",
              "areaInitials": null,
              "cityCode": "0977",
              "center": "94.928453,36.406367",
              "areaLevel": 3,
              "areaParentId": 632800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632802,
              "id": 3187,
              "areaName": "德令哈市",
              "areaCode": "0977",
              "areaShort": "Delingha",
              "areaInitials": null,
              "cityCode": "0977",
              "center": "97.360984,37.369436",
              "areaLevel": 3,
              "areaParentId": 632800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632821,
              "id": 3188,
              "areaName": "乌兰县",
              "areaCode": "0977",
              "areaShort": "Wulan",
              "areaInitials": null,
              "cityCode": "0977",
              "center": "98.480195,36.929749",
              "areaLevel": 3,
              "areaParentId": 632800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632822,
              "id": 3189,
              "areaName": "都兰县",
              "areaCode": "0977",
              "areaShort": "Dulan",
              "areaInitials": null,
              "cityCode": "0977",
              "center": "98.095844,36.302496",
              "areaLevel": 3,
              "areaParentId": 632800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 632823,
              "id": 3190,
              "areaName": "天峻县",
              "areaCode": "0977",
              "areaShort": "Tianjun",
              "areaInitials": null,
              "cityCode": "0977",
              "center": "99.022984,37.300851",
              "areaLevel": 3,
              "areaParentId": 632800,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 640000,
      "id": 3191,
      "areaName": "宁夏回族自治区",
      "areaCode": "[]",
      "areaShort": "Ningxia",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "106.259126,38.472641",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 640100,
          "id": 3192,
          "areaName": "银川市",
          "areaCode": "0951",
          "areaShort": "Yinchuan",
          "areaInitials": null,
          "cityCode": "360",
          "center": "106.230909,38.487193",
          "areaLevel": 2,
          "areaParentId": 640000,
          "areaDictionaryVOList": [
            {
              "areaId": 640104,
              "id": 3193,
              "areaName": "兴庆区",
              "areaCode": "0951",
              "areaShort": "Xingqing",
              "areaInitials": null,
              "cityCode": "0951",
              "center": "106.28865,38.473609",
              "areaLevel": 3,
              "areaParentId": 640100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640105,
              "id": 3194,
              "areaName": "西夏区",
              "areaCode": "0951",
              "areaShort": "Xixia",
              "areaInitials": null,
              "cityCode": "0951",
              "center": "106.161106,38.502605",
              "areaLevel": 3,
              "areaParentId": 640100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640106,
              "id": 3195,
              "areaName": "金凤区",
              "areaCode": "0951",
              "areaShort": "Jinfeng",
              "areaInitials": null,
              "cityCode": "0951",
              "center": "106.239679,38.47436",
              "areaLevel": 3,
              "areaParentId": 640100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640121,
              "id": 3196,
              "areaName": "永宁县",
              "areaCode": "0951",
              "areaShort": "Yongning",
              "areaInitials": null,
              "cityCode": "0951",
              "center": "106.253145,38.277372",
              "areaLevel": 3,
              "areaParentId": 640100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640122,
              "id": 3197,
              "areaName": "贺兰县",
              "areaCode": "0951",
              "areaShort": "Helan",
              "areaInitials": null,
              "cityCode": "0951",
              "center": "106.349861,38.554599",
              "areaLevel": 3,
              "areaParentId": 640100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640181,
              "id": 3198,
              "areaName": "灵武市",
              "areaCode": "0951",
              "areaShort": "Lingwu",
              "areaInitials": null,
              "cityCode": "0951",
              "center": "106.340053,38.102655",
              "areaLevel": 3,
              "areaParentId": 640100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 640200,
          "id": 3199,
          "areaName": "石嘴山市",
          "areaCode": "0952",
          "areaShort": "Shizuishan",
          "areaInitials": null,
          "cityCode": "335",
          "center": "106.383303,38.983236",
          "areaLevel": 2,
          "areaParentId": 640000,
          "areaDictionaryVOList": [
            {
              "areaId": 640202,
              "id": 3200,
              "areaName": "大武口区",
              "areaCode": "0952",
              "areaShort": "Dawukou",
              "areaInitials": null,
              "cityCode": "0952",
              "center": "106.367958,39.01918",
              "areaLevel": 3,
              "areaParentId": 640200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640205,
              "id": 3201,
              "areaName": "惠农区",
              "areaCode": "0952",
              "areaShort": "Huinong",
              "areaInitials": null,
              "cityCode": "0952",
              "center": "106.781176,39.239302",
              "areaLevel": 3,
              "areaParentId": 640200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640221,
              "id": 3202,
              "areaName": "平罗县",
              "areaCode": "0952",
              "areaShort": "Pingluo",
              "areaInitials": null,
              "cityCode": "0952",
              "center": "106.523474,38.913544",
              "areaLevel": 3,
              "areaParentId": 640200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 640300,
          "id": 3203,
          "areaName": "吴忠市",
          "areaCode": "0953",
          "areaShort": "Wuzhong",
          "areaInitials": null,
          "cityCode": "322",
          "center": "106.198913,37.997428",
          "areaLevel": 2,
          "areaParentId": 640000,
          "areaDictionaryVOList": [
            {
              "areaId": 640302,
              "id": 3204,
              "areaName": "利通区",
              "areaCode": "0953",
              "areaShort": "Litong",
              "areaInitials": null,
              "cityCode": "0953",
              "center": "106.212613,37.98349",
              "areaLevel": 3,
              "areaParentId": 640300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640303,
              "id": 3205,
              "areaName": "红寺堡区",
              "areaCode": "0953",
              "areaShort": "Hongsibao",
              "areaInitials": null,
              "cityCode": "0953",
              "center": "106.062113,37.425702",
              "areaLevel": 3,
              "areaParentId": 640300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640323,
              "id": 3206,
              "areaName": "盐池县",
              "areaCode": "0953",
              "areaShort": "Yanchi",
              "areaInitials": null,
              "cityCode": "0953",
              "center": "107.407358,37.783205",
              "areaLevel": 3,
              "areaParentId": 640300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640324,
              "id": 3207,
              "areaName": "同心县",
              "areaCode": "0953",
              "areaShort": "Tongxin",
              "areaInitials": null,
              "cityCode": "0953",
              "center": "105.895309,36.95449",
              "areaLevel": 3,
              "areaParentId": 640300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640381,
              "id": 3208,
              "areaName": "青铜峡市",
              "areaCode": "0953",
              "areaShort": "Qingtongxia",
              "areaInitials": null,
              "cityCode": "0953",
              "center": "106.078817,38.021302",
              "areaLevel": 3,
              "areaParentId": 640300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 640400,
          "id": 3209,
          "areaName": "固原市",
          "areaCode": "0954",
          "areaShort": "Guyuan",
          "areaInitials": null,
          "cityCode": "246",
          "center": "106.24261,36.015855",
          "areaLevel": 2,
          "areaParentId": 640000,
          "areaDictionaryVOList": [
            {
              "areaId": 640402,
              "id": 3210,
              "areaName": "原州区",
              "areaCode": "0954",
              "areaShort": "Yuanzhou",
              "areaInitials": null,
              "cityCode": "0954",
              "center": "106.287781,36.003739",
              "areaLevel": 3,
              "areaParentId": 640400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640422,
              "id": 3211,
              "areaName": "西吉县",
              "areaCode": "0954",
              "areaShort": "Xiji",
              "areaInitials": null,
              "cityCode": "0954",
              "center": "105.729085,35.963912",
              "areaLevel": 3,
              "areaParentId": 640400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640423,
              "id": 3212,
              "areaName": "隆德县",
              "areaCode": "0954",
              "areaShort": "Longde",
              "areaInitials": null,
              "cityCode": "0954",
              "center": "106.111595,35.625914",
              "areaLevel": 3,
              "areaParentId": 640400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640424,
              "id": 3213,
              "areaName": "泾源县",
              "areaCode": "0954",
              "areaShort": "Jingyuan",
              "areaInitials": null,
              "cityCode": "0954",
              "center": "106.330646,35.498159",
              "areaLevel": 3,
              "areaParentId": 640400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640425,
              "id": 3214,
              "areaName": "彭阳县",
              "areaCode": "0954",
              "areaShort": "Pengyang",
              "areaInitials": null,
              "cityCode": "0954",
              "center": "106.631809,35.858815",
              "areaLevel": 3,
              "areaParentId": 640400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 640500,
          "id": 3215,
          "areaName": "中卫市",
          "areaCode": "1953",
          "areaShort": "Zhongwei",
          "areaInitials": null,
          "cityCode": "181",
          "center": "105.196902,37.499972",
          "areaLevel": 2,
          "areaParentId": 640000,
          "areaDictionaryVOList": [
            {
              "areaId": 640502,
              "id": 3216,
              "areaName": "沙坡头区",
              "areaCode": "1953",
              "areaShort": "Shapotou",
              "areaInitials": null,
              "cityCode": "1953",
              "center": "105.173721,37.516883",
              "areaLevel": 3,
              "areaParentId": 640500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640521,
              "id": 3217,
              "areaName": "中宁县",
              "areaCode": "1953",
              "areaShort": "Zhongning",
              "areaInitials": null,
              "cityCode": "1953",
              "center": "105.685218,37.491546",
              "areaLevel": 3,
              "areaParentId": 640500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 640522,
              "id": 3218,
              "areaName": "海原县",
              "areaCode": "1953",
              "areaShort": "Haiyuan",
              "areaInitials": null,
              "cityCode": "1953",
              "center": "105.643487,36.565033",
              "areaLevel": 3,
              "areaParentId": 640500,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 650000,
      "id": 3219,
      "areaName": "新疆维吾尔自治区",
      "areaCode": "[]",
      "areaShort": "Xinjiang",
      "areaInitials": null,
      "cityCode": "[]",
      "center": "87.627704,43.793026",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 650100,
          "id": 3220,
          "areaName": "乌鲁木齐市",
          "areaCode": "0991",
          "areaShort": "Urumqi",
          "areaInitials": null,
          "cityCode": "92",
          "center": "87.616848,43.825592",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 650102,
              "id": 3221,
              "areaName": "天山区",
              "areaCode": "0991",
              "areaShort": "Tianshan",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.631676,43.794399",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650103,
              "id": 3222,
              "areaName": "沙依巴克区",
              "areaCode": "0991",
              "areaShort": "Shayibake",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.598195,43.800939",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650104,
              "id": 3223,
              "areaName": "新市区",
              "areaCode": "0991",
              "areaShort": "Xinshi",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.569431,43.855378",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650105,
              "id": 3224,
              "areaName": "水磨沟区",
              "areaCode": "0991",
              "areaShort": "Shuimogou",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.642481,43.832459",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650106,
              "id": 3225,
              "areaName": "头屯河区",
              "areaCode": "0991",
              "areaShort": "Toutunhe",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.428141,43.877664",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650107,
              "id": 3226,
              "areaName": "达坂城区",
              "areaCode": "0991",
              "areaShort": "Dabancheng",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "88.311099,43.363668",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650109,
              "id": 3227,
              "areaName": "米东区",
              "areaCode": "0991",
              "areaShort": "Midong",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.655935,43.974784",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650121,
              "id": 3228,
              "areaName": "乌鲁木齐县",
              "areaCode": "0991",
              "areaShort": "Wulumuqi",
              "areaInitials": null,
              "cityCode": "0991",
              "center": "87.409417,43.47136",
              "areaLevel": 3,
              "areaParentId": 650100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 650200,
          "id": 3229,
          "areaName": "克拉玛依市",
          "areaCode": "0990",
          "areaShort": "Karamay",
          "areaInitials": null,
          "cityCode": "95",
          "center": "84.889207,45.579888",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 650202,
              "id": 3230,
              "areaName": "独山子区",
              "areaCode": "0990",
              "areaShort": "Dushanzi",
              "areaInitials": null,
              "cityCode": "0990",
              "center": "84.886974,44.328095",
              "areaLevel": 3,
              "areaParentId": 650200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650203,
              "id": 3231,
              "areaName": "克拉玛依区",
              "areaCode": "0990",
              "areaShort": "Kelamayi",
              "areaInitials": null,
              "cityCode": "0990",
              "center": "84.867844,45.602525",
              "areaLevel": 3,
              "areaParentId": 650200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650204,
              "id": 3232,
              "areaName": "白碱滩区",
              "areaCode": "0990",
              "areaShort": "Baijiantan",
              "areaInitials": null,
              "cityCode": "0990",
              "center": "85.131696,45.687854",
              "areaLevel": 3,
              "areaParentId": 650200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 650205,
              "id": 3233,
              "areaName": "乌尔禾区",
              "areaCode": "0990",
              "areaShort": "Wuerhe",
              "areaInitials": null,
              "cityCode": "0990",
              "center": "85.693742,46.089148",
              "areaLevel": 3,
              "areaParentId": 650200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 652100,
          "id": 3234,
          "areaName": "吐鲁番地区",
          "areaCode": null,
          "areaShort": "Turpan",
          "areaInitials": null,
          "cityCode": "89",
          "center": null,
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 652101,
              "id": 3235,
              "areaName": "吐鲁番市",
              "areaCode": null,
              "areaShort": "Tulufan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 652100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652122,
              "id": 3236,
              "areaName": "鄯善县",
              "areaCode": null,
              "areaShort": "Shanshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 652100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652123,
              "id": 3237,
              "areaName": "托克逊县",
              "areaCode": null,
              "areaShort": "Tuokexun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 652100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 652200,
          "id": 3238,
          "areaName": "哈密地区",
          "areaCode": null,
          "areaShort": "Hami",
          "areaInitials": null,
          "cityCode": "91",
          "center": null,
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 652201,
              "id": 3239,
              "areaName": "哈密市",
              "areaCode": null,
              "areaShort": "Hami",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 652200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652222,
              "id": 3240,
              "areaName": "巴里坤哈萨克自治县",
              "areaCode": null,
              "areaShort": "Balikun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 652200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652223,
              "id": 3241,
              "areaName": "伊吾县",
              "areaCode": null,
              "areaShort": "Yiwu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 652200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 652300,
          "id": 3242,
          "areaName": "昌吉回族自治州",
          "areaCode": "0994",
          "areaShort": "Changji",
          "areaInitials": null,
          "cityCode": "93",
          "center": "87.308224,44.011182",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 652301,
              "id": 3243,
              "areaName": "昌吉市",
              "areaCode": "0994",
              "areaShort": "Changji",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "87.267532,44.014435",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652302,
              "id": 3244,
              "areaName": "阜康市",
              "areaCode": "0994",
              "areaShort": "Fukang",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "87.952991,44.164402",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652323,
              "id": 3245,
              "areaName": "呼图壁县",
              "areaCode": "0994",
              "areaShort": "Hutubi",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "86.871584,44.179361",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652324,
              "id": 3246,
              "areaName": "玛纳斯县",
              "areaCode": "0994",
              "areaShort": "Manasi",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "86.20368,44.284722",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652325,
              "id": 3247,
              "areaName": "奇台县",
              "areaCode": "0994",
              "areaShort": "Qitai",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "89.593967,44.022066",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652327,
              "id": 3248,
              "areaName": "吉木萨尔县",
              "areaCode": "0994",
              "areaShort": "Jimusaer",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "89.180437,44.000497",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652328,
              "id": 3249,
              "areaName": "木垒哈萨克自治县",
              "areaCode": "0994",
              "areaShort": "Mulei",
              "areaInitials": null,
              "cityCode": "0994",
              "center": "90.286028,43.834689",
              "areaLevel": 3,
              "areaParentId": 652300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 652700,
          "id": 3250,
          "areaName": "博尔塔拉蒙古自治州",
          "areaCode": "0909",
          "areaShort": "Bortala",
          "areaInitials": null,
          "cityCode": "88",
          "center": "82.066363,44.906039",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 652701,
              "id": 3251,
              "areaName": "博乐市",
              "areaCode": "0909",
              "areaShort": "Bole",
              "areaInitials": null,
              "cityCode": "0909",
              "center": "82.051004,44.853869",
              "areaLevel": 3,
              "areaParentId": 652700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652702,
              "id": 3252,
              "areaName": "阿拉山口市",
              "areaCode": "0909",
              "areaShort": "Alashankou",
              "areaInitials": null,
              "cityCode": "0909",
              "center": "82.559396,45.172227",
              "areaLevel": 3,
              "areaParentId": 652700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652722,
              "id": 3253,
              "areaName": "精河县",
              "areaCode": "0909",
              "areaShort": "Jinghe",
              "areaInitials": null,
              "cityCode": "0909",
              "center": "82.890656,44.599393",
              "areaLevel": 3,
              "areaParentId": 652700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652723,
              "id": 3254,
              "areaName": "温泉县",
              "areaCode": "0909",
              "areaShort": "Wenquan",
              "areaInitials": null,
              "cityCode": "0909",
              "center": "81.024816,44.968856",
              "areaLevel": 3,
              "areaParentId": 652700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 652800,
          "id": 3255,
          "areaName": "巴音郭楞蒙古自治州",
          "areaCode": "0996",
          "areaShort": "Bayingol",
          "areaInitials": null,
          "cityCode": "86",
          "center": "86.145297,41.764115",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 652801,
              "id": 3256,
              "areaName": "库尔勒市",
              "areaCode": "0996",
              "areaShort": "Kuerle",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "86.174633,41.725891",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652822,
              "id": 3257,
              "areaName": "轮台县",
              "areaCode": "0996",
              "areaShort": "Luntai",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "84.252156,41.777702",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652823,
              "id": 3258,
              "areaName": "尉犁县",
              "areaCode": "0996",
              "areaShort": "Yuli",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "86.261321,41.343933",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652824,
              "id": 3259,
              "areaName": "若羌县",
              "areaCode": "0996",
              "areaShort": "Ruoqiang",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "88.167152,39.023241",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652825,
              "id": 3260,
              "areaName": "且末县",
              "areaCode": "0996",
              "areaShort": "Qiemo",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "85.529702,38.145485",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652826,
              "id": 3261,
              "areaName": "焉耆回族自治县",
              "areaCode": "0996",
              "areaShort": "Yanqi",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "86.574067,42.059759",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652827,
              "id": 3262,
              "areaName": "和静县",
              "areaCode": "0996",
              "areaShort": "Hejing",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "86.384065,42.323625",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652828,
              "id": 3263,
              "areaName": "和硕县",
              "areaCode": "0996",
              "areaShort": "Heshuo",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "86.876799,42.284331",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652829,
              "id": 3264,
              "areaName": "博湖县",
              "areaCode": "0996",
              "areaShort": "Bohu",
              "areaInitials": null,
              "cityCode": "0996",
              "center": "86.631997,41.980152",
              "areaLevel": 3,
              "areaParentId": 652800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 652900,
          "id": 3265,
          "areaName": "阿克苏地区",
          "areaCode": "0997",
          "areaShort": "Aksu",
          "areaInitials": null,
          "cityCode": "85",
          "center": "80.260605,41.168779",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 652901,
              "id": 3266,
              "areaName": "阿克苏市",
              "areaCode": "0997",
              "areaShort": "Akesu",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "80.263387,41.167548",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652922,
              "id": 3267,
              "areaName": "温宿县",
              "areaCode": "0997",
              "areaShort": "Wensu",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "80.238959,41.276688",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652923,
              "id": 3268,
              "areaName": "库车县",
              "areaCode": "0997",
              "areaShort": "Kuche",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "82.987312,41.714696",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652924,
              "id": 3269,
              "areaName": "沙雅县",
              "areaCode": "0997",
              "areaShort": "Shaya",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "82.781818,41.221666",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652925,
              "id": 3270,
              "areaName": "新和县",
              "areaCode": "0997",
              "areaShort": "Xinhe",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "82.618736,41.551206",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652926,
              "id": 3271,
              "areaName": "拜城县",
              "areaCode": "0997",
              "areaShort": "Baicheng",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "81.85148,41.795912",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652927,
              "id": 3272,
              "areaName": "乌什县",
              "areaCode": "0997",
              "areaShort": "Wushi",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "79.224616,41.222319",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652928,
              "id": 3273,
              "areaName": "阿瓦提县",
              "areaCode": "0997",
              "areaShort": "Awati",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "80.375053,40.643647",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 652929,
              "id": 3274,
              "areaName": "柯坪县",
              "areaCode": "0997",
              "areaShort": "Keping",
              "areaInitials": null,
              "cityCode": "0997",
              "center": "79.054497,40.501936",
              "areaLevel": 3,
              "areaParentId": 652900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 653000,
          "id": 3275,
          "areaName": "克孜勒苏柯尔克孜自治州",
          "areaCode": "0908",
          "areaShort": "Kizilsu",
          "areaInitials": null,
          "cityCode": "84",
          "center": "76.167819,39.714526",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 653001,
              "id": 3276,
              "areaName": "阿图什市",
              "areaCode": "0908",
              "areaShort": "Atushi",
              "areaInitials": null,
              "cityCode": "0908",
              "center": "76.1684,39.71616",
              "areaLevel": 3,
              "areaParentId": 653000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653022,
              "id": 3277,
              "areaName": "阿克陶县",
              "areaCode": "0908",
              "areaShort": "Aketao",
              "areaInitials": null,
              "cityCode": "0908",
              "center": "75.947396,39.147785",
              "areaLevel": 3,
              "areaParentId": 653000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653023,
              "id": 3278,
              "areaName": "阿合奇县",
              "areaCode": "0908",
              "areaShort": "Aheqi",
              "areaInitials": null,
              "cityCode": "0908",
              "center": "78.446253,40.936936",
              "areaLevel": 3,
              "areaParentId": 653000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653024,
              "id": 3279,
              "areaName": "乌恰县",
              "areaCode": "0908",
              "areaShort": "Wuqia",
              "areaInitials": null,
              "cityCode": "0908",
              "center": "75.259227,39.71931",
              "areaLevel": 3,
              "areaParentId": 653000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 653100,
          "id": 3280,
          "areaName": "喀什地区",
          "areaCode": "0998",
          "areaShort": "Kashgar",
          "areaInitials": null,
          "cityCode": "83",
          "center": "75.989741,39.47046",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 653101,
              "id": 3281,
              "areaName": "喀什市",
              "areaCode": "0998",
              "areaShort": "Kashi",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "75.99379,39.467685",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653121,
              "id": 3282,
              "areaName": "疏附县",
              "areaCode": "0998",
              "areaShort": "Shufu",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "75.862813,39.375043",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653122,
              "id": 3283,
              "areaName": "疏勒县",
              "areaCode": "0998",
              "areaShort": "Shule",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "76.048139,39.401384",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653123,
              "id": 3284,
              "areaName": "英吉沙县",
              "areaCode": "0998",
              "areaShort": "Yingjisha",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "76.175729,38.930381",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653124,
              "id": 3285,
              "areaName": "泽普县",
              "areaCode": "0998",
              "areaShort": "Zepu",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "77.259675,38.18529",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653125,
              "id": 3286,
              "areaName": "莎车县",
              "areaCode": "0998",
              "areaShort": "Shache",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "77.245761,38.41422",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653126,
              "id": 3287,
              "areaName": "叶城县",
              "areaCode": "0998",
              "areaShort": "Yecheng",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "77.413836,37.882989",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653127,
              "id": 3288,
              "areaName": "麦盖提县",
              "areaCode": "0998",
              "areaShort": "Maigaiti",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "77.610125,38.898001",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653128,
              "id": 3289,
              "areaName": "岳普湖县",
              "areaCode": "0998",
              "areaShort": "Yuepuhu",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "76.8212,39.2198",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653129,
              "id": 3290,
              "areaName": "伽师县",
              "areaCode": "0998",
              "areaShort": "Jiashi",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "76.723719,39.488181",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653130,
              "id": 3291,
              "areaName": "巴楚县",
              "areaCode": "0998",
              "areaShort": "Bachu",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "78.549296,39.785155",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653131,
              "id": 3292,
              "areaName": "塔什库尔干塔吉克自治县",
              "areaCode": "0998",
              "areaShort": "Tashikuergantajike",
              "areaInitials": null,
              "cityCode": "0998",
              "center": "75.229889,37.772094",
              "areaLevel": 3,
              "areaParentId": 653100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 653200,
          "id": 3293,
          "areaName": "和田地区",
          "areaCode": "0903",
          "areaShort": "Hotan",
          "areaInitials": null,
          "cityCode": "82",
          "center": "79.922211,37.114157",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 653201,
              "id": 3294,
              "areaName": "和田市",
              "areaCode": "0903",
              "areaShort": "Hetianshi",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "79.913534,37.112148",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653221,
              "id": 3295,
              "areaName": "和田县",
              "areaCode": "0903",
              "areaShort": "Hetianxian",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "79.81907,37.120031",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653222,
              "id": 3296,
              "areaName": "墨玉县",
              "areaCode": "0903",
              "areaShort": "Moyu",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "79.728683,37.277143",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653223,
              "id": 3297,
              "areaName": "皮山县",
              "areaCode": "0903",
              "areaShort": "Pishan",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "78.283669,37.62145",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653224,
              "id": 3298,
              "areaName": "洛浦县",
              "areaCode": "0903",
              "areaShort": "Luopu",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "80.188986,37.073667",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653225,
              "id": 3299,
              "areaName": "策勒县",
              "areaCode": "0903",
              "areaShort": "Cele",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "80.806159,36.998335",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653226,
              "id": 3300,
              "areaName": "于田县",
              "areaCode": "0903",
              "areaShort": "Yutian",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "81.677418,36.85708",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 653227,
              "id": 3301,
              "areaName": "民丰县",
              "areaCode": "0903",
              "areaShort": "Minfeng",
              "areaInitials": null,
              "cityCode": "0903",
              "center": "82.695861,37.06408",
              "areaLevel": 3,
              "areaParentId": 653200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 654000,
          "id": 3302,
          "areaName": "伊犁哈萨克自治州",
          "areaCode": "0999",
          "areaShort": "Ili",
          "areaInitials": null,
          "cityCode": "90",
          "center": "81.324136,43.916823",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 654002,
              "id": 3303,
              "areaName": "伊宁市",
              "areaCode": "0999",
              "areaShort": "Yining",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "81.27795,43.908558",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654003,
              "id": 3304,
              "areaName": "奎屯市",
              "areaCode": "0999",
              "areaShort": "Kuitun",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "84.903267,44.426529",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654004,
              "id": 3305,
              "areaName": "霍尔果斯市",
              "areaCode": "0999",
              "areaShort": "Huoerguosi",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "80.411271,44.213941",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654021,
              "id": 3306,
              "areaName": "伊宁县",
              "areaCode": "0999",
              "areaShort": "Yining",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "81.52745,43.977119",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654022,
              "id": 3307,
              "areaName": "察布查尔锡伯自治县",
              "areaCode": "0999",
              "areaShort": "Chabuchaerxibo",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "81.151337,43.840726",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654023,
              "id": 3308,
              "areaName": "霍城县",
              "areaCode": "0999",
              "areaShort": "Huocheng",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "80.87898,44.055984",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654024,
              "id": 3309,
              "areaName": "巩留县",
              "areaCode": "0999",
              "areaShort": "Gongliu",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "82.231718,43.482628",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654025,
              "id": 3310,
              "areaName": "新源县",
              "areaCode": "0999",
              "areaShort": "Xinyuan",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "83.232848,43.433896",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654026,
              "id": 3311,
              "areaName": "昭苏县",
              "areaCode": "0999",
              "areaShort": "Zhaosu",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "81.130974,43.157293",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654027,
              "id": 3312,
              "areaName": "特克斯县",
              "areaCode": "0999",
              "areaShort": "Tekesi",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "81.836206,43.217183",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654028,
              "id": 3313,
              "areaName": "尼勒克县",
              "areaCode": "0999",
              "areaShort": "Nileke",
              "areaInitials": null,
              "cityCode": "0999",
              "center": "82.511809,43.800247",
              "areaLevel": 3,
              "areaParentId": 654000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 654200,
          "id": 3314,
          "areaName": "塔城地区",
          "areaCode": "0901",
          "areaShort": "Qoqek",
          "areaInitials": null,
          "cityCode": "94",
          "center": "82.980316,46.745364",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 654201,
              "id": 3315,
              "areaName": "塔城市",
              "areaCode": "0901",
              "areaShort": "Tacheng",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "82.986978,46.751428",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654202,
              "id": 3316,
              "areaName": "乌苏市",
              "areaCode": "0901",
              "areaShort": "Wusu",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "84.713396,44.41881",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654221,
              "id": 3317,
              "areaName": "额敏县",
              "areaCode": "0901",
              "areaShort": "Emin",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "83.628303,46.524673",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654223,
              "id": 3318,
              "areaName": "沙湾县",
              "areaCode": "0901",
              "areaShort": "Shawan",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "85.619416,44.326388",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654224,
              "id": 3319,
              "areaName": "托里县",
              "areaCode": "0901",
              "areaShort": "Tuoli",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "83.60695,45.947638",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654225,
              "id": 3320,
              "areaName": "裕民县",
              "areaCode": "0901",
              "areaShort": "Yumin",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "82.982667,46.201104",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654226,
              "id": 3321,
              "areaName": "和布克赛尔蒙古自治县",
              "areaCode": "0901",
              "areaShort": "Hebukesaier",
              "areaInitials": null,
              "cityCode": "0901",
              "center": "85.728328,46.793235",
              "areaLevel": 3,
              "areaParentId": 654200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 654300,
          "id": 3322,
          "areaName": "阿勒泰地区",
          "areaCode": "0906",
          "areaShort": "Altay",
          "areaInitials": null,
          "cityCode": "96",
          "center": "88.141253,47.844924",
          "areaLevel": 2,
          "areaParentId": 650000,
          "areaDictionaryVOList": [
            {
              "areaId": 654301,
              "id": 3323,
              "areaName": "阿勒泰市",
              "areaCode": "0906",
              "areaShort": "Aletai",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "88.131842,47.827308",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654321,
              "id": 3324,
              "areaName": "布尔津县",
              "areaCode": "0906",
              "areaShort": "Buerjin",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "86.874923,47.702163",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654322,
              "id": 3325,
              "areaName": "富蕴县",
              "areaCode": "0906",
              "areaShort": "Fuyun",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "89.525504,46.994115",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654323,
              "id": 3326,
              "areaName": "福海县",
              "areaCode": "0906",
              "areaShort": "Fuhai",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "87.486703,47.111918",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654324,
              "id": 3327,
              "areaName": "哈巴河县",
              "areaCode": "0906",
              "areaShort": "Habahe",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "86.418621,48.060846",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654325,
              "id": 3328,
              "areaName": "青河县",
              "areaCode": "0906",
              "areaShort": "Qinghe",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "90.37555,46.679113",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 654326,
              "id": 3329,
              "areaName": "吉木乃县",
              "areaCode": "0906",
              "areaShort": "Jimunai",
              "areaInitials": null,
              "cityCode": "0906",
              "center": "85.874096,47.443101",
              "areaLevel": 3,
              "areaParentId": 654300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 710000,
      "id": 3338,
      "areaName": "台湾",
      "areaCode": "1886",
      "areaShort": "Taiwan",
      "areaInitials": null,
      "cityCode": "1886",
      "center": "121.509062,25.044332",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 710100,
          "id": 3339,
          "areaName": "台北市",
          "areaCode": null,
          "areaShort": "Taipei",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710101,
              "id": 3340,
              "areaName": "松山区",
              "areaCode": null,
              "areaShort": "Songshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710102,
              "id": 3341,
              "areaName": "信义区",
              "areaCode": null,
              "areaShort": "Xinyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710103,
              "id": 3342,
              "areaName": "大安区",
              "areaCode": null,
              "areaShort": "Da'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710104,
              "id": 3343,
              "areaName": "中山区",
              "areaCode": null,
              "areaShort": "Zhongshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710105,
              "id": 3344,
              "areaName": "中正区",
              "areaCode": null,
              "areaShort": "Zhongzheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710106,
              "id": 3345,
              "areaName": "大同区",
              "areaCode": null,
              "areaShort": "Datong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710107,
              "id": 3346,
              "areaName": "万华区",
              "areaCode": null,
              "areaShort": "Wanhua",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710108,
              "id": 3347,
              "areaName": "文山区",
              "areaCode": null,
              "areaShort": "Wenshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710109,
              "id": 3348,
              "areaName": "南港区",
              "areaCode": null,
              "areaShort": "Nangang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710110,
              "id": 3349,
              "areaName": "内湖区",
              "areaCode": null,
              "areaShort": "Nahu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710111,
              "id": 3350,
              "areaName": "士林区",
              "areaCode": null,
              "areaShort": "Shilin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710112,
              "id": 3351,
              "areaName": "北投区",
              "areaCode": null,
              "areaShort": "Beitou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710200,
          "id": 3352,
          "areaName": "高雄市",
          "areaCode": null,
          "areaShort": "Kaohsiung",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710201,
              "id": 3353,
              "areaName": "盐埕区",
              "areaCode": null,
              "areaShort": "Yancheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710202,
              "id": 3354,
              "areaName": "鼓山区",
              "areaCode": null,
              "areaShort": "Gushan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710203,
              "id": 3355,
              "areaName": "左营区",
              "areaCode": null,
              "areaShort": "Zuoying",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710204,
              "id": 3356,
              "areaName": "楠梓区",
              "areaCode": null,
              "areaShort": "Nanzi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710205,
              "id": 3357,
              "areaName": "三民区",
              "areaCode": null,
              "areaShort": "Sanmin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710206,
              "id": 3358,
              "areaName": "新兴区",
              "areaCode": null,
              "areaShort": "Xinxing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710207,
              "id": 3359,
              "areaName": "前金区",
              "areaCode": null,
              "areaShort": "Qianjin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710208,
              "id": 3360,
              "areaName": "苓雅区",
              "areaCode": null,
              "areaShort": "Lingya",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710209,
              "id": 3361,
              "areaName": "前镇区",
              "areaCode": null,
              "areaShort": "Qianzhen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710210,
              "id": 3362,
              "areaName": "旗津区",
              "areaCode": null,
              "areaShort": "Qijin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710211,
              "id": 3363,
              "areaName": "小港区",
              "areaCode": null,
              "areaShort": "Xiaogang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710212,
              "id": 3364,
              "areaName": "凤山区",
              "areaCode": null,
              "areaShort": "Fengshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710213,
              "id": 3365,
              "areaName": "林园区",
              "areaCode": null,
              "areaShort": "Linyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710214,
              "id": 3366,
              "areaName": "大寮区",
              "areaCode": null,
              "areaShort": "Daliao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710215,
              "id": 3367,
              "areaName": "大树区",
              "areaCode": null,
              "areaShort": "Dashu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710216,
              "id": 3368,
              "areaName": "大社区",
              "areaCode": null,
              "areaShort": "Dashe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710217,
              "id": 3369,
              "areaName": "仁武区",
              "areaCode": null,
              "areaShort": "Renwu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710218,
              "id": 3370,
              "areaName": "鸟松区",
              "areaCode": null,
              "areaShort": "Niaosong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710219,
              "id": 3371,
              "areaName": "冈山区",
              "areaCode": null,
              "areaShort": "Gangshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710220,
              "id": 3372,
              "areaName": "桥头区",
              "areaCode": null,
              "areaShort": "Qiaotou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710221,
              "id": 3373,
              "areaName": "燕巢区",
              "areaCode": null,
              "areaShort": "Yanchao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710222,
              "id": 3374,
              "areaName": "田寮区",
              "areaCode": null,
              "areaShort": "Tianliao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710223,
              "id": 3375,
              "areaName": "阿莲区",
              "areaCode": null,
              "areaShort": "Alian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710224,
              "id": 3376,
              "areaName": "路竹区",
              "areaCode": null,
              "areaShort": "Luzhu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710225,
              "id": 3377,
              "areaName": "湖内区",
              "areaCode": null,
              "areaShort": "Huna",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710226,
              "id": 3378,
              "areaName": "茄萣区",
              "areaCode": null,
              "areaShort": "Qieding",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710227,
              "id": 3379,
              "areaName": "永安区",
              "areaCode": null,
              "areaShort": "Yong'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710228,
              "id": 3380,
              "areaName": "弥陀区",
              "areaCode": null,
              "areaShort": "Mituo",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710229,
              "id": 3381,
              "areaName": "梓官区",
              "areaCode": null,
              "areaShort": "Ziguan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710230,
              "id": 3382,
              "areaName": "旗山区",
              "areaCode": null,
              "areaShort": "Qishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710231,
              "id": 3383,
              "areaName": "美浓区",
              "areaCode": null,
              "areaShort": "Meinong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710232,
              "id": 3384,
              "areaName": "六龟区",
              "areaCode": null,
              "areaShort": "Liugui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710233,
              "id": 3385,
              "areaName": "甲仙区",
              "areaCode": null,
              "areaShort": "Jiaxian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710234,
              "id": 3386,
              "areaName": "杉林区",
              "areaCode": null,
              "areaShort": "Shanlin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710235,
              "id": 3387,
              "areaName": "内门区",
              "areaCode": null,
              "areaShort": "Namen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710236,
              "id": 3388,
              "areaName": "茂林区",
              "areaCode": null,
              "areaShort": "Maolin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710237,
              "id": 3389,
              "areaName": "桃源区",
              "areaCode": null,
              "areaShort": "Taoyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710238,
              "id": 3390,
              "areaName": "那玛夏区",
              "areaCode": null,
              "areaShort": "Namaxia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710300,
          "id": 3391,
          "areaName": "基隆市",
          "areaCode": null,
          "areaShort": "Keelung",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710301,
              "id": 3392,
              "areaName": "中正区",
              "areaCode": null,
              "areaShort": "Zhongzheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710302,
              "id": 3393,
              "areaName": "七堵区",
              "areaCode": null,
              "areaShort": "Qidu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710303,
              "id": 3394,
              "areaName": "暖暖区",
              "areaCode": null,
              "areaShort": "Nuannuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710304,
              "id": 3395,
              "areaName": "仁爱区",
              "areaCode": null,
              "areaShort": "Renai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710305,
              "id": 3396,
              "areaName": "中山区",
              "areaCode": null,
              "areaShort": "Zhongshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710306,
              "id": 3397,
              "areaName": "安乐区",
              "areaCode": null,
              "areaShort": "Anle",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710307,
              "id": 3398,
              "areaName": "信义区",
              "areaCode": null,
              "areaShort": "Xinyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710400,
          "id": 3399,
          "areaName": "台中市",
          "areaCode": null,
          "areaShort": "Taichung",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710401,
              "id": 3400,
              "areaName": "中区",
              "areaCode": null,
              "areaShort": "Zhongqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710402,
              "id": 3401,
              "areaName": "东区",
              "areaCode": null,
              "areaShort": "Dongqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710403,
              "id": 3402,
              "areaName": "南区",
              "areaCode": null,
              "areaShort": "Nanqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710404,
              "id": 3403,
              "areaName": "西区",
              "areaCode": null,
              "areaShort": "Xiqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710405,
              "id": 3404,
              "areaName": "北区",
              "areaCode": null,
              "areaShort": "Beiqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710406,
              "id": 3405,
              "areaName": "西屯区",
              "areaCode": null,
              "areaShort": "Xitun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710407,
              "id": 3406,
              "areaName": "南屯区",
              "areaCode": null,
              "areaShort": "Nantun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710408,
              "id": 3407,
              "areaName": "北屯区",
              "areaCode": null,
              "areaShort": "Beitun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710409,
              "id": 3408,
              "areaName": "丰原区",
              "areaCode": null,
              "areaShort": "Fengyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710410,
              "id": 3409,
              "areaName": "东势区",
              "areaCode": null,
              "areaShort": "Dongshi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710411,
              "id": 3410,
              "areaName": "大甲区",
              "areaCode": null,
              "areaShort": "Dajia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710412,
              "id": 3411,
              "areaName": "清水区",
              "areaCode": null,
              "areaShort": "Qingshui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710413,
              "id": 3412,
              "areaName": "沙鹿区",
              "areaCode": null,
              "areaShort": "Shalu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710414,
              "id": 3413,
              "areaName": "梧栖区",
              "areaCode": null,
              "areaShort": "Wuqi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710415,
              "id": 3414,
              "areaName": "后里区",
              "areaCode": null,
              "areaShort": "Houli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710416,
              "id": 3415,
              "areaName": "神冈区",
              "areaCode": null,
              "areaShort": "Shengang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710417,
              "id": 3416,
              "areaName": "潭子区",
              "areaCode": null,
              "areaShort": "Tanzi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710418,
              "id": 3417,
              "areaName": "大雅区",
              "areaCode": null,
              "areaShort": "Daya",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710419,
              "id": 3418,
              "areaName": "新社区",
              "areaCode": null,
              "areaShort": "Xinshe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710420,
              "id": 3419,
              "areaName": "石冈区",
              "areaCode": null,
              "areaShort": "Shigang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710421,
              "id": 3420,
              "areaName": "外埔区",
              "areaCode": null,
              "areaShort": "Waipu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710422,
              "id": 3421,
              "areaName": "大安区",
              "areaCode": null,
              "areaShort": "Da'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710423,
              "id": 3422,
              "areaName": "乌日区",
              "areaCode": null,
              "areaShort": "Wuri",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710424,
              "id": 3423,
              "areaName": "大肚区",
              "areaCode": null,
              "areaShort": "Dadu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710425,
              "id": 3424,
              "areaName": "龙井区",
              "areaCode": null,
              "areaShort": "Longjing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710426,
              "id": 3425,
              "areaName": "雾峰区",
              "areaCode": null,
              "areaShort": "Wufeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710427,
              "id": 3426,
              "areaName": "太平区",
              "areaCode": null,
              "areaShort": "Taiping",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710428,
              "id": 3427,
              "areaName": "大里区",
              "areaCode": null,
              "areaShort": "Dali",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710429,
              "id": 3428,
              "areaName": "和平区",
              "areaCode": null,
              "areaShort": "Heping",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710500,
          "id": 3429,
          "areaName": "台南市",
          "areaCode": null,
          "areaShort": "Tainan",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710501,
              "id": 3430,
              "areaName": "东区",
              "areaCode": null,
              "areaShort": "Dongqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710502,
              "id": 3431,
              "areaName": "南区",
              "areaCode": null,
              "areaShort": "Nanqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710504,
              "id": 3432,
              "areaName": "北区",
              "areaCode": null,
              "areaShort": "Beiqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710506,
              "id": 3433,
              "areaName": "安南区",
              "areaCode": null,
              "areaShort": "Annan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710507,
              "id": 3434,
              "areaName": "安平区",
              "areaCode": null,
              "areaShort": "Anping",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710508,
              "id": 3435,
              "areaName": "中西区",
              "areaCode": null,
              "areaShort": "Zhongxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710509,
              "id": 3436,
              "areaName": "新营区",
              "areaCode": null,
              "areaShort": "Xinying",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710510,
              "id": 3437,
              "areaName": "盐水区",
              "areaCode": null,
              "areaShort": "Yanshui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710511,
              "id": 3438,
              "areaName": "白河区",
              "areaCode": null,
              "areaShort": "Baihe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710512,
              "id": 3439,
              "areaName": "柳营区",
              "areaCode": null,
              "areaShort": "Liuying",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710513,
              "id": 3440,
              "areaName": "后壁区",
              "areaCode": null,
              "areaShort": "Houbi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710514,
              "id": 3441,
              "areaName": "东山区",
              "areaCode": null,
              "areaShort": "Dongshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710515,
              "id": 3442,
              "areaName": "麻豆区",
              "areaCode": null,
              "areaShort": "Madou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710516,
              "id": 3443,
              "areaName": "下营区",
              "areaCode": null,
              "areaShort": "Xiaying",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710517,
              "id": 3444,
              "areaName": "六甲区",
              "areaCode": null,
              "areaShort": "Liujia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710518,
              "id": 3445,
              "areaName": "官田区",
              "areaCode": null,
              "areaShort": "Guantian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710519,
              "id": 3446,
              "areaName": "大内区",
              "areaCode": null,
              "areaShort": "Dana",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710520,
              "id": 3447,
              "areaName": "佳里区",
              "areaCode": null,
              "areaShort": "Jiali",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710521,
              "id": 3448,
              "areaName": "学甲区",
              "areaCode": null,
              "areaShort": "Xuejia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710522,
              "id": 3449,
              "areaName": "西港区",
              "areaCode": null,
              "areaShort": "Xigang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710523,
              "id": 3450,
              "areaName": "七股区",
              "areaCode": null,
              "areaShort": "Qigu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710524,
              "id": 3451,
              "areaName": "将军区",
              "areaCode": null,
              "areaShort": "Jiangjun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710525,
              "id": 3452,
              "areaName": "北门区",
              "areaCode": null,
              "areaShort": "Beimen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710526,
              "id": 3453,
              "areaName": "新化区",
              "areaCode": null,
              "areaShort": "Xinhua",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710527,
              "id": 3454,
              "areaName": "善化区",
              "areaCode": null,
              "areaShort": "Shanhua",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710528,
              "id": 3455,
              "areaName": "新市区",
              "areaCode": null,
              "areaShort": "Xinshi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710529,
              "id": 3456,
              "areaName": "安定区",
              "areaCode": null,
              "areaShort": "Anding",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710530,
              "id": 3457,
              "areaName": "山上区",
              "areaCode": null,
              "areaShort": "Shanshang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710531,
              "id": 3458,
              "areaName": "玉井区",
              "areaCode": null,
              "areaShort": "Yujing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710532,
              "id": 3459,
              "areaName": "楠西区",
              "areaCode": null,
              "areaShort": "Nanxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710533,
              "id": 3460,
              "areaName": "南化区",
              "areaCode": null,
              "areaShort": "Nanhua",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710534,
              "id": 3461,
              "areaName": "左镇区",
              "areaCode": null,
              "areaShort": "Zuozhen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710535,
              "id": 3462,
              "areaName": "仁德区",
              "areaCode": null,
              "areaShort": "Rende",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710536,
              "id": 3463,
              "areaName": "归仁区",
              "areaCode": null,
              "areaShort": "Guiren",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710537,
              "id": 3464,
              "areaName": "关庙区",
              "areaCode": null,
              "areaShort": "Guanmiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710538,
              "id": 3465,
              "areaName": "龙崎区",
              "areaCode": null,
              "areaShort": "Longqi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710539,
              "id": 3466,
              "areaName": "永康区",
              "areaCode": null,
              "areaShort": "Yongkang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710600,
          "id": 3467,
          "areaName": "新竹市",
          "areaCode": null,
          "areaShort": "Hsinchu",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710601,
              "id": 3468,
              "areaName": "东区",
              "areaCode": null,
              "areaShort": "Dongqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710602,
              "id": 3469,
              "areaName": "北区",
              "areaCode": null,
              "areaShort": "Beiqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710603,
              "id": 3470,
              "areaName": "香山区",
              "areaCode": null,
              "areaShort": "Xiangshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710700,
          "id": 3471,
          "areaName": "嘉义市",
          "areaCode": null,
          "areaShort": "Chiayi",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710701,
              "id": 3472,
              "areaName": "东区",
              "areaCode": null,
              "areaShort": "Dongqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710702,
              "id": 3473,
              "areaName": "西区",
              "areaCode": null,
              "areaShort": "Xiqu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 710800,
          "id": 3474,
          "areaName": "新北市",
          "areaCode": null,
          "areaShort": "New Taipei",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 710801,
              "id": 3475,
              "areaName": "板桥区",
              "areaCode": null,
              "areaShort": "Banqiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710802,
              "id": 3476,
              "areaName": "三重区",
              "areaCode": null,
              "areaShort": "Sanzhong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710803,
              "id": 3477,
              "areaName": "中和区",
              "areaCode": null,
              "areaShort": "Zhonghe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710804,
              "id": 3478,
              "areaName": "永和区",
              "areaCode": null,
              "areaShort": "Yonghe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710805,
              "id": 3479,
              "areaName": "新庄区",
              "areaCode": null,
              "areaShort": "Xinzhuang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710806,
              "id": 3480,
              "areaName": "新店区",
              "areaCode": null,
              "areaShort": "Xindian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710807,
              "id": 3481,
              "areaName": "树林区",
              "areaCode": null,
              "areaShort": "Shulin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710808,
              "id": 3482,
              "areaName": "莺歌区",
              "areaCode": null,
              "areaShort": "Yingge",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710809,
              "id": 3483,
              "areaName": "三峡区",
              "areaCode": null,
              "areaShort": "Sanxia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710810,
              "id": 3484,
              "areaName": "淡水区",
              "areaCode": null,
              "areaShort": "Danshui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710811,
              "id": 3485,
              "areaName": "汐止区",
              "areaCode": null,
              "areaShort": "Xizhi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710812,
              "id": 3486,
              "areaName": "瑞芳区",
              "areaCode": null,
              "areaShort": "Ruifang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710813,
              "id": 3487,
              "areaName": "土城区",
              "areaCode": null,
              "areaShort": "Tucheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710814,
              "id": 3488,
              "areaName": "芦洲区",
              "areaCode": null,
              "areaShort": "Luzhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710815,
              "id": 3489,
              "areaName": "五股区",
              "areaCode": null,
              "areaShort": "Wugu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710816,
              "id": 3490,
              "areaName": "泰山区",
              "areaCode": null,
              "areaShort": "Taishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710817,
              "id": 3491,
              "areaName": "林口区",
              "areaCode": null,
              "areaShort": "Linkou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710818,
              "id": 3492,
              "areaName": "深坑区",
              "areaCode": null,
              "areaShort": "Shenkeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710819,
              "id": 3493,
              "areaName": "石碇区",
              "areaCode": null,
              "areaShort": "Shiding",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710820,
              "id": 3494,
              "areaName": "坪林区",
              "areaCode": null,
              "areaShort": "Pinglin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710821,
              "id": 3495,
              "areaName": "三芝区",
              "areaCode": null,
              "areaShort": "Sanzhi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710822,
              "id": 3496,
              "areaName": "石门区",
              "areaCode": null,
              "areaShort": "Shimen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710823,
              "id": 3497,
              "areaName": "八里区",
              "areaCode": null,
              "areaShort": "Bali",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710824,
              "id": 3498,
              "areaName": "平溪区",
              "areaCode": null,
              "areaShort": "Pingxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710825,
              "id": 3499,
              "areaName": "双溪区",
              "areaCode": null,
              "areaShort": "Shuangxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710826,
              "id": 3500,
              "areaName": "贡寮区",
              "areaCode": null,
              "areaShort": "Gongliao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710827,
              "id": 3501,
              "areaName": "金山区",
              "areaCode": null,
              "areaShort": "Jinshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710828,
              "id": 3502,
              "areaName": "万里区",
              "areaCode": null,
              "areaShort": "Wanli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 710829,
              "id": 3503,
              "areaName": "乌来区",
              "areaCode": null,
              "areaShort": "Wulai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 710800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712200,
          "id": 3504,
          "areaName": "宜兰县",
          "areaCode": null,
          "areaShort": "Yilan",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712201,
              "id": 3505,
              "areaName": "宜兰市",
              "areaCode": null,
              "areaShort": "Yilan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712221,
              "id": 3506,
              "areaName": "罗东镇",
              "areaCode": null,
              "areaShort": "Luodong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712222,
              "id": 3507,
              "areaName": "苏澳镇",
              "areaCode": null,
              "areaShort": "Suao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712223,
              "id": 3508,
              "areaName": "头城镇",
              "areaCode": null,
              "areaShort": "Toucheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712224,
              "id": 3509,
              "areaName": "礁溪乡",
              "areaCode": null,
              "areaShort": "Jiaoxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712225,
              "id": 3510,
              "areaName": "壮围乡",
              "areaCode": null,
              "areaShort": "Zhuangwei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712226,
              "id": 3511,
              "areaName": "员山乡",
              "areaCode": null,
              "areaShort": "Yuanshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712227,
              "id": 3512,
              "areaName": "冬山乡",
              "areaCode": null,
              "areaShort": "Dongshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712228,
              "id": 3513,
              "areaName": "五结乡",
              "areaCode": null,
              "areaShort": "Wujie",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712229,
              "id": 3514,
              "areaName": "三星乡",
              "areaCode": null,
              "areaShort": "Sanxing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712230,
              "id": 3515,
              "areaName": "大同乡",
              "areaCode": null,
              "areaShort": "Datong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712231,
              "id": 3516,
              "areaName": "南澳乡",
              "areaCode": null,
              "areaShort": "Nanao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712300,
          "id": 3517,
          "areaName": "桃园县",
          "areaCode": null,
          "areaShort": "Taoyuan",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712301,
              "id": 3518,
              "areaName": "桃园市",
              "areaCode": null,
              "areaShort": "Taoyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712302,
              "id": 3519,
              "areaName": "中坜市",
              "areaCode": null,
              "areaShort": "Zhongli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712303,
              "id": 3520,
              "areaName": "平镇市",
              "areaCode": null,
              "areaShort": "Pingzhen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712304,
              "id": 3521,
              "areaName": "八德市",
              "areaCode": null,
              "areaShort": "Bade",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712305,
              "id": 3522,
              "areaName": "杨梅市",
              "areaCode": null,
              "areaShort": "Yangmei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712306,
              "id": 3523,
              "areaName": "芦竹市",
              "areaCode": null,
              "areaShort": "Luzhu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712321,
              "id": 3524,
              "areaName": "大溪镇",
              "areaCode": null,
              "areaShort": "Daxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712324,
              "id": 3525,
              "areaName": "大园乡",
              "areaCode": null,
              "areaShort": "Dayuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712325,
              "id": 3526,
              "areaName": "龟山乡",
              "areaCode": null,
              "areaShort": "Guishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712327,
              "id": 3527,
              "areaName": "龙潭乡",
              "areaCode": null,
              "areaShort": "Longtan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712329,
              "id": 3528,
              "areaName": "新屋乡",
              "areaCode": null,
              "areaShort": "Xinwu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712330,
              "id": 3529,
              "areaName": "观音乡",
              "areaCode": null,
              "areaShort": "Guanyin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712331,
              "id": 3530,
              "areaName": "复兴乡",
              "areaCode": null,
              "areaShort": "Fuxing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712400,
          "id": 3531,
          "areaName": "新竹县",
          "areaCode": null,
          "areaShort": "Hsinchu",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712401,
              "id": 3532,
              "areaName": "竹北市",
              "areaCode": null,
              "areaShort": "Zhubei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712421,
              "id": 3533,
              "areaName": "竹东镇",
              "areaCode": null,
              "areaShort": "Zhudong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712422,
              "id": 3534,
              "areaName": "新埔镇",
              "areaCode": null,
              "areaShort": "Xinpu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712423,
              "id": 3535,
              "areaName": "关西镇",
              "areaCode": null,
              "areaShort": "Guanxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712424,
              "id": 3536,
              "areaName": "湖口乡",
              "areaCode": null,
              "areaShort": "Hukou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712425,
              "id": 3537,
              "areaName": "新丰乡",
              "areaCode": null,
              "areaShort": "Xinfeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712426,
              "id": 3538,
              "areaName": "芎林乡",
              "areaCode": null,
              "areaShort": "Xionglin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712427,
              "id": 3539,
              "areaName": "横山乡",
              "areaCode": null,
              "areaShort": "Hengshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712428,
              "id": 3540,
              "areaName": "北埔乡",
              "areaCode": null,
              "areaShort": "Beipu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712429,
              "id": 3541,
              "areaName": "宝山乡",
              "areaCode": null,
              "areaShort": "Baoshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712430,
              "id": 3542,
              "areaName": "峨眉乡",
              "areaCode": null,
              "areaShort": "Emei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712431,
              "id": 3543,
              "areaName": "尖石乡",
              "areaCode": null,
              "areaShort": "Jianshi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712432,
              "id": 3544,
              "areaName": "五峰乡",
              "areaCode": null,
              "areaShort": "Wufeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712500,
          "id": 3545,
          "areaName": "苗栗县",
          "areaCode": null,
          "areaShort": "Miaoli",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712501,
              "id": 3546,
              "areaName": "苗栗市",
              "areaCode": null,
              "areaShort": "Miaoli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712521,
              "id": 3547,
              "areaName": "苑里镇",
              "areaCode": null,
              "areaShort": "Yuanli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712522,
              "id": 3548,
              "areaName": "通霄镇",
              "areaCode": null,
              "areaShort": "Tongxiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712523,
              "id": 3549,
              "areaName": "竹南镇",
              "areaCode": null,
              "areaShort": "Zhunan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712524,
              "id": 3550,
              "areaName": "头份镇",
              "areaCode": null,
              "areaShort": "Toufen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712525,
              "id": 3551,
              "areaName": "后龙镇",
              "areaCode": null,
              "areaShort": "Houlong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712526,
              "id": 3552,
              "areaName": "卓兰镇",
              "areaCode": null,
              "areaShort": "Zhuolan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712527,
              "id": 3553,
              "areaName": "大湖乡",
              "areaCode": null,
              "areaShort": "Dahu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712528,
              "id": 3554,
              "areaName": "公馆乡",
              "areaCode": null,
              "areaShort": "Gongguan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712529,
              "id": 3555,
              "areaName": "铜锣乡",
              "areaCode": null,
              "areaShort": "Tongluo",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712530,
              "id": 3556,
              "areaName": "南庄乡",
              "areaCode": null,
              "areaShort": "Nanzhuang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712531,
              "id": 3557,
              "areaName": "头屋乡",
              "areaCode": null,
              "areaShort": "Touwu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712532,
              "id": 3558,
              "areaName": "三义乡",
              "areaCode": null,
              "areaShort": "Sanyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712533,
              "id": 3559,
              "areaName": "西湖乡",
              "areaCode": null,
              "areaShort": "Xihu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712534,
              "id": 3560,
              "areaName": "造桥乡",
              "areaCode": null,
              "areaShort": "Zaoqiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712535,
              "id": 3561,
              "areaName": "三湾乡",
              "areaCode": null,
              "areaShort": "Sanwan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712536,
              "id": 3562,
              "areaName": "狮潭乡",
              "areaCode": null,
              "areaShort": "Shitan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712537,
              "id": 3563,
              "areaName": "泰安乡",
              "areaCode": null,
              "areaShort": "Tai'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712700,
          "id": 3564,
          "areaName": "彰化县",
          "areaCode": null,
          "areaShort": "Changhua",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712701,
              "id": 3565,
              "areaName": "彰化市",
              "areaCode": null,
              "areaShort": "Zhanghuashi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712721,
              "id": 3566,
              "areaName": "鹿港镇",
              "areaCode": null,
              "areaShort": "Lugang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712722,
              "id": 3567,
              "areaName": "和美镇",
              "areaCode": null,
              "areaShort": "Hemei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712723,
              "id": 3568,
              "areaName": "线西乡",
              "areaCode": null,
              "areaShort": "Xianxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712724,
              "id": 3569,
              "areaName": "伸港乡",
              "areaCode": null,
              "areaShort": "Shengang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712725,
              "id": 3570,
              "areaName": "福兴乡",
              "areaCode": null,
              "areaShort": "Fuxing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712726,
              "id": 3571,
              "areaName": "秀水乡",
              "areaCode": null,
              "areaShort": "Xiushui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712727,
              "id": 3572,
              "areaName": "花坛乡",
              "areaCode": null,
              "areaShort": "Huatan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712728,
              "id": 3573,
              "areaName": "芬园乡",
              "areaCode": null,
              "areaShort": "Fenyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712729,
              "id": 3574,
              "areaName": "员林镇",
              "areaCode": null,
              "areaShort": "Yuanlin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712730,
              "id": 3575,
              "areaName": "溪湖镇",
              "areaCode": null,
              "areaShort": "Xihu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712731,
              "id": 3576,
              "areaName": "田中镇",
              "areaCode": null,
              "areaShort": "Tianzhong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712732,
              "id": 3577,
              "areaName": "大村乡",
              "areaCode": null,
              "areaShort": "Dacun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712733,
              "id": 3578,
              "areaName": "埔盐乡",
              "areaCode": null,
              "areaShort": "Puyan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712734,
              "id": 3579,
              "areaName": "埔心乡",
              "areaCode": null,
              "areaShort": "Puxin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712735,
              "id": 3580,
              "areaName": "永靖乡",
              "areaCode": null,
              "areaShort": "Yongjing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712736,
              "id": 3581,
              "areaName": "社头乡",
              "areaCode": null,
              "areaShort": "Shetou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712737,
              "id": 3582,
              "areaName": "二水乡",
              "areaCode": null,
              "areaShort": "Ershui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712738,
              "id": 3583,
              "areaName": "北斗镇",
              "areaCode": null,
              "areaShort": "Beidou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712739,
              "id": 3584,
              "areaName": "二林镇",
              "areaCode": null,
              "areaShort": "Erlin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712740,
              "id": 3585,
              "areaName": "田尾乡",
              "areaCode": null,
              "areaShort": "Tianwei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712741,
              "id": 3586,
              "areaName": "埤头乡",
              "areaCode": null,
              "areaShort": "Pitou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712742,
              "id": 3587,
              "areaName": "芳苑乡",
              "areaCode": null,
              "areaShort": "Fangyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712743,
              "id": 3588,
              "areaName": "大城乡",
              "areaCode": null,
              "areaShort": "Dacheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712744,
              "id": 3589,
              "areaName": "竹塘乡",
              "areaCode": null,
              "areaShort": "Zhutang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712745,
              "id": 3590,
              "areaName": "溪州乡",
              "areaCode": null,
              "areaShort": "Xizhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712800,
          "id": 3591,
          "areaName": "南投县",
          "areaCode": null,
          "areaShort": "Nantou",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712801,
              "id": 3592,
              "areaName": "南投市",
              "areaCode": null,
              "areaShort": "Nantoushi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712821,
              "id": 3593,
              "areaName": "埔里镇",
              "areaCode": null,
              "areaShort": "Puli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712822,
              "id": 3594,
              "areaName": "草屯镇",
              "areaCode": null,
              "areaShort": "Caotun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712823,
              "id": 3595,
              "areaName": "竹山镇",
              "areaCode": null,
              "areaShort": "Zhushan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712824,
              "id": 3596,
              "areaName": "集集镇",
              "areaCode": null,
              "areaShort": "Jiji",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712825,
              "id": 3597,
              "areaName": "名间乡",
              "areaCode": null,
              "areaShort": "Mingjian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712826,
              "id": 3598,
              "areaName": "鹿谷乡",
              "areaCode": null,
              "areaShort": "Lugu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712827,
              "id": 3599,
              "areaName": "中寮乡",
              "areaCode": null,
              "areaShort": "Zhongliao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712828,
              "id": 3600,
              "areaName": "鱼池乡",
              "areaCode": null,
              "areaShort": "Yuchi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712829,
              "id": 3601,
              "areaName": "国姓乡",
              "areaCode": null,
              "areaShort": "Guoxing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712830,
              "id": 3602,
              "areaName": "水里乡",
              "areaCode": null,
              "areaShort": "Shuili",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712831,
              "id": 3603,
              "areaName": "信义乡",
              "areaCode": null,
              "areaShort": "Xinyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712832,
              "id": 3604,
              "areaName": "仁爱乡",
              "areaCode": null,
              "areaShort": "Renai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712800,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 712900,
          "id": 3605,
          "areaName": "云林县",
          "areaCode": null,
          "areaShort": "Yunlin",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 712901,
              "id": 3606,
              "areaName": "斗六市",
              "areaCode": null,
              "areaShort": "Douliu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712921,
              "id": 3607,
              "areaName": "斗南镇",
              "areaCode": null,
              "areaShort": "Dounan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712922,
              "id": 3608,
              "areaName": "虎尾镇",
              "areaCode": null,
              "areaShort": "Huwei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712923,
              "id": 3609,
              "areaName": "西螺镇",
              "areaCode": null,
              "areaShort": "Xiluo",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712924,
              "id": 3610,
              "areaName": "土库镇",
              "areaCode": null,
              "areaShort": "Tuku",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712925,
              "id": 3611,
              "areaName": "北港镇",
              "areaCode": null,
              "areaShort": "Beigang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712926,
              "id": 3612,
              "areaName": "古坑乡",
              "areaCode": null,
              "areaShort": "Gukeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712927,
              "id": 3613,
              "areaName": "大埤乡",
              "areaCode": null,
              "areaShort": "Dapi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712928,
              "id": 3614,
              "areaName": "莿桐乡",
              "areaCode": null,
              "areaShort": "Citong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712929,
              "id": 3615,
              "areaName": "林内乡",
              "areaCode": null,
              "areaShort": "Linna",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712930,
              "id": 3616,
              "areaName": "二仑乡",
              "areaCode": null,
              "areaShort": "Erlun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712931,
              "id": 3617,
              "areaName": "仑背乡",
              "areaCode": null,
              "areaShort": "Lunbei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712932,
              "id": 3618,
              "areaName": "麦寮乡",
              "areaCode": null,
              "areaShort": "Mailiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712933,
              "id": 3619,
              "areaName": "东势乡",
              "areaCode": null,
              "areaShort": "Dongshi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712934,
              "id": 3620,
              "areaName": "褒忠乡",
              "areaCode": null,
              "areaShort": "Baozhong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712935,
              "id": 3621,
              "areaName": "台西乡",
              "areaCode": null,
              "areaShort": "Taixi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712936,
              "id": 3622,
              "areaName": "元长乡",
              "areaCode": null,
              "areaShort": "Yuanchang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712937,
              "id": 3623,
              "areaName": "四湖乡",
              "areaCode": null,
              "areaShort": "Sihu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712938,
              "id": 3624,
              "areaName": "口湖乡",
              "areaCode": null,
              "areaShort": "Kouhu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 712939,
              "id": 3625,
              "areaName": "水林乡",
              "areaCode": null,
              "areaShort": "Shuilin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 712900,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713000,
          "id": 3626,
          "areaName": "嘉义县",
          "areaCode": null,
          "areaShort": "Chiayi",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713001,
              "id": 3627,
              "areaName": "太保市",
              "areaCode": null,
              "areaShort": "Taibao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713002,
              "id": 3628,
              "areaName": "朴子市",
              "areaCode": null,
              "areaShort": "Puzi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713023,
              "id": 3629,
              "areaName": "布袋镇",
              "areaCode": null,
              "areaShort": "Budai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713024,
              "id": 3630,
              "areaName": "大林镇",
              "areaCode": null,
              "areaShort": "Dalin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713025,
              "id": 3631,
              "areaName": "民雄乡",
              "areaCode": null,
              "areaShort": "Minxiong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713026,
              "id": 3632,
              "areaName": "溪口乡",
              "areaCode": null,
              "areaShort": "Xikou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713027,
              "id": 3633,
              "areaName": "新港乡",
              "areaCode": null,
              "areaShort": "Xingang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713028,
              "id": 3634,
              "areaName": "六脚乡",
              "areaCode": null,
              "areaShort": "Liujiao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713029,
              "id": 3635,
              "areaName": "东石乡",
              "areaCode": null,
              "areaShort": "Dongshi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713030,
              "id": 3636,
              "areaName": "义竹乡",
              "areaCode": null,
              "areaShort": "Yizhu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713031,
              "id": 3637,
              "areaName": "鹿草乡",
              "areaCode": null,
              "areaShort": "Lucao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713032,
              "id": 3638,
              "areaName": "水上乡",
              "areaCode": null,
              "areaShort": "Shuishang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713033,
              "id": 3639,
              "areaName": "中埔乡",
              "areaCode": null,
              "areaShort": "Zhongpu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713034,
              "id": 3640,
              "areaName": "竹崎乡",
              "areaCode": null,
              "areaShort": "Zhuqi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713035,
              "id": 3641,
              "areaName": "梅山乡",
              "areaCode": null,
              "areaShort": "Meishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713036,
              "id": 3642,
              "areaName": "番路乡",
              "areaCode": null,
              "areaShort": "Fanlu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713037,
              "id": 3643,
              "areaName": "大埔乡",
              "areaCode": null,
              "areaShort": "Dapu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713038,
              "id": 3644,
              "areaName": "阿里山乡",
              "areaCode": null,
              "areaShort": "Alishan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713000,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713300,
          "id": 3645,
          "areaName": "屏东县",
          "areaCode": null,
          "areaShort": "Pingtung",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713301,
              "id": 3646,
              "areaName": "屏东市",
              "areaCode": null,
              "areaShort": "Pingdong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713321,
              "id": 3647,
              "areaName": "潮州镇",
              "areaCode": null,
              "areaShort": "Chaozhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713322,
              "id": 3648,
              "areaName": "东港镇",
              "areaCode": null,
              "areaShort": "Donggang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713323,
              "id": 3649,
              "areaName": "恒春镇",
              "areaCode": null,
              "areaShort": "Hengchun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713324,
              "id": 3650,
              "areaName": "万丹乡",
              "areaCode": null,
              "areaShort": "Wandan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713325,
              "id": 3651,
              "areaName": "长治乡",
              "areaCode": null,
              "areaShort": "Changzhi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713326,
              "id": 3652,
              "areaName": "麟洛乡",
              "areaCode": null,
              "areaShort": "Linluo",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713327,
              "id": 3653,
              "areaName": "九如乡",
              "areaCode": null,
              "areaShort": "Jiuru",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713328,
              "id": 3654,
              "areaName": "里港乡",
              "areaCode": null,
              "areaShort": "Ligang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713329,
              "id": 3655,
              "areaName": "盐埔乡",
              "areaCode": null,
              "areaShort": "Yanpu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713330,
              "id": 3656,
              "areaName": "高树乡",
              "areaCode": null,
              "areaShort": "Gaoshu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713331,
              "id": 3657,
              "areaName": "万峦乡",
              "areaCode": null,
              "areaShort": "Wanluan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713332,
              "id": 3658,
              "areaName": "内埔乡",
              "areaCode": null,
              "areaShort": "Napu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713333,
              "id": 3659,
              "areaName": "竹田乡",
              "areaCode": null,
              "areaShort": "Zhutian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713334,
              "id": 3660,
              "areaName": "新埤乡",
              "areaCode": null,
              "areaShort": "Xinpi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713335,
              "id": 3661,
              "areaName": "枋寮乡",
              "areaCode": null,
              "areaShort": "Fangliao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713336,
              "id": 3662,
              "areaName": "新园乡",
              "areaCode": null,
              "areaShort": "Xinyuan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713337,
              "id": 3663,
              "areaName": "崁顶乡",
              "areaCode": null,
              "areaShort": "Kanding",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713338,
              "id": 3664,
              "areaName": "林边乡",
              "areaCode": null,
              "areaShort": "Linbian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713339,
              "id": 3665,
              "areaName": "南州乡",
              "areaCode": null,
              "areaShort": "Nanzhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713340,
              "id": 3666,
              "areaName": "佳冬乡",
              "areaCode": null,
              "areaShort": "Jiadong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713341,
              "id": 3667,
              "areaName": "琉球乡",
              "areaCode": null,
              "areaShort": "Liuqiu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713342,
              "id": 3668,
              "areaName": "车城乡",
              "areaCode": null,
              "areaShort": "Checheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713343,
              "id": 3669,
              "areaName": "满州乡",
              "areaCode": null,
              "areaShort": "Manzhou",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713344,
              "id": 3670,
              "areaName": "枋山乡",
              "areaCode": null,
              "areaShort": "Fangshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713345,
              "id": 3671,
              "areaName": "三地门乡",
              "areaCode": null,
              "areaShort": "Sandimen",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713346,
              "id": 3672,
              "areaName": "雾台乡",
              "areaCode": null,
              "areaShort": "Wutai",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713347,
              "id": 3673,
              "areaName": "玛家乡",
              "areaCode": null,
              "areaShort": "Majia",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713348,
              "id": 3674,
              "areaName": "泰武乡",
              "areaCode": null,
              "areaShort": "Taiwu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713349,
              "id": 3675,
              "areaName": "来义乡",
              "areaCode": null,
              "areaShort": "Laiyi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713350,
              "id": 3676,
              "areaName": "春日乡",
              "areaCode": null,
              "areaShort": "Chunri",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713351,
              "id": 3677,
              "areaName": "狮子乡",
              "areaCode": null,
              "areaShort": "Shizi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713352,
              "id": 3678,
              "areaName": "牡丹乡",
              "areaCode": null,
              "areaShort": "Mudan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713300,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713400,
          "id": 3679,
          "areaName": "台东县",
          "areaCode": null,
          "areaShort": "Taitung",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713401,
              "id": 3680,
              "areaName": "台东市",
              "areaCode": null,
              "areaShort": "Taidong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713421,
              "id": 3681,
              "areaName": "成功镇",
              "areaCode": null,
              "areaShort": "Chenggong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713422,
              "id": 3682,
              "areaName": "关山镇",
              "areaCode": null,
              "areaShort": "Guanshan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713423,
              "id": 3683,
              "areaName": "卑南乡",
              "areaCode": null,
              "areaShort": "Beinan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713424,
              "id": 3684,
              "areaName": "鹿野乡",
              "areaCode": null,
              "areaShort": "Luye",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713425,
              "id": 3685,
              "areaName": "池上乡",
              "areaCode": null,
              "areaShort": "Chishang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713426,
              "id": 3686,
              "areaName": "东河乡",
              "areaCode": null,
              "areaShort": "Donghe",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713427,
              "id": 3687,
              "areaName": "长滨乡",
              "areaCode": null,
              "areaShort": "Changbin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713428,
              "id": 3688,
              "areaName": "太麻里乡",
              "areaCode": null,
              "areaShort": "Taimali",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713429,
              "id": 3689,
              "areaName": "大武乡",
              "areaCode": null,
              "areaShort": "Dawu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713430,
              "id": 3690,
              "areaName": "绿岛乡",
              "areaCode": null,
              "areaShort": "Lvdao",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713431,
              "id": 3691,
              "areaName": "海端乡",
              "areaCode": null,
              "areaShort": "Haiduan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713432,
              "id": 3692,
              "areaName": "延平乡",
              "areaCode": null,
              "areaShort": "Yanping",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713433,
              "id": 3693,
              "areaName": "金峰乡",
              "areaCode": null,
              "areaShort": "Jinfeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713434,
              "id": 3694,
              "areaName": "达仁乡",
              "areaCode": null,
              "areaShort": "Daren",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713435,
              "id": 3695,
              "areaName": "兰屿乡",
              "areaCode": null,
              "areaShort": "Lanyu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713400,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713500,
          "id": 3696,
          "areaName": "花莲县",
          "areaCode": null,
          "areaShort": "Hualien",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713501,
              "id": 3697,
              "areaName": "花莲市",
              "areaCode": null,
              "areaShort": "Hualian",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713521,
              "id": 3698,
              "areaName": "凤林镇",
              "areaCode": null,
              "areaShort": "Fenglin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713522,
              "id": 3699,
              "areaName": "玉里镇",
              "areaCode": null,
              "areaShort": "Yuli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713523,
              "id": 3700,
              "areaName": "新城乡",
              "areaCode": null,
              "areaShort": "Xincheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713524,
              "id": 3701,
              "areaName": "吉安乡",
              "areaCode": null,
              "areaShort": "Ji'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713525,
              "id": 3702,
              "areaName": "寿丰乡",
              "areaCode": null,
              "areaShort": "Shoufeng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713526,
              "id": 3703,
              "areaName": "光复乡",
              "areaCode": null,
              "areaShort": "Guangfu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713527,
              "id": 3704,
              "areaName": "丰滨乡",
              "areaCode": null,
              "areaShort": "Fengbin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713528,
              "id": 3705,
              "areaName": "瑞穗乡",
              "areaCode": null,
              "areaShort": "Ruisui",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713529,
              "id": 3706,
              "areaName": "富里乡",
              "areaCode": null,
              "areaShort": "Fuli",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713530,
              "id": 3707,
              "areaName": "秀林乡",
              "areaCode": null,
              "areaShort": "Xiulin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713531,
              "id": 3708,
              "areaName": "万荣乡",
              "areaCode": null,
              "areaShort": "Wanrong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713532,
              "id": 3709,
              "areaName": "卓溪乡",
              "areaCode": null,
              "areaShort": "Zhuoxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713500,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713600,
          "id": 3710,
          "areaName": "澎湖县",
          "areaCode": null,
          "areaShort": "Penghu",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713601,
              "id": 3711,
              "areaName": "马公市",
              "areaCode": null,
              "areaShort": "Magong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713621,
              "id": 3712,
              "areaName": "湖西乡",
              "areaCode": null,
              "areaShort": "Huxi",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713622,
              "id": 3713,
              "areaName": "白沙乡",
              "areaCode": null,
              "areaShort": "Baisha",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713623,
              "id": 3714,
              "areaName": "西屿乡",
              "areaCode": null,
              "areaShort": "Xiyu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713624,
              "id": 3715,
              "areaName": "望安乡",
              "areaCode": null,
              "areaShort": "Wang'an",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713600,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713625,
              "id": 3716,
              "areaName": "七美乡",
              "areaCode": null,
              "areaShort": "Qimei",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713600,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713700,
          "id": 3717,
          "areaName": "金门县",
          "areaCode": null,
          "areaShort": "Jinmen",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713701,
              "id": 3718,
              "areaName": "金城镇",
              "areaCode": null,
              "areaShort": "Jincheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713702,
              "id": 3719,
              "areaName": "金湖镇",
              "areaCode": null,
              "areaShort": "Jinhu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713703,
              "id": 3720,
              "areaName": "金沙镇",
              "areaCode": null,
              "areaShort": "Jinsha",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713704,
              "id": 3721,
              "areaName": "金宁乡",
              "areaCode": null,
              "areaShort": "Jinning",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713705,
              "id": 3722,
              "areaName": "烈屿乡",
              "areaCode": null,
              "areaShort": "Lieyu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713700,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713706,
              "id": 3723,
              "areaName": "乌丘乡",
              "areaCode": null,
              "areaShort": "Wuqiu",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713700,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 713800,
          "id": 3724,
          "areaName": "连江县",
          "areaCode": null,
          "areaShort": "Lienchiang",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 710000,
          "areaDictionaryVOList": [
            {
              "areaId": 713801,
              "id": 3725,
              "areaName": "南竿乡",
              "areaCode": null,
              "areaShort": "Nangan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713802,
              "id": 3726,
              "areaName": "北竿乡",
              "areaCode": null,
              "areaShort": "Beigan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713803,
              "id": 3727,
              "areaName": "莒光乡",
              "areaCode": null,
              "areaShort": "Juguang",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713800,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 713804,
              "id": 3728,
              "areaName": "东引乡",
              "areaCode": null,
              "areaShort": "Dongyin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 713800,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 810000,
      "id": 3729,
      "areaName": "香港特别行政区",
      "areaCode": "1852",
      "areaShort": "Hong Kong",
      "areaInitials": null,
      "cityCode": "2912",
      "center": "114.171203,22.277468",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 810100,
          "id": 3730,
          "areaName": "香港岛",
          "areaCode": null,
          "areaShort": "Hong Kong Island",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 810000,
          "areaDictionaryVOList": [
            {
              "areaId": 810101,
              "id": 3731,
              "areaName": "中西区",
              "areaCode": null,
              "areaShort": "Central and Western District",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810102,
              "id": 3732,
              "areaName": "湾仔区",
              "areaCode": null,
              "areaShort": "Wan Chai District",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810103,
              "id": 3733,
              "areaName": "东区",
              "areaCode": null,
              "areaShort": "Eastern District",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810104,
              "id": 3734,
              "areaName": "南区",
              "areaCode": null,
              "areaShort": "Southern District",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 810200,
          "id": 3735,
          "areaName": "九龙",
          "areaCode": null,
          "areaShort": "Kowloon",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 810000,
          "areaDictionaryVOList": [
            {
              "areaId": 810201,
              "id": 3736,
              "areaName": "油尖旺区",
              "areaCode": null,
              "areaShort": "Yau Tsim Mong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810202,
              "id": 3737,
              "areaName": "深水埗区",
              "areaCode": null,
              "areaShort": "Sham Shui Po",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810203,
              "id": 3738,
              "areaName": "九龙城区",
              "areaCode": null,
              "areaShort": "Jiulongcheng",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810204,
              "id": 3739,
              "areaName": "黄大仙区",
              "areaCode": null,
              "areaShort": "Wong Tai Sin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810200,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810205,
              "id": 3740,
              "areaName": "观塘区",
              "areaCode": null,
              "areaShort": "Kwun Tong",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 810300,
          "id": 3741,
          "areaName": "新界",
          "areaCode": null,
          "areaShort": "New Territories",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 810000,
          "areaDictionaryVOList": [
            {
              "areaId": 810301,
              "id": 3742,
              "areaName": "荃湾区",
              "areaCode": null,
              "areaShort": "Tsuen Wan",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810302,
              "id": 3743,
              "areaName": "屯门区",
              "areaCode": null,
              "areaShort": "Tuen Mun",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810303,
              "id": 3744,
              "areaName": "元朗区",
              "areaCode": null,
              "areaShort": "Yuen Long",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810304,
              "id": 3745,
              "areaName": "北区",
              "areaCode": null,
              "areaShort": "North District",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810305,
              "id": 3746,
              "areaName": "大埔区",
              "areaCode": null,
              "areaShort": "Tai Po",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810306,
              "id": 3747,
              "areaName": "西贡区",
              "areaCode": null,
              "areaShort": "Sai Kung",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810307,
              "id": 3748,
              "areaName": "沙田区",
              "areaCode": null,
              "areaShort": "Sha Tin",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810308,
              "id": 3749,
              "areaName": "葵青区",
              "areaCode": null,
              "areaShort": "Kwai Tsing",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 810309,
              "id": 3750,
              "areaName": "离岛区",
              "areaCode": null,
              "areaShort": "Outlying Islands",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 810300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 820000,
      "id": 3751,
      "areaName": "澳门特别行政区",
      "areaCode": "1853",
      "areaShort": "Macau",
      "areaInitials": null,
      "cityCode": "2911",
      "center": "113.543028,22.186835",
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": [
        {
          "areaId": 820100,
          "id": 3752,
          "areaName": "澳门半岛",
          "areaCode": null,
          "areaShort": "MacauPeninsula",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 820000,
          "areaDictionaryVOList": [
            {
              "areaId": 820101,
              "id": 3753,
              "areaName": "花地玛堂区",
              "areaCode": null,
              "areaShort": "Nossa Senhora de Fatima",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 820102,
              "id": 3754,
              "areaName": "圣安多尼堂区",
              "areaCode": null,
              "areaShort": "Santo Antonio",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 820103,
              "id": 3755,
              "areaName": "大堂区",
              "areaCode": null,
              "areaShort": "Sé",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 820104,
              "id": 3756,
              "areaName": "望德堂区",
              "areaCode": null,
              "areaShort": "Sao Lazaro",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820100,
              "areaDictionaryVOList": null
            },
            {
              "areaId": 820105,
              "id": 3757,
              "areaName": "风顺堂区",
              "areaCode": null,
              "areaShort": "Sao Lourenco",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820100,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 820200,
          "id": 3758,
          "areaName": "氹仔岛",
          "areaCode": null,
          "areaShort": "Taipa",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 820000,
          "areaDictionaryVOList": [
            {
              "areaId": 820201,
              "id": 3759,
              "areaName": "嘉模堂区",
              "areaCode": null,
              "areaShort": "Our Lady Of Carmel's Parish",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820200,
              "areaDictionaryVOList": null
            }
          ]
        },
        {
          "areaId": 820300,
          "id": 3760,
          "areaName": "路环岛",
          "areaCode": null,
          "areaShort": "Coloane",
          "areaInitials": null,
          "cityCode": null,
          "center": null,
          "areaLevel": 2,
          "areaParentId": 820000,
          "areaDictionaryVOList": [
            {
              "areaId": 820301,
              "id": 3761,
              "areaName": "圣方济各堂区",
              "areaCode": null,
              "areaShort": "St Francis Xavier's Parish",
              "areaInitials": null,
              "cityCode": null,
              "center": null,
              "areaLevel": 3,
              "areaParentId": 820300,
              "areaDictionaryVOList": null
            }
          ]
        }
      ]
    },
    {
      "areaId": 900000,
      "id": 3762,
      "areaName": "钓鱼岛",
      "areaCode": null,
      "areaShort": "DiaoyuDao",
      "areaInitials": null,
      "cityCode": null,
      "center": null,
      "areaLevel": 1,
      "areaParentId": 0,
      "areaDictionaryVOList": []
    }
  ]
